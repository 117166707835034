/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import defaultAvatar from '../../assets/images/user-avatar.png';

const useStyles = makeStyles((theme) => ({
  chip: {},
  avatar: {
    marginRight: theme.spacing(1),
  },
  fontBold: {
    fontWeight: 'bold',
  },
}));

function TableRow({
  id,
  name,
  avatar,
  email,
  memberSince,
  location,
  isSubscriptionActive,
  product,
  subscriptionStatus,
  onClickMember,
}) {
  const classes = useStyles();

  const handleClick = () => () => {
    onClickMember(id);
  };

  return (
    <>
      <TableCell component="th" scope="row">
        <Tooltip title={`View/Edit ${name}'s profile`} placement="right">
          <Button
            color="primary"
            className={classes.button}
            onClick={handleClick(id)}>
            <Avatar
              alt={name}
              src={avatar || defaultAvatar}
              className={classes.avatar}
            />
            <span className={classes.fontBold}>{name}</span>
          </Button>
        </Tooltip>
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{memberSince}</TableCell>
      <TableCell>{location}</TableCell>
      <TableCell>
        <Chip
          variant="outlined"
          size="small"
          label={
            subscriptionStatus === 'active'
              ? 'Yes'
              : subscriptionStatus === 'trialing'
              ? 'Trial'
              : 'No'
          }
          className={classes.chip}
          color={isSubscriptionActive === true ? 'primary' : 'secondary'}
        />
      </TableCell>
      <TableCell>
        {product
          ? String(product).includes('month')
            ? 'Monthly'
            : 'Yearly'
          : '?'}
      </TableCell>
    </>
  );
}

export default TableRow;
