import { createSlice, createSelector } from "@reduxjs/toolkit";

/**
 * NOTE:
 * This reducer has been brought in with the messages feature that we are implementing.
 * Other reducers are still using older syntax (due to been implemented long time ago).
 * Would be nice to migrate them to the new syntax and have this one dialog state instead of dialog
 * inside each reducer.
 */

export const DIALOGS_FEATURE_KEY = "dialogs";

export const initialDialogsState = {
  list: {},
  params: null,
};

export const dialogsSlice = createSlice({
  name: DIALOGS_FEATURE_KEY,
  initialState: initialDialogsState,
  reducers: {
    setDialog(state, action) {
      state.list[action.payload.dialog] = action.payload.open;

      if (action.payload.params) {
        state.params = action.payload.params;
      }

      if (action.payload.open === false) {
        state.params = null;
      }
    },
  },
});

/*
 * Export reducer/ations for store configuration.
 */
export const { setDialog } = dialogsSlice.actions;

export const dialogsReducer = dialogsSlice.reducer;

/* Selectors */
const dialogsSelector = (state) => state.dialogs;

export const makeSelectIsDialogOpen = (dialog) =>
  createSelector([dialogsSelector], ({ list }) => {
    if (typeof list[dialog] === "boolean") return list[dialog];

    return false;
  });

export const makeSelectDialogParams = () =>
  createSelector([dialogsSelector], ({ params }) => params);
