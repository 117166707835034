import { createSelector } from 'reselect';

// selectors
const userDetailsSelector = (state) => {
  return state.user.details;
};
export const makeSelectUserDetails = () =>
  createSelector([userDetailsSelector], (userDetails) => userDetails);

export const makeSelectUserRole = () =>
  createSelector([userDetailsSelector], (userDetails) => userDetails.role);

export const makeSelectUserOrgId = () =>
  createSelector(
    [userDetailsSelector],
    (userDetails) => userDetails.organisationId,
  );

const firebaseIdSelector = (state) => {
  return state.user.auth.firebaseId;
};
export const makeSelectFirebaseId = () =>
  createSelector([firebaseIdSelector], (firebaseId) => firebaseId);

const userSubscriptionSelector = (state) => {
  return state.user.subscription;
};
export const makeSelectUserSubscription = () =>
  createSelector(
    [userSubscriptionSelector],
    (userSubscription) => userSubscription,
  );
