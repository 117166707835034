import React from "react";
import Typography from "@material-ui/core/Typography";

export default function Title({ children, removeVarient = false }) {
  return (
    <Typography
      component="h2"
      variant={removeVarient ? undefined : "h6"}
      color="primary"
      gutterBottom
    >
      {children}
    </Typography>
  );
}
