import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableTemplate from '../TableSortableTemplate';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  fontBold: {
    fontWeight: 'bold',
  },
  button: {
    padding: '0 3px',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  filterBox: {
    padding: theme.spacing(0, 1, 1),
  },
  colorDanger: {
    color: red[500],
  },
}));

export default function SMOrgsTableList({
  tableHeaders,
  organisations,
  onFilter,
  onClickOrg,
  isLoading,
  order,
  orderBy,
  onRequestSort,
}) {
  const classes = useStyles();

  const _tableRowRenderer = ({ rowData: row }) => {
    return (
      <>
        {/* Org Name */}
        <TableCell component="th" scope="row">
          <Tooltip title={`View/Edit ${row.name}`} placement="right">
            <Button color="primary" className={classes.button} onClick={onClickOrg(row.id)}>
              <span className={clsx(classes.fontBold, !row.active && classes.colorDanger)}>
                {row.name}
              </span>
            </Button>
          </Tooltip>
        </TableCell>
        {/* Status */}
        <TableCell>
          <span className={clsx(!row.active && classes.colorDanger)}>
            {row.active ? 'Active' : 'Inactive'}
          </span>
        </TableCell>
        {/* Licenses in use */}
        <TableCell>
          <span className={clsx(!row.active && classes.colorDanger)}>
            {row.activeMembers} / {row.licences}
          </span>
        </TableCell>
        {/* Created */}
        <TableCell>
          <span className={clsx(!row.active && classes.colorDanger)}>
            {moment(row.createdOn).calendar()}
          </span>
        </TableCell>
      </>
    );
  };

  return (
    <Paper className={classes.root}>
      <TableTemplate
        tableHeaders={tableHeaders}
        tableData={organisations}
        onFilter={onFilter}
        filterLabel="Filter Organisations"
        filterPlaceholder="Type in the organisation name"
        isLoading={isLoading}
        rowRenderer={_tableRowRenderer}
        sortedHeaders
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        empty={
          <Box display="flex" justifyContent="center" flexDirection="row">
            <p>No organisations found</p>
          </Box>
        }
      />
    </Paper>
  );
}
