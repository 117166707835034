import React from "react";
import { v4 as uuidV4 } from "uuid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClearIcon from "@material-ui/icons/Clear";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LoadingIndicator from "./LoadingIndicator";
import { truncateString, formatBytes } from "../lib/utils";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: "10px",
    maxHeight: "400px",
    overflowY: "scroll",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  submitIcon: {
    margin: theme.spacing(0, 1),
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  progressText: {
    fontStyle: "italic",
    marginLeft: theme.spacing(1),
  },
  fileInput: {
    display: "none",
  },
  folderIcon: {
    color: theme.palette.primary.main,
  },
  folderIconError: {
    color: "rgb(210, 93, 107)",
  },
  progressBarContainer: {
    position: "relative",
  },
  progressBar: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    backgroundColor: "rgba(0, 197, 0, 0.5)",
  },
  percentageText: {
    position: "absolute",
    left: 0,
    backgroundColor: "green",
    color: "white",
    padding: "0 3px",
    width: "32px",
    display: "block",
    textAlign: "center",
  },
  errorMsg: {
    backgroundColor: "#fd695d",
    color: "#fdefef",
    padding: "3px",
    borderRadius: "4px",
    fontWeight: "bold",
  },
}));

function AddScheduleFilesDialog({
  isOpen,
  onClickCancel,
  onSubmit,
  isUploading,
  uploadProgress,
  isUploadComplete,
  onComplete,
  dialogTitle,
  errorMessage,
}) {
  const initialValues = {
    fileToUpload: null,
    isSubmitted: false,
  };

  const [values, setValues] = React.useState(initialValues);

  const handleAddFile = (event) => {
    const file = event.target.files[0];
    const validFileTypes = ["application/pdf"];

    if (new RegExp(validFileTypes.join("|")).test(file.type)) {
      file.id = uuidV4();
      setValues((vals) => ({
        ...vals,
        fileToUpload: file,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isUploadComplete) {
      setValues(initialValues);
      onComplete();
      return;
    }

    setValues({ ...values, isSubmitted: true });

    if (values.fileToUpload === null) return;

    onSubmit({ file: values.fileToUpload });
  };

  const classes = useStyles();
  const hasUploadProgressPercentage = Boolean(
    uploadProgress && uploadProgress.percentage
  );
  const hasUploadProgressState = Boolean(
    uploadProgress && uploadProgress.state
  );

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {dialogTitle || "Add to Playlist"}
        </DialogTitle>
        <DialogContent>
          <form
            className={classes.form}
            id="upload-schedule-form"
            onSubmit={handleSubmit}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Box mb={2} width="100%">
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <input
                              accept="application/pdf"
                              className={classes.fileInput}
                              id="file-input"
                              type="file"
                              onChange={handleAddFile}
                            />
                            <Tooltip
                              title="Choose a PDF file ..."
                              placement="top"
                            >
                              <label htmlFor="file-input">
                                <IconButton
                                  disabled={isUploading || isUploadComplete}
                                  component="span"
                                  className={
                                    values.fileToUpload === null &&
                                    values.isSubmitted
                                      ? classes.folderIconError
                                      : classes.folderIcon
                                  }
                                >
                                  <FolderOpenIcon fontSize="inherit" />
                                </IconButton>
                              </label>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={10}>
                            <Tooltip
                              title={
                                values.fileToUpload
                                  ? `${values.fileToUpload.name} (${formatBytes(
                                      values.fileToUpload.size
                                    )})`
                                  : ""
                              }
                              placement="top"
                            >
                              <div className={classes.progressBarContainer}>
                                <p>
                                  {values.fileToUpload ? (
                                    `${truncateString(
                                      values.fileToUpload.name,
                                      45
                                    )} (${formatBytes(
                                      values.fileToUpload.size
                                    )})`
                                  ) : (
                                    <span style={{ fontStyle: "italic" }}>
                                      Click the icon to choose a PDF file ...
                                    </span>
                                  )}
                                </p>
                                {(isUploading || isUploadComplete) && (
                                  <span
                                    className={classes.progressBar}
                                    style={{
                                      width: `${
                                        uploadProgress &&
                                        uploadProgress.percentage
                                          ? uploadProgress.percentage
                                          : 0
                                      }%`,
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      {isUploading &&
                                        hasUploadProgressPercentage && (
                                          <span
                                            className={classes.percentageText}
                                          >
                                            {Math.floor(
                                              uploadProgress.percentage
                                            )}
                                            %
                                          </span>
                                        )}
                                      {hasUploadProgressPercentage &&
                                        hasUploadProgressState &&
                                        uploadProgress.state === "done" && (
                                          <CheckCircleIcon
                                            fontSize="small"
                                            style={{
                                              position: "absolute",
                                              right: "2px",
                                              color: "green",
                                            }}
                                          />
                                        )}
                                    </div>
                                  </span>
                                )}
                              </div>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </form>
          <div style={{ minHeight: "26px" }}>
            {errorMessage && (
              <Typography
                component="p"
                variant="body2"
                className={classes.errorMsg}
              >
                {errorMessage && <span>Upload failed: {errorMessage}</span>}
              </Typography>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Fade in={isUploading}>
            <Box
              width="45%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <LoadingIndicator size={16} />
              <Typography
                variant="body2"
                component="span"
                className={classes.progressText}
              >
                Uploading, please wait ...
              </Typography>
            </Box>
          </Fade>
          <Button
            disabled={isUploading || isUploadComplete}
            variant="outlined"
            color="primary"
            onClick={onClickCancel}
          >
            <ClearIcon className={classes.rightMargin} />
            Cancel
          </Button>
          <Button
            disabled={String(values.name).trim().length === 0 || isUploading}
            variant="contained"
            color="primary"
            type="submit"
            form="upload-schedule-form"
          >
            <CheckCircleIcon className={classes.rightMargin} />
            {isUploadComplete ? "Done" : "Start upload ..."}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddScheduleFilesDialog;
