import { createReducer } from 'redux-create-reducer';
import * as types from '../actions/types';

const initialState = {
  list: [],
  names: {},
  dialogs: {
    addLyrics: false,
    deleteLyrics: false,
    editLyricsName: false,
  },
};

const mapUpdatedLyricsName = (lyricsId, name) => lyric => {
  if (lyric.id === lyricsId) {
    return { ...lyric, name };
  }
  return { ...lyric };
};

export const lyrics = createReducer(initialState, {
  // Playlists
  [types.GET_LYRICS_OK](state, action) {
    return {
      ...state,
      names: action.lyrics.reduce((obj, a) => {
        obj[a.id] = {
          name: a.name,
        };
        return obj;
      }, {}),
      list: [...action.lyrics],
    };
  },
  [types.DELETE_LYRICS_OK](state, action) {
    const { fileId } = action;
    const { [fileId]: unwantedLyrics, ...restNames } = state.names; // eslint-disable-line
    return {
      ...state,
      names: {
        ...restNames,
      },
      list: state.list.filter(p => p.id !== fileId),
    };
  },
  [types.UPDATE_LYRICS_NAME_OK](state, action) {
    const { lyrics: lyric } = action;

    return {
      ...state,
      names: {
        ...state.names,
        [lyric.id]: { name: lyric.name },
      },
      list: state.list.map(mapUpdatedLyricsName(lyric.id, lyric.name)),
    };
  },
  // Dialogs
  [types.TOGGLE_ADD_LYRICS_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        addLyrics: action.isOpen,
      },
    };
  },
  [types.TOGGLE_DELETE_LYRICS_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        deleteLyrics: action.isOpen,
      },
    };
  },
  [types.TOGGLE_EDIT_LYRICS_NAME_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        editLyricsName: action.isOpen,
      },
    };
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
