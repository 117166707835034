import React from 'react';
import { connect } from 'react-redux';
import UpdateNameDialog from '../../components/UpdateNameDialog';
import { setEditLyricsNameDialog, updateLyricsName } from '../../lib/store/actions/lyrics';
import {
  selectIsEditLyricsNameDialogOpen,
  selectIsUpdatingLyricsName,
} from '../../lib/store/selectors/lyrics';

function EditLyricsNameDialog({
  isOpen,
  onSetEditLyricsNameDialog,
  onUpdateLyricsName,
  isUpdating,
  lyricsId,
  lyricsName,
}) {
  const closeDialog = () => {
    onSetEditLyricsNameDialog(false);
  };

  const saveLyricsName = ({ name }) => {
    if (!lyricsId) return;
    onUpdateLyricsName({ name, id: lyricsId }, () => {
      onSetEditLyricsNameDialog(false);
    });
  };

  return (
    <UpdateNameDialog
      isOpen={isOpen}
      onClickCancel={closeDialog}
      onSubmit={saveLyricsName}
      isUpdating={isUpdating}
      currentName={lyricsName}
      dialogTitle="Update lyrics name"
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectIsEditLyricsNameDialogOpen(state),
    isUpdating: selectIsUpdatingLyricsName(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetEditLyricsNameDialog: setEditLyricsNameDialog,
    onUpdateLyricsName: updateLyricsName,
  }
)(EditLyricsNameDialog);
