import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UploadPDFDialog from '../UploadPDFDialog';
import EditLyricsNameDialog from './EditLyricsNameDialog';
import DeleteLyricsDialog from './DeleteLyricsDialog';
import LyricsTable from '../../components/Lyrics/LyricsTable';
import {
  makeSelectLyricsTableData,
  selectIsFetchingLyrics,
  selectIsAddLyricsDialogOpen,
} from '../../lib/store/selectors/lyrics';
import { makeSelectAuthToken } from '../../lib/store/selectors/auth';
import {
  getLyrics,
  setDeleteLyricsDialog,
  setEditLyricsNameDialog,
  setAddLyricsDialog,
} from '../../lib/store/actions/lyrics';
import { API_ENDPOINTS } from '../../lib/constants/api-endpoints';

class LyricsList extends React.PureComponent {
  _isMounted = false;

  state = {
    filterText: '',
    selectedId: '',
  };

  componentDidMount() {
    this._isMounted = true;
    const { onGetLyrics } = this.props;
    onGetLyrics();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState = (state, callback) => {
    if (this._isMounted) {
      this.setState(state, () => {
        if (callback && typeof callback === 'function') callback();
      });
    }
  };

  onFilterLyrics = event => {
    const { value } = event.target;
    this.updateState({
      filterText: String(value).toLowerCase(),
    });
  };

  clickDeleteLyrics = selectedId => {
    const { onSetDeleteLyricsDialog } = this.props;
    this.updateState({ selectedId });
    onSetDeleteLyricsDialog(true);
  };

  clickEditLyrics = selectedId => {
    const { onSetEditLyricsNameDialog } = this.props;
    this.updateState({ selectedId });
    onSetEditLyricsNameDialog(true);
  };

  deleteLyricsCallback = () => {
    this.updateState({ selectedId: '' });
  };

  closeAddPDFDialog = () => {
    const { onSetAddLyricsDialog } = this.props;
    onSetAddLyricsDialog(false);
  };

  refreshLyrics = () => {
    const { onGetLyrics } = this.props;
    onGetLyrics();
  };

  render() {
    const { filterText, selectedId } = this.state;
    const { lyrics, isFetching, isAddDialogOpen, authToken } = this.props;

    const _lyrics =
      filterText.length > 0
        ? lyrics.filter(c =>
            String(c.name)
              .toLowerCase()
              .includes(filterText)
          )
        : lyrics;

    const tableHeaders = [{ title: 'Lyrics', width: '70%' }, 'Edit Name', 'Delete?'];

    const selectedLyrics = lyrics.find(p => p.id === selectedId);

    return (
      <>
        <UploadPDFDialog
          isOpen={isAddDialogOpen}
          onCloseDialog={this.closeAddPDFDialog}
          onUploadCompleteCB={this.refreshLyrics}
          uploadURL={API_ENDPOINTS.lyrics.replace(':FILE_ID', '')}
          dialogTitle="Add new lyrics"
          authToken={authToken}
        />
        <EditLyricsNameDialog
          lyricsId={selectedId}
          lyricsName={selectedLyrics ? selectedLyrics.name : ''}
        />
        <DeleteLyricsDialog
          fileId={selectedId}
          fileName={selectedLyrics ? selectedLyrics.name : ''}
          onDeleteCB={this.deleteLyricsCallback}
        />
        <LyricsTable
          tableHeaders={tableHeaders}
          lyrics={_lyrics}
          onFilter={this.onFilterLyrics}
          isFetching={isFetching}
          filterText={filterText}
          onClickDelete={this.clickDeleteLyrics}
          onClickEdit={this.clickEditLyrics}
          emptyText="No lyrics found - use the menu to add some."
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const selectLyricsTableData = makeSelectLyricsTableData();
  const selectAuthToken = makeSelectAuthToken();
  return {
    lyrics: selectLyricsTableData(state),
    isFetching: selectIsFetchingLyrics(state),
    isAddDialogOpen: selectIsAddLyricsDialogOpen(state),
    authToken: selectAuthToken(state),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      onGetLyrics: getLyrics,
      onSetDeleteLyricsDialog: setDeleteLyricsDialog,
      onSetEditLyricsNameDialog: setEditLyricsNameDialog,
      onSetAddLyricsDialog: setAddLyricsDialog,
    }
  )
)(LyricsList);
