import { authRequest } from './apiSetup';
import { API_ENDPOINTS } from '../../constants/api-endpoints';

/*
 * Programs API
 ** */

export async function getProgramSchedules(idToken) {
  const qldEndpoint = API_ENDPOINTS.weeklyPrograms.replace(/:CONTENT_TYPE/, 7); // QLD
  const nswEndpoint = API_ENDPOINTS.weeklyPrograms.replace(/:CONTENT_TYPE/, 8); // NSW

  const qld = {
    id: 'qld',
    name: 'QLD',
    url: null,
  };
  const nsw = {
    id: 'nsw',
    name: 'NSW',
    url: null,
  };

  const qldRes = await authRequest(idToken, { method: 'GET', endpoint: qldEndpoint });
  if (qldRes.data !== null) {
    const q = qldRes.data.pop();
    qld.id = q.id;
    qld.createdOn = q.createdOn;
    qld.url = q.url;
  }
  const nswRes = await authRequest(idToken, { method: 'GET', endpoint: nswEndpoint });
  if (nswRes.data !== null) {
    const n = nswRes.data.pop();
    nsw.id = n.id;
    nsw.createdOn = n.createdOn;
    nsw.url = n.url;
  }
  return [qld, nsw];
}

// Playlist
export async function getProgramPlaylist(idToken) {
  const endpoint = API_ENDPOINTS.programs.replace(/:PROGRAM_ID/, '');
  const response = await authRequest(idToken, { method: 'GET', endpoint });
  return response.data;
}

export async function deletePlaylistFile(idToken, { fileId }) {
  const endpoint = API_ENDPOINTS.programs.replace(/:PROGRAM_ID/, fileId);
  const response = await authRequest(idToken, { method: 'DELETE', endpoint });
  return response.state === 1;
}
