import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($name: String!, $subFolders: [ObjectID]) {
    createCategory(name: $name, sub_folders: $subFolders) {
      id
    }
  }
`;

export const EDIT_CATEGORY_NAME = gql`
  mutation UpdateCategory(
    $categoryId: ObjectID!
    $name: String
    $icon: String
    $subFolders: [ObjectID]
  ) {
    updateCategory(
      category_id: $categoryId
      name: $name
      icon: $icon
      sub_folders: $subFolders
    ) {
      id
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($categoryId: ObjectID!) {
    deleteCategory(category_id: $categoryId)
  }
`;
