import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Page from '../PageV2';
import WeeklySchedules from '../../containers/Programs/WeeklySchedules';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function WeeklyProgramsPage() {
  const classes = useStyles();

  return (
    <Page
      title={
        <>
          <CalendarTodayIcon className={classes.icon} /> Weekly Programs
        </>
      }
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 8,
      }}>
      <WeeklySchedules />
    </Page>
  );
}

export default WeeklyProgramsPage;
