import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import withWidth from "@material-ui/core/withWidth";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/styles";
import Notifications from "./Notifications";
import AuthorizedApolloProvider from "../lib/api/apolloProvider";
import theme from "../theme";
import { APP_ROUTES } from "../lib/constants/app-routes";
import LoginPage from "./Login";
import Logout from "./Logout";
import DashboardLayoutContext from "./DashboardLayout";
import FourOhFour from "../pages/FourOhFour";
import ImageAlbumListPage, { ImageAlbumPage } from "../pages/SightNSound";
import MembersListPage, { MemberPage } from "../pages/Members";
import SMOrganisationListPage, {
  SMOrganisationPage,
} from "../pages/SMOrganisations";
import PlaylistCollectionPage, { PlaylistPage } from "../pages/Playlists";
import ProgramsPage, {
  WeeklyProgramsPage,
  CulturalProgramsPage,
  CulturalSubFolder,
} from "../pages/Programs";
import QuizzesPage from "../pages/QuizzesPage";
import HymnsPage from "../pages/HymnsPage";
import LyricsPage from "../pages/LyricsPage";
import MessagesPage from "../pages/MessagesPage";
import AcitvityPage from "../pages/ActivityPage";
/* Organisation Pages */
import OrgMembersListPage, {
  OrgMemberImportPage,
} from "../pagesOrganisation/Members";
import OrgAdminProfilePage from "../pagesOrganisation/AdminProfile";
import FloatingLoadingIndicator from "./FloatingLoadingIndicator";
import "react-image-lightbox/style.css";
import { USER_ROLES } from "../lib/constants/user-roles";
import { store } from "../lib/store/configureStore";

function Protect({ component: Component, requiredRole }) {
  const isAuth = useSelector((state) => {
    return Boolean(
      state && state.user && state.user.auth && state.user.auth.idToken
    );
  });
  const userRole = useSelector((state) => {
    if (state && state.user && state.user.details && state.user.details.role) {
      return state.user.details.role;
    }
    return null;
  });

  // Check if is logged in
  if (!isAuth) {
    return <Redirect to={APP_ROUTES.logout} />;
  }

  // Check if has correct role
  if (requiredRole !== userRole) {
    return <Redirect to="page-not-found" />;
  }

  return <Component />;
}

const App = ({ width }) => {
  const userRole = useSelector((state) => {
    if (state && state.user && state.user.details && state.user.details.role) {
      return state.user.details.role;
    }
    return null;
  });

  return (
    <AuthorizedApolloProvider store={store}>
      <SnackbarProvider
        preventDuplicate
        maxSnack={4}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={theme}>
            <DashboardLayoutContext.Provider value={width}>
              <CssBaseline />
              <Switch>
                <Redirect
                  exact
                  path="/"
                  to={
                    userRole === USER_ROLES.ADMIN
                      ? APP_ROUTES.members
                      : APP_ROUTES.organisation.members
                  }
                />
                {/* Organisations Admin Pages */}
                <Route
                  path={APP_ROUTES.organisation.members}
                  exact
                  render={() => (
                    <Protect
                      component={OrgMembersListPage}
                      requiredRole={USER_ROLES.ORGANISATION}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.organisation.import}
                  exact
                  render={() => (
                    <Protect
                      component={OrgMemberImportPage}
                      requiredRole={USER_ROLES.ORGANISATION}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.organisation.account}
                  exact
                  render={() => (
                    <Protect
                      component={OrgAdminProfilePage}
                      requiredRole={USER_ROLES.ORGANISATION}
                    />
                  )}
                />

                {/* SM Admin Pages */}
                <Route
                  path={APP_ROUTES.smOrganisations}
                  exact
                  render={() => (
                    <Protect
                      component={SMOrganisationListPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={`${APP_ROUTES.smOrganisations}/:id`}
                  exact
                  render={() => (
                    <Protect
                      component={SMOrganisationPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.sightsound}
                  exact
                  render={() => (
                    <Protect
                      component={ImageAlbumListPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={`${APP_ROUTES.sightsound}/:id`}
                  exact
                  render={() => (
                    <Protect
                      component={ImageAlbumPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.members}
                  exact
                  render={() => (
                    <Protect
                      component={MembersListPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={`${APP_ROUTES.members}/:id`}
                  exact
                  render={() => (
                    <Protect
                      component={MemberPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.playlists}
                  exact
                  render={() => (
                    <Protect
                      component={PlaylistCollectionPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={`${APP_ROUTES.playlists}/:id`}
                  exact
                  render={() => (
                    <Protect
                      component={PlaylistPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.programs}
                  exact
                  render={() => (
                    <Protect
                      component={ProgramsPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.culturalprograms}
                  exact
                  render={() => (
                    <Protect
                      component={CulturalProgramsPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={`${APP_ROUTES.culturalprograms}/:categoryId`}
                  exact
                  render={() => (
                    <Protect
                      component={CulturalSubFolder}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.weeklyprograms}
                  exact
                  render={() => (
                    <Protect
                      component={WeeklyProgramsPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.quizzes}
                  exact
                  render={() => (
                    <Protect
                      component={QuizzesPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.hymns}
                  exact
                  render={() => (
                    <Protect
                      component={HymnsPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.activity}
                  exact
                  render={() => (
                    <Protect
                      component={AcitvityPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.lyrics}
                  exact
                  render={() => (
                    <Protect
                      component={LyricsPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route
                  path={APP_ROUTES.messages}
                  exact
                  render={() => (
                    <Protect
                      component={MessagesPage}
                      requiredRole={USER_ROLES.ADMIN}
                    />
                  )}
                />
                <Route path={APP_ROUTES.login} exact component={LoginPage} />
                <Route path={APP_ROUTES.logout} exact component={Logout} />
                <Route path="*" exact component={FourOhFour} />
              </Switch>
              <Notifications />
              <FloatingLoadingIndicator />
            </DashboardLayoutContext.Provider>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </AuthorizedApolloProvider>
  );
};

export default withWidth()(App);
