import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import Page from '../PageV2';
import PlaylistCollection from '../../containers/Playlists/PlaylistCollection';
import { setCreatePlaylistDialog } from '../../lib/store/actions/playlists';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function PlaylistCollectionPage({ onSetCreatePlaylistDialog }) {
  const classes = useStyles();

  const pageMenuItems = [
    {
      title: (
        <>
          <AddIcon className={classes.icon} /> Create New Playlist
        </>
      ),
      onClick: () => onSetCreatePlaylistDialog(true),
    },
  ];

  return (
    <Page
      title={
        <>
          <LibraryMusicIcon className={classes.icon} /> Music Genres
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 8,
      }}>
      <PlaylistCollection />
    </Page>
  );
}

export default connect(null, {
  onSetCreatePlaylistDialog: setCreatePlaylistDialog,
})(PlaylistCollectionPage);
