import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { showNotification } from "../../../lib/store/actions/notifications";
import { NOTIFICATION_TYPES } from "../../../lib/constants/notification-types";
import {
  makeSelectIsDialogOpen,
  setDialog,
} from "../../../lib/store/reducers/dialog";
import { DIALOG_TYPES } from "../../../lib/constants/dialog-types";
import { SEND_BROADCAST_TEXT_MESSAGE } from "../../../lib/api/graphql/mutations/messages";
import AddBroadcastMsgForm from "../../../components/Messages/Dialogs/NewMessage";

function AddBroadcastDialog() {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    makeSelectIsDialogOpen(DIALOG_TYPES.ADD_NEW_BROADCAST)
  );

  const handleClose = () => {
    dispatch(
      setDialog({ open: false, dialog: DIALOG_TYPES.ADD_NEW_BROADCAST })
    );
  };

  const [sendBroadcastTextMessage, { loading }] = useMutation(
    SEND_BROADCAST_TEXT_MESSAGE,
    {
      refetchQueries: ["Last_broadcast"],
      onCompleted: () => {
        dispatch(
          showNotification({
            message: "Broadcast message sent",
            options: { variant: NOTIFICATION_TYPES.Success },
          })
        );
        handleClose();
      },
      onError: (error) => {
        dispatch(
          showNotification({
            message: error?.message || "Failed to send the broadcast message",
            options: { variant: NOTIFICATION_TYPES.Error },
          })
        );
      },
    }
  );

  const handleSend = async (text) => {
    if (!text) return;
    await sendBroadcastTextMessage({ variables: { text } });
  };

  return (
    <AddBroadcastMsgForm
      isOpen={isOpen}
      onClickCancel={handleClose}
      onSubmit={handleSend}
      loading={loading}
    />
  );
}

export default AddBroadcastDialog;
