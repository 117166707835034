import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import RefreshIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import ImageAlbumList from '../../containers/SightNSound/ImageAlbumList';
import Page from '../Page';
import {
  setCreateImageAlbumDialog,
  getImageAlbums,
} from '../../lib/store/actions/imageAlbums';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function ImageAlbumListPage({ onSetCreateImageAlbumDialog, onGetImageAlbums }) {
  const classes = useStyles();

  const pageMenuItems = [
    {
      title: (
        <>
          <AddIcon className={classes.icon} /> Create New Album
        </>
      ),
      onClick: () => onSetCreateImageAlbumDialog(true),
    },
    {
      title: (
        <>
          <RefreshIcon className={classes.icon} /> Refresh Album List
        </>
      ),
      onClick: onGetImageAlbums,
    },
  ];

  return (
    <Page
      title={
        <>
          <PhotoLibraryIcon className={classes.icon} /> Silver Scenes: Image
          Albums
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 8,
      }}>
      <ImageAlbumList />
    </Page>
  );
}

export default connect(null, {
  onSetCreateImageAlbumDialog: setCreateImageAlbumDialog,
  onGetImageAlbums: getImageAlbums,
})(ImageAlbumListPage);
