/* eslint-disable arrow-body-style */
import { v4 as uuidV4 } from 'uuid';
import { imagesApi } from '../../api';
import * as types from './types';
import { apiRequest } from './api-request';
import { showNotification } from './notifications';
import { NOTIFICATION_TYPES } from '../../constants/notification-types';
import { getAuthToken, checkHttpError } from './authTokenHelper';

export const setCreateImageAlbumDialog = (isOpen) => ({
  type: types.TOGGLE_CREATE_IMAGE_ALBUM_DIALOG,
  isOpen,
});

export const setEditAlbumNameDialog = (isOpen) => ({
  type: types.TOGGLE_EDIT_ALBUM_NAME_DIALOG,
  isOpen,
});

export const setDeleteImageAlbumDialog = (isOpen) => ({
  type: types.TOGGLE_DELETE_IMAGE_ALBUM_DIALOG,
  isOpen,
});

export const setDeleteImageMode = (isDelete) => ({
  type: types.SET_DELETE_IMAGE_MODE,
  isDelete,
});

export const getImageAlbums = (cb) => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_GET_IMAGE_ALBUMS_REQ,
    message: 'Loading image albums',
  });
  dispatch(apiReqAction);
  imagesApi
    .getImageAlbums(getAuthToken(getState()))
    .then(
      (albums) => {
        dispatch({
          type: types.GET_IMAGE_ALBUMS_OK,
          albums,
          apiReqId: apiReqAction.id,
        });

        if (cb && typeof cb === 'function') cb(albums);
      },
      (error) => dispatch(checkHttpError(error, apiReqAction.id)),
    )
    .catch((error) => {
      dispatch(
        showNotification({
          message: `Get Albums: ${error.message}` || 'Cannot get albums',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};

const deleteImage = (authToken, { albumId, imageId }) => {
  return new Promise((resolve, reject) => {
    imagesApi
      .deleteImage(authToken, { albumId, imageId })
      .then(
        (res) => {
          if (!res) return reject(new Error('Cannot delete image'));
          resolve({ imageId, deleted: true });
        },
        (error) => {
          reject(error.message);
        },
      )
      .catch((error) => reject(error));
  });
};

export const deleteImages =
  ({ albumId, imageIds }) =>
  async (dispatch, getState) => {
    try {
      await imageIds.reduce((promise, imageId) => {
        return promise.then(async () => {
          const apiReqAction = apiRequest({
            requestType: types.API_DEL_IMAGES_REQ,
            message: 'Deleting images',
          });
          dispatch(apiReqAction);
          const delVal = await deleteImage(getAuthToken(getState()), {
            albumId,
            imageId,
          });

          dispatch({ type: types.DELETE_IMAGE_OK, apiReqId: apiReqAction.id });
          return delVal;
        });
      }, Promise.resolve());
      dispatch(
        showNotification({
          message: 'Images have been deleted from the database',
          options: {
            variant: NOTIFICATION_TYPES.Success,
          },
        }),
      );
      dispatch({ type: types.DELETE_IMAGES_OPTIMISTIC, albumId, imageIds });
    } catch (error) {
      dispatch(
        showNotification({
          message:
            `Get Images: ${error.message}` || 'Cannot delete album images',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
    }
  };

export const deleteAlbum =
  ({ albumId }, successCB) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_DEL_IMAGE_ALBUMS_REQ,
      message: 'Deleting album',
    });
    dispatch(apiReqAction);
    dispatch(
      showNotification({
        message: 'Deleting album ... please wait.',
        options: {
          variant: NOTIFICATION_TYPES.Info,
          autoHideDuration: 2000,
        },
      }),
    );
    imagesApi
      .deleteAlbum(getAuthToken(getState()), { albumId })
      .then(
        () => {
          dispatch({
            type: types.DELETE_IMAGE_ALBUM_OK,
            albumId,
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'Album successfully deleted',
              options: {
                variant: NOTIFICATION_TYPES.Success,
                autoHideDuration: 2000,
              },
            }),
          );
          if (successCB && typeof successCB === 'function') successCB();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message: `Delete Album: ${error.message}` || 'Cannot delete album',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const saveAlbumName =
  ({ albumId, albumName }) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_UPD_IMAGE_ALBUMS_REQ,
      message: 'Saving name',
    });
    dispatch(apiReqAction);
    dispatch(
      showNotification({
        message: 'Updating ... please wait.',
        options: {
          variant: NOTIFICATION_TYPES.Info,
          autoHideDuration: 2000,
        },
      }),
    );
    imagesApi
      .editAlbumName(getAuthToken(getState()), { albumId, albumName })
      .then(
        () => {
          dispatch({
            type: types.SAVE_IMAGE_ALBUM_NAME_OK,
            album: { id: albumId, name: albumName },
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'Album name updated',
              options: {
                variant: NOTIFICATION_TYPES.Success,
              },
            }),
          );
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message:
              `Edit Album Name: ${error.message}` ||
              'Cannot update image album name',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const addImageAlbum = (albumName) => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_ADD_IMAGE_ALBUMS_REQ,
    message: 'Creating new image album',
  });
  dispatch(apiReqAction);

  const tempId = uuidV4();
  dispatch({
    type: types.ADD_IMAGE_ALBUM_OPTIMISTIC,
    album: {
      id: tempId,
      name: albumName,
      images: [],
      numImages: 0,
    },
  });

  imagesApi
    .addCommunityAlbum(getAuthToken(getState()), albumName)
    .then(
      (albumId) => {
        dispatch({
          type: types.ADD_IMAGE_ALBUM_OK,
          album: { tempId, realId: albumId },
          apiReqId: apiReqAction.id,
        });
      },
      (error) => dispatch(checkHttpError(error, apiReqAction.id)),
    )
    .catch((error) => {
      dispatch(
        showNotification({
          message:
            `Add Album: ${error.message}` || 'Cannot add community album',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};

export const getImages = (albumId) => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_GET_IMAGES_REQ,
    message: 'Loading Images',
  });
  dispatch(apiReqAction);

  imagesApi
    .getImages(getAuthToken(getState()), { albumId })
    .then(
      (images) => {
        const dispatchObj = {
          type: types.GET_IMAGES_OK,
          images,
          albumId,
          apiReqId: apiReqAction.id,
        };
        // check that we have previously fetched albums (won't happen if someone opens an album page without going through the main album list page first)
        const { albums } = getState().imageAlbums;
        if (albums.length === 0)
          return dispatch(getImageAlbums(() => dispatch(dispatchObj))); // eslint-disable-line
        dispatch(dispatchObj);
      },
      (error) => dispatch(checkHttpError(error, apiReqAction.id)),
    )
    .catch((error) => {
      dispatch(
        showNotification({
          message: `Get Images: ${error.message}` || 'Cannot get images',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};

export const createNewAlbum =
  (albumName, successCB) => (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_ADD_IMAGE_ALBUMS_REQ,
      message: 'Creating image album',
    });
    dispatch(apiReqAction);

    const tempId = uuidV4();
    dispatch({
      type: types.ADD_IMAGE_ALBUM_OPTIMISTIC,
      album: {
        id: tempId,
        name: albumName,
        images: [],
        numImages: 0,
      },
    });

    imagesApi
      .createNewAlbum(getAuthToken(getState()), { albumName })
      .then(
        (albumId) => {
          dispatch({
            type: types.ADD_IMAGE_ALBUM_OK,
            album: { tempId, realId: albumId },
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'New image album saved to the database',
              options: {
                variant: NOTIFICATION_TYPES.Success,
              },
            }),
          );
          if (successCB && typeof successCB === 'function') successCB();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message:
              `Create Album: ${error.message}` || 'Cannot create image album',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };
