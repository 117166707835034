import { gql } from '@apollo/client';

export const GET_LAST_BROADCAST = gql`
  query Last_broadcast {
    last_broadcast {
      id
      createdAt
      text
      user {
        id
        FirstName
        LastName
      }
    }
  }
`;

export const GET_AUTOMATED_MESSAGE = gql`
  query GetAutomatedMessage {
    global {
      automated_message
    }
  }
`;

export const GET_MESSAGES = gql`
  query Messages($type: MessageType) {
    messages(type: $type) {
      Type
      Read
      CreatedOn
      Body
      id
      user {
        id
        LastName
        FirstName
        Email
      }
    }
  }
`;
