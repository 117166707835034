class UploadProgress {
  constructor({ onSend, onProgress, onLoad, onError }) {
    this.onSend = onSend;
    this.onProgress = onProgress || this.dummy;
    this.onLoad = onLoad || this.dummy;
    this.onError = onError || this.dummy;
  }

  dummy = () => {};

  upload = () => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener('progress', this.onProgress);

      req.upload.addEventListener('load', this.onLoad);

      req.upload.addEventListener('error', () => {
        this.onError();
        reject(req.response || 'Something went wrong uploading to the server.');
      });

      req.onreadystatechange = function () {
        if (
          req.status === 401 ||
          req.status === 400 ||
          req.status === 404 ||
          req.status === 500 ||
          req.status === 504 ||
          req.status === 413 ||
          (req.status >= 400 && req.status < 600)
        ) {
          let errorMessage = 'Problem uploading the file to the server.';
          if (req.status === 400)
            errorMessage = 'An error has occured on the server.';
          if (req.status === 404)
            errorMessage = 'Cannot find or access the server.';
          if (req.status === 401)
            errorMessage =
              'Authorisation to upload to the server cannot be found';
          if (req.status === 413) errorMessage = 'File is too large to upload';
          reject(errorMessage);
          return;
        }
        if (req.readyState === 4 && req.status === 200) {
          resolve(req.response);
        }
      };

      this.onSend(req);
    });
  };
}

export default UploadProgress;
