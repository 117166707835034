/*
 * NOTE: Icons are found here: https://material.io/tools/icons/?style=baseline
 * */

import MessageIcon from '@material-ui/icons/Message';
import PeopleIcon from '@material-ui/icons/People';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import TheaterComedyIcon from '@material-ui/icons/Theaters'; //TODO: Find an icon, in the latest library it can be imported like import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import GamesIcon from '@material-ui/icons/Games';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import BusinessIcon from '@material-ui/icons/Business';
import ActivityIcon from '@material-ui/icons/Assessment';
import LockIcon from '@material-ui/icons/Lock';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';
import { APP_ROUTES } from './app-routes';

export const NAVIGATION_MENU_ITEMS = {
  main: {
    heading: 'Community',
    menuItems: [
      {
        icon: PeopleIcon,
        linkTo: APP_ROUTES.members,
        text: 'Members',
      },
      {
        icon: MessageIcon,
        linkTo: APP_ROUTES.messages,
        text: 'Messages',
      },
      {
        icon: ActivityIcon,
        linkTo: APP_ROUTES.activity,
        text: 'Activity',
      },
    ],
  },
  orgs: {
    heading: 'B2B',
    menuItems: [
      {
        icon: BusinessIcon,
        linkTo: APP_ROUTES.smOrganisations,
        text: 'Organisations',
      },
    ],
  },
  onDemand: {
    heading: 'App Content',
    menuItems: [
      {
        icon: LibraryMusicIcon,
        linkTo: APP_ROUTES.playlists,
        text: 'Music Genres',
      },
      {
        icon: PhotoLibraryIcon,
        linkTo: APP_ROUTES.sightsound,
        text: 'Silver Scenes',
      },
      {
        icon: LibraryBooksIcon,
        linkTo: APP_ROUTES.programs,
        text: 'Broadcasts',
      },
      {
        icon: TheaterComedyIcon,
        linkTo: APP_ROUTES.culturalprograms,
        text: 'Cultural Programs',
      },
      {
        icon: CalendarTodayIcon,
        linkTo: APP_ROUTES.weeklyprograms,
        text: 'Weekly Programs',
      },
      {
        icon: GamesIcon,
        linkTo: APP_ROUTES.quizzes,
        text: 'Quizzes',
      },
      {
        icon: QueueMusicIcon,
        linkTo: APP_ROUTES.hymns,
        text: 'Hymns',
      },
      {
        icon: QueueMusicIcon,
        linkTo: APP_ROUTES.lyrics,
        text: 'Lyrics (OLD)',
      },
    ],
  },
  other: {
    heading: '',
    menuItems: [
      {
        icon: LockIcon,
        dialog: true,
        dialogType: DIALOG_TYPES.UPDATE_USER_PASSWORD,
        text: 'Change Password',
      },
      {
        icon: LogoutIcon,
        linkTo: APP_ROUTES.logout,
        text: 'Logout',
      },
    ],
  },
};
