/* eslint-disable no-nested-ternary */
import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClearIcon from "@material-ui/icons/Clear";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import LoadingIndicator from "../LoadingIndicator";
import InputField from "../InputField";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: "10px",
    maxHeight: "400px",
    overflowY: "scroll",
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  progressText: {
    fontStyle: "italic",
    marginLeft: theme.spacing(1),
  },
}));

function UpdLicenseCountDialog({
  licences,
  members,
  isOpen,
  isUpdating,
  onCancel,
  onSubmit,
}) {
  const initialValues = {
    memberCount: 0,
    licenceCount: 0,
    isSubmitted: false,
    isUpdated: false,
  };

  const [values, setValues] = React.useState(initialValues);

  React.useEffect(() => {
    if (typeof licences === "number" && typeof members === "number") {
      setValues((vals) => ({
        ...vals,
        licenceCount: licences,
        memberCount: members,
      }));
    }
  }, [licences, members]);

  const handleChange = (event) => {
    const { value } = event.target;
    setValues((vals) => ({ ...vals, licenceCount: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newCount = parseInt(values.licenceCount, 10);

    if (newCount === licences) {
      // no change, just close
      onCancel();
      return;
    }

    setValues((vals) => ({ ...vals, isSubmitted: true }));

    if (values.memberCount > newCount || newCount > 1000000 || newCount < 0)
      return;

    onSubmit({ licences: newCount });
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update License Count</DialogTitle>
        <DialogContent>
          <form
            className={classes.form}
            id="upd-license-form"
            onSubmit={handleSubmit}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  Current Licence count: {licences}
                  <br />
                  Allocated licences: {members}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <InputField
                  id="licence-count"
                  inputType="text"
                  type="number"
                  label="Licence Count"
                  onChange={handleChange}
                  placeholder="Enter the licence count"
                  InputLabelProps={{ shrink: true }}
                  disabled={isUpdating}
                  name="licence-count"
                  value={values.licenceCount}
                  error={
                    (values.isSubmitted &&
                      (values.memberCount > parseInt(values.licenceCount, 10) ||
                        parseInt(values.licenceCount, 10) > 1000000)) ||
                    parseInt(values.licenceCount, 10) < 0
                  }
                  helperText={
                    values.isSubmitted &&
                    values.memberCount > parseInt(values.licenceCount, 10)
                      ? "New licence count cannot be less than allocated license count. Please contact the organisation to deallocate licences."
                      : parseInt(values.licenceCount, 10) > 1000000
                      ? "Maximum number is 1000000."
                      : parseInt(values.licenceCount, 10) < 0
                      ? "Only numbers greater than 0 are allowed."
                      : ""
                  }
                  classes={classes}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Fade in={isUpdating}>
            <Box
              width="45%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <LoadingIndicator size={16} />
              <Typography
                variant="body2"
                component="span"
                className={classes.progressText}
              >
                Updating, please wait ...
              </Typography>
            </Box>
          </Fade>
          <Button
            disabled={isUpdating}
            variant="outlined"
            color="primary"
            onClick={onCancel}
          >
            <ClearIcon className={classes.rightMargin} />
            Cancel
          </Button>
          <Button
            disabled={isUpdating}
            variant="contained"
            color="primary"
            type="submit"
            form="upd-license-form"
          >
            <CheckCircleIcon className={classes.rightMargin} />
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UpdLicenseCountDialog;
