import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import Dialog from '../../components/Organisation/ChangeOrgUserPasswordDialog';
import { makeSelectIsOrgActive } from '../../lib/store/selectors/organisation';
import { makeSelectUserDetails } from '../../lib/store/selectors/user';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { UPDATE_USER_PASSWORD } from '../../lib/api/graphql/mutations/organizations';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';
import {
  makeSelectIsDialogOpen,
  setDialog,
} from '../../lib/store/reducers/dialog';

const ChangeAdminPasswordDialog = ({ isOrgActive, user }) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(
    makeSelectIsDialogOpen(DIALOG_TYPES.UPDATE_USER_PASSWORD),
  );

  const [updatePassword, { loading: isUpdating }] = useMutation(
    UPDATE_USER_PASSWORD,
    {
      onCompleted: () => {
        dispatch(
          showNotification({
            message: 'password updated successfully',
            options: {
              variant: NOTIFICATION_TYPES.Success,
            },
          }),
        );

        handleClose();
      },
      onError: (error) => {
        dispatch(
          showNotification({
            options: { variant: NOTIFICATION_TYPES.Error },
            message: `Error updating password: ${error?.message}`,
          }),
        );
      },
    },
  );

  const handleChangeAdminPassword = async ({ password }) => {
    if (!user) return;

    await updatePassword({
      variables: {
        userId: user.id,
        password,
      },
    });
  };

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialog: DIALOG_TYPES.UPDATE_USER_PASSWORD,
      }),
    );
  };

  return (
    <Dialog
      isUpdating={isUpdating}
      isOpen={isOpen}
      isOrgActive={isOrgActive}
      onCancel={handleClose}
      onSubmit={handleChangeAdminPassword}
    />
  );
};

const mapStateToProps = (state) => {
  const selectUserDetails = makeSelectUserDetails();
  const selectIsOrgActive = makeSelectIsOrgActive();
  return {
    isOrgActive: selectIsOrgActive(state),
    user: selectUserDetails(state),
  };
};

export default connect(mapStateToProps)(ChangeAdminPasswordDialog);
