import { createReducer } from 'redux-create-reducer';
import * as types from '../actions/types';

const initialState = {
  selected: null,
  pagination: {},
  list: [],
};

export const members = createReducer(initialState, {
  [types.GET_MEMBERS_OK](state, action) {
    return {
      ...state,
      selected: null,
      pagination: { ...action.pagination },
      list: [...action.users],
    };
  },
  [types.GET_MEMBER_OK](state, action) {
    const { member, navigation } = action;

    return {
      ...state,
      selected: { ...member, navigation },
    };
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
