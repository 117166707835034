import { useState } from 'react';
import { compose } from 'recompose';
import { v4 as uuidV4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { withRouter, useParams } from 'react-router-dom';
import SongsTable from '../../components/SongsTable';
import EditPlaylistNameDialog from './EditPlaylistNameDialog';
import DeletePlaylistDialog from './DeletePlaylistDialog';
import DeletePlaylistSongDialog from './DeletePlaylistSongDialog';
import UploadToPlaylistDialog from '../UploadToPlaylistDialog';
import {
  selectCurrentPlaylistSongs,
  makeSelectCurrentPlaylistName,
  selectAddSongsToPlaylistDialogOpen,
} from '../../lib/store/selectors/playlists';
import { makeSelectAuthToken } from '../../lib/store/selectors/auth';
import {
  setDeletePlaylistSongDialog,
  setAddSongsToPlaylistDialog,
} from '../../lib/store/actions/playlists';
import { APP_ROUTES } from '../../lib/constants/app-routes';
import { API_ENDPOINTS } from '../../lib/constants/api-endpoints';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { showNotification } from '../../lib/store/actions/notifications';
import * as types from '../../lib/store/actions/types';
import { GET_PLAYLIST } from '../../lib/api/graphql/queries/playlist';
import defaultArtwork from '../../assets/images/music-note.jpg';

const SinglePlaylistSongs = ({
  playlistName,
  songs,
  isAddSongDialogOpen,
  authToken,
  onSetDeletePlaylistSongDialog,
  onSetAddSongsToPlaylistDialog,
  history,
}) => {
  const { id: playlistId } = useParams();
  const dispatch = useDispatch();

  const { loading: isFetching, refetch } = useQuery(GET_PLAYLIST, {
    variables: {
      playlistId: playlistId,
    },
    onCompleted: (data) => {
      if (!data?.playlist?.id) return;

      const playlist = {
        id: data.playlist.id,
        name: data.playlist.Name,
        artwork: data.playlist.ArtworkURL || defaultArtwork,
        list:
          data.playlist.files?.map((f) => ({
            id: f.id,
            url: f.Url,
            title: f.Title,
            artist: f.Artist,
            createdOn: f.CreatedOn,
          })) || [],
      };

      dispatch({
        type: types.GET_PLAYLIST_OK,
        playlist,
        playlistId: playlistId,
        apiReqId: uuidV4(),
      });
    },
    onError: (err) => {
      dispatch(
        showNotification({
          message:
            `Get playlist: ${err.message}` || 'Cannot get playlist details',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
    },
  });

  const [filterText, setFilterText] = useState('');
  const [songIdToDelete, setSongIdToDelete] = useState('');

  const onFilterPlaylists = (event) => {
    const { value } = event.target;
    setFilterText(String(value).toLowerCase());
  };

  const navigateToPlaylistsPage = () => {
    history.replace(APP_ROUTES.playlists);
  };

  const clickDeleteSong = (songId) => {
    setSongIdToDelete(songId);
    onSetDeletePlaylistSongDialog(true);
  };

  const clickDeleteSongCallback = () => {
    setSongIdToDelete('');
  };

  const refreshPlaylist = async () => {
    await refetch();
  };

  const closeAddSongDialog = () => {
    onSetAddSongsToPlaylistDialog(false);
  };

  const _songs =
    filterText.length > 0
      ? songs.filter(
          (c) =>
            String(c.artist).toLowerCase().includes(filterText) ||
            String(c.title).toLowerCase().includes(filterText),
        )
      : songs;

  const tableHeaders = [
    { title: 'Title / Artist', width: '70%' },
    'Player Controls',
    'Delete?',
  ];

  const song = songs.find((s) => s.id === songIdToDelete);

  return (
    <>
      <UploadToPlaylistDialog
        isOpen={isAddSongDialogOpen}
        onCloseDialog={closeAddSongDialog}
        onUploadCompleteCB={refreshPlaylist}
        uploadURL={`${API_ENDPOINTS.playlists
          .replace(/:PLAYLIST_ID/, playlistId)
          ?.concat(`?id=${playlistId}`)}`}
        authToken={authToken}
      />
      <EditPlaylistNameDialog
        playlistId={playlistId}
        playlistName={playlistName}
      />
      <DeletePlaylistDialog
        playlistId={playlistId}
        playlistName={playlistName}
        onNavigateToPlaylistsPage={navigateToPlaylistsPage}
      />
      <DeletePlaylistSongDialog
        playlistId={playlistId}
        playlistName={playlistName}
        songId={songIdToDelete}
        songName={song ? song.title : ''}
        onDeleteCB={clickDeleteSongCallback}
      />
      <SongsTable
        tableHeaders={tableHeaders}
        songs={_songs}
        onFilter={onFilterPlaylists}
        isFetching={isFetching}
        onClickDeleteSong={clickDeleteSong}
        emptyText="No songs found - use the menu to add some."
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const selectCurrentPlaylistName = makeSelectCurrentPlaylistName();
  const selectAuthToken = makeSelectAuthToken();

  return {
    playlistName: selectCurrentPlaylistName(state, ownProps),
    songs: selectCurrentPlaylistSongs(state, ownProps),
    isAddSongDialogOpen: selectAddSongsToPlaylistDialogOpen(state),
    authToken: selectAuthToken(state),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    onSetDeletePlaylistSongDialog: setDeletePlaylistSongDialog,
    onSetAddSongsToPlaylistDialog: setAddSongsToPlaylistDialog,
  }),
)(SinglePlaylistSongs);
