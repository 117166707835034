import React from 'react';
import { connect } from 'react-redux';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { deleteQuiz, setDeleteQuizDialog } from '../../lib/store/actions/quizzes';
import {
  selectIsDeleteQuizDialogOpen,
  selectIsDeletingQuiz,
} from '../../lib/store/selectors/quizzes';

function DeleteQuizDialog({
  isOpen,
  isDeleting,
  fileId,
  onSetDeleteQuizDialog,
  onDeleteQuiz,
  fileName,
  onDeleteCB,
}) {
  const closeDialog = () => {
    onSetDeleteQuizDialog(false);
  };

  const deleteCurrentPlaylistSong = () => {
    onDeleteQuiz({ fileId }, () => {
      onSetDeleteQuizDialog(false);
      if (onDeleteCB && typeof onDeleteCB === 'function') onDeleteCB();
    });
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={closeDialog}
      onConfirm={deleteCurrentPlaylistSong}
      isInProgress={isDeleting}
      inProgressMessage="Deleting, please wait ..."
      confirmationBody={
        <>
          Please confirm you'd like to delete "{fileName}"?
          <br />
          <br />
          <strong>Note:</strong> This action cannot be undone.
        </>
      }
      confirmationBtnText="Yes, delete it!"
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectIsDeleteQuizDialogOpen(state),
    isDeleting: selectIsDeletingQuiz(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetDeleteQuizDialog: setDeleteQuizDialog,
    onDeleteQuiz: deleteQuiz,
  }
)(DeleteQuizDialog);
