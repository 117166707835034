import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: 'auto',
  },
});

function CSVImportPreviewList({ errors, data }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headers = [
    'File Row No.',
    'FirstName',
    'LastName',
    'Email',
    'BirthDate',
    'Country',
    'Postcode',
    'State',
  ];

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((column) => (
                <TableCell key={column} style={{ whiteSpace: 'nowrap' }}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const {
                  Country,
                  Email,
                  FirstName,
                  LastName,
                  BirthDate,
                  Postcode,
                  State,
                } = row;

                return (
                  <React.Fragment key={index}>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell
                        style={{
                          color: errors[index] ? 'red' : '#222',
                        }}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#222',
                        }}>
                        {FirstName}
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#222',
                        }}>
                        {LastName}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            !!errors[index] && errors[index].field === 'Email'
                              ? 'red'
                              : '#222',
                        }}>
                        {Email}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            !!errors[index] &&
                            errors[index].field === 'BirthDate'
                              ? 'red'
                              : '#222',
                        }}>
                        {BirthDate}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            !!errors[index] && errors[index].field === 'Country'
                              ? 'red'
                              : '#222',
                          whiteSpace: 'nowrap',
                        }}>
                        {Country}
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#222',
                        }}>
                        {Postcode}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            !!errors[index] && errors[index].field === 'State'
                              ? 'red'
                              : '#222',
                        }}>
                        {State}
                      </TableCell>
                    </TableRow>
                    {!!errors[index] && (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell colSpan={10}>
                          <span
                            style={{
                              color: 'red',
                              fontStyle: 'italic',
                            }}>{`Row ${index + 1} error: ${
                            errors[index].failReason
                          }`}</span>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

CSVImportPreviewList.defaultProps = {
  errors: {},
  data: [],
};

export default CSVImportPreviewList;
