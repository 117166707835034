import React from 'react';
import { connect } from 'react-redux';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { deletePlaylist, setDeletePlaylistDialog } from '../../lib/store/actions/playlists';
import {
  selectDeletePlaylistDialogOpen,
  selectIsDeletingPlaylist,
} from '../../lib/store/selectors/playlists';

function DeletePlaylistDialog({
  isOpen,
  isDeleting,
  playlistId,
  onSetDeletePlaylistDialog,
  onDeletePlaylist,
  playlistName,
  onNavigateToPlaylistsPage,
}) {
  const closeDialog = () => {
    onSetDeletePlaylistDialog(false);
  };

  const deleteCurrentPlaylist = () => {
    onDeletePlaylist({ playlistId }, () => {
      onSetDeletePlaylistDialog(false);
      onNavigateToPlaylistsPage();
    });
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={closeDialog}
      onConfirm={deleteCurrentPlaylist}
      isInProgress={isDeleting}
      inProgressMessage="Deleting, please wait ..."
      confirmationBody={
        <>
          Please confirm you'd like to delete the playlist "{playlistName}" and all its songs?
          <br />
          <br />
          <strong>Note:</strong> This action cannot be undone.
        </>
      }
      confirmationBtnText="Yes, delete it!"
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectDeletePlaylistDialogOpen(state),
    isDeleting: selectIsDeletingPlaylist(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetDeletePlaylistDialog: setDeletePlaylistDialog,
    onDeletePlaylist: deletePlaylist,
  }
)(DeletePlaylistDialog);
