import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-au';
import StatsList from '../../components/Activity/StatsList';
import StatTitleLink from '../../components/Activity/StatTitleLink';
import {
  selectQuizStatsData,
  selectHasCulturalQuizStatsData,
} from '../../lib/store/selectors/activity';
import { selectCulturalPrograms } from '../../lib/store/selectors/activity';
import { GQL_STATISTICS_CONTENT_TYPE } from '../../lib/constants';
import { useGetStats } from './useGetStats';

moment.locale('en-au');

function CulturalPrograms({ culturalPrograms, hasLoaded }) {
  const { fetchStats, loading } = useGetStats({
    type: GQL_STATISTICS_CONTENT_TYPE.culturalPrograms,
  });

  React.useEffect(() => {
    if (!hasLoaded) {
      const thirtyDaysAgo = moment().subtract(1, 'month').startOf('day');
      fetchStats(thirtyDaysAgo.toISOString());
    }
  }, [hasLoaded]);

  const renderRowTitle = (folderId, folderName) => {
    if (folderName === 'Unknown (DELETED)') return folderName;
    return (
      <StatTitleLink
        link={'culturalprograms'} //todo: Navigate inside cultural category when supported, currently the id that we get is the folder id, thus we can't navigate inside the category
        linkText={folderName}
        titleType="Program"
      />
    );
  };

  const refreshData = () => {
    const thirtyDaysAgo = moment().subtract(1, 'month').startOf('day');
    fetchStats(thirtyDaysAgo.toISOString());
  };

  return (
    <StatsList
      stats={culturalPrograms}
      isLoading={loading}
      renderRowTitle={renderRowTitle}
      onClickRefresh={refreshData}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    culturalPrograms: selectCulturalPrograms(state),
    stats: selectQuizStatsData(state),
    hasLoaded: selectHasCulturalQuizStatsData(state),
  };
};

export default connect(mapStateToProps)(CulturalPrograms);
