import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PeopleIcon from '@material-ui/icons/People';
import Page from '../../pages/PageV2';
import CSVImportMember from '../../containers/Organisation/Members/CSVImportMember';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function MemberImportPage() {
  const classes = useStyles();

  return (
    <Page
      title={
        <>
          <PeopleIcon className={classes.icon} /> Member CSV Import
        </>
      }
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <CSVImportMember />
    </Page>
  );
}

export default MemberImportPage;
