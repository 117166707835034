export const DIALOG_TYPES = {
  // A
  ADD_NEW_BROADCAST: 'ADD_NEW_BROADCAST',
  ADD_OR_UPDATE_AUTOMATED_MESSAGE: 'ADD_OR_UPDATE_AUTOMATED_MESSAGE',
  ADD_ADMIN: 'ADD_ADMIN',
  ADD_ORG: 'ADD_ORG',

  // U
  UPDATE_ORG_LICENSE: 'UPDATE_ORG_LICENSE',
  UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
  UPDATE_FILE_NAME: 'UPDATE_FILE_NAME',
};
