import React from 'react';
import { connect } from 'react-redux';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { deleteLyrics, setDeleteLyricsDialog } from '../../lib/store/actions/lyrics';
import {
  selectIsDeleteLyricsDialogOpen,
  selectIsDeletingLyrics,
} from '../../lib/store/selectors/lyrics';

function DeleteLyricsDialog({
  isOpen,
  isDeleting,
  fileId,
  onSetDeleteLyricsDialog,
  onDeleteLyrics,
  fileName,
  onDeleteCB,
}) {
  const closeDialog = () => {
    onSetDeleteLyricsDialog(false);
  };

  const deleteCurrentPlaylistSong = () => {
    onDeleteLyrics({ fileId }, () => {
      onSetDeleteLyricsDialog(false);
      if (onDeleteCB && typeof onDeleteCB === 'function') onDeleteCB();
    });
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={closeDialog}
      onConfirm={deleteCurrentPlaylistSong}
      isInProgress={isDeleting}
      inProgressMessage="Deleting, please wait ..."
      confirmationBody={
        <>
          Please confirm you'd like to delete "{fileName}"?
          <br />
          <br />
          <strong>Note:</strong> This action cannot be undone.
        </>
      }
      confirmationBtnText="Yes, delete it!"
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectIsDeleteLyricsDialogOpen(state),
    isDeleting: selectIsDeletingLyrics(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetDeleteLyricsDialog: setDeleteLyricsDialog,
    onDeleteLyrics: deleteLyrics,
  }
)(DeleteLyricsDialog);
