import { createSelector } from 'reselect';
import {
  API_GET_HYMNS_PLAYLIST_REQ,
  API_DEL_HYMNS_FILE_REQ,
} from '../actions/types';

const hymnsStateSelector = (state) => state.hymns;
export const selectHymns = createSelector(
  [hymnsStateSelector],
  (hymns) => hymns,
);

/* Playlist selectors */
const deleteHymnsFileDialogOpenSelector = (state) =>
  state.hymns.dialogs.deleteHymnsFile;
export const selectIsDeleteHymnsFileDialogOpen = createSelector(
  [deleteHymnsFileDialogOpenSelector],
  (isOpen) => isOpen,
);

const addymnsFileDialogOpenSelector = (state) =>
  state.hymns.dialogs.addHymnsFile;
export const selectIsAddHymnsFileDialogOpen = createSelector(
  [addymnsFileDialogOpenSelector],
  (isOpen) => isOpen,
);

const hymnsPlaylistsTableSelector = (state) => {
  return state.hymns.playlist;
};
export const selectHymnsPlaylistTableData = createSelector(
  [hymnsPlaylistsTableSelector],
  (playlist) => playlist,
);

const isFetchingHymnsPlaylistSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_GET_HYMNS_PLAYLIST_REQ,
  );
};
export const selectIsFetchingHymnsPlaylist = createSelector(
  [isFetchingHymnsPlaylistSelector],
  (isFetching) => isFetching,
);

const isDeletingHymnsFileSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_DEL_HYMNS_FILE_REQ,
  );
};
export const selectIsDeletingHymnsFile = createSelector(
  [isDeletingHymnsFileSelector],
  (isDeleting) => isDeleting,
);
