import { createSelector } from 'reselect';

// selectors
const isAuthSelector = state => {
  return !!state.user.auth && !!state.user.auth.idToken;
};
const authTokenSelector = state => state.user.auth.idToken;

export const makeSelectIsAuth = () =>
  createSelector(
    [isAuthSelector],
    isAuth => isAuth
  );

export const makeSelectAuthToken = () =>
  createSelector(
    [authTokenSelector],
    token => token
  );
