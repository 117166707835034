import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PrevIcon from '@material-ui/icons/ChevronLeft';
import NextIcon from '@material-ui/icons/ChevronRight';
import PersonIcon from '@material-ui/icons/Person';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator';
import TableTemplate from '../TableTemplate';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    // flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  usernameBox: {
    padding: theme.spacing(0.5, 1.5),
    borderRadius: '5px',
    background: '#efefef',
    color: theme.palette.primary.main,
  },
  name: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    display: 'block',
  },
  username: {
    display: 'block',
    fontSize: '1.2em',
    fontWeight: 'normal',
    color: '#808080',
  },
  platform: {
    display: 'block',
    fontSize: '1.2em',
    fontWeight: 'normal',
    color: '#404040',
  },
  userIcon: {
    fontSize: '4em',
    color: '#525252',
  },
  subscriptionDetail: {
    display: 'block',
    fontSize: '1em',
    fontWeight: 'normal',
    color: '#222222',
    marginTop: theme.spacing(1),
    '& > span': {
      fontWeight: 'bold',
      color: '#1f2733',
    },
  },
  subscriptionCancelled: {
    fontWeight: 'normal !important',
    color: '#222222',
    fontStyle: 'italic',
  },
  noActiveSubscription: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: '#e05b5b',
  },
  navDivider: {
    backgroundColor: '#b7b7b7',
    height: 30,
    width: 1,
    margin: '0 10px',
  },
}));

function SubscriptionInfo({ subscription, isSubscriptionActive, classes }) {
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center">
        <Typography component="h2" variant="h6">
          Subscription Info:
        </Typography>
        <Chip
          size="small"
          label={
            subscription.status === 'active'
              ? 'Active'
              : subscription.status === 'trialing'
              ? 'Trial'
              : 'In-Active'
          }
          color={isSubscriptionActive === true ? 'primary' : 'secondary'}
          style={{ marginLeft: 8 }}
        />
      </Box>

      {!!subscription && (
        <Box>
          <span className={classes.subscriptionDetail}>
            <span>Subscription Plan:</span>{' '}
            {(subscription?.type && subscription?.type?.includes('month')
              ? 'Monthly'
              : 'Yearly') || '-'}
          </span>
          <span className={classes.subscriptionDetail}>
            <span>Current Period Start:</span>{' '}
            {subscription?.current_period_start
              ? moment(subscription?.current_period_start).format(
                  'DD/MM/YYYY hh:mm:ss a',
                )
              : ''}
          </span>
          <span className={classes.subscriptionDetail}>
            <span>Current Period End:</span>{' '}
            {subscription?.current_period_end
              ? moment(subscription?.current_period_end).format(
                  'DD/MM/YYYY hh:mm:ss a',
                )
              : ''}
          </span>
          <span className={classes.subscriptionDetail}>
            <span>Auto-Renewal:</span>{' '}
            {subscription?.cancel_at_period_end ? 'On' : 'Off'}
          </span>
        </Box>
      )}

      {!subscription && (
        <Box>
          <Typography
            component="p"
            variant="body2"
            className={classes.noActiveSubscription}>
            Member does not have an active subscription
          </Typography>
        </Box>
      )}
    </>
  );
}

function MemberNavButton({ direction, link, name }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={direction === 'next' ? 'flex-start' : 'flex-end'}>
      <Typography component="div" variant="caption">
        {direction}
      </Typography>
      <Link to={link}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center">
          {direction === 'previous' && (
            <>
              <PrevIcon />
              <Box mx={0.2} />
            </>
          )}
          <Typography component="div" variant="body2">
            {name}
          </Typography>
          {direction === 'next' && (
            <>
              <Box mx={0.2} />
              <NextIcon />
            </>
          )}
        </Box>
      </Link>
    </Box>
  );
}

export default function Profile({
  member,
  navigation,
  isFetching,
  subscription,
}) {
  const classes = useStyles();

  if (isFetching)
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={2}>
        <LoadingIndicator size={25} />
      </Box>
    );

  const _tableRowRenderer = (row, index) => {
    const { id, Body, CreatedOn } = row; // eslint-disable-line

    return (
      <>
        <TableCell>{Body}</TableCell>
        <TableCell scope="row">
          {moment(CreatedOn).format('hh:mm a, DD/MM/YYYY ')}
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Grid container className={classes.gridRoot} spacing={2}>
        <Box mt={2} width="100%" />
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center">
            {navigation.prev && (
              <MemberNavButton
                direction="previous"
                link={navigation.prev.link}
                name={navigation.prev.name}
              />
            )}
            {(navigation.next || navigation.prev) && (
              <div className={classes.navDivider} />
            )}
            {navigation.next && (
              <MemberNavButton
                direction="next"
                link={navigation.next.link}
                name={navigation.next.name}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box mx={2} width="100%" display="flex" flexDirection="row">
            <Box>
              <PersonIcon className={classes.userIcon} />
            </Box>
            <Box>
              <span
                className={
                  classes.name
                }>{`${member.firstName} ${member.lastName}`}</span>
              <span className={classes.username}>{member.email}</span>
              <span className={classes.username}>
                {member.location.country === 'Australia'
                  ? `${member.location.postcode} ${member.location.state}`
                  : member.location.postcode}
                , {member.location.country}
              </span>
              <span className={classes.username}>Joined: {member.since}</span>
              <span className={classes.username}>
                Number of saved cards: {member?.savedCards?.length || 0}
              </span>
              <span className={classes.username}>
                <span>Last login:</span>{' '}
                {member?.lastAccess
                  ? moment(member?.lastAccess).format('DD/MM/YYYY hh:mm a')
                  : ''}
              </span>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <SubscriptionInfo
            subscription={subscription}
            isSubscriptionActive={subscription.isActive}
            classes={classes}
          />
        </Grid>
        {member?.messages.length > 0 && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2" variant="h6">
                Sent Messages
              </Typography>
              <Box mt={2}>
                <TableTemplate
                  data={member?.messages || []}
                  isLoading={isFetching}
                  tableHeaders={['Message', 'Sent at']}
                  empty={
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="row">
                      <p></p>
                    </Box>
                  }
                  loading={
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="row">
                      <LoadingIndicator size={28} />
                    </Box>
                  }
                  keyExtractor={(row) => row.id}
                  rowRenderer={_tableRowRenderer}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
