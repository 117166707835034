import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import MembersTableList from '../../components/Members/TableList';
import MembersTableRow from '../../components/Members/TableRow';
import { APP_ROUTES } from '../../lib/constants/app-routes';
import { selectMembersTableData } from '../../lib/store/selectors/members';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { GET_PAGINATED_MEMBERS } from '../../lib/api/graphql/queries/user';
import * as types from '../../lib/store/actions/types';

function MembersList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const paginationData = useSelector(selectMembersTableData);

  const [searchText, setSearchText] = React.useState('');
  const [sortOrder, setSortOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [resultsPerPage, setResultsPerPage] = React.useState(10);
  const [pageNumber, setPageNumber] = React.useState(0);

  const { loading: isFetching, refetch } = useQuery(GET_PAGINATED_MEMBERS, {
    variables: {
      search: searchText,
      sort: sortOrder === 'asc' ? 'ASC' : 'DESC',
      sortField:
        orderBy === 'location'
          ? 'State'
          : orderBy === 'joined'
          ? 'CreatedOn'
          : orderBy,
      resultsPerPage,
      pageNumber,
    },
    onCompleted: (data) => {
      if (!data || !Array.isArray(data?.paginatedClients?.edges)) return;

      const { edges, totalCount } = data.paginatedClients;

      const newPagination = {
        currentPage: pageNumber,
        totalRows: totalCount,
      };

      const users = edges.map((edge) => ({
        id: edge.id,
        firstName: edge.FirstName,
        lastName: edge.LastName,
        email: edge.Email,
        subscription: edge.subscription,
        product: edge?.subscription?.type,
        createdOn: edge.CreatedOn,
        isSubscriptionActive: edge?.subscription?.status === 'active',
        subscriptionStatus: edge?.subscription?.status,
        location: {
          state: edge.State || '',
          country: edge.Country || '',
          postcode: edge.Postcode || '',
        },
      }));

      dispatch({
        type: types.GET_MEMBERS_OK,
        pagination: newPagination,
        users: users,
        apiReqId: uuidV4(),
      });
    },
    onError: (error) => {
      dispatch(
        showNotification({
          message: `Get members: ${error.message}` || 'Cannot get members',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
    },
  });

  const fetchMembers = () => {
    refetch();
  };

  const onSearchMembers = (_searchText) => {
    setPageNumber(0);
    setSearchText(_searchText);
  };

  const handleChangePage = (newPage) => {
    const { pagination: _pagination } = paginationData;
    let _page;
    if (newPage === 'first') {
      _page = 0;
    } else if (newPage === 'last') {
      _page = Math.floor(_pagination.totalRows / resultsPerPage);
    } else {
      _page = newPage;
    }

    setPageNumber(_page);
    fetchMembers();
  };

  const handleChangeRowsPerPage = (rows) => {
    setResultsPerPage(rows);
    setPageNumber(1);
    fetchMembers();
  };

  const onClickMember = (memberId) => {
    history.push(`${APP_ROUTES.members}/${memberId}`);
  };

  const handleRenderRow = (data) => (
    <MembersTableRow onClickMember={onClickMember} {...data} />
  );

  const tableHeaders = [
    { id: 'name', label: 'Name', sortable: true },
    { id: 'email', label: 'Email' },
    { id: 'joined', label: 'Member Since', sortable: true },
    { id: 'location', label: 'Member Location', sortable: true },
    { id: 'subscription', label: 'Subscription Active' },
    { id: 'product', label: 'Subscription Plan' },
  ];

  const { members, pagination } = paginationData;

  const handleRequestSortTableColumn = (_, property) => {
    const isAsc = sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPageNumber(1);
    fetchMembers();
  };

  return (
    <MembersTableList
      rowsPerPageOptions={[10, 20, 30]}
      tableHeaders={tableHeaders}
      members={members}
      pagination={pagination}
      isFetching={isFetching}
      rowsPerPage={resultsPerPage}
      order={sortOrder}
      orderBy={orderBy}
      rowRenderer={handleRenderRow}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onChangePage={handleChangePage}
      onRequestSort={handleRequestSortTableColumn}
      onSearch={onSearchMembers}
    />
  );
}

export default MembersList;
