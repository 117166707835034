import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#01579b",
      light: "#0182e7",
      dark: "#012c4f",
    },
    secondary: {
      main: "#f38027",
      light: "#f7ab6f",
      dark: "#c35b0b",
    },
    tertiary: {
      main: "#b77dad",
      light: "#d2aed2",
      dark: "#955395",
    },
    error: {
      main: "rgb(210, 93, 107)",
    },
    background: {
      default: "#fff",
    },
    messages: {
      incoming: "#acdcff",
      outgoing: "#d0e8eb",
      RQC: "rgba(253, 114, 30, 0.9)",
    },
  },
});

export default theme;
