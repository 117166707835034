import { createReducer } from 'redux-create-reducer';
import sort from 'fast-sort';
import * as types from '../actions/types';

const initialState = {
  albums: [],
  albumNames: {},
  dialogs: {
    createAlbum: false,
    deleteAlbum: false,
    editAlbumName: false,
  },
  modes: {
    deleteImages: false,
  },
};

const mapImages = (img) => ({
  id: img.id,
  src: img.url,
  name: img.fileName,
  width: 1,
  height: 1,
});

const mapAlbums = (album) => ({
  id: album.id,
  images:
    album.storageFileList && Array.isArray(album.storageFileList)
      ? album.storageFileList.map(mapImages)
      : [],
  name: album.name,
  numImages:
    album.storageFileList && Array.isArray(album.storageFileList)
      ? album.storageFileList.length
      : 0,
});

const mapUpdatedAlbumImages = (images, albumId) => (album) => {
  if (album.id === albumId) {
    return { ...album, images: images.map(mapImages) };
  }
  return { ...album };
};

const mapUpdatedAlbumName = (albumId, name) => (album) => {
  if (album.id === albumId) {
    return { ...album, name };
  }
  return { ...album };
};

const mapDeletedAlbumImages = (imageIds, albumId) => (album) => {
  if (album.id === albumId) {
    const images = album.images.filter((image) => !imageIds.includes(image.id));
    return {
      ...album,
      images,
      numImages: images.length,
    };
  }
  return { ...album };
};

const sortAlbums = (albumsArray) => {
  return sort(albumsArray).by([{ asc: (c) => String(c.name).toLowerCase() }]);
};

export const imageAlbums = createReducer(initialState, {
  [types.GET_IMAGE_ALBUMS_OK](state, action) {
    const { albums } = action;
    return {
      ...state,
      albumNames: action.albums.reduce((obj, a) => {
        obj[a.id] = {
          name: a.name,
        };
        return obj;
      }, {}),
      albums: sortAlbums(albums.map(mapAlbums)),
    };
  },
  [types.GET_IMAGES_OK](state, action) {
    const { images, albumId } = action;
    return {
      ...state,
      albums: state.albums
        ? state.albums.map(mapUpdatedAlbumImages(images, albumId))
        : null,
    };
  },
  [types.DELETE_IMAGES_OPTIMISTIC](state, action) {
    const { albumId, imageIds } = action;

    return {
      ...state,
      albums: state.albums.map(mapDeletedAlbumImages(imageIds, albumId)),
    };
  },
  [types.SAVE_IMAGE_ALBUM_NAME_OK](state, action) {
    const { album } = action;

    return {
      ...state,
      albumNames: {
        ...state.albumNames,
        [album.id]: { name: album.name },
      },
      albums: state.albums.map(mapUpdatedAlbumName(album.id, album.name)),
    };
  },
  [types.DELETE_IMAGE_ALBUM_OK](state, action) {
    const { albumId } = action;
    const { [albumId]: unwantedAlbum, ...restNames } = state.albumNames; // eslint-disable-line
    return {
      ...state,
      albumNames: {
        ...restNames,
      },
      albums: sortAlbums(state.albums.filter((a) => a.id !== albumId)),
    };
  },
  [types.ADD_IMAGE_ALBUM_OPTIMISTIC](state, action) {
    return {
      ...state,
      albumNames: {
        ...state.albumNames,
        [action.album.id]: { name: action.album.name },
      },
      albums: sortAlbums([...state.albums, action.album]),
    };
  },
  [types.ADD_IMAGE_ALBUM_OK](state, action) {
    const { album } = action;
    const { [album.tempId]: albm, ...restNames } = state.albumNames;

    return {
      ...state,
      albumNames: {
        ...restNames,
        [album.realId]: { ...albm },
      },
      albums: state.albums.map((a) => {
        if (a.id === album.tempId) {
          return {
            ...a,
            id: album.realId,
          };
        }
        return { ...a };
      }),
    };
  },
  // Dialogs
  [types.TOGGLE_CREATE_IMAGE_ALBUM_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        createAlbum: action.isOpen,
      },
    };
  },
  [types.TOGGLE_EDIT_ALBUM_NAME_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        editAlbumName: action.isOpen,
      },
    };
  },
  [types.TOGGLE_DELETE_IMAGE_ALBUM_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        deleteAlbum: action.isOpen,
      },
    };
  },
  // Modes
  [types.SET_DELETE_IMAGE_MODE](state, action) {
    return {
      ...state,
      modes: {
        ...state.modes,
        deleteImages: action.isDelete,
      },
    };
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
