import React from 'react';
import { Link } from 'react-router-dom';

function StatTitleLink({ link, linkText, titleType, isExternal }) {
  const linkComponent = isExternal ? (
    <a target="_blank" rel="noopener noreferrer" href={link}>
      {linkText}
    </a>
  ) : (
    <Link to={link}>{linkText}</Link>
  );
  return (
    <>
      {titleType}: {linkComponent}
    </>
  );
}

export default StatTitleLink;
