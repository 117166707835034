import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
    flexGrow: 1,
  },
}));

export default function Main({ tabIndex, onChangeTab, tabTitles, children }) {
  const classes = useStyles();
  const hasTitles = Array.isArray(tabTitles) && tabTitles.length > 0;

  return (
    <Paper className={classes.root}>
      <Tabs
        value={tabIndex}
        onChange={onChangeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {hasTitles && tabTitles.map(title => <Tab key={title} label={title} />)}
      </Tabs>
      <Divider />
      <Box component="div" p={2}>
        {children}
      </Box>
    </Paper>
  );
}
