import { authRequest } from './apiSetup';
import { API_ENDPOINTS } from '../../constants/api-endpoints';

/*
 * Hymns API
 ** */

export async function getHymnsPlaylist(idToken) {
  const endpoint = API_ENDPOINTS.hymns.replace(/:HYMN_ID/, '');
  const response = await authRequest(idToken, { method: 'GET', endpoint });
  return response.data;
}

export async function deletePlaylistFile(idToken, { fileId }) {
  const endpoint = API_ENDPOINTS.hymns.replace(/:HYMN_ID/, fileId);
  const response = await authRequest(idToken, { method: 'DELETE', endpoint });
  return response.state === 1;
}
