import { createReducer } from 'redux-create-reducer';
import * as types from '../actions/types';

const initialState = {
  list: [],
  names: {},
  dialogs: {
    addQuizzes: false,
    deleteQuiz: false,
    editQuizName: false,
  },
};

const mapUpdatedQuizName = (quizId, name) => quiz => {
  if (quiz.id === quizId) {
    return { ...quiz, name };
  }
  return { ...quiz };
};

export const quizzes = createReducer(initialState, {
  // Playlists
  [types.GET_QUIZZES_OK](state, action) {
    return {
      ...state,
      names: action.quizzes.reduce((obj, a) => {
        obj[a.id] = {
          name: a.name,
        };
        return obj;
      }, {}),
      list: [...action.quizzes],
    };
  },
  [types.DELETE_QUIZZES_OK](state, action) {
    const { fileId } = action;
    const { [fileId]: unwantedQuiz, ...restNames } = state.names; // eslint-disable-line
    return {
      ...state,
      names: {
        ...restNames,
      },
      list: state.list.filter(p => p.id !== fileId),
    };
  },
  [types.UPDATE_QUIZ_NAME_OK](state, action) {
    const { quiz } = action;

    return {
      ...state,
      names: {
        ...state.names,
        [quiz.id]: { name: quiz.name },
      },
      list: state.list.map(mapUpdatedQuizName(quiz.id, quiz.name)),
    };
  },
  // Dialogs
  [types.TOGGLE_ADD_QUIZZES_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        addQuizzes: action.isOpen,
      },
    };
  },
  [types.TOGGLE_DELETE_QUIZ_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        deleteQuiz: action.isOpen,
      },
    };
  },
  [types.TOGGLE_EDIT_QUIZ_NAME_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        editQuizName: action.isOpen,
      },
    };
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
