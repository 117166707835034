import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DangerIcon from '@material-ui/icons/ErrorOutlineOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    borderRadius: 5,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: theme.spacing(2),
  },
  infoBg: {
    backgroundColor: 'rgb(232, 244, 253)',
  },
  infoText: {
    color: 'rgb(13, 60, 97)',
  },
  infoIcon: {
    color: '#2196f3',
  },
  dangerBg: {
    backgroundColor: 'rgb(253, 236, 234)',
  },
  dangerText: {
    color: 'rgb(97, 26, 21)',
  },
  dangerIcon: {
    color: '#f44336',
  },
  warningBg: {
    backgroundColor: 'rgb(255, 244, 229)',
  },
  warningText: {
    color: 'rgb(102, 60, 0)',
  },
  warningIcon: {
    color: '#ff9800',
  },
}));

function Alert({ severity, title, children }) {
  const classes = useStyles();

  let Icon = InfoIcon;
  let textColor = classes.infoText;
  let iconColor = classes.infoIcon;
  let bgColor = classes.infoBg;
  if (severity === 'warning') {
    Icon = WarningIcon;
    bgColor = classes.warningBg;
    textColor = classes.warningText;
    iconColor = classes.warningIcon;
  }
  if (severity === 'danger') {
    Icon = DangerIcon;
    bgColor = classes.dangerBg;
    textColor = classes.dangerText;
    iconColor = classes.dangerIcon;
  }

  return (
    <div className={clsx(classes.root, bgColor)}>
      {title && <span className={classes.title}>{title}</span>}
      <div className={classes.messageContainer}>
        <div className={classes.iconContainer}>
          <Icon className={iconColor} />
        </div>
        <span className={textColor}>{children}</span>
      </div>
    </div>
  );
}

Alert.defaultProps = {
  severity: 'info',
  title: '',
};

export default Alert;
