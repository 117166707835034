import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import LoadingIndicator from './LoadingIndicator';

const StyledTableSortLabel = withStyles(theme => ({
  root: {
    '&:hover': {
      color: `${theme.palette.common.white} !important`,
    },
  },
  active: {
    color: `${theme.palette.common.white} !important`,
  },
  icon: {
    color: `${theme.palette.common.white} !important`,
  },
}))(TableSortLabel);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useEnhancedTableHeadStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function EnhancedTableHead(props) {
  const classes = useEnhancedTableHeadStyles();
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = property => event => {
    if (onRequestSort && typeof onRequestSort === 'function') onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <StyledTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </StyledTableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.defaultProps = {
  headCells: [],
};

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
    padding: theme.spacing(0, 1),
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  greyBg: {
    backgroundColor: '#f1f1f1',
  },
  whiteBg: {
    backgroundColor: theme.palette.common.white,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  button: {
    padding: '0 3px',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  filterBox: {
    padding: theme.spacing(0, 1, 1),
  },
}));

function TableSortableTemplate({
  tableHeaders,
  tableData,
  onFilter,
  filterText,
  filterPlaceholder,
  filterLabel,
  isLoading,
  rowRenderer,
  sortedHeaders,
  order,
  orderBy,
  onRequestSort,
  empty,
}) {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rowHeight = 53;
  const emptyRows =
    rowsPerPage < tableData.length
      ? rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage)
      : 0;

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <Paper className={classes.root}>
      <Box display="flex" flexDirection="row" width="100%" className={classes.filterBox}>
        <TextField
          id="table-filter"
          label={filterLabel || 'Filter Data'}
          type="search"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          placeholder={filterPlaceholder || 'Filter table data ...'}
          onChange={onFilter}
          value={filterText}
          autoComplete="off"
        />
      </Box>
      <div className={classes.tableWrapper}>
        <Table size="small" className={classes.table}>
          {!sortedHeaders && (
            <TableHead>
              <TableRow>
                {tableHeaders.map(header => (
                  <StyledTableCell key={header.id}>{header.label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          {sortedHeaders && (
            <EnhancedTableHead
              headCells={tableHeaders}
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
            />
          )}
          <TableBody>
            {!isLoading && tableData.length === 0 && empty && (
              <TableRow>
                <TableCell colSpan={tableHeaders.length}>{empty}</TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow>
                <TableCell colSpan={tableHeaders ? tableHeaders.length : 1}>
                  <Box display="flex" justifyContent="center" flexDirection="row">
                    <LoadingIndicator size={28} />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
              <TableRow
                key={i}
                className={clsx(classes.tableRow, i % 2 === 0 ? classes.greyBg : classes.whiteBg)}
              >
                {rowRenderer && rowRenderer({ rowData: row })}
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: rowHeight * emptyRows }}>
                <TableCell colSpan={tableHeaders ? tableHeaders.length : 1} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={4}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
}

TableSortableTemplate.defaultProps = {
  tableData: [],
  sortedHeaders: false,
};

export default TableSortableTemplate;
