import ImageResizer from 'react-image-file-resizer';

export function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export const reduceImageSize = ({ file, width, height, compression, fileType }, callback) => {
  const maxHeight = height !== undefined ? height : 1080;
  const maxWidth = width !== undefined ? width : 1080;
  const _compression = compression !== undefined ? compression : 90;
  const _fileType = fileType !== undefined ? fileType : 'JPEG';

  ImageResizer.imageFileResizer(
    file,
    maxWidth,
    maxHeight,
    _fileType,
    _compression,
    0,
    fileBlob => {
      const newFile = new File([fileBlob], file.name, { type: file.type });
      newFile.id = file.id;
      callback(null, newFile);
    },
    'blob'
  );
};

export function truncateString(str, maxLength, useWordBoundary) {
  if (typeof str !== 'string') return '';
  if (str.length <= maxLength) return str;
  const subString = str.substr(0, maxLength - 1);
  return `${useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString} ...`;
}

// Source:: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`; // eslint-disable-line
}

// Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
export const convertToCsv = function(content, fileName, mimeType) {
  const a = document.createElement('a');
  const _mimeType = mimeType || 'application/octet-stream';

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([content], {
        type: _mimeType,
      }),
      fileName
    );
  } else if (URL && 'download' in a) {
    // html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: _mimeType,
      })
    );
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.location.href = `data:application/octet-stream,${encodeURIComponent(content)}`; // only this mime type is supported
  }
};
