import { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Dialog from '../../../components/Organisation/AddOrgMemberDialog';
import {
  toggleAddMemberDialog,
  refreshOrgMembers,
} from '../../../lib/store/actions/organisation';
import {
  selectIsAddMemberDialogOpen,
  selectIsEmailInUse,
  makeSelectIsOrgActive,
} from '../../../lib/store/selectors/organisation';
import { CHECK_EMAIL_EXISTS } from '../../../lib/api/graphql/queries/user';
import { ADD_ORG_USER } from '../../../lib/api/graphql/mutations/organizations';
import * as types from '../../../lib/store/actions/types';
import { showNotification } from '../../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../../lib/constants/notification-types';
import { makeSelectUserOrgId } from '../../../lib/store/selectors/user';

const AddOrgMemberDialog = ({
  isOpen,
  isEmailInUse,
  isOrgActive,
  onRefreshMembers,
  onToggleDialog,
}) => {
  const dispatch = useDispatch();
  const userOrgId = useSelector(makeSelectUserOrgId());

  const [member, setMember] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    postcode: '',
    state: '',
    country: '',
    active: false,
  });

  const [checkEmail, { loading: isCheckingEmail }] = useLazyQuery(
    CHECK_EMAIL_EXISTS,
    {
      onCompleted: (data) => {
        dispatch({
          type: types.CHK_ORG_MEMBER_EMAIL_OK,
          isInUse: !!data?.checkEmail,
          apiReqId: uuidV4(),
        });
      },
      onError: (error) => {
        dispatch(
          showNotification({
            message:
              `Validate email: ${error.message}` || 'Cannot validate email',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
      },
    },
  );

  const [addMember, { loading: isAdding }] = useMutation(ADD_ORG_USER, {
    refetchQueries: ['Organization', 'PaginatedClients'],
    onCompleted: () => {
      dispatch(
        showNotification({
          message: 'New member added',
          options: {
            variant: NOTIFICATION_TYPES.Success,
          },
        }),
      );
      onRefreshMembers();
      onToggleDialog();
    },

    onError: (error) => {
      dispatch(
        showNotification({
          message: `Error: ${error.message}` || 'Error adding member',
          options: {
            variant: NOTIFICATION_TYPES.Error,
          },
        }),
      );
    },
  });

  const handleAddMember = async (_member) => {
    await addMember({
      variables: {
        member: _member,
        orgId: userOrgId,
      },
    });
  };

  const handleClose = () => {
    onToggleDialog();
  };

  const handleCheckEmail = async (email) => {
    await checkEmail({ variables: { email } });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMember((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Dialog
      isLoading={isAdding}
      isOpen={isOpen}
      isEmailInUse={isEmailInUse}
      isCheckingEmailInUse={isCheckingEmail}
      isOrgActive={isOrgActive}
      onCheckEmail={handleCheckEmail}
      onCancel={handleClose}
      onSubmit={handleAddMember}
      onChange={handleChange}
      member={member}
    />
  );
};

const mapStateToProps = (state) => {
  const selectIsOrgActive = makeSelectIsOrgActive();

  return {
    isOpen: selectIsAddMemberDialogOpen(state),
    isEmailInUse: selectIsEmailInUse(state),
    isOrgActive: selectIsOrgActive(state),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    onRefreshMembers: refreshOrgMembers,
    onToggleDialog: toggleAddMemberDialog,
  }),
)(AddOrgMemberDialog);
