import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import Post from './Post';
import VirtualList from '../VirtualList';
import LoadingIndicator from '../LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 1.5),
    height: 'calc(100vh - 400px)',
    border: 'solid',
    borderColor: grey[300],
    borderWidth: 1,
    borderRadius: 4,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

function Messages({ messages, isLoading }) {
  const classes = useStyles();

  const _rowRenderer = (msg) => (
    <Post
      {...msg}
      bodyTextStyles={{
        whiteSpace: 'pre',
        maxHeight: '200px',
        overflow: 'auto',
      }}
    />
  );

  return (
    <div className={classes.root}>
      <VirtualList data={messages} rowRender={_rowRenderer} />
      {isLoading && (
        <div className={classes.loadingContainer}>
          <LoadingIndicator size={26} />
        </div>
      )}
    </div>
  );
}

export default Messages;
