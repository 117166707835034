import { useState } from 'react';
import fastSort from 'fast-sort';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Table from '../../components/SMOrganisations/TableList';
import { APP_ROUTES } from '../../lib/constants/app-routes';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { GET_ORGANIZATIONS } from '../../lib/api/graphql/queries/organizations';

const OrganisationsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [filterText, setFilterText] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const { data, loading: isLoading } = useQuery(GET_ORGANIZATIONS, {
    onError: () => {
      dispatch(
        showNotification({
          options: { variant: NOTIFICATION_TYPES.Error },
          message: 'Error while fetching organisations',
        }),
      );
    },
  });

  const smOrgs =
    data?.organizations?.map((org) => ({
      id: org.id,
      name: org.Name,
      activeMembers: org.active_count || 0,
      active: org.Active,
      createdOn: org.CreatedOn,
      licences: org.Licences,
    })) || [];

  const onFilterOrgs = (event) => {
    const { value } = event.target;
    setFilterText(String(value).toLowerCase());
  };

  const onClickOrg = (orgId) => () => {
    history.push(`${APP_ROUTES.smOrganisations}/${orgId}`);
  };

  const getSortFunction = (columnId) => {
    const sortFuncs = {
      name: (s) => s.name,
      license: (s) => s.licences,
      status: (s) => s.active,
      joined: (s) => new Date(s.createdOn),
    };

    return sortFuncs[columnId];
  };

  const handleRequestSortTableColumn = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortFunc =
    order === 'asc'
      ? { asc: getSortFunction(orderBy) }
      : { desc: getSortFunction(orderBy) };

  const _orgs =
    filterText.length > 0
      ? fastSort(
          smOrgs.filter((c) =>
            String(c.name).toLowerCase().includes(filterText),
          ),
        ).by([sortFunc])
      : fastSort([...smOrgs]).by([sortFunc]);

  const tableHeaders = [
    { id: 'name', label: 'Name' },
    { id: 'status', label: 'Status' },
    { id: 'license', label: 'Licenses in use' },
    { id: 'joined', label: 'Created' },
  ];

  return (
    <Table
      tableHeaders={tableHeaders}
      organisations={_orgs}
      onFilter={onFilterOrgs}
      onClickOrg={onClickOrg}
      isLoading={isLoading}
      order={order}
      orderBy={orderBy}
      onRequestSort={handleRequestSortTableColumn}
    />
  );
};

export default OrganisationsList;
