import { loginRequest, authRequest } from './apiSetup';
import { API_ENDPOINTS } from '../../constants/api-endpoints';

/*
 * Authentication API
 ** */

export function login({ username, password, firebaseId }) {
  return loginRequest(username, password, firebaseId);
}

export function logout(idToken, firebaseId) {
  const endpoint = API_ENDPOINTS.logout.replace(/:FIREBASE_DEVICE_ID/, firebaseId);
  return authRequest(idToken, { method: 'GET', endpoint }).then(() => true);
}
