import { createReducer } from 'redux-create-reducer';
import * as types from '../actions/types';

const initialState = [];

export const messages = createReducer(initialState, {
  [types.GET_MESSAGE_OK](state, action) {
    const { message } = action;
    return [message, ...state];
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
