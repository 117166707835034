import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import { USER_LOGIN, FORGOT_PWD } from '../lib/api/graphql/mutations/login';
import { GET_USER_DETAILS } from '../lib/api/graphql/queries/user';

import { APP_ROUTES } from '../lib/constants/app-routes';
import { makeSelectIsAuth } from '../lib/store/selectors/auth';
import { makeSelectUserRole } from '../lib/store/selectors/user';
import { showNotification } from '../lib/store/actions/notifications';
import { USER_ROLES } from '../lib/constants/user-roles';
import * as types from '../lib/store/actions/types';

const Login = ({ isAuth, role }) => {
  const dispatch = useDispatch();

  const showToast = (message, variant) => {
    dispatch(
      showNotification({
        message: message,
        options: {
          variant,
        },
      }),
    );
  };

  const [getUserDetails, { loading: gettingUserDetails }] = useLazyQuery(
    GET_USER_DETAILS,
    {
      onError: (error) => {
        showToast(error?.message || 'Login failed', 'error');
      },
    },
  );

  const [loginUser, { loading: loginLoading }] = useMutation(USER_LOGIN, {
    onCompleted: async (data) => {
      let decodedJwt;

      try {
        decodedJwt = jwtDecode(data.login);
      } catch (e) {
        showToast(e?.message || 'Login failed', 'error');
        return;
      }

      if (!decodedJwt) {
        return showToast('Login failed', 'error');
      }

      const { data: gqlUserData } = await getUserDetails({
        variables: { userId: decodedJwt?.ID },
        context: {
          headers: {
            Authorization: `Bearer ${data.login}`,
          },
        },
      });

      const userData = {
        auth: {
          idToken: data.login,
          firebaseId: 'my-cool-admin-firebase-id',
        },
        id: decodedJwt?.ID,
        email: gqlUserData?.user?.email,
        firstName: gqlUserData?.user?.FirstName,
        lastName: gqlUserData?.user?.LastName,
        organisationId: !decodedJwt?.primarygroupsid
          ? '000000000000000000000000' // copied from the old code (lib/store/reducers/user.js)
          : gqlUserData?.user?.Organisation?.id,
      };

      dispatch({
        type: types.LOGIN_OK,
        userData,
        apiReqId: uuidV4(),
      });
    },
    onError: (error) => {
      showToast(error?.message || 'Login failed', 'error');
    },
  });

  const [forgotPwd, { loading: fpwdLoading }] = useMutation(FORGOT_PWD, {
    onError: (error) => {
      showToast(error?.message || 'Forgot password failed', 'error');
    },
  });

  const onSubmit = async (username, password) => {
    await loginUser({
      variables: {
        email: username,
        password: password,
      },
    });
  };

  const onForgotPass = async (email) => {
    await forgotPwd({
      variables: {
        email,
      },
    });
  };

  if (isAuth && role === USER_ROLES.ADMIN) {
    return <Redirect to={APP_ROUTES.members} />;
  }

  if (isAuth && role === USER_ROLES.ORGANISATION) {
    return <Redirect to={APP_ROUTES.organisation.members} />;
  }

  return (
    <LoginPage
      onSubmit={onSubmit}
      onForgotPass={onForgotPass}
      isLoading={loginLoading || gettingUserDetails}
    />
  );
};

const mapStateToProps = (state) => {
  const selectIsAuth = makeSelectIsAuth();
  const selectUserRole = makeSelectUserRole();
  return {
    isAuth: selectIsAuth(state),
    role: selectUserRole(state),
  };
};

export default connect(mapStateToProps)(Login);
