import React from 'react';
import { makeStyles } from '@material-ui/styles';
import BusinessIcon from '@material-ui/icons/Business';
import Page from '../PageV2';
import SMOrgProfile from '../../containers/SMOganisations/Profile';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function OrganisationPage() {
  const classes = useStyles();

  return (
    <Page
      title={
        <>
          <BusinessIcon className={classes.icon} /> Organisation Details
        </>
      }
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <SMOrgProfile />
    </Page>
  );
}

export default OrganisationPage;
