import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: 0,
    listStyleType: "none",
    overflowY: "auto",
    paddingBottom: "5px",
  },

  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#718096",
    fontFamily: "Archivo, sans-serif",
    fontSize: "14px",
  },

  title: {
    color: "#718096",
    fontFamily: "Archivo, sans-serif",
    fontWeight: 500,
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  handCursor: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export function Breadcrumbs({
  crumbs,
  textStyles,
  separator,
  hideArrow,
  highlightLast,
  maxCrumbs,
}) {
  const classes = useStyles();
  const parsedCrumbs = handleLimitCrumbs();

  function handleHighlightLast(index) {
    if (highlightLast && parsedCrumbs.length - 1 === index) {
      return {
        color: "#4A5568",
        fontWeight: 600,
      };
    }
    return {};
  }

  function handleLimitCrumbs() {
    if (maxCrumbs && maxCrumbs < crumbs.length) {
      const crumbsToHide = crumbs.length - maxCrumbs;
      const newCrumbs = crumbs.slice(crumbsToHide);
      return [
        {
          title: "...",
          onClick: crumbs[crumbsToHide - 1].onClick,
        },
        ...newCrumbs,
      ];
    }
    return crumbs;
  }

  return (
    <ul className={classes.root}>
      {hideArrow ? null : (
        <li className={classes.item} style={{ marginRight: "5px" }}>
          <ArrowBackIosIcon fontSize="inherit" />
        </li>
      )}
      {parsedCrumbs.map(({ title, onClick }, index) => (
        <li
          key={index}
          className={clsx(classes.item, !!onClick && classes.handCursor)}
          style={{ marginTop: "5px" }}
          onClick={onClick}
        >
          <Typography
            component="span"
            className={classes.title}
            style={{
              ...textStyles,
              ...handleHighlightLast(index),
            }}
          >
            {title}
          </Typography>
          {index < parsedCrumbs.length - 1 ? (
            separator ? (
              separator
            ) : (
              <div style={{ margin: "0 10px" }}>/</div>
            )
          ) : null}
        </li>
      ))}
    </ul>
  );
}

Breadcrumbs.defaultProps = {
  textStyles: {},
};

export default Breadcrumbs;
