import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import LoadingIndicator from '../LoadingIndicator';
import TableTemplate from '../TableTemplate';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  button: {
    padding: '0 3px',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  filterBox: {
    padding: theme.spacing(0, 1, 1),
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  nameIndex: {
    marginRight: theme.spacing(1),
  },
  quizName: {
    fontWeight: 'bold',
    fontSize: '1em',
    display: 'block',
  },
  playBtn: {
    '&:hover > span': {
      color: '#4dff4d',
    },
  },
  pauseBtnIcon: {
    color: '#ff9200',
  },
  stopBtnIcon: {
    color: '#ff3b3b',
  },
}));

export default function QuizzesTable({
  tableHeaders,
  quizzes,
  onFilter,
  filterText,
  isFetching,
  onClickDelete,
  onClickEdit,
  emptyText,
}) {
  const classes = useStyles();

  /* Quiz Functions */
  const handleClickDelete = id => () => {
    onClickDelete(id);
  };

  const handleClickEdit = id => () => {
    onClickEdit(id);
  };

  /* Table helper/render functions */
  const _tableKeyExtractor = row => row.id;

  const _tableRowRenderer = (row, index) => {
    const { id, url, name } = row; // eslint-disable-line

    return (
      <>
        <TableCell component="th" scope="row">
          <div className={classes.nameContainer}>
            <div className={classes.nameIndex}>{`${index + 1}.`}</div>
            <div>
              <span className={classes.quizName}>
                <a target="_blank" rel="noopener noreferrer" href={url}>
                  {name}
                </a>
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <IconButton aria-label={`Edit ${name}`} onClick={handleClickEdit(id)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell scope="row">
          <IconButton aria-label={`Delete ${name}`} onClick={handleClickDelete(id)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <Paper className={classes.root}>
      <Box display="flex" flexDirection="row" width="100%" className={classes.filterBox}>
        <TextField
          id="contacts-search"
          label="Filter by quiz name"
          type="search"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          placeholder="Type in the quiz name ..."
          onChange={onFilter}
          value={filterText}
          autoComplete="off"
        />
      </Box>
      <TableTemplate
        data={quizzes}
        isLoading={isFetching}
        tableHeaders={tableHeaders}
        empty={
          <Box display="flex" justifyContent="center" flexDirection="row">
            <p>{emptyText}</p>
          </Box>
        }
        loading={
          <Box display="flex" justifyContent="center" flexDirection="row">
            <LoadingIndicator size={28} />
          </Box>
        }
        keyExtractor={_tableKeyExtractor}
        rowRenderer={_tableRowRenderer}
      />
    </Paper>
  );
}
