import React, { Component } from 'react';

class DragAndDropForm extends Component {
  dropRef = React.createRef();

  dragCounter = 0;

  state = {
    isDragging: false,
  };

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter = this.dragCounter + 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ isDragging: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter = this.dragCounter - 1;
    if (this.dragCounter === 0) {
      this.setState({ isDragging: false });
    }
  };

  handleDrop = e => {
    const { onHandleDrop } = this.props;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onHandleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  render() {
    const { isDragging } = this.state;
    const { children } = this.props;

    return (
      <div style={{ display: 'inline-block', position: 'relative' }} ref={this.dropRef}>
        {isDragging && (
          <div
            style={{
              border: 'dashed grey 4px',
              backgroundColor: 'rgba(255,255,255,.8)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
              borderRadius: '50%',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '40%',
                right: 0,
                left: 0,
                textAlign: 'center',
                color: 'grey',
                fontSize: 36,
              }}
            >
              <div>drop here :)</div>
            </div>
          </div>
        )}
        {children}
      </div>
    );
  }
}
export default DragAndDropForm;

/**

follow - https://malcoded.com/posts/react-file-upload/ for multiple progressive image uploads
follow - https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929 for creating a file drop zone
follow - https://getbootstrap.com/docs/4.0/components/forms/#file-browser for creating a file input wiht bootstrap
follow - https://programmingwithmosh.com/javascript/react-file-upload-proper-server-side-nodejs-easy/ for ideas for file validation

*/
