import { createReducer } from 'redux-create-reducer';
import sort from 'fast-sort';
import * as types from '../actions/types';

const initialState = {
  lists: [],
  dialogs: {
    createPlaylist: false,
    deletePlaylist: false,
    updatePlaylistName: false,
    addSongsToPlaylist: false,
    deletePlaylistSong: false,
  },
};

const mapUpdatedPlaylistName = (playlistId, name) => (playlist) => {
  if (playlist.id === playlistId) {
    return { ...playlist, name };
  }
  return { ...playlist };
};

const sortPlaylists = (arr) => {
  return sort(arr).by([{ asc: (o) => String(o.name).toLowerCase() }]);
};

export const playlists = createReducer(initialState, {
  // PLaylists
  [types.GET_PLAYLISTS_OK](state, action) {
    return {
      ...state,
      lists: action.playlists,
    };
  },
  [types.GET_PLAYLIST_OK](state, action) {
    const { playlistId, playlist } = action;

    const _playlists = [...state.lists];
    const index = _playlists.findIndex((m) => m.id === playlistId);

    if (index < 0) {
      return {
        ...state,
        lists: sortPlaylists([...state.lists, playlist]),
      };
    }

    return {
      ...state,
      lists: state.lists.map((m) => {
        if (m.id === playlistId) {
          return { ...m, ...playlist };
        }
        return { ...m };
      }),
    };
  },
  [types.ADD_PLAYLIST_OPTIMISTIC](state, action) {
    return {
      ...state,
      lists: sortPlaylists([...state.lists, action.playlist]),
    };
  },
  [types.ADD_PLAYLIST_OK](state, action) {
    const { playlist } = action;
    return {
      ...state,
      lists: state.lists.map((a) => {
        if (a.id === playlist.tempId) {
          return {
            ...a,
            id: playlist.realId,
          };
        }
        return { ...a };
      }),
    };
  },
  [types.UPDATE_PLAYLIST_NAME_OK](state, action) {
    const { playlist } = action;

    return {
      ...state,
      lists: state.lists.map(
        mapUpdatedPlaylistName(playlist.id, playlist.name),
      ),
    };
  },
  [types.DELETE_PLAYLIST_OK](state, action) {
    const { playlistId } = action;

    return {
      ...state,
      lists: sortPlaylists(state.lists.filter((a) => a.id !== playlistId)),
    };
  },
  [types.DELETE_PLAYLIST_SONG_OK](state, action) {
    const { playlistId, songId } = action;

    return {
      ...state,
      lists: state.lists.map((a) => {
        if (a.id === playlistId) {
          return {
            ...a,
            list: a.list.filter((b) => b.id !== songId),
          };
        }
        return { ...a };
      }),
    };
  },
  [types.UPDATE_PLAYLIST_ARTWORK](state, action) {
    const { playlistId, artworkURL } = action;

    return {
      ...state,
      lists: state.lists.map((a) => {
        if (a.id === playlistId) {
          return {
            ...a,
            artwork: artworkURL,
          };
        }
        return { ...a };
      }),
    };
  },
  // Dialogs
  [types.TOGGLE_CREATE_PLAYLIST_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        createPlaylist: action.isOpen,
      },
    };
  },
  [types.TOGGLE_DELETE_PLAYLIST_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        deletePlaylist: action.isOpen,
      },
    };
  },
  [types.TOGGLE_EDIT_PLAYLIST_NAME_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        updatePlaylistName: action.isOpen,
      },
    };
  },
  [types.TOGGLE_ADD_SONGS_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        addSongsToPlaylist: action.isOpen,
      },
    };
  },
  [types.TOGGLE_DELETE_PLAYLIST_SONG_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        deletePlaylistSong: action.isOpen,
      },
    };
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
