import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { green, red, orange, grey } from '@material-ui/core/colors';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

const useCardStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  cardGreen: {
    boxShadow: 'none',
    backgroundColor: '#f5fff8',
  },
  cardOrange: {
    boxShadow: 'none',
    backgroundColor: '#fff4d9',
  },
  cardRed: {
    boxShadow: 'none',
    backgroundColor: '#fff0f0',
  },
  card: {
    boxShadow: 'none',
    border: '1px solid',
    borderColor: grey[200],
  },
  textGreen: {
    color: green[600],
  },
  textOrange: {
    color: orange[600],
  },
  textRed: {
    color: red[600],
  },
  title: {
    fontSize: 14,
  },
  buttons: {
    justifyContent: 'center',
  },
});

const LicenseCount = ({ licenses, activeMembers, members, disabled, onClickUpdate }) => {
  const classes = useCardStyles();
  const ratio = activeMembers / licenses;
  let textClass = classes.textGreen;
  const total = activeMembers + licenses;
  let variant = 'h2';

  if (total >= 20000) {
    variant = 'h3';
  }

  if (total >= 100000) {
    variant = 'h4';
  }

  if (total >= 1000000) {
    variant = 'h5';
  }

  if (ratio >= 0.8) {
    textClass = classes.textRed;
  }

  if (ratio > 0.6 && ratio < 0.85) {
    textClass = classes.textOrange;
  }

  return (
    <Card
      classes={{
        root: classes.card,
      }}
      className={classes.root}
    >
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Licenses Allocated
        </Typography>
        <Typography variant={variant} component="h2" className={textClass}>
          {activeMembers} / {licenses}
        </Typography>
        <Typography component="div" variant="caption">
          {activeMembers} user accounts allocated out of a maximum of {licenses}
        </Typography>
        <Typography component="div" variant="caption">
          Total number of members: {members}
        </Typography>
      </CardContent>
      <CardActions classes={{ root: classes.buttons }}>
        <Button
          disabled={disabled}
          color="default"
          variant="outlined"
          size="small"
          onClick={onClickUpdate}
        >
          Update Licence Count
        </Button>
      </CardActions>
    </Card>
  );
};

LicenseCount.defaultProps = {
  licenses: 0,
  activeMembers: 0,
};

export default LicenseCount;
