import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavMenuSection from '../components/NavMenuSection';
import AppNavigation from '../components/AppNavigation';
import { makeSelectUserRole } from '../lib/store/selectors/user';
import { NAVIGATION_MENU_ITEMS } from '../lib/constants/navigation-menu';
import { ORG_NAVIGATION_MENU_ITEMS } from '../lib/constants/org-navigation-menu';
import { USER_ROLES } from '../lib/constants/user-roles';
import ChangePasswordDialog from './dialogs/ChangePasswordDialog';

const Navigation = ({
  isDrawerOpen,
  location: { pathname },
  notifications,
  role,
}) => {
  const roleMenu =
    role === USER_ROLES.ADMIN
      ? NAVIGATION_MENU_ITEMS
      : ORG_NAVIGATION_MENU_ITEMS;
  const menuItemKeys = Object.keys(roleMenu);
  const menu = menuItemKeys.map((key, menuIndex) => {
    const { heading, menuItems } = roleMenu[key];
    return (
      <>
        <NavMenuSection
          key={menuIndex}
          heading={heading}
          menuItems={menuItems}
          hasDivider={menuIndex < menuItemKeys.length - 1}
          currentPath={pathname}
          notifications={notifications}
        />
        {role === USER_ROLES.ADMIN && <ChangePasswordDialog />}
      </>
    );
  });

  return <AppNavigation isDrawerOpen={isDrawerOpen} menu={menu} />;
};

const mapStateToProps = (state) => {
  const selectUserRole = makeSelectUserRole();
  return {
    notifications: {},
    role: selectUserRole(state),
  };
};

export default compose(withRouter, connect(mapStateToProps))(Navigation);
