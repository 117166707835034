import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-au';
import StatsList from '../../components/Activity/StatsList';
import StatTitleLink from '../../components/Activity/StatTitleLink';
import {
  selectProgramsStatsData,
  selectHasLoadedProgramsStatsData,
} from '../../lib/store/selectors/activity';
import { APP_ROUTES } from '../../lib/constants/app-routes';
import { GQL_STATISTICS_CONTENT_TYPE } from '../../lib/constants';
import { useGetStats } from './useGetStats';

moment.locale('en-au');

function Programs({ stats, hasLoaded }) {
  const { fetchStats, loading } = useGetStats({
    type: GQL_STATISTICS_CONTENT_TYPE.programs,
  });

  React.useEffect(() => {
    if (!hasLoaded) {
      const thirtyDaysAgo = moment().subtract(1, 'month').startOf('day');
      fetchStats(thirtyDaysAgo.toISOString());
    }
  }, [hasLoaded]);

  const renderRowTitle = (_, folderName) => {
    if (folderName === 'Unknown (DELETED)') return folderName;
    return (
      <StatTitleLink
        link={`${APP_ROUTES.programs}`}
        linkText={folderName}
        titleType="Program"
      />
    );
  };

  const refreshData = () => {
    const thirtyDaysAgo = moment().subtract(1, 'month').startOf('day');
    fetchStats(thirtyDaysAgo.toISOString());
  };

  return (
    <StatsList
      stats={stats}
      isLoading={loading}
      renderRowTitle={renderRowTitle}
      onClickRefresh={refreshData}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    stats: selectProgramsStatsData(state),
    hasLoaded: selectHasLoadedProgramsStatsData(state),
  };
};

export default connect(mapStateToProps)(Programs);
