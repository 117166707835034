import { authRequest } from './apiSetup';
import { API_ENDPOINTS } from '../../constants/api-endpoints';

/*
 * Organisation Members API
 ** */

// const delay = ms => new Promise(resolve => setTimeout(() => resolve(true), ms));

export async function getOrganisationDetails(idToken) {
  const endpoint = API_ENDPOINTS.orgDetails;

  const response = await authRequest(idToken, { method: 'GET', endpoint });
  return response.data;
}

export async function getOrgMembers(idToken, { params }) {
  const endpoint = API_ENDPOINTS.orgMembers;
  const response = await authRequest(idToken, {
    method: 'POST',
    endpoint,
    body: JSON.stringify(params),
  });
  return response.data;
}

export async function getOrgMemberById(idToken, { id }) {
  const endpoint = API_ENDPOINTS.orgMember.replace(/:USER_ID/, id);
  const response = await authRequest(idToken, { method: 'GET', endpoint });
  return response.data;
}

export async function updateOrgUserPassword(idToken, { id, password }) {
  const endpoint = API_ENDPOINTS.orgChangeUserPassword.replace(/:USER_ID/, id);
  const response = await authRequest(idToken, {
    method: 'POST',
    endpoint,
    body: JSON.stringify({ password }),
  });
  return response.data;
}

export function updateOrgMemberById(idToken, { id, updatedData }) {
  const { active, ...restData } = updatedData;

  const toggleMemberStatus = async () => {
    const endpoint = active
      ? API_ENDPOINTS.orgMemberEnable.replace(/:USER_ID/, id)
      : API_ENDPOINTS.orgMemberDisable.replace(/:USER_ID/, id);
    await authRequest(idToken, {
      method: 'PUT',
      endpoint,
    }).then((response) => {
      return response.data;
    });
  };

  const updateMember = async () => {
    const endpoint = API_ENDPOINTS.orgMember.replace(/:USER_ID/, id);
    await authRequest(idToken, {
      method: 'PUT',
      endpoint,
      body: JSON.stringify({ ...restData }),
    }).then((response) => {
      return response.data;
    });
  };

  const runQuery = async () => {
    if (Object.keys(restData).length > 0) {
      await updateMember();
    }
    if (typeof active === 'boolean') {
      await toggleMemberStatus();
    }
    return true;
  };

  return runQuery();
}
