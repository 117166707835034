import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Redirect, withRouter } from 'react-router-dom';
import { logout } from '../lib/store/actions/auth';
import { showNotification } from '../lib/store/actions/notifications';
import { APP_ROUTES } from '../lib/constants/app-routes';
import { makeSelectIsAuth } from '../lib/store/selectors/auth';

const ProtectComponent = () => Component => {
  class Protected extends React.PureComponent {
    render() {
      const { isAuth, ...passThroughProps } = this.props;

      // Check if authorised
      if (!isAuth) {
        return <Redirect to={APP_ROUTES.login} />;
      }

      return <Component {...passThroughProps} />;
    }
  }

  const mapStateToProps = state => {
    const selectIsAuth = makeSelectIsAuth();

    return {
      isAuth: selectIsAuth(state),
    };
  };

  return compose(
    connect(
      mapStateToProps,
      {
        onLogout: logout,
        onShowNotification: showNotification,
      }
    ),
    withRouter
  )(Protected);
};

export function secureComponent(Component) {
  return ProtectComponent()(Component);
}

export default ProtectComponent;
