import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import NameAndArtwork from "../../../components/Playlist/NameAndArwork";
import { showNotification } from "../../../lib/store/actions/notifications";
import { makeSelectAuthToken } from "../../../lib/store/selectors/auth";
import { reduceImageSize } from "../../../lib/utils";
import UploadProgress from "../../../lib/utils/uploadProgress";
import { API_ENDPOINTS } from "../../../lib/constants/api-endpoints";
import { GET_CATEGORIES_NAMES_AND_ICONS } from "../../../lib/api/graphql/queries/category";
import defaultArtwork from "../../../assets/images/music-note.jpg";

function CulturalNameAndArtwork() {
  const params = useParams();
  const dispatch = useDispatch();

  const playlistId = params.categoryId;
  const authToken = useSelector(makeSelectAuthToken());

  const [isReducingImageSize, setIsReducingImageSize] = React.useState(false);

  const {
    data: GQLData,
    loading: detailsLoading,
    refetch: refetchDetails,
  } = useQuery(GET_CATEGORIES_NAMES_AND_ICONS, {
    onError: (error) => {
      dispatch(
        showNotification({
          options: { variant: "error" },
          message: error.message || "Error fetching categories",
        })
      );
    },
  });

  const onUploadImage = async (file) => {
    setIsReducingImageSize(true);

    const doUpload = new UploadProgress({
      onSend: (req) => {
        reduceImageSize(
          { file, width: 600, height: 600, compression: 90 },
          (error, newFile) => {
            const formData = new FormData();
            formData.append("file", newFile, newFile.name);
            formData.append("category_id", playlistId);
            formData.append("type", "icon");

            req.open("POST", API_ENDPOINTS.categoryArtwork);
            req.setRequestHeader("Authorization", `Bearer ${authToken}`); // eslint-disable-line

            req.send(formData);
          }
        );
      },
    });

    try {
      await doUpload.upload();
      setIsReducingImageSize(false);
      refetchDetails();
    } catch (error) {
      dispatch(
        showNotification({
          message: "There was a problem updating the image",
          options: {
            variant: "warning",
          },
        })
      );

      setIsReducingImageSize(false);
    }
  };

  const category = GQLData?.categories?.find((c) => c.id === playlistId);

  return (
    <NameAndArtwork
      name={category?.name || ""}
      artwork={category?.icon || defaultArtwork}
      onUploadImage={onUploadImage}
      isLoading={detailsLoading}
      isUpdating={isReducingImageSize}
    />
  );
}

export default CulturalNameAndArtwork;
