import { authRequest } from './apiSetup';
import { API_ENDPOINTS } from '../../constants/api-endpoints';

/*
 * Playlists API
 ** */

export async function getPlaylists(idToken) {
  const endpoint = API_ENDPOINTS.playlists.replace(/:PLAYLIST_ID/, '');
  const response = await authRequest(idToken, { method: 'GET', endpoint });
  return response.data;
}

export async function getPlaylistById(idToken, { playlistId }) {
  const endpoint = API_ENDPOINTS.playlists.replace(/:PLAYLIST_ID/, playlistId);
  const response = await authRequest(idToken, { method: 'GET', endpoint });
  return response.data;
}

export async function createPlaylist(idToken, { name }) {
  const endpoint = API_ENDPOINTS.playlists.replace(/:PLAYLIST_ID/, '');
  const response = await authRequest(idToken, { method: 'POST', endpoint, body: JSON.stringify(name) });
  return response.data;
}

export async function updatePlaylistName(idToken, { id, name }) {
  const endpoint = API_ENDPOINTS.playlistName.replace(/:PLAYLIST_ID/, id);
  const response = await authRequest(idToken, { method: 'PUT', endpoint, body: JSON.stringify(name) });
  return response.data;
}

export async function deletePlaylist(idToken, { id }) {
  const endpoint = API_ENDPOINTS.playlists
    .replace(/:PLAYLIST_ID/, id)
    ?.concat(`?id=${id}`);
  const response = await authRequest(idToken, { method: 'DELETE', endpoint });
  return response.state === 1;
}

export async function deletePlaylistSong(idToken, { id, songId }) {
  const endpoint = API_ENDPOINTS.playlistFile
    .replace(/:PLAYLIST_ID/, id)
    .replace(/:FILE_ID/, songId);
  const response = await authRequest(idToken, { method: 'DELETE', endpoint });
  return response.state === 1;
}
