// Generic API
export const API_REQUEST = 'API_REQUEST';
export const API_END = 'API_END';

// Login/Logout
export const LOGIN_OK = 'LOGIN_OK';
export const LOGOUT = 'LOGOUT';

// Toast Notifications
export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// Admin
export const VALIDATE_ADMIN_PASSWORD_OK = 'VALIDATE_ADMIN_PASSWORD_OK';
export const RESET_ADMIN_PASSWORD_VALIDATION =
  'RESET_ADMIN_PASSWORD_VALIDATION';
export const REGISTER_ADMIN_OK = 'REGISTER_ADMIN_OK';
export const RESET_REGISTER_ADMIN_FORM = 'RESET_REGISTER_ADMIN_FORM';

// Activity
export const GET_HYMNS_STATS_OK = 'GET_HYMNS_STATS_OK';
export const GET_IMAGE_ALBUM_STATS_OK = 'GET_IMAGE_ALBUM_STATS_OK';
export const GET_QUIZZES_STATS_OK = 'GET_QUIZZES_STATS_OK';
export const GET_LYRICS_STATS_OK = 'GET_LYRICS_STATS_OK';
export const GET_RADIO_STATS_OK = 'GET_RADIO_STATS_OK';
export const GET_PROGRAMS_STATS_OK = 'GET_PROGRAMS_STATS_OK';
export const GET_WEEKLY_PROGRAMS_STATS_OK = 'GET_WEEKLY_PROGRAMS_STATS_OK';
export const GET_PLAYLISTS_STATS_OK = 'GET_PLAYLISTS_STATS_OK';
export const GET_CULTURAL_PROGRAMS_STATS_OK = 'GET_CULTURAL_PROGRAMS_STATS_OK';

// Hymns
export const GET_HYMNS_PLAYLIST_OK = 'GET_HYMNS_PLAYLIST_OK';
export const DELETE_HYMNS_FILE_OK = 'DELETE_HYMNS_FILE_OK';
export const TOGGLE_DELETE_HYMNS_FILE_DIALOG =
  'TOGGLE_DELETE_HYMNS_FILE_DIALOG';
export const TOGGLE_ADD_HYMNS_FILE_DIALOG = 'TOGGLE_ADD_HYMNS_FILE_DIALOG';

// Image Albums
export const GET_IMAGE_ALBUMS_OK = 'GET_IMAGE_ALBUMS_OK';
export const GET_IMAGES_OK = 'GET_IMAGES_OK';
export const DELETE_IMAGE_ALBUM_OK = 'DELETE_IMAGE_ALBUM_OK';
export const DELETE_IMAGES_OPTIMISTIC = 'DELETE_IMAGES_OPTIMISTIC';
export const DELETE_IMAGE_OK = 'DELETE_IMAGE_OK';
export const SAVE_IMAGE_ALBUM_NAME_OK = 'SAVE_IMAGE_ALBUM_NAME_OK';
export const ADD_IMAGE_ALBUM_OPTIMISTIC = 'ADD_IMAGE_ALBUM_OPTIMISTIC';
export const ADD_IMAGE_ALBUM_OK = 'ADD_IMAGE_ALBUM_OK';
export const TOGGLE_CREATE_IMAGE_ALBUM_DIALOG =
  'TOGGLE_CREATE_IMAGE_ALBUM_DIALOG';
export const TOGGLE_EDIT_ALBUM_NAME_DIALOG = 'TOGGLE_EDIT_ALBUM_NAME_DIALOG';
export const TOGGLE_DELETE_IMAGE_ALBUM_DIALOG =
  'TOGGLE_DELETE_IMAGE_ALBUM_DIALOG';
export const SET_DELETE_IMAGE_MODE = 'SET_DELETE_IMAGE_MODE';

// Lyrics
export const GET_LYRICS_OK = 'GET_LYRICS_OK';
export const DELETE_LYRICS_OK = 'DELETE_LYRICS_OK';
export const TOGGLE_DELETE_LYRICS_DIALOG = 'TOGGLE_DELETE_LYRICS_DIALOG';
export const TOGGLE_EDIT_LYRICS_NAME_DIALOG = 'TOGGLE_EDIT_LYRICS_NAME_DIALOG';
export const TOGGLE_ADD_LYRICS_DIALOG = 'TOGGLE_ADD_LYRICS_DIALOG';
export const UPDATE_LYRICS_NAME_OK = 'UPDATE_LYRICS_NAME_OK';

// Members
export const GET_MEMBERS_OK = 'GET_MEMBERS_OK';
export const GET_MEMBER_OK = 'GET_MEMBER_OK';

// Messages
export const GET_MESSAGE_OK = 'GET_MESSAGE_OK';

// Organisation
export const TOGGLE_ORG_ADD_MEMBER_DIALOG = 'TOGGLE_ORG_ADD_MEMBER_DIALOG';
export const TOGGLE_ORG_EDIT_MEMBER_DIALOG = 'TOGGLE_ORG_EDIT_MEMBER_DIALOG';
export const TOGGLE_ORG_UPG_MEMBER_PASSWORD_DIALOG =
  'TOGGLE_ORG_UPG_MEMBER_PASSWORD_DIALOG';
export const TOGGLE_ORG_UPD_ADMIN_PASSWORD_DIALOG =
  'TOGGLE_ORG_UPD_ADMIN_PASSWORD_DIALOG';
export const GET_ORG_DETAILS_OK = 'GET_ORG_DETAILS_OK';
export const GET_ORG_MEMBERS_OK = 'GET_ORG_MEMBERS_OK';
export const GET_ORG_MEMBER_OK = 'GET_ORG_MEMBER_OK';
export const ADD_ORG_MEMBER_OK = 'ADD_ORG_MEMBER_OK';
export const IMPORT_ORG_MEMBERS_OK = 'IMPORT_ORG_MEMBERS_OK';
export const UPD_ORG_MEMBER_OK = 'UPD_ORG_MEMBER_OK';
export const UPD_ORG_MEMBER_PASSWORD_OK = 'UPD_ORG_MEMBER_PASSWORD_OK';
export const CHK_ORG_MEMBER_EMAIL_OK = 'CHK_ORG_MEMBER_EMAIL_OK';
export const UPD_ORG_ADMIN_PASSWORD_OK = 'UPD_ORG_ADMIN_PASSWORD_OK';
export const RESET_ORG_MEMBER_EMAIL_CHECK = 'RESET_ORG_MEMBER_EMAIL_CHECK';
export const CLEAR_SELECTED_ORG_MEMBER = 'CLEAR_SELECTED_ORG_MEMBER';
export const CLEAR_ORG_MEMBER_IMPORT_RESULTS =
  'CLEAR_ORG_MEMBER_IMPORT_RESULTS';

// Notifications
export const NOTIFICATION_PERMISSION = 'NOTIFICATION_PERMISSION';

// Playlist
export const GET_PLAYLISTS_OK = 'GET_PLAYLISTS_OK';
export const GET_PLAYLIST_OK = 'GET_PLAYLIST_OK';
export const TOGGLE_CREATE_PLAYLIST_DIALOG = 'TOGGLE_CREATE_PLAYLIST_DIALOG';
export const TOGGLE_DELETE_PLAYLIST_DIALOG = 'TOGGLE_DELETE_PLAYLIST_DIALOG';
export const TOGGLE_EDIT_PLAYLIST_NAME_DIALOG =
  'TOGGLE_EDIT_PLAYLIST_NAME_DIALOG';
export const TOGGLE_DELETE_PLAYLIST_SONG_DIALOG =
  'TOGGLE_DELETE_PLAYLIST_SONG_DIALOG';
export const TOGGLE_ADD_SONGS_DIALOG = 'TOGGLE_ADD_SONGS_DIALOG';
export const ADD_PLAYLIST_OPTIMISTIC = 'ADD_PLAYLIST_OPTIMISTIC';
export const ADD_PLAYLIST_OK = 'ADD_PLAYLIST_OK';
export const UPDATE_PLAYLIST_NAME_OK = 'UPDATE_PLAYLIST_NAME_OK';
export const UPDATE_PLAYLIST_ARTWORK = 'UPDATE_PLAYLIST_ARTWORK';
export const DELETE_PLAYLIST_OK = 'DELETE_PLAYLIST_OK';
export const DELETE_PLAYLIST_SONG_OK = 'DELETE_PLAYLIST_SONG_OK';

// Programs (Weekly PDF's and Playlist)
export const GET_PROGRAM_PLAYLIST_OK = 'GET_PROGRAM_PLAYLIST_OK';
export const DELETE_PROGRAM_FILE_OK = 'DELETE_PROGRAM_FILE_OK';
export const TOGGLE_DELETE_PROGRAM_FILE_DIALOG =
  'TOGGLE_DELETE_PROGRAM_FILE_DIALOG';
export const TOGGLE_ADD_PROGRAM_FILE_DIALOG = 'TOGGLE_ADD_PROGRAM_FILE_DIALOG';
export const GET_PROGRAM_SCHEDULES_OK = 'GET_PROGRAM_SCHEDULES_OK';
export const TOGGLE_UPLOAD_SCHEDULE_DIALOG = 'TOGGLE_UPLOAD_SCHEDULE_DIALOG';
export const TOGGLE_CREATE_CULTURAL_CATEGORY =
  'TOGGLE_CREATE_CULTURAL_CATEGORY';
export const TOGGLE_UPDATE_CULTURAL_CATEGORY =
  'TOGGLE_UPDATE_CULTURAL_CATEGORY';
export const TOGGLE_ADD_CULTURAL_CATEGORY_SONGS =
  'TOGGLE_ADD_CULTURAL_CATEGORY_SONGS';
export const TOGGLE_DELETE_CULTURAL_CATEGORY =
  'TOGGLE_DELETE_CULTURAL_CATEGORY';
export const TOGGLE_DELETE_CULTURAL_SONG = 'TOGGLE_DELETE_CULTURAL_SONG';

// Quizzes
export const GET_QUIZZES_OK = 'GET_QUIZZES_OK';
export const DELETE_QUIZZES_OK = 'DELETE_QUIZZES_OK';
export const TOGGLE_DELETE_QUIZ_DIALOG = 'TOGGLE_DELETE_QUIZ_DIALOG';
export const TOGGLE_EDIT_QUIZ_NAME_DIALOG = 'TOGGLE_EDIT_QUIZ_NAME_DIALOG';
export const TOGGLE_ADD_QUIZZES_DIALOG = 'TOGGLE_ADD_QUIZZES_DIALOG';
export const UPDATE_QUIZ_NAME_OK = 'UPDATE_QUIZ_NAME_OK';

// API Request Types
/* Authentication */
export const API_LOGOUT_REQ = 'API_LOGOUT_REQ';
/* Admin Users */
export const API_VALIDATE_ADMIN_PASSWORD_REQ =
  'API_VALIDATE_ADMIN_PASSWORD_REQ';
export const API_REGISTER_ADMIN_REQ = 'API_REGISTER_ADMIN_REQ';
/* Activity */
export const API_GET_LYRICS_STATS_REQ = 'API_GET_LYRICS_STATS_REQ';
/* Hymns */
export const API_GET_HYMNS_PLAYLIST_REQ = 'API_GET_HYMNS_PLAYLIST_REQ';
export const API_DEL_HYMNS_FILE_REQ = 'API_DEL_HYMNS_FILE_REQ';
/* Image Albums */
export const API_ADD_IMAGE_ALBUMS_REQ = 'API_ADD_IMAGE_ALBUMS_REQ';
export const API_GET_IMAGE_ALBUMS_REQ = 'API_GET_IMAGE_ALBUMS_REQ';
export const API_UPD_IMAGE_ALBUMS_REQ = 'API_UPD_IMAGE_ALBUMS_REQ';
export const API_DEL_IMAGE_ALBUMS_REQ = 'API_DEL_IMAGE_ALBUMS_REQ';
export const API_GET_IMAGES_REQ = 'API_GET_IMAGES_REQ';
export const API_DEL_IMAGES_REQ = 'API_DEL_IMAGES_REQ';
/* Lyrics */
export const API_GET_LYRICS_REQ = 'API_GET_LYRICS_REQ';
export const API_DEL_LYRICS_REQ = 'API_DEL_LYRICS_REQ';
export const API_UPD_LYRICS_REQ = 'API_UPD_LYRICS_REQ';
/* Members */
export const API_GET_MEMBER_REQ = 'API_GET_MEMBER_REQ';
/* Messages */
export const API_GET_MESSAGES_REQ = 'API_GET_MESSAGES_REQ';
export const API_GET_MESSAGE_REQ = 'API_GET_MESSAGE_REQ';
/* Organisation */
export const API_GET_ORG_MEMBERS_REQ = 'API_GET_ORG_MEMBERS_REQ';
export const API_GET_ORG_MEMBER_REQ = 'API_GET_ORG_MEMBER_REQ';
export const API_ADD_ORG_MEMBER_REQ = 'API_ADD_ORG_MEMBER_REQ';
export const API_IMPORT_ORG_MEMBERS_REQ = 'API_IMPORT_ORG_MEMBERS_REQ';
export const API_CHK_ORG_MEMBER_EMAIL_REQ = 'API_CHK_ORG_MEMBER_EMAIL_REQ';
export const API_UPD_ORG_MEMBER_PASSWORD_REQ =
  'API_UPD_ORG_MEMBER_PASSWORD_REQ';
export const API_UPD_ORG_ADMIN_PASSWORD_REQ = 'API_UPD_ORG_ADMIN_PASSWORD_REQ';
/* Playlists */
export const API_GET_PLAYLISTS_REQ = 'API_GET_PLAYLISTS_REQ';
export const API_ADD_PLAYLIST_REQ = 'API_ADD_PLAYLIST_REQ';
export const API_UPD_PLAYLIST_REQ = 'API_UPD_PLAYLIST_REQ';
export const API_DEL_PLAYLIST_REQ = 'API_DEL_PLAYLIST_REQ';
export const API_DEL_PLAYLIST_SONG_REQ = 'API_DEL_PLAYLIST_SONG_REQ';
export const API_ADD_SONGS_TO_PLAYLIST_REQ = 'API_ADD_SONGS_TO_PLAYLIST_REQ';
/* Programs (Weekly PDF's and Playlist) */
export const API_GET_PROGRAM_PLAYLIST_REQ = 'API_GET_PROGRAM_PLAYLIST_REQ';
export const API_DEL_PROGRAM_FILE_REQ = 'API_DEL_PROGRAM_FILE_REQ';
export const API_GET_PROGRAM_SCHEDULES_REQ = 'API_GET_PROGRAM_SCHEDULES_REQ';
/* Quizzes */
export const API_GET_QUIZZES_REQ = 'API_GET_QUIZZES_REQ';
export const API_DEL_QUIZZES_REQ = 'API_DEL_QUIZZES_REQ';
export const API_UPD_QUIZZES_REQ = 'API_UPD_QUIZZES_REQ';
