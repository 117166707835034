import React from 'react';
import { connect } from 'react-redux';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import {
  setDeleteImageAlbumDialog,
  deleteAlbum,
} from '../../lib/store/actions/imageAlbums';
import {
  selectDeleteDialogOpen,
  selectIsDeletingImageAlbum,
} from '../../lib/store/selectors/imageAlbums';

function DeleteAlbumDialog({
  isOpen,
  isDeleting,
  albumId,
  onSetDeleteImageAlbumDialog,
  onDeleteAlbum,
  albumName,
  onNavigateToImageAlbumsPage,
}) {
  const closeDialog = () => {
    onSetDeleteImageAlbumDialog(false);
  };

  const deleteCurrentAlbum = () => {
    if (!albumId) return;
    onDeleteAlbum({ albumId }, () => {
      onSetDeleteImageAlbumDialog(false);
      onNavigateToImageAlbumsPage();
    });
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={closeDialog}
      onConfirm={deleteCurrentAlbum}
      isInProgress={isDeleting}
      inProgressMessage="Deleting, please wait ..."
      confirmationBody={
        <>
          Please confirm you'd like to delete the image album "{albumName}" and
          all its images?
          <br />
          <br />
          <strong>Note:</strong> This action cannot be undone.
        </>
      }
      confirmationBtnText="Yes, delete it!"
    />
  );
}

const mapStateToProps = (state) => {
  return {
    isOpen: selectDeleteDialogOpen(state),
    isDeleting: selectIsDeletingImageAlbum(state),
  };
};

export default connect(mapStateToProps, {
  onSetDeleteImageAlbumDialog: setDeleteImageAlbumDialog,
  onDeleteAlbum: deleteAlbum,
})(DeleteAlbumDialog);
