import * as activity from './handlers/activity';
import * as auth from './handlers/authentication';
import * as hymns from './handlers/hymns';
import * as images from './handlers/images';
import * as lyrics from './handlers/lyrics';
import * as members from './handlers/members';
import * as messages from './handlers/messages';
import * as organisation from './handlers/organisation';
import * as playlists from './handlers/playlists';
import * as programs from './handlers/programs';
import * as quizzes from './handlers/quizzes';

export const activityApi = activity;
export const authApi = auth;
export const hymnsApi = hymns;
export const imagesApi = images;
export const lyricsApi = lyrics;
export const membersApi = members;
export const messagesApi = messages;
export const playlistsApi = playlists;
export const programsApi = programs;
export const quizzesApi = quizzes;
export const orgApi = organisation;
