/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import Fade from '@material-ui/core/Fade';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Box from '@material-ui/core/Box';
import LoadingIndicator from '../LoadingIndicator';
import ErrorMessage from '../ErrorMessage';
import usePrevious from '../../containers/usePrevious';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: '10px',
    maxHeight: '400px',
    overflowY: 'auto',
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  progressText: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(1),
  },
  successInput: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderColor: 'green',
      borderLeftWidth: 6,
      padding: '4px !important', // override inline-style
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
    width: '95%',
  },
}));

function ChangeOrgMemberPasswordDialog({
  name,
  isOpen,
  isUpdating,
  isOrgActive,
  onCancel,
  onSubmit,
}) {
  const prevIsOpen = usePrevious(isOpen);

  const initialValues = {
    password: '',
    showPassword: false,
    isSubmitted: false,
  };
  const [values, setValues] = React.useState(initialValues);

  React.useEffect(() => {
    if (isOpen && !prevIsOpen) {
      setValues(initialValues);
    }
  }, [isOpen, prevIsOpen, initialValues]);

  const handleClose = () => {
    setValues(initialValues);
    onCancel();
  };

  const handleChange = (field) => (event) => {
    const { value } = event.target;

    setValues((vals) => ({ ...vals, [field]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setValues((vals) => ({ ...vals, isSubmitted: true }));

    const isNotValid = values.password.trim().length < 5;

    if (isNotValid) return;

    onSubmit({ password: values.password.trim() });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {name
            ? `Enter the new password for ${name}`
            : 'Enter the new password'}
        </DialogTitle>
        <DialogContent>
          <form
            className={classes.form}
            id="update-password-form"
            onSubmit={handleSubmit}
            autoComplete="new-password">
            <Grid container spacing={2}>
              {!isOrgActive && (
                <Grid item xs={12}>
                  <ErrorMessage message="Organisation Inactive. Cannot make changes." />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  id="password"
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  type={values.showPassword ? 'text' : 'password'}
                  label="Password"
                  disabled={isUpdating || !isOrgActive}
                  error={
                    values.isSubmitted && values.password.trim().length < 5
                  }
                  helperText="A password with at least 5 characters"
                  autoComplete="new-password"
                  value={values.password}
                  onChange={handleChange('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}>
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Fade in={isUpdating}>
            <Box
              width="45%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center">
              <LoadingIndicator size={16} />
              <Typography
                variant="body2"
                component="span"
                className={classes.progressText}>
                Updating, please wait ...
              </Typography>
            </Box>
          </Fade>
          <Button
            disabled={isUpdating}
            variant="outlined"
            color="primary"
            onClick={handleClose}>
            <ClearIcon className={classes.rightMargin} />
            Cancel
          </Button>
          <Button
            disabled={isUpdating || !isOrgActive}
            variant="contained"
            color="primary"
            type="submit"
            form="update-password-form">
            <CheckCircleIcon className={classes.rightMargin} />
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ChangeOrgMemberPasswordDialog.defaultProps = {};

export default ChangeOrgMemberPasswordDialog;
