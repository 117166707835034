import { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { compose } from 'recompose';
import { withRouter, useParams } from 'react-router-dom';
import NameAndArtwork from '../../components/Playlist/NameAndArwork';
import { showNotification } from '../../lib/store/actions/notifications';
import { updatePlaylistArtwork } from '../../lib/store/actions/playlists';
import {
  makeSelectCurrentPlaylistArtwork,
  makeSelectCurrentPlaylistName,
} from '../../lib/store/selectors/playlists';
import { makeSelectAuthToken } from '../../lib/store/selectors/auth';
import { reduceImageSize } from '../../lib/utils';
import UploadProgress from '../../lib/utils/uploadProgress';
import { API_ENDPOINTS } from '../../lib/constants/api-endpoints';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import * as types from '../../lib/store/actions/types';
import defaultArtwork from '../../assets/images/music-note.jpg';
import { GET_PLAYLIST_NAME_AND_ARTWORK } from '../../lib/api/graphql/queries/playlist';

const SinglePlaylistNameNArtwork = ({
  name,
  artwork,
  authToken,
  onUpdatePlaylistArtwork,
  onShowNotification,
}) => {
  const dispatch = useDispatch();

  const { id: playlistId } = useParams();
  const [isReducingImageSize, setIsReducingImageSize] = useState(false);

  const { loading: isFetching, refetch } = useQuery(
    GET_PLAYLIST_NAME_AND_ARTWORK,
    {
      variables: {
        playlistId: playlistId,
      },
      onCompleted: (data) => {
        if (!data?.playlist?.id) return;

        dispatch({
          type: types.GET_PLAYLIST_OK,
          playlist: {
            id: data.playlist.id,
            name: data.playlist.Name,
            artwork: data.playlist.ArtworkURL || defaultArtwork,
          },
          playlistId: playlistId,
          apiReqId: uuidV4(),
        });
      },
      onError: (err) => {
        dispatch(
          showNotification({
            message:
              `Get playlist artwork: ${err.message}` || 'Cannot get playlist',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
      },
    },
  );

  const onUploadImage = async (file) => {
    try {
      setIsReducingImageSize(true);

      const doUpload = new UploadProgress({
        onSend: (req) => {
          reduceImageSize(
            { file, width: 600, height: 600, compression: 90 },
            (error, newFile) => {
              const formData = new FormData();
              formData.append('artwork', newFile, newFile.name);

              req.open(
                'PUT',
                API_ENDPOINTS.playlistArtwork.replace(
                  /:PLAYLIST_ID/,
                  playlistId,
                ),
              );
              req.setRequestHeader('Authorization', `Bearer ${authToken}`);

              req.send(formData);
            },
          );
        },
      });

      const response = await doUpload.upload();
      setIsReducingImageSize(false);

      const resJSON = JSON.parse(response);
      const { state, data } = resJSON;

      if (state === 1) {
        await refetch();
        onUpdatePlaylistArtwork({ id: playlistId, artworkURL: data });
      } else {
        onShowNotification({
          message: 'There was a problem updating the image',
          options: {
            variant: 'warning',
          },
        });
      }
    } catch (e) {
      console.log('upload error:', e.message);
    }
  };

  return (
    <NameAndArtwork
      name={name}
      artwork={artwork}
      onUploadImage={onUploadImage}
      isLoading={isFetching}
      isUpdating={isReducingImageSize}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const selectAuthToken = makeSelectAuthToken();
  const selectCurrentPlaylistArtwork = makeSelectCurrentPlaylistArtwork();
  const selectCurrentPlaylistName = makeSelectCurrentPlaylistName();

  return {
    name: selectCurrentPlaylistName(state, ownProps),
    artwork: selectCurrentPlaylistArtwork(state, ownProps),
    authToken: selectAuthToken(state),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    onUpdatePlaylistArtwork: updatePlaylistArtwork,
    onShowNotification: showNotification,
  }),
)(SinglePlaylistNameNArtwork);
