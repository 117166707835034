/* eslint-disable no-nested-ternary */
import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import LoadingIndicator from '../LoadingIndicator';
import InputField from '../InputField';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: '10px',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  progressText: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(1),
  },
}));

function AddOrgDialog({ isOpen, isLoading, onCancel, onSubmit }) {
  const initialValues = {
    name: '',
    licences: 0,
    isSubmitted: false,
  };

  const [values, setValues] = React.useState(initialValues);

  const handleChange = (field) => (event) => {
    const { value } = event.target;
    setValues((vals) => ({
      ...vals,
      [field]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setValues((vals) => ({ ...vals, isSubmitted: true }));

    const licences = parseInt(values.licences, 10);
    const isInvalid = values.name.trim().length === 0 || licences <= 0;

    if (isInvalid) return;

    onSubmit({ name: values.name, licences: Math.round(licences) });
  };

  const classes = useStyles();

  const fields = [
    {
      id: 'name',
      label: 'Organisation Name',
      inputType: 'text',
      value: values.name,
      onChange: handleChange('name'),
      error: values.isSubmitted && values.name.trim().length === 0,
      helperText:
        values.isSubmitted && values.name.trim().length === 0
          ? 'Organisation name is required'
          : '',
    },
    {
      id: 'licences',
      label: 'Licence Count',
      inputType: 'text',
      type: 'number',
      value: values.licences,
      onChange: handleChange('licences'),
      error: values.isSubmitted && parseInt(values.licences, 10) <= 0,
      helperText:
        values.isSubmitted && parseInt(values.licences, 10) <= 0
          ? 'Licence count is required'
          : '',
    },
  ];

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Organisation</DialogTitle>
        <DialogContent>
          <form
            className={classes.form}
            id="add-org-form"
            onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Enter in the organisation details and press save
                </Typography>
              </Grid>
              {fields.map((fieldProps) => (
                <Grid item xs={12} key={fieldProps.id}>
                  <InputField {...fieldProps} classes={classes} />
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Fade in={isLoading}>
            <Box
              width="45%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center">
              <LoadingIndicator size={16} />
              <Typography
                variant="body2"
                component="span"
                className={classes.progressText}>
                Saving, please wait ...
              </Typography>
            </Box>
          </Fade>
          <Button
            disabled={isLoading}
            variant="outlined"
            color="primary"
            onClick={onCancel}>
            <ClearIcon className={classes.rightMargin} />
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            form="add-org-form">
            <CheckCircleIcon className={classes.rightMargin} />
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddOrgDialog;
