/* eslint-disable arrow-body-style */
import * as types from './types';
import { hymnsApi } from '../../api';
import { apiRequest } from './api-request';
import { showNotification } from './notifications';
import { NOTIFICATION_TYPES } from '../../constants/notification-types';
import { getAuthToken, checkHttpError } from './authTokenHelper';

export const setDeleteHymnsFileDialog = (isOpen) => ({
  type: types.TOGGLE_DELETE_HYMNS_FILE_DIALOG,
  isOpen,
});

export const setAddHymnsFileDialog = (isOpen) => ({
  type: types.TOGGLE_ADD_HYMNS_FILE_DIALOG,
  isOpen,
});

/* Hymns */
export const getHymnsPlaylist = (callback) => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_GET_HYMNS_PLAYLIST_REQ,
    message: 'Loading hymns',
  });
  dispatch(apiReqAction);
  hymnsApi
    .getHymnsPlaylist(getAuthToken(getState()))
    .then(
      (hymnsPlaylist) => {
        dispatch({
          type: types.GET_HYMNS_PLAYLIST_OK,
          hymnsPlaylist:
            hymnsPlaylist !== null ? hymnsPlaylist.audioFileList : [],
          apiReqId: apiReqAction.id,
        });
        if (callback && typeof callback === 'function')
          callback(hymnsPlaylist?.audioFileList || []);
      },
      (error) => dispatch(checkHttpError(error, apiReqAction.id)),
    )
    .catch((error) => {
      dispatch(
        showNotification({
          message: `Get hymns: ${error.message}` || 'Cannot get hymns',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};

export const deleteHymnsFile =
  ({ fileId }, successCB) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_DEL_HYMNS_FILE_REQ,
      message: 'Deleting hymn',
    });
    dispatch(apiReqAction);

    hymnsApi
      .deletePlaylistFile(getAuthToken(getState()), { fileId })
      .then(
        () => {
          dispatch({
            type: types.DELETE_HYMNS_FILE_OK,
            fileId,
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'Hymn successfully deleted',
              options: {
                variant: NOTIFICATION_TYPES.Success,
                autoHideDuration: 2000,
              },
            }),
          );
          if (successCB && typeof successCB === 'function') successCB();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message: `Delete hymn: ${error.message}` || 'Cannot delete hymn',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };
