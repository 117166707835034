/* eslint-disable arrow-body-style */
import { orgApi } from '../../api';
import * as types from './types';
import { apiRequest } from './api-request';
import { showNotification } from './notifications';
import { NOTIFICATION_TYPES } from '../../constants/notification-types';
import { getAuthToken, checkHttpError } from './authTokenHelper';

export const toggleAddMemberDialog = () => ({
  type: types.TOGGLE_ORG_ADD_MEMBER_DIALOG,
});

export const toggleEditMemberDialog = () => ({
  type: types.TOGGLE_ORG_EDIT_MEMBER_DIALOG,
});

export const toggleUpdateMemberPasswordDialog = (member) => ({
  type: types.TOGGLE_ORG_UPG_MEMBER_PASSWORD_DIALOG,
  member,
});

export const toggleUpdateAdminPasswordDialog = () => ({
  type: types.TOGGLE_ORG_UPD_ADMIN_PASSWORD_DIALOG,
});

export const clearSelectedOrgMember = () => ({
  type: types.CLEAR_SELECTED_ORG_MEMBER,
});

export const clearOrgMemberImportResults = () => ({
  type: types.CLEAR_ORG_MEMBER_IMPORT_RESULTS,
});

export const importOrgMembers =
  ({ members, callback }) =>
  (dispatch, getState) => {
    dispatch({ type: types.CLEAR_ORG_MEMBER_IMPORT_RESULTS });

    const apiReqAction = apiRequest({
      requestType: types.API_IMPORT_ORG_MEMBERS_REQ,
      message: 'Importing members',
    });

    dispatch(apiReqAction);

    orgApi
      .importOrgMembers(getAuthToken(getState()), { members })
      .then(
        (importResults) => {
          dispatch({
            type: types.IMPORT_ORG_MEMBERS_OK,
            importResults,
            apiReqId: apiReqAction.id,
          });
          if (callback && typeof callback === 'function') callback();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        let message =
          `Import members: ${error.message}` || 'Cannot import members';
        if (error.message === 'Error: Request failed with status code 406') {
          message = 'Unable to make changes. Organisation inactive';
        }

        dispatch(
          showNotification({
            message,
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const updateOrgMemberPassword =
  ({ memberId, password, callback }) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_UPD_ORG_MEMBER_PASSWORD_REQ,
      message: 'Updating member',
    });

    dispatch(apiReqAction);

    orgApi
      .updateOrgUserPassword(getAuthToken(getState()), {
        id: memberId,
        password,
      })
      .then(
        () => {
          dispatch({
            type: types.UPD_ORG_MEMBER_PASSWORD_OK,
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'Member password updated',
              options: {
                variant: NOTIFICATION_TYPES.Success,
              },
            }),
          );
          if (callback && typeof callback === 'function') callback();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        let message =
          `Update member password: ${error.message}` ||
          'Cannot update member password';
        if (error.message === 'Error: Request failed with status code 406') {
          message = 'Unable to make changes. Organisation inactive';
        }

        dispatch(
          showNotification({
            message,
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const getOrgMembers =
  ({ params, callback }) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_GET_ORG_MEMBERS_REQ,
      message: 'Loading members',
    });

    dispatch(apiReqAction);

    orgApi
      .getOrgMembers(getAuthToken(getState()), { params })
      .then(
        (data) => {
          dispatch({
            type: types.GET_ORG_MEMBERS_OK,
            pagination: data.pagination,
            users: data.users,
            apiReqId: apiReqAction.id,
            getParams: params,
          });
          if (callback && typeof callback === 'function') callback();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message: `Get members: ${error.message}` || 'Cannot get members',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const getOrgMemberById =
  ({ id, callback }) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_GET_ORG_MEMBER_REQ,
      message: 'Loading member',
    });

    dispatch(apiReqAction);

    orgApi
      .getOrgMemberById(getAuthToken(getState()), { id })
      .then(
        (data) => {
          dispatch({
            type: types.GET_ORG_MEMBER_OK,
            member: data.user,
            apiReqId: apiReqAction.id,
          });
          if (callback && typeof callback === 'function') callback();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message: `Get member: ${error.message}` || 'Cannot get member',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const refreshOrgMembers = () => (dispatch, getState) => {
  const params = getState().organisation.users.lastGetParams;
  if (Object.keys(params).length > 0) {
    dispatch(
      getOrgMembers({
        params,
      }),
    );
  }
};
