import React from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import Messages from '../../components/Messages/Messages';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { showNotification } from '../../lib/store/actions/notifications';
import { GET_MESSAGES } from '../../lib/api/graphql/queries/messages';
import { GQL_MESSAGE_TYPES } from '../../lib/constants';

function MessagesList() {
  const dispatch = useDispatch();

  const { data, loading } = useQuery(GET_MESSAGES, {
    onError: () => {
      dispatch(
        showNotification({
          options: { variant: NOTIFICATION_TYPES.Error },
          message: 'Error while fetching messages',
        }),
      );
    },
  });

  const _messages = React.useMemo(() => {
    if (!Array.isArray(data?.messages)) return [];

    return [...data.messages].map((msg) => {
      return {
        id: msg?.id,
        body: msg?.Body,
        type: GQL_MESSAGE_TYPES[msg?.Type] || 0,
        createdOn: msg?.CreatedOn,
        read: msg?.Read,
        user: {
          id: msg?.user?.id,
          firstName: msg?.user?.FirstName,
          lastName: msg?.user?.LastName,
          email: msg?.user?.Email,
        },
      };
    });
  }, [data]);

  return <Messages messages={_messages || []} isLoading={loading} />;
}

export default MessagesList;
