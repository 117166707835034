import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectLoadingMessage } from '../lib/store/selectors/api-requests';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    border: '1px solid #847f7f',
    borderRadius: '10px',
    padding: '0 8px 0 0',
    backgroundColor: '#efefef',
    boxShadow: '1px 1px 3px #222',
  },
  progress: {
    margin: theme.spacing(1),
  },
  text: {
    fontStyle: 'italic',
    fontSize: '13px',
    margin: 0,
    padding: 0,
    color: '#222',
  },
}));

function FloatingLoadingIndicator({ message }) {
  const classes = useStyles();

  if (!message) return null;

  return (
    <div className={classes.container}>
      <CircularProgress className={classes.progress} size={13} />
      <p className={classes.text}>{message}</p>
    </div>
  );
}

export default connect(state => ({ message: selectLoadingMessage(state) }))(
  FloatingLoadingIndicator
);
