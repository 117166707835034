import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import Dialog from '../../components/Organisation/ChangeOrgUserPasswordDialog';
import { toggleUpdateAdminPasswordDialog } from '../../lib/store/actions/organisation';
import {
  selectIsChangeAdminPasswordDialogOpen,
  makeSelectIsOrgActive,
} from '../../lib/store/selectors/organisation';
import { makeSelectUserDetails } from '../../lib/store/selectors/user';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { UPDATE_USER_PASSWORD } from '../../lib/api/graphql/mutations/organizations';

const ChangeAdminPasswordDialog = ({
  isOpen,
  isOrgActive,
  user,
  onToggleDialog,
}) => {
  const dispatch = useDispatch();

  const [updatePassword, { loading: isUpdating }] = useMutation(
    UPDATE_USER_PASSWORD,
    {
      onCompleted: () => {
        dispatch(
          showNotification({
            message: 'Admin password updated',
            options: {
              variant: NOTIFICATION_TYPES.Success,
            },
          }),
        );

        onToggleDialog();
      },
      onError: (error) => {
        dispatch(
          showNotification({
            options: { variant: NOTIFICATION_TYPES.Error },
            message: `Error updating user password: ${error?.message}`,
          }),
        );
      },
    },
  );

  const handleChangeAdminPassword = async ({ password }) => {
    if (!user) return;

    await updatePassword({
      variables: {
        userId: user.id,
        password,
      },
    });
  };

  const handleClose = () => {
    onToggleDialog();
  };

  return (
    <Dialog
      isUpdating={isUpdating}
      isOpen={isOpen}
      isOrgActive={isOrgActive}
      onCancel={handleClose}
      onSubmit={handleChangeAdminPassword}
    />
  );
};

const mapStateToProps = (state) => {
  const selectUserDetails = makeSelectUserDetails();
  const selectIsOrgActive = makeSelectIsOrgActive();
  return {
    isOpen: selectIsChangeAdminPasswordDialogOpen(state),
    isOrgActive: selectIsOrgActive(state),
    user: selectUserDetails(state),
  };
};

export default connect(mapStateToProps, {
  onToggleDialog: toggleUpdateAdminPasswordDialog,
})(ChangeAdminPasswordDialog);
