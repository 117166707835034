import { connect } from 'react-redux';
import { compose } from 'recompose';
import Protected from './Protected';
import withReduxPersistor from './withReduxPersistor';
import { logout } from '../lib/store/actions/auth';
import { makeSelectFirebaseId } from '../lib/store/selectors/user';

const Logout = ({ onLogout, firebaseId, persistor }) => {
  onLogout(firebaseId, persistor);
  return null;
};

const mapStateToProps = state => {
  const selectFirebaseId = makeSelectFirebaseId();
  return {
    firebaseId: selectFirebaseId(state),
  };
};

export default compose(
  Protected(),
  withReduxPersistor,
  connect(
    mapStateToProps,
    {
      onLogout: logout,
    }
  )
)(Logout);
