import { gql } from "@apollo/client";

export const SET_AUTOMATED_MESSAGE = gql`
  mutation SetAutomatedMessage($content: String!) {
    setAutomatedMessage(content: $content)
  }
`;

export const SEND_BROADCAST_TEXT_MESSAGE = gql`
  mutation SendBroadcast($text: String!) {
    sendBroadcast(text: $text) {
      id
    }
  }
`;
