import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClearIcon from "@material-ui/icons/Clear";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LoadingIndicator from "../../LoadingIndicator";
import InputField from "../../InputField";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  submitIcon: {
    margin: theme.spacing(0, 1),
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  progressText: {
    fontStyle: "italic",
    marginLeft: theme.spacing(1),
  },
}));

function CreatePlaylist({
  isOpen,
  onClickCancel,
  onSubmit,
  loading,
  originalName,
}) {
  const initialValues = {
    name: "",
    isSubmitted: false,
  };

  React.useEffect(() => {
    setValues({ ...values, name: originalName });
  }, [originalName]);

  const [values, setValues] = React.useState(initialValues);

  const handleChange = (event) => {
    setValues({ ...values, name: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, isSubmitted: true });
    const { name } = values;
    const nameError = String(name).trim().length === 0;

    if (!nameError) {
      onSubmit({ name });
      setValues(initialValues);
    }
  };

  const textFieldProps = {
    inputType: "text",
    id: "name",
    label: "Name",
    value: values.name,
    name: "name",
    onChange: handleChange,
    placeholder: "Category name",
    InputLabelProps: {
      shrink: true,
    },
    autoFocus: true,
    disabled: loading,
    error: values.isSubmitted && String(values.name).trim().length === 0,
    helperText:
      values.isSubmitted && String(values.name).trim().length === 0
        ? "A name is required"
        : "",
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {originalName ? "Update " : "Create a new "}Category
        </DialogTitle>
        <DialogContent>
          <form
            className={classes.form}
            id="create-category-form"
            onSubmit={handleSubmit}
          >
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
              className={classes.dialogGridContent}
            >
              <Grid item xs={12}>
                <InputField {...textFieldProps} classes={classes} />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Fade in={loading}>
            <Box
              width="45%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <LoadingIndicator size={16} />
              <Typography
                variant="body2"
                component="span"
                className={classes.progressText}
              >
                {originalName ? "Updating" : "Creating"}, please wait ...
              </Typography>
            </Box>
          </Fade>
          <Button
            disabled={loading}
            variant="outlined"
            color="primary"
            onClick={onClickCancel}
          >
            <ClearIcon className={classes.rightMargin} />
            Cancel
          </Button>
          <Button
            disabled={String(values.name).trim().length === 0 || loading}
            variant="contained"
            color="primary"
            type="submit"
            form="create-category-form"
          >
            <CheckCircleIcon className={classes.rightMargin} />
            {originalName ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreatePlaylist;
