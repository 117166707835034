import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ClearIcon from '@material-ui/icons/Close';
import OkIcon from '@material-ui/icons/Check';
import DoneIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DragAndDropForm from './forms/DragAndDropForm';
import UploadProgressBar from '../../../components/UploadFile/UploadProgressBar';
import '../../../components/UploadFile/CommunityAlbums.css';
import { reduceImageSize } from '../../../lib/utils';
import { NOTIFICATION_TYPES } from '../../../lib/constants/notification-types';
import { API_ENDPOINTS } from '../../../lib/constants/api-endpoints';
import { makeSelectAuthToken } from '../../../lib/store/selectors/auth';
import { showNotification } from '../../../lib/store/actions/notifications';
import UploadProgress from '../../../lib/utils/uploadProgress';

const UploadFileList = ({
  albumId,
  authToken,
  onComplete,
  onReturnHome,
  onShowNotification,
}) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [successfullUploaded, setSuccessfullUploaded] = useState(false);

  useEffect(() => {
    return () => {
      setFiles([]);
      setUploadProgress({});
      setUploading(false);
      setSuccessfullUploaded(false);
    };
  }, []);

  const handleDrop = (newFiles) => {
    const filteredFiles = Array.from(newFiles).filter((file) =>
      new RegExp(['image/jpeg', 'image/bmp', 'image/png'].join('|')).test(
        file.type,
      ),
    );
    setFiles((prevFiles) =>
      successfullUploaded
        ? [...filteredFiles]
        : [...prevFiles, ...filteredFiles],
    );
    setSuccessfullUploaded(false);
  };

  const onRemoveFile = (file) => () => {
    setFiles((prevFiles) => prevFiles.filter((f) => f.id !== file.id));
  };

  const onSuccessfulUpload = () => {
    setFiles([]);
    setSuccessfullUploaded(false);
  };

  const onStartFileUpload = async () => {
    setUploading(true);
    setUploadProgress({});
    try {
      await files.reduce(async (promise, file) => {
        await promise;
        return sendRequest(file);
      }, Promise.resolve());
      setSuccessfullUploaded(true);
      setUploading(false);
      onComplete();
    } catch (e) {
      onShowNotification({
        message: `Error uploading: ${e}`,
        options: {
          variant: NOTIFICATION_TYPES.Warning,
        },
      });
      setUploading(false);
    }
  };

  const sendRequest = (file) => {
    const doUpload = new UploadProgress({
      onSend: (req) => {
        reduceImageSize({ file }, (error, newFile) => {
          const formData = new FormData();
          formData.append('file', newFile, newFile.name);

          req.open(
            'POST',
            API_ENDPOINTS.imageAlbums.replace(/:ALBUM_ID/, albumId),
          );
          req.setRequestHeader('Authorization', `Bearer ${authToken}`);

          req.send(formData);
        });
      },
      onLoad: () => {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.id]: { state: 'done', percentage: 100 },
        }));
      },
      onError: () => {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.id]: { state: 'error', percentage: 0 },
        }));
      },
      onProgress: (event) => {
        if (event.lengthComputable) {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.id]: {
              state: 'pending',
              percentage: (event.loaded / event.total) * 100,
            },
          }));
        }
      },
    });

    return doUpload.upload();
  };

  const renderUploadProgress = (file) => {
    const fileUploadProgress = uploadProgress[file.id];

    if (uploading || successfullUploaded) {
      return (
        <>
          <div
            style={{
              position: 'relative',
              height: 30,
            }}>
            <UploadProgressBar
              progress={fileUploadProgress ? fileUploadProgress.percentage : 0}
            />
            <DoneIcon
              className="UploadCompleteIcon"
              style={{
                opacity:
                  fileUploadProgress && fileUploadProgress.state === 'done'
                    ? 0.7
                    : 0,
              }}
            />
          </div>
        </>
      );
    }
  };

  const isRemoveBtnDisabled = successfullUploaded || uploading;

  const uploadBtnProps = successfullUploaded
    ? {
        onClick: onSuccessfulUpload,
        variant: 'outlined',
        color: 'primary',
        children: (
          <>
            <ClearIcon style={{ marginRight: '8px' }} />
            Clear Images
          </>
        ),
      }
    : {
        disabled: files.length === 0 || uploading,
        onClick: onStartFileUpload,
        variant: 'contained',
        color: 'primary',
        children: uploading ? (
          <>
            <CloudUploadIcon style={{ marginRight: '8px' }} />
            Uploading ...
          </>
        ) : (
          <>
            <CloudUploadIcon style={{ marginRight: '8px' }} />
            Upload Images
          </>
        ),
      };

  return (
    <div className="Upload">
      <div className="Content">
        <DragAndDropForm onHandleDrop={handleDrop}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            component="div"
            className="drop-zone">
            <CloudUploadIcon fontSize="large" />
            <Typography variant="h6" component="span">
              File Drop Zone ...
            </Typography>
            <Box mt={1}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="upload-button-file"
                multiple
                type="file"
                onChange={(e) => handleDrop(e.target.files)}
              />
              <label htmlFor="upload-button-file">
                <Button variant="outlined" component="span">
                  Select Files ...
                </Button>
              </label>
            </Box>
          </Box>
        </DragAndDropForm>
        <div className="Files">
          {files.map((file, index) => (
            <div key={file.id} className="FileRowWrapper">
              <div>
                <TextField
                  onChange={(event) => {
                    const { value } = event.target;
                    const updatedFile = new File([file], value, {
                      type: file.type,
                      lastModified: file.lastModified,
                    });
                    const filesCopy = [...files];
                    filesCopy.splice(index, 1, updatedFile);
                    setFiles(filesCopy);
                  }}
                  id={file.id}
                  value={file.name}
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                />
                {renderUploadProgress(file)}
              </div>
              <Tooltip title="Remove file" placement="top">
                <IconButton
                  disabled={isRemoveBtnDisabled}
                  type="button"
                  aria-label="Remove file"
                  onClick={onRemoveFile(file)}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
      <div className="Actions">
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column">
          <Button fullWidth type="button" {...uploadBtnProps} />
          {successfullUploaded && (
            <Box width="100%" mt={1}>
              <Button
                fullWidth
                type="button"
                variant="contained"
                color="primary"
                onClick={onReturnHome}>
                <OkIcon style={{ marginRight: '8px' }} />
                Finish
              </Button>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const selectAuthToken = makeSelectAuthToken();
  return {
    authToken: selectAuthToken(state),
  };
};

export default connect(mapStateToProps, {
  onShowNotification: showNotification,
})(UploadFileList);
