import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import RefreshIcon from '@material-ui/icons/Refresh';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import VirtualList from '../VirtualList';
import LoadingIndicator from '../LoadingIndicator';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
    height: 'calc(100vh - 350px)',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderBottom: 'solid',
    borderBottomColor: grey[400],
    borderBottomWidth: 1,
  },
  mainTitle: {
    marginBottom: theme.spacing(0.5),
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  viewStatsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    maxWidth: 300,
    padding: theme.spacing(1),
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  viewStat: {
    border: '1px solid #efefef',
    borderRadius: 4,
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  yAxis: {
    color: '#efefef',
  },
  graphContainer: {
    width: 700,
    height: 200,
    border: '1px solid #e2e2e2',
    borderRadius: 4,
    paddingTop: 20,
    paddingBottom: 10,
    paddingRight: 50,
  },
  refreshContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  refreshIcon: {
    marginRight: theme.spacing(1),
  },
}));

function StatsList({ stats, isLoading, renderRowTitle, totalTitle, graphTitle, onClickRefresh }) {
  const classes = useStyles();

  const _rowRender = item => {
    const { folderId, folderName, totalViewCount, chartData, uniqueUsers, parentFolderId } = item;

    return (
      <div className={classes.container}>
        <div className={classes.mainTitle}>
          <Typography component="h1" variant="body1">
            {renderRowTitle(folderId, folderName, parentFolderId)}
          </Typography>
        </div>
        <div className={classes.statsContainer}>
          <div className={classes.viewStatsContainer}>
            <div className={classes.viewStat}>
              <Typography component="h2" variant="body2" className={classes.statsTitle}>
                {totalTitle || 'Total Views'}
              </Typography>
              <Typography component="span" variant="h6">
                {totalViewCount}
              </Typography>
            </div>
            <div className={classes.viewStat}>
              <Typography component="h2" variant="body2" className={classes.statsTitle}>
                Unique Users
              </Typography>
              <Typography component="span" variant="h5">
                {Object.keys(uniqueUsers).length}
              </Typography>
            </div>
          </div>
          <div>
            <Typography component="h2" variant="body2" className={classes.statsTitle}>
              {graphTitle || 'Views by Day (Last 30 days)'}
            </Typography>
            <div className={classes.graphContainer}>
              <ResponsiveContainer>
                <BarChart data={chartData}>
                  <XAxis dataKey="label" />
                  <YAxis
                    type="number"
                    domain={[0, Math.ceil(totalViewCount / chartData.length) + 2]}
                    allowDecimals={false}
                  />
                  <Tooltip />
                  <Bar dataKey="viewCount" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {onClickRefresh && (
        <div className={classes.refreshContainer}>
          <Fab
            onClick={onClickRefresh}
            disabled={isLoading}
            variant="extended"
            size="small"
            color="primary"
            aria-label="Refresh"
          >
            <RefreshIcon className={classes.refreshIcon} />
            Refresh
          </Fab>
        </div>
      )}
      <VirtualList data={stats} rowRender={_rowRender} />
      {stats.length === 0 && !isLoading && (
        <div className={classes.loadingContainer}>
          <Typography component="p" variant="body2" className={classes.statsTitle}>
            No data found
          </Typography>
        </div>
      )}
      {isLoading && (
        <div className={classes.loadingContainer}>
          <LoadingIndicator size={26} />
        </div>
      )}
    </div>
  );
}

export default StatsList;
