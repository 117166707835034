import React from 'react';
import { useQuery } from '@apollo/client'; 
import 'moment/locale/en-au';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import EmptyMessage from '../../components/Messages/EmptyMessage';
import { setDialog } from '../../lib/store/reducers/dialog';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';
import NewAutomatedMsgDialog from './dialogs/NewAutomatedMessage';
import LoadingIndicator from '../../components/LoadingIndicator';
import Post from '../../components/Messages/Post';
import { GET_AUTOMATED_MESSAGE } from '../../lib/api/graphql/queries/messages';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 1.5),
    border: 'solid',
    borderColor: grey[300],
    borderWidth: 1,
    borderRadius: 4,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  subHeaderText: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    whiteSpace: 'pre',
    maxHeight: '200px',
    overflow: 'auto',
  },
}));

function AutoResponse() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GET_AUTOMATED_MESSAGE, {
    onError: () => {
      dispatch(
        showNotification({
          options: { variant: NOTIFICATION_TYPES.Error },
          message: 'Error while fetching last broadcast message',
        }),
      );
    },
  });

  const handleOpenAutoResponseDialog = () => {
    dispatch(
      setDialog({
        open: true,
        dialog: DIALOG_TYPES.ADD_OR_UPDATE_AUTOMATED_MESSAGE,
      }),
    );
  };

  const isEmpty = !data?.global?.automated_message && !loading && !error;

  if (isEmpty) {
    return (
      <div className={classes.root}>
        <NewAutomatedMsgDialog />
        <EmptyMessage
          emptyHeaderText="No Automated Message to show"
          emptyBodyText="You’ll be able to see the Automated Message preview here once You add it."
          onClickCreate={handleOpenAutoResponseDialog}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <NewAutomatedMsgDialog originalText={data?.global?.automated_message} />
      {loading ? (
        <div className={classes.loadingContainer}>
          <LoadingIndicator size={26} />
        </div>
      ) : (
        <Post
          showUser={false}
          type={4} //autoResponse
          read
          customSubHeader={
            <Typography className={classes.subHeaderText}>
              {data?.global?.automated_message || 'Automated Message goes here'}
            </Typography>
          }
        />
      )}
    </div>
  );
}

export default AutoResponse;
