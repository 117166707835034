import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ActivityIcon from '@material-ui/icons/Assessment';
import Page from './PageV2';
import MainContainer from '../containers/Activity/MainContainer';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function ActivityPage() {
  const classes = useStyles();

  return (
    <Page
      title={
        <>
          <ActivityIcon className={classes.icon} /> User Activity
        </>
      }
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <MainContainer />
    </Page>
  );
}

export default ActivityPage;
