import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CreatePlaylistDialog from "./CreatePlaylistDialog";
import CollectionTable from "../../components/Playlist/CollectionTable";
import { getPlaylists } from "../../lib/store/actions/playlists";
import { APP_ROUTES } from "../../lib/constants/app-routes";
import {
  selectPlaylistsTableData,
  selectIsFetchingPlaylists,
} from "../../lib/store/selectors/playlists";

class PlaylistCollection extends React.PureComponent {
  _isMounted = false;

  state = {
    filterText: "",
  };

  componentDidMount() {
    this._isMounted = true;
    const { onGetPlaylists } = this.props;
    onGetPlaylists();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState = (state, callback) => {
    if (this._isMounted) {
      this.setState(state, () => {
        if (callback && typeof callback === "function") callback();
      });
    }
  };

  onFilterPlaylists = (event) => {
    const { value } = event.target;
    this.updateState({
      filterText: String(value).toLowerCase(),
    });
  };

  onClickPlaylist = (playlistId) => () => {
    const { history } = this.props;
    history.push(`${APP_ROUTES.playlists}/${playlistId}`);
  };

  render() {
    const { filterText } = this.state;
    const { playlists, isFetching } = this.props;

    const _playlists =
      filterText.length > 0
        ? playlists.filter((c) =>
            String(c.name).toLowerCase().includes(filterText)
          )
        : playlists;

    const tableHeaders = ["Name", "Number of songs"];

    return (
      <>
        <CreatePlaylistDialog />
        <CollectionTable
          tableHeaders={tableHeaders}
          playlists={_playlists}
          onFilter={this.onFilterPlaylists}
          onClickPlaylist={this.onClickPlaylist}
          isFetching={isFetching}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    playlists: selectPlaylistsTableData(state),
    isFetching: selectIsFetchingPlaylists(state),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    onGetPlaylists: getPlaylists,
  })
)(PlaylistCollection);
