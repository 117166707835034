import { createReducer } from 'redux-create-reducer';
import * as types from '../actions/types';

const initialState = {
  dialogs: {
    addMember: false,
    editMember: false,
    memberPassword: false,
    adminPassword: false,
  },
  details: {
    name: '',
    licences: 0,
    licencesActive: 0,
    members: 0,
    orgActive: true,
  },
  emailInUse: null,
  memberPasswordReset: null,
  users: {
    lastGetParams: {},
    pagination: {},
    selected: null,
    list: [],
    importResults: {},
  },
};

export const organisation = createReducer(initialState, {
  [types.TOGGLE_ORG_UPD_ADMIN_PASSWORD_DIALOG](state) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        adminPassword: !state.dialogs.adminPassword,
      },
    };
  },
  [types.TOGGLE_ORG_UPG_MEMBER_PASSWORD_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        memberPassword: !state.dialogs.memberPassword,
      },
      memberPasswordReset: action.member
        ? {
            ...action.member,
          }
        : null,
    };
  },
  [types.TOGGLE_ORG_ADD_MEMBER_DIALOG](state) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        addMember: !state.dialogs.addMember,
      },
    };
  },
  [types.TOGGLE_ORG_EDIT_MEMBER_DIALOG](state) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        editMember: !state.dialogs.editMember,
      },
    };
  },
  [types.GET_ORG_DETAILS_OK](state, action) {
    return {
      ...state,
      details: { ...action.details },
    };
  },
  [types.UPD_ORG_MEMBER_OK](state, action) {
    return {
      ...state,
      users: {
        ...state.users,
        selected: null,
        list: state.users.list.map(u => {
          if (u.id === action.memberId) {
            return {
              ...u,
              ...action.updatedData,
            };
          }
          return { ...u };
        }),
      },
    };
  },
  [types.RESET_ORG_MEMBER_EMAIL_CHECK](state) {
    return {
      ...state,
      emailInUse: null,
    };
  },
  [types.CHK_ORG_MEMBER_EMAIL_OK](state, action) {
    return {
      ...state,
      emailInUse: action.isInUse,
    };
  },
  [types.GET_ORG_MEMBERS_OK](state, action) {
    return {
      ...state,
      users: {
        ...state.users,
        lastGetParams: { ...action.getParams },
        selected: null,
        pagination: { ...action.pagination },
        list: [...action.users],
      },
    };
  },
  [types.GET_ORG_MEMBER_OK](state, action) {
    return {
      ...state,
      users: {
        ...state.users,
        selected: { ...action.member },
      },
    };
  },
  [types.CLEAR_SELECTED_ORG_MEMBER](state) {
    return {
      ...state,
      users: {
        ...state.users,
        selected: null,
      },
    };
  },
  [types.CLEAR_ORG_MEMBER_IMPORT_RESULTS](state) {
    return {
      ...state,
      users: {
        ...state.users,
        importResults: {},
      },
    };
  },
  [types.IMPORT_ORG_MEMBERS_OK](state, action) {
    return {
      ...state,
      users: {
        ...state.users,
        importResults: { ...action.importResults },
      },
    };
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
