import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query Categories {
    categories {
      name
      id
      sub_folders {
        id
        files {
          id
        }
      }
      icon
    }
  }
`;

export const GET_CATEGORIES_NAMES_AND_ICONS = gql`
  query Categories {
    categories {
      name
      id
      icon
    }
  }
`;

export const GET_CATEGORIES_SUB_FOLDER = gql`
  query Sub_folders {
    categories {
      id
      name
      sub_folders {
        id
        Type
        Name
        ArtworkURL
        CreatedOn
        files {
          id
          Title
          Url
          Artist
          CreatedOn
        }
      }
    }
  }
`;
