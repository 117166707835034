import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Title from "./Title";
import Breadcrumbs from "./Breadcrumbs";

/**
 * @description PageCards component for displaying cards on a page
 * @param {Array} cards - Array of objects containing the following properties:
 * - id: String
 * - title: JSX Element or String
 * - menuItems: Array of objects
 * - gridContainerProps: Object
 * - gridItemProps: Object
 * - isMenuDisabled: Boolean
 * - breadcrumbs: Array of objects
 * - subCards: Array of objects of the same structure as cards
 * - paperElevation: Number
 * - Element: JSX Element
 * @param {Boolean} isSubCardItem - Boolean to indicate if the PageCards component is a sub-section
 * @returns {JSX} - PageCards component
 */
function PageCards({ cards, isSubCardItem }) {
  if (!cards || cards.length === 0) return null;

  return (
    <React.Fragment>
      {cards.map((card, index) => {
        return (
          <PageCardItem
            key={card?.id + index}
            isSubCardItem={isSubCardItem}
            {...card}
          />
        );
      })}
    </React.Fragment>
  );
}

export default PageCards;

function PageCardItem({
  gridContainerProps,
  gridItemProps,
  breadcrumbs,
  title,
  menuItems,
  isMenuDisabled,
  subCards,
  paperElevation = 1,
  Element,
  isSubCardItem,
}) {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = React.useState(null);

  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleClickMenuItem = (callback) => () => {
    if (callback && typeof callback === "function") callback();
    handleCloseMenu();
  };

  return (
    <Grid container {...gridContainerProps}>
      <Grid item xs={12} {...gridItemProps}>
        <Paper className={classes.paper} elevation={paperElevation}>
          {Boolean(breadcrumbs) && breadcrumbs.length > 0 && (
            <Breadcrumbs crumbs={breadcrumbs} />
          )}
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            direction="row"
          >
            <Grid item className={classes.centerContent}>
              <Title removeVarient={isSubCardItem}>{title}</Title>
            </Grid>
            {menuItems && (
              <Grid item>
                <Tooltip title="More Options" placement="top">
                  <div>
                    <IconButton
                      disabled={isMenuDisabled}
                      aria-label="More"
                      aria-controls="more-options-menu"
                      aria-haspopup="true"
                      onClick={handleOpenMenu}
                    >
                      <MenuIcon fontSize="large" />
                    </IconButton>
                  </div>
                </Tooltip>
                <Menu
                  id="more-options-menu"
                  anchorEl={menuAnchor}
                  keepMounted
                  open={Boolean(menuAnchor)}
                  onClose={handleCloseMenu}
                >
                  {menuItems.map((menu, i) => (
                    <MenuItem
                      key={i}
                      onClick={handleClickMenuItem(menu.onClick)}
                    >
                      {menu.title}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            )}
          </Grid>
          {(Boolean(title) || menuItems) && !isSubCardItem && <Divider />}
          {Element}
          {subCards && subCards.length > 0 && (
            <PageCards cards={subCards} isSubCardItem />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  gridListroot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    padding: theme.spacing(2),
  },
  gridList: {
    maxWidth: "80%",
  },
  gridListTileBarRoot: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  gridListTileBarTitle: {
    color: "#222222",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  menuButton: {
    marginLeft: "auto",
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
