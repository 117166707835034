import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppToolbar from './AppToolbar';
import Navigation from '../containers/Navigation';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function DashboardLayout({
  isDrawerOpen,
  onDrawerOpen,
  onDrawerClose,
  children,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppToolbar
        isDrawerOpen={isDrawerOpen}
        onClickOpenDrawer={onDrawerOpen}
        onClickCloseDrawer={onDrawerClose}
      />
      <Navigation isDrawerOpen={isDrawerOpen} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
}
