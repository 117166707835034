import React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import EditIcon from '@material-ui/icons/Edit';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import NameAndArtwork from '../../../containers/Programs/Cultural/CulturalNameAndArtwork';
import CulturalSubFolder from '../../../containers/Programs/Cultural/CulturalSubFolder';
import Page from '../../PageV2';
import {
  setUpdateCulturalCategoryDialog,
  setDeleteCulturalCategoryDialog,
  setAddCulturalCategorySongsDialog,
} from '../../../lib/store/actions/programs';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1.5),
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  artworkAndName: {
    height: '350px',
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

function SubFolderPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const pageMenuItems = [
    {
      title: (
        <>
          <PlaylistAddIcon className={classes.icon} /> Add song(s) to category
          ...
        </>
      ),
      onClick: () => dispatch(setAddCulturalCategorySongsDialog(true)),
    },
    {
      title: (
        <>
          <EditIcon className={classes.icon} /> Edit category name
        </>
      ),
      onClick: () => dispatch(setUpdateCulturalCategoryDialog(true)),
    },
    {
      title: (
        <>
          <DeleteIcon className={classes.icon} /> Delete entire category
        </>
      ),
      onClick: () => dispatch(setDeleteCulturalCategoryDialog(true)),
    },
  ];

  return (
    <Page
      title={
        <>
          <LibraryMusicIcon className={classes.icon} /> Category Details
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 12,
      }}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <CulturalSubFolder />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Paper className={classes.artworkAndName}>
            <NameAndArtwork />
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
}

export default SubFolderPage;
