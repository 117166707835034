import { gql } from '@apollo/client';

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organizations {
      id
      Name
      active_count
      Active
      CreatedOn
      Licences
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query Organization($orgId: ObjectID!) {
    organization(org_id: $orgId) {
      id
      admins {
        id
        Email
        FirstName
        LastName
      }
      CreatedOn
      Licences
      Active
      Name
      active_count
      members_count
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query User($userId: ObjectID!) {
    user(user_id: $userId) {
      id
      BirthDate
      AgreeMail
      Active
      Country
      CreatedOn
      Email
      FirstName
      LastName
      Postcode
      State
      saved_cards {
        stripe_id
      }
      subscription {
        type
        status
        current_period_start
        current_period_end
        cancel_at_period_end
      }
      Organisation {
        id
      }
      LastAccess
      messages {
        id
        Body
        CreatedOn
      }
    }
  }
`;
