import { createSelector } from 'reselect';
import {
  API_GET_LYRICS_STATS_REQ,
} from '../actions/types';

/* Activity selectors */

/* Hymns - selectors */
const hasLoadedHymnsStatsData = (state) => {
  return state.activity.loaded.hymns;
};
export const selectHasLoadedHymnsStatsData = createSelector(
  [hasLoadedHymnsStatsData],
  (hasLoaded) => hasLoaded,
);

const hymnsStatsDataSelector = (state) => {
  return state.activity.hymns;
};
export const selectHymnsStatsData = createSelector(
  [hymnsStatsDataSelector],
  (stats) => stats,
);

/* Image Albums (Sight & Sound) - selectors */
const hasLoadedImageAlbumStatsData = (state) => {
  return state.activity.loaded.imageAlbums;
};
export const selectHasLoadedImageAlbumStatsData = createSelector(
  [hasLoadedImageAlbumStatsData],
  (hasLoaded) => hasLoaded,
);

const imageAlbumStatsDataSelector = (state) => {
  return state.activity.imageAlbums;
};
export const selectImageAlbumStatsData = createSelector(
  [imageAlbumStatsDataSelector],
  (stats) => stats,
);

/* Quizzes - selectors */
const hasLoadedQuizStatsData = (state) => {
  return state.activity.loaded.quizzes;
};
export const selectHasLoadedQuizStatsData = createSelector(
  [hasLoadedQuizStatsData],
  (hasLoaded) => hasLoaded,
);

const quizStatsDataSelector = (state) => {
  return state.activity.quizzes;
};
export const selectQuizStatsData = createSelector(
  [quizStatsDataSelector],
  (stats) => stats,
);

/* Lyrics - selectors */
const hasLoadedLyricsStatsData = (state) => {
  return state.activity.loaded.lyrics;
};
export const selectHasLoadedLyricsStatsData = createSelector(
  [hasLoadedLyricsStatsData],
  (hasLoaded) => hasLoaded,
);

const lyricsStatsDataSelector = (state) => {
  return state.activity.lyrics;
};
export const selectLyricsStatsData = createSelector(
  [lyricsStatsDataSelector],
  (stats) => stats,
);

const isFetchingLyricsStatsSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_GET_LYRICS_STATS_REQ,
  );
};
export const selectIsFetchingLyricsStats = createSelector(
  [isFetchingLyricsStatsSelector],
  (isFetching) => isFetching,
);

/* Radio - selectors */
const hasLoadedRadioStatsData = (state) => {
  return state.activity.loaded.radio;
};
export const selectHasLoadedRadioStatsData = createSelector(
  [hasLoadedRadioStatsData],
  (hasLoaded) => hasLoaded,
);

const radioStatsDataSelector = (state) => {
  return state.activity.radio;
};
export const selectRadioStatsData = createSelector(
  [radioStatsDataSelector],
  (stats) => stats,
);

/* Programs - selectors */
const hasLoadedProgramsStatsData = (state) => {
  return state.activity.loaded.programs;
};
export const selectHasLoadedProgramsStatsData = createSelector(
  [hasLoadedProgramsStatsData],
  (hasLoaded) => hasLoaded,
);

const programsStatsDataSelector = (state) => {
  return state.activity.programs;
};
export const selectProgramsStatsData = createSelector(
  [programsStatsDataSelector],
  (stats) => stats,
);

/* Weekly Programs - selectors */
const hasLoadedWeeklyProgramsStatsData = (state) => {
  return state.activity.loaded.weeklyPrograms;
};
export const selectHasLoadedWeeklyProgramsStatsData = createSelector(
  [hasLoadedWeeklyProgramsStatsData],
  (hasLoaded) => hasLoaded,
);

const weeklyProgramsStatsDataSelector = (state) => {
  return state.activity.weeklyPrograms;
};
export const selectWeeklyProgramsStatsData = createSelector(
  [weeklyProgramsStatsDataSelector],
  (stats) => stats,
);

/* Playlists - selectors */
const hasLoadedPlaylistsStatsData = (state) => {
  return state.activity.loaded.playlists;
};
export const selectHasLoadedPlaylistsStatsData = createSelector(
  [hasLoadedPlaylistsStatsData],
  (hasLoaded) => hasLoaded,
);

const playlistsStatsDataSelector = (state) => {
  return state.activity.playlists;
};
export const selectPlaylistsStatsData = createSelector(
  [playlistsStatsDataSelector],
  (stats) => stats,
);

/* Cultural Programs */
const cultralProgramsSelector = (state) => {
  return state.activity.cultralPrograms;
};
export const selectCulturalPrograms = createSelector(
  [cultralProgramsSelector],
  (stats) => stats,
);

const hasLoadedCulturalStatsData = (state) => {
  return state.activity.loaded.cultralPrograms;
};
export const selectHasCulturalQuizStatsData = createSelector(
  [hasLoadedCulturalStatsData],
  (hasLoaded) => hasLoaded,
);
