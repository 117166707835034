import { createSelector } from 'reselect';
import { API_GET_LYRICS_REQ, API_DEL_LYRICS_REQ, API_UPD_LYRICS_REQ } from '../actions/types';

/* Lyrics selectors */
const lyricsTableSelector = state => {
  return state.lyrics.list;
};
export const makeSelectLyricsTableData = () =>
  createSelector(
    [lyricsTableSelector],
    lyrics => lyrics
  );

const isFetchingLyricsSelector = state => {
  return !!state.apiRequest.find(req => req.requestType === API_GET_LYRICS_REQ);
};
export const selectIsFetchingLyrics = createSelector(
  [isFetchingLyricsSelector],
  isFetching => isFetching
);

const deleteLyricsDialogOpenSelector = state => state.lyrics.dialogs.deleteLyrics;
export const selectIsDeleteLyricsDialogOpen = createSelector(
  [deleteLyricsDialogOpenSelector],
  isOpen => isOpen
);

const isDeletingLyricsSelector = state => {
  return !!state.apiRequest.find(req => req.requestType === API_DEL_LYRICS_REQ);
};
export const selectIsDeletingLyrics = createSelector(
  [isDeletingLyricsSelector],
  isDeleting => isDeleting
);

const editLyricsNameDialogOpenSelector = state => state.lyrics.dialogs.editLyricsName;
export const selectIsEditLyricsNameDialogOpen = createSelector(
  [editLyricsNameDialogOpenSelector],
  isOpen => isOpen
);

const isUpdatingLyricsNameSelector = state => {
  return !!state.apiRequest.find(req => req.requestType === API_UPD_LYRICS_REQ);
};
export const selectIsUpdatingLyricsName = createSelector(
  [isUpdatingLyricsNameSelector],
  isUpdating => isUpdating
);

const addLyricsDialogOpenSelector = state => state.lyrics.dialogs.addLyrics;
export const selectIsAddLyricsDialogOpen = createSelector(
  [addLyricsDialogOpenSelector],
  isOpen => isOpen
);
