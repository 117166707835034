/* eslint-disable arrow-body-style */
import { v4 as uuidV4 } from 'uuid';
import * as types from './types';
import { playlistsApi } from '../../api';
import { apiRequest } from './api-request';
import { showNotification } from './notifications';
import { NOTIFICATION_TYPES } from '../../constants/notification-types';
import { getAuthToken, checkHttpError } from './authTokenHelper';
import defaultArtwork from '../../../assets/images/music-note.jpg';
import sort from 'fast-sort';

export const setCreatePlaylistDialog = (isOpen) => ({
  type: types.TOGGLE_CREATE_PLAYLIST_DIALOG,
  isOpen,
});

export const setDeletePlaylistDialog = (isOpen) => ({
  type: types.TOGGLE_DELETE_PLAYLIST_DIALOG,
  isOpen,
});

export const setEditPlaylistNameDialog = (isOpen) => ({
  type: types.TOGGLE_EDIT_PLAYLIST_NAME_DIALOG,
  isOpen,
});

export const setAddSongsToPlaylistDialog = (isOpen) => ({
  type: types.TOGGLE_ADD_SONGS_DIALOG,
  isOpen,
});

export const setDeletePlaylistSongDialog = (isOpen) => ({
  type: types.TOGGLE_DELETE_PLAYLIST_SONG_DIALOG,
  isOpen,
});

export const getPlaylists = (callback) => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_GET_PLAYLISTS_REQ,
    message: 'Loading playlists',
  });
  dispatch(apiReqAction);
  playlistsApi
    .getPlaylists(getAuthToken(getState()))
    .then(
      (playlists) => {
        const incoming = sort(
          playlists?.map((p) => ({
            id: p.id,
            name: p.name,
            artwork: p.artworkURL || defaultArtwork,
            list: p.audioFileList,
          })) || [],
        ).by([{ asc: (o) => String(o.name).toLowerCase() }]);

        dispatch({
          type: types.GET_PLAYLISTS_OK,
          playlists: incoming,
          apiReqId: apiReqAction.id,
        });
        if (callback && typeof callback === 'function') callback(incoming);
      },
      (error) => dispatch(checkHttpError(error, apiReqAction.id)),
    )
    .catch((error) => {
      dispatch(
        showNotification({
          message: `Get playlists: ${error.message}` || 'Cannot get playlists',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};

export const createPlaylist =
  ({ name }, successCB) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_ADD_PLAYLIST_REQ,
      message: 'Creating playlist',
    });
    dispatch(apiReqAction);

    const tempId = uuidV4();
    dispatch({
      type: types.ADD_PLAYLIST_OPTIMISTIC,
      playlist: {
        id: tempId,
        name,
        list: [],
        artwork: defaultArtwork,
      },
    });

    playlistsApi
      .createPlaylist(getAuthToken(getState()), { name })
      .then(
        (plauylistId) => {
          dispatch({
            type: types.ADD_PLAYLIST_OK,
            playlist: { tempId, realId: plauylistId },
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'New playlist saved to the database',
              options: {
                variant: NOTIFICATION_TYPES.Success,
              },
            }),
          );
          if (successCB && typeof successCB === 'function') successCB();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message:
              `Create playlist: ${error.message}` || 'Cannot create playlist',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const savePlaylistName =
  ({ id, name }, successCB) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_UPD_PLAYLIST_REQ,
      message: 'Updating name',
    });
    dispatch(apiReqAction);

    playlistsApi
      .updatePlaylistName(getAuthToken(getState()), { id, name })
      .then(
        () => {
          dispatch({
            type: types.UPDATE_PLAYLIST_NAME_OK,
            playlist: { id, name },
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'Playlist name updated',
              options: {
                variant: NOTIFICATION_TYPES.Success,
              },
            }),
          );
          if (successCB && typeof successCB === 'function') successCB();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message:
              `Edit playlist name: ${error.message}` ||
              'Cannot update playlist name',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const deletePlaylist =
  ({ playlistId }, successCB) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_DEL_PLAYLIST_REQ,
      message: 'Deleting playlist',
    });
    dispatch(apiReqAction);

    playlistsApi
      .deletePlaylist(getAuthToken(getState()), { id: playlistId })
      .then(
        () => {
          dispatch({
            type: types.DELETE_PLAYLIST_OK,
            playlistId,
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'Playlist successfully deleted',
              options: {
                variant: NOTIFICATION_TYPES.Success,
                autoHideDuration: 2000,
              },
            }),
          );
          if (successCB && typeof successCB === 'function') successCB();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message:
              `Delete playlist: ${error.message}` || 'Cannot delete playlist',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const deletePlaylistSong =
  ({ playlistId, songId }, successCB) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_DEL_PLAYLIST_SONG_REQ,
      message: 'Deleting playlist song',
    });
    dispatch(apiReqAction);

    playlistsApi
      .deletePlaylistSong(getAuthToken(getState()), { id: playlistId, songId })
      .then(
        () => {
          dispatch({
            type: types.DELETE_PLAYLIST_SONG_OK,
            playlistId,
            songId,
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'Playlist song successfully deleted',
              options: {
                variant: NOTIFICATION_TYPES.Success,
                autoHideDuration: 2000,
              },
            }),
          );
          if (successCB && typeof successCB === 'function') successCB();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message:
              `Delete playlist song: ${error.message}` ||
              'Cannot delete playlist song',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const updatePlaylistArtwork = ({ id, artworkURL }) => {
  return {
    type: types.UPDATE_PLAYLIST_ARTWORK,
    playlistId: id,
    artworkURL,
  };
};
