import React from 'react';
import { connect } from 'react-redux';

import DetailsComponent from '../../components/Organisation/Details';
import { selectOrgDetails } from '../../lib/store/selectors/organisation';

import { useGetOrgDetails } from './useGetOrgDetails.js';

function Details({ licences, members, licencesInUse, isOrgActive }) {
  const { loading: gettingOrgDetails, refetch } = useGetOrgDetails();

  const handleRefresh = async () => {
    await refetch();
  };

  return (
    <DetailsComponent
      licences={licences}
      members={members}
      inUse={licencesInUse}
      isLoading={gettingOrgDetails}
      isOrgActive={isOrgActive}
      onRefresh={handleRefresh}
    />
  );
}

const mapStateToProps = (state) => {
  const { licences, licencesActive, members, orgActive } =
    selectOrgDetails(state);

  return {
    licences,
    members,
    licencesInUse: licencesActive,
    isOrgActive: orgActive,
  };
};

export default connect(mapStateToProps)(Details);
