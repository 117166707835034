/* eslint-disable no-nested-ternary */
import React from 'react';
import debounce from 'lodash.debounce';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import LoadingIndicator from '../LoadingIndicator';
import InputField from '../InputField';
import ErrorMessage from '../ErrorMessage';
import usePrevious from '../../containers/usePrevious';
import { isValidEmail } from '../../lib/utils';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: '10px',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  progressText: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(1),
  },
  successInput: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderColor: 'green',
      borderLeftWidth: 6,
      padding: '4px !important', // override inline-style
    },
  },
}));

function EditOrgMemberDialog({
  member,
  isOpen,
  isLoading,
  isUpdating,
  isEmailInUse,
  isCheckingEmailInUse,
  isOrgActive,
  onCancel,
  onSubmit,
  onCheckEmail,
}) {
  const prevIsOpen = usePrevious(isOpen);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    postcode: '',
    state: '', // If Australia, ACT, NSW, NT, QLD, SA, TAS, VIC, WA; if New Zealand, not appilcable
    country: '', // Australia or New Zealand
    active: true, // active = true, inactive = false
    emailInUse: false,
    checkingEmailInUse: false,
    isSubmitted: false,
    changed: {},
  };
  const debouncedFn = React.useRef(null);
  const [values, setValues] = React.useState(initialValues);

  React.useEffect(() => {
    if (isOpen && !prevIsOpen) {
      setValues(initialValues);
    }
  }, [isOpen, prevIsOpen, initialValues]);

  React.useEffect(() => {
    setValues((vals) => ({
      ...vals,
      emailInUse: isEmailInUse,
      checkingEmailInUse: isCheckingEmailInUse,
    }));
  }, [isEmailInUse, isCheckingEmailInUse, member]);

  React.useEffect(() => {
    setValues((vals) => ({
      ...vals,
      firstName: member ? member.firstName : '',
      lastName: member ? member.lastName : '',
      email: member ? member.email : '',
      postcode: member ? member.postcode : '',
      state: member ? member.state : '',
      country: member ? member.country : '',
    }));
  }, [member]);

  const handleClose = () => {
    setValues(initialValues);
    onCancel();
  };

  const handleCheckEmailDelayed = (emailToCheck) => {
    if (!debouncedFn.current) {
      debouncedFn.current = debounce((email) => {
        const isCurrent = member && member.email && email === member.email;
        if (!isCurrent && isValidEmail(email)) {
          onCheckEmail(email);
        }
      }, 600);
    }

    debouncedFn.current(emailToCheck);
  };

  const handleChange = (field) => (event) => {
    if (field === 'active') {
      return setValues((vals) => ({
        ...vals,
        [field]: !vals[field],
        changed: { ...vals.changed, active: true },
      }));
    }

    const { value } = event.target;

    if (field === 'country' && (value === '' || value === 'New Zealand')) {
      return setValues((vals) => ({
        ...vals,
        [field]: value,
        state: '',
        changed: { ...vals.changed, country: true },
      }));
    }

    setValues((vals) => ({
      ...vals,
      [field]: value,
      changed: { ...vals.changed, [field]: true },
    }));

    if (field === 'email') {
      handleCheckEmailDelayed(value);
    }

    if (field === 'email' && (value === '' || !isValidEmail(value))) {
      setValues((vals) => ({ ...vals, emailInUse: null }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setValues((vals) => ({ ...vals, isSubmitted: true }));

    const isNotValid =
      values.firstName.trim().length === 0 ||
      values.lastName.trim().length === 0 ||
      values.email.trim().length === 0 ||
      !isValidEmail(values.email) ||
      (values.country === 'Australia' && values.state === '');

    if (isNotValid) return;

    const updatedData = Object.keys(values.changed).reduce((acc, key) => {
      if (
        (key === 'email' && member && member.email === values.email.trim()) ||
        (key === 'firstName' &&
          member &&
          member.firstName === values.firstName.trim()) ||
        (key === 'lastName' &&
          member &&
          member.lastName === values.lastName.trim())
      ) {
        return acc;
      }

      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});

    onSubmit({ updatedData });
  };

  const classes = useStyles();

  const emailHelperText = () => {
    if (
      values.isSubmitted &&
      (values.email.trim().length === 0 || !isValidEmail(values.email))
    ) {
      return 'A valid email is required';
    }

    if (values.emailInUse === true) {
      return 'This email is already in use';
    }

    if (values.checkingEmailInUse) {
      return 'Checking if email is available ...';
    }

    return '';
  };

  const emailError = () => {
    if (values.emailInUse) {
      return true;
    }

    return (
      values.isSubmitted &&
      (values.email.trim().length === 0 ||
        !isValidEmail(values.email) ||
        values.emailInUse)
    );
  };

  const fields = [
    {
      id: 'firstName',
      label: 'First name',
      inputType: 'text',
      disabled: isLoading || isUpdating || !isOrgActive,
      value: values.firstName,
      onChange: handleChange('firstName'),
      error: values.isSubmitted && values.firstName.trim().length === 0,
      helperText:
        values.isSubmitted && values.firstName.trim().length === 0
          ? 'First name is required'
          : '',
    },
    {
      id: 'lastName',
      label: 'Last name',
      inputType: 'text',
      disabled: isLoading || isUpdating || !isOrgActive,
      value: values.lastName,
      onChange: handleChange('lastName'),
      error: values.isSubmitted && values.lastName.trim().length === 0,
      helperText:
        values.isSubmitted && values.lastName.trim().length === 0
          ? 'Last name is required'
          : '',
    },
    {
      id: 'email',
      label: 'Login Email',
      inputType: 'text',
      disabled:
        isLoading || values.checkingEmailInUse || isUpdating || !isOrgActive,
      value: values.email,
      autoComplete: 'new-password',
      onChange: handleChange('email'),
      error: emailError(),
      helperText: emailHelperText(),
    },
    {
      inputType: 'select',
      id: 'country',
      name: 'country',
      label: 'Country',
      disabled: isLoading || isUpdating || !isOrgActive,
      value: values.country,
      onChange: handleChange('country'),
      options: [
        { label: 'None', value: '' },
        { label: 'Australia', value: 'Australia' },
        { label: 'New Zealand', value: 'New Zealand' },
      ],
    },
    {
      inputType: 'text',
      id: 'postcode',
      name: 'postcode',
      label: 'Postcode',
      disabled: isLoading || isUpdating || !isOrgActive,
      value: values.postcode,
      onChange: handleChange('postcode'),
    },
    {
      inputType: 'select',
      id: 'state',
      name: 'state',
      label: 'State',
      disabled:
        isLoading ||
        values.country !== 'Australia' ||
        isUpdating ||
        !isOrgActive,
      value: values.state,
      onChange: handleChange('state'),
      options: [
        { label: 'None', value: '' },
        { label: 'Australian Capital Territory', value: 'ACT' },
        { label: 'New South Wales', value: 'NSW' },
        { label: 'Northern Territory', value: 'NT' },
        { label: 'Queensland', value: 'QLD' },
        { label: 'South Australia', value: 'SA' },
        { label: 'Tasmania', value: 'TAS' },
        { label: 'Victoria', value: 'VIC' },
        { label: 'Western Australia', value: 'WA' },
      ],
      error:
        values.isSubmitted &&
        values.country === 'Australia' &&
        values.state === '',
      helperText:
        values.country === 'Australia'
          ? 'Choose a state'
          : 'Only required for Australian members',
    },
  ];

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Edit member details and press save{' '}
          {isLoading && (
            <div style={{ display: 'inline-block' }}>
              <Typography
                variant="body2"
                component="span"
                className={classes.progressText}>
                Loading, please wait ...
              </Typography>
              <div style={{ marginRight: 5, display: 'inline-block' }} />
              <LoadingIndicator size={16} />
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <form
            className={classes.form}
            id="add-member-form"
            onSubmit={handleSubmit}
            autoComplete="off">
            <Grid container spacing={2}>
              {!isOrgActive && (
                <Grid item xs={12}>
                  <ErrorMessage message="Organisation Inactive. Cannot make changes." />
                </Grid>
              )}

              {fields.map((fieldProps) => (
                <Grid item xs={6} key={fieldProps.id}>
                  <InputField {...fieldProps} classes={classes} />
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Fade in={isUpdating}>
            <Box
              width="45%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center">
              <LoadingIndicator size={16} />
              <Typography
                variant="body2"
                component="span"
                className={classes.progressText}>
                Saving, please wait ...
              </Typography>
            </Box>
          </Fade>
          <Button
            disabled={isUpdating}
            variant="outlined"
            color="primary"
            onClick={handleClose}>
            <ClearIcon className={classes.rightMargin} />
            Cancel
          </Button>
          <Button
            disabled={isLoading || isUpdating || !isOrgActive}
            variant="contained"
            color="primary"
            type="submit"
            form="add-member-form">
            <CheckCircleIcon className={classes.rightMargin} />
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditOrgMemberDialog.defaultProps = {
  member: {},
};

export default EditOrgMemberDialog;
