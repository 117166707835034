import { createReducer } from 'redux-create-reducer';
import { USER_ROLES } from '../../constants/user-roles';
import * as types from '../actions/types';

const initialState = {
  auth: {},
  details: {},
};

export const user = createReducer(initialState, {
  [types.LOGIN_OK](state, action) {
    const { auth, firebaseId, ...rest } = action.userData;
    return {
      ...state,
      auth: {
        idToken: auth.idToken,
        refreshToken: auth.refreshToken,
        firebaseId,
      },
      details: {
        id: rest.id,
        email: rest.email,
        firstName: rest.firstName,
        lastName: rest.lastName,
        organisationId: rest.organisationId,
        role:
          rest.organisationId &&
          (rest.organisationId === '000000000000000000000000' ||
            rest.organisationId === '00000000-0000-0000-0000-000000000000')
            ? USER_ROLES.ADMIN
            : USER_ROLES.ORGANISATION,
      },
    };
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
