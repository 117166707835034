import { authRequest } from './apiSetup';
import { API_ENDPOINTS } from '../../constants/api-endpoints';

/*
 * Image Albums API
 ** */

export async function getImages(idToken, { albumId }) {
  const endpoint = API_ENDPOINTS.imageAlbums.replace(/:ALBUM_ID/, albumId);
  const response = await authRequest(idToken, { method: 'GET', endpoint });
  return response.data;
}

export async function getImageAlbums(idToken) {
  const endpoint = API_ENDPOINTS.imageAlbums.replace(/:ALBUM_ID/, '');
  const response = await authRequest(idToken, { method: 'GET', endpoint });
  return response.data;
}

export async function deleteImage(idToken, { albumId, imageId }) {
  const endpoint = API_ENDPOINTS.imageAlbumFile
    .replace(/:ALBUM_ID/, albumId)
    .replace(/:FILE_ID/, imageId);
  const response = await authRequest(idToken, { method: 'DELETE', endpoint });
  return response.state === 1;
}

export async function deleteAlbum(idToken, { albumId }) {
  const endpoint = API_ENDPOINTS.imageAlbums.replace(/:ALBUM_ID/, albumId);
  const response = await authRequest(idToken, { method: 'DELETE', endpoint });
  return response.state === 1;
}

export async function editAlbumName(idToken, { albumId, albumName }) {
  const endpoint = API_ENDPOINTS.imageAlbums.replace(/:ALBUM_ID/, albumId);
  const response = await authRequest(idToken, {
    method: 'PUT',
    endpoint,
    body: JSON.stringify(albumName),
  });
  return response.state === 1;
}

export async function createNewAlbum(idToken, { albumName }) {
  const endpoint = API_ENDPOINTS.imageAlbums.replace(/:ALBUM_ID/, '');
  const response = await authRequest(idToken, {
    method: 'POST',
    endpoint,
    body: JSON.stringify(albumName),
  });
  return response.data;
}
