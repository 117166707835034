import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { PersistorContext } from './withReduxPersistor';
import { store, persistor } from '../lib/store/configureStore';
import App from './App';

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <PersistorContext.Provider value={persistor}>
        <Router>
          <App />
        </Router>
      </PersistorContext.Provider>
    </PersistGate>
  </Provider>
);

export default Root;
