import React from 'react';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayCircleOutline';
import PauseIcon from '@material-ui/icons/PauseCircleOutline';
import StopIcon from '@material-ui/icons/StopRounded';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import LoadingIndicator from './LoadingIndicator';
import TableTemplate from './TableTemplate';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  button: {
    padding: '0 3px',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  filterBox: {
    padding: theme.spacing(0, 1, 1),
  },
  songTitleArtistContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  songIndex: {
    marginRight: theme.spacing(1),
  },
  songTitle: {
    fontWeight: 'bold',
    fontSize: '1em',
    display: 'block',
  },
  songArtist: {
    fontSize: '0.9em',
  },
  playBtn: {
    '&:hover > span': {
      color: '#4dff4d',
    },
  },
  pauseBtnIcon: {
    color: '#ff9200',
  },
  stopBtnIcon: {
    color: '#ff3b3b',
  },
}));

export default function SongsTable({
  tableHeaders,
  songs,
  onFilter,
  filterText,
  isFetching,
  onClickDeleteSong,
  emptyText,
}) {
  const initialState = {
    songId: '',
    songUrl: '',
    playing: false,
  };

  const [player, setPlayer] = React.useState(initialState);
  const classes = useStyles();

  /* Player Controls */
  const handleStop = () => {
    setPlayer({ playing: false });
  };

  const handlePlayPause = (songId, songUrl) => () => {
    setPlayer({
      songId,
      playing: !(player.songId === songId && player.playing),
      songUrl,
    });
  };

  /* Song Functions */
  const handleClickDelete = (id) => () => {
    if (player.playing) {
      handleStop();
    }
    onClickDeleteSong(id);
  };

  /* Table helper/render functions */
  const _tableKeyExtractor = (row) => row.id;

  const _tableRowRenderer = (row, index) => {
    const { id, url, title, artist, Title } = row; // eslint-disable-line

    const isThisSong = id === player.songId;

    return (
      <>
        <TableCell component="th" scope="row">
          <div className={classes.songTitleArtistContainer}>
            <div className={classes.songIndex}>{`${index + 1}.`}</div>
            <div>
              <span className={classes.songTitle}>{title}</span>
              <span className={classes.songArtist}>{artist}</span>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div>
            <IconButton
              aria-label={
                isThisSong && player.playing
                  ? `Pause ${title}`
                  : `Play ${title}`
              }
              onClick={handlePlayPause(id, url)}
              className={classes.playBtn}>
              {isThisSong && player.playing ? (
                <PauseIcon
                  fontSize="small"
                  className={isThisSong ? classes.pauseBtnIcon : null}
                />
              ) : (
                <PlayArrowIcon fontSize="small" />
              )}
            </IconButton>
            <IconButton
              disabled={!isThisSong}
              aria-label={`Stop ${title}`}
              onClick={handleStop}>
              <StopIcon
                fontSize="small"
                className={isThisSong ? classes.stopBtnIcon : null}
              />
            </IconButton>
          </div>
        </TableCell>
        <TableCell scope="row">
          <IconButton
            aria-label={`Delete ${title}`}
            onClick={handleClickDelete(id)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <Paper className={classes.root}>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        className={classes.filterBox}>
        <TextField
          id="contacts-search"
          label="Filter by artist or title"
          type="search"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          placeholder="Type in the playlist name ..."
          onChange={onFilter}
          value={filterText}
          autoComplete="off"
        />
      </Box>
      <ReactPlayer
        playing={player.playing}
        url={player.songUrl}
        width="0%"
        height="0%"
      />
      <TableTemplate
        data={songs}
        isLoading={isFetching}
        tableHeaders={tableHeaders}
        empty={
          <Box display="flex" justifyContent="center" flexDirection="row">
            <p>{emptyText}</p>
          </Box>
        }
        loading={
          <Box display="flex" justifyContent="center" flexDirection="row">
            <LoadingIndicator size={28} />
          </Box>
        }
        keyExtractor={_tableKeyExtractor}
        rowRenderer={_tableRowRenderer}
      />
    </Paper>
  );
}
