import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import PrevIcon from '@material-ui/icons/ChevronLeft';
import LoadingIndicator from '../components/LoadingIndicator';
import signInImage from '../assets/images/login-image.jpg';
import logo from '../assets/images/sm-logo-2021.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  logo: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    width: '100%',
    height: '100px',
  },
  image: {
    backgroundImage: `url(${signInImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  submitIcon: {
    margin: theme.spacing(0, 1),
  },
}));

export default function LoginPage({ onSubmit, onForgotPass, isLoading }) {
  const classes = useStyles();
  const [forgotPwd, setForgotPwd] = React.useState(false);
  const [forgotEmail, setForgotEmail] = React.useState('');
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    error: {
      username: false,
      password: false,
    },
  });

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    setValues({
      ...values,
      [name]: value,
      error: {
        ...values.error,
        [name]: value.trim().length === 0,
      },
    });
  };

  const handleForgotPassword = () => {
    if (forgotEmail === '') return;
    onForgotPass(forgotEmail);
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    const { username, password, error } = values;
    if (error.username || error.password) {
      // TODO: show error message here
      return;
    }

    onSubmit(username, password);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item className={classes.logo} />
          </Grid>
          {!forgotPwd && (
            <>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  error={values.error.username}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  placeholder="Username"
                  autoFocus
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange('username')}
                  value={values.username}
                />
                <TextField
                  error={values.error.password}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  placeholder="Password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange('password')}
                  value={values.password}
                />
                <Button
                  disabled={isLoading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}>
                  {isLoading && <LoadingIndicator size={26} />}
                  {!isLoading && (
                    <>
                      <LockOutlinedIcon className={classes.submitIcon} />
                      Login
                    </>
                  )}
                </Button>
              </form>

              <Button
                disabled={isLoading}
                fullWidth
                variant="text"
                color="primary"
                onClick={() => setForgotPwd(true)}
                className={classes.submit}>
                {!isLoading && <>Forgot password?</>}
              </Button>
            </>
          )}
          {forgotPwd && (
            <>
              <Button
                disabled={isLoading}
                variant="text"
                color="primary"
                style={{ alignSelf: 'flex-start' }}
                onClick={() => setForgotPwd(false)}
                className={classes.submit}>
                <PrevIcon />
                Back
              </Button>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="forgot_email"
                label="Email"
                type="email"
                name="email"
                placeholder="john@doe.com"
                autoFocus
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  setForgotEmail(event?.target?.value);
                }}
                value={forgotEmail}
              />
              <Button
                disabled={isLoading}
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => handleForgotPassword()}
                className={classes.submit}>
                Request new password
              </Button>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
