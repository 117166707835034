import React from 'react';
import DashboardLayout from '../components/DashboardLayout';

const DashboardLayoutContext = React.createContext(null);

export const withDashboardLayout = Component => props => (
  <DashboardLayoutContext.Consumer>
    {width => {
      function Layout() {
        const [isDrawerOpen, setDrawerOpen] = React.useState(!(width === 'xs' || width === 'sm'));
        const handleDrawerOpen = () => {
          setDrawerOpen(true);
        };
        const handleDrawerClose = () => {
          setDrawerOpen(false);
        };

        return (
          <DashboardLayout
            isDrawerOpen={isDrawerOpen}
            onDrawerOpen={handleDrawerOpen}
            onDrawerClose={handleDrawerClose}
          >
            <Component {...props} />
          </DashboardLayout>
        );
      }
      return <Layout />;
    }}
  </DashboardLayoutContext.Consumer>
);

export default DashboardLayoutContext;
