import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import { green, orange, red, grey } from '@material-ui/core/colors';
import ErrorMessage from '../ErrorMessage';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textGreen: {
    color: green[600],
  },
  textOrange: {
    color: orange[600],
  },
  textRed: {
    color: red[600],
  },
  textLoading: {
    color: grey[500],
  },
  text: {
    fontSize: '2em',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  textRight: {
    marginLeft: theme.spacing(1),
  },
  textLeft: {
    marginRight: theme.spacing(1),
  },
}));

function Details({ licences, inUse, members, isLoading, isOrgActive, onRefresh }) {
  const classes = useStyles();

  const ratio = inUse / licences;
  let textClass = classes.textGreen;

  if (ratio >= 0.8) {
    textClass = classes.textRed;
  }

  if (ratio > 0.6 && ratio < 0.85) {
    textClass = classes.textOrange;
  }

  if (isLoading) {
    textClass = classes.textLoading;
  }

  return (
    <Box mt={2}>
      {!isOrgActive && (
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <ErrorMessage message="Organisation Inactive. Cannot make changes." />
        </Box>
      )}
      <div className={classes.container}>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="baseline">
          <span className={clsx(classes.text, classes.textLeft)}>{members}</span>
          <span style={{ fontSize: '0.9em' }}>members</span>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="baseline">
          <span style={{ fontSize: '0.9em' }}>Licences used: </span>
          <span className={clsx(classes.textRight, classes.text, textClass)}>
            {inUse} / {licences}
          </span>
          <div style={{ alignSelf: 'flex-start' }}>
            <Tooltip title="Refresh licence count" placement="bottom">
              <IconButton onClick={onRefresh} disabled={isLoading} component="span">
                <RefreshIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        </Box>
      </div>
    </Box>
  );
}

Details.defaultProps = {
  licences: 0,
  inUse: 0,
  members: 0,
};

export default Details;
