import { createReducer } from 'redux-create-reducer';
import * as types from '../actions/types';

const initialState = [];

function filterId(state, action) {
  return state.filter((req) => req.id !== action.apiReqId);
}

export const apiRequest = createReducer(initialState, {
  [types.API_REQUEST]: (state, action) => [
    ...state,
    { id: action.id, message: action.message, requestType: action.requestType },
  ],
  [types.API_END]: (state, action) => filterId(state, action),
  [types.LOGIN_OK]: (state, action) => filterId(state, action),
  /* Activity */
  [types.GET_HYMNS_STATS_OK]: (state, action) => filterId(state, action),
  [types.GET_CULTURAL_PROGRAMS_STATS_OK]: (state, action) =>
    filterId(state, action),
  [types.GET_IMAGE_ALBUM_STATS_OK]: (state, action) => filterId(state, action),
  [types.GET_QUIZZES_STATS_OK]: (state, action) => filterId(state, action),
  [types.GET_LYRICS_STATS_OK]: (state, action) => filterId(state, action),
  [types.GET_RADIO_STATS_OK]: (state, action) => filterId(state, action),
  [types.GET_PROGRAMS_STATS_OK]: (state, action) => filterId(state, action),
  [types.GET_WEEKLY_PROGRAMS_STATS_OK]: (state, action) =>
    filterId(state, action),
  [types.GET_PLAYLISTS_STATS_OK]: (state, action) => filterId(state, action),
  /* Hymns */
  [types.GET_HYMNS_PLAYLIST_OK]: (state, action) => filterId(state, action),
  [types.DELETE_HYMNS_FILE_OK]: (state, action) => filterId(state, action),
  /* Image Albums */
  [types.GET_IMAGE_ALBUMS_OK]: (state, action) => filterId(state, action),
  [types.GET_IMAGES_OK]: (state, action) => filterId(state, action),
  [types.DELETE_IMAGE_OK]: (state, action) => filterId(state, action),
  [types.SAVE_IMAGE_ALBUM_NAME_OK]: (state, action) => filterId(state, action),
  [types.DELETE_IMAGE_ALBUM_OK]: (state, action) => filterId(state, action),
  [types.ADD_IMAGE_ALBUM_OK]: (state, action) => filterId(state, action),
  /* Lyrics */
  [types.GET_LYRICS_OK]: (state, action) => filterId(state, action),
  [types.DELETE_LYRICS_OK]: (state, action) => filterId(state, action),
  [types.UPDATE_LYRICS_NAME_OK]: (state, action) => filterId(state, action),
  /* Members */
  [types.GET_MEMBERS_OK]: (state, action) => filterId(state, action),
  [types.GET_MEMBER_OK]: (state, action) => filterId(state, action),

  /* Organisation */
  [types.GET_ORG_MEMBERS_OK]: (state, action) => filterId(state, action),
  [types.GET_ORG_MEMBER_OK]: (state, action) => filterId(state, action),
  [types.UPD_ORG_MEMBER_OK]: (state, action) => filterId(state, action),
  [types.ADD_ORG_MEMBER_OK]: (state, action) => filterId(state, action),
  [types.IMPORT_ORG_MEMBERS_OK]: (state, action) => filterId(state, action),
  [types.CHK_ORG_MEMBER_EMAIL_OK]: (state, action) => filterId(state, action),
  [types.GET_ORG_DETAILS_OK]: (state, action) => filterId(state, action),
  [types.UPD_ORG_MEMBER_PASSWORD_OK]: (state, action) =>
    filterId(state, action),
  [types.UPD_ORG_ADMIN_PASSWORD_OK]: (state, action) => filterId(state, action),
  /* Playlists */
  [types.GET_PLAYLISTS_OK]: (state, action) => filterId(state, action),
  [types.GET_PLAYLIST_OK]: (state, action) => filterId(state, action),
  [types.ADD_PLAYLIST_OK]: (state, action) => filterId(state, action),
  [types.UPDATE_PLAYLIST_NAME_OK]: (state, action) => filterId(state, action),
  [types.DELETE_PLAYLIST_OK]: (state, action) => filterId(state, action),
  [types.DELETE_PLAYLIST_SONG_OK]: (state, action) => filterId(state, action),
  /* Programs */
  [types.GET_PROGRAM_PLAYLIST_OK]: (state, action) => filterId(state, action),
  [types.DELETE_PROGRAM_FILE_OK]: (state, action) => filterId(state, action),
  /* Programs - Weekly Schedules */
  [types.GET_PROGRAM_SCHEDULES_OK]: (state, action) => filterId(state, action),
  /* Quizzes */
  [types.GET_QUIZZES_OK]: (state, action) => filterId(state, action),
  [types.DELETE_QUIZZES_OK]: (state, action) => filterId(state, action),
  [types.UPDATE_QUIZ_NAME_OK]: (state, action) => filterId(state, action),

  // [types.INIT_FIREBASE_SUCCESS]: (state, action) => filterId(state, action),
  [types.LOGOUT]: () => initialState,
});
