/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import SelectImagesIcon from '@material-ui/icons/ViewComfy';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Page from '../Page';
import ImageAlbum from '../../containers/SightNSound/ImageAlbum';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  getImages,
  setDeleteImageMode,
  setEditAlbumNameDialog,
  setDeleteImageAlbumDialog,
} from '../../lib/store/actions/imageAlbums';
import {
  makeSelectCurrentAlbum,
  makeSelectIsFetchingImages,
  // selectIsDeleteImageMode,
} from '../../lib/store/selectors/imageAlbums';

const styles = (theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
});

class ImageAlbumPage extends React.Component {
  componentDidMount() {
    const { onGetImages, paramAlbumId } = this.props;

    onGetImages(paramAlbumId);
  }

  shouldComponentUpdate(nextProps) {
    const hasAlbumChanged = this.props.album !== nextProps.album;
    const hasLoadingChanged = this.props.isLoading !== nextProps.isLoading;
    const hasDeleteModeChanged =
      this.props.isDeleteImageMode !== nextProps.isDeleteImageMode;

    return hasAlbumChanged || hasLoadingChanged || hasDeleteModeChanged;
  }

  render() {
    const {
      album,
      classes,
      isLoading,
      isDeleteImageMode,
      onSetDeleteImageMode,
      onSetEditAlbumNameDialog,
      onSetDeleteImageAlbumDialog,
    } = this.props;

    const pageMenuItems = [
      {
        title: (
          <>
            <EditIcon className={classes.icon} /> Edit album name
          </>
        ),
        onClick: () => onSetEditAlbumNameDialog(true),
      },
      {
        title: (
          <>
            <DeleteIcon className={classes.icon} /> Delete album
          </>
        ),
        onClick: () => onSetDeleteImageAlbumDialog(true),
      },
      {
        title: (
          <>
            <SelectImagesIcon className={classes.icon} /> Select images to
            delete ...
          </>
        ),
        onClick: () => onSetDeleteImageMode(true),
      },
    ];

    const loadingComponent = isLoading ? (
      <LoadingIndicator size={17} />
    ) : (
      <span style={{ color: 'red' }}>Album not found!</span>
    );

    return (
      <Page
        title={
          <>
            <PhotoLibraryIcon className={classes.icon} />{' '}
            {album ? album.name : loadingComponent}
          </>
        }
        menuItems={pageMenuItems}
        isMenuDisabled={
          isLoading || isDeleteImageMode || (!album && !isLoading)
        }
        gridContainerProps={{
          spacing: 3,
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        gridItemProps={{
          xs: 12,
          lg: 12,
        }}>
        {album && <ImageAlbum />}
        {!album && isLoading && (
          <Box
            width="100%"
            mt={2}
            flexDirection="row"
            justifyContent="center"
            display="flex">
            <LoadingIndicator size={17} />
          </Box>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectCurrentAlbum = makeSelectCurrentAlbum();
  const selectIsFetchingImages = makeSelectIsFetchingImages();
  return {
    album: selectCurrentAlbum(state, ownProps),
    paramAlbumId: ownProps.match.params.id,
    isLoading: selectIsFetchingImages(state),
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    onGetImages: getImages,
    onSetDeleteImageMode: setDeleteImageMode,
    onSetEditAlbumNameDialog: setEditAlbumNameDialog,
    onSetDeleteImageAlbumDialog: setDeleteImageAlbumDialog,
  }),
)(ImageAlbumPage);
