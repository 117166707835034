import gql from 'graphql-tag';

export const GET_STATS = gql`
  query Stats($type: ContentType!) {
    stats(type: $type) {
      stats {
        userId
        createdOn
      }
      object_type
      object {
        folder_name
        folder_id
        file_name
        file_id
      }
      entity_id
      count
    }
  }
`;
