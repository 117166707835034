import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteCulturalSongDialog } from "../../../lib/store/actions/programs";
import { selectDeleteCulturalSongDialogOpen } from "../../../lib/store/selectors/programs";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { deletePlaylistSong } from "../../../lib/store/actions/playlists";
import { selectIsDeletingPlaylistSong } from "../../../lib/store/selectors/playlists";

function DeleteCulturalSongDialog({
  categoryName,
  playlistId,
  onDeleteCB,
  songName,
  songId,
}) {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectDeleteCulturalSongDialogOpen);
  const isDeleting = useSelector(selectIsDeletingPlaylistSong);

  const handleClose = () => {
    dispatch(setDeleteCulturalSongDialog(false));
  };

  const handleDelete = async () => {
    if (!playlistId || !songId) return;

    await dispatch(
      deletePlaylistSong({ playlistId, songId: songId }, () => {
        handleClose();
        onDeleteCB(); //to refresh the list of songs
      })
    );
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onConfirm={handleDelete}
      onCancel={handleClose}
      confirmationBtnText="Yes, delete it!"
      inProgressMessage="Deleting, please wait ..."
      isInProgress={isDeleting}
      confirmationBody={
        <>
          Please confirm you'd like to delete the song "{songName}" from the
          category "{categoryName}"?
          <br />
          <br />
          <strong>Note:</strong> This action cannot be undone.
        </>
      }
    />
  );
}

export default DeleteCulturalSongDialog;
