import React from 'react';
import { connect } from 'react-redux';
import EditAlbumName from '../../components/ImageAlbums/Dialogs/EditAlbumName';
import { setEditAlbumNameDialog } from '../../lib/store/actions/imageAlbums';
import { selectEditNameDialogOpen } from '../../lib/store/selectors/imageAlbums';

function EditAlbumNameDialog({ isOpen, onSetEditAlbumNameDialog, onSubmit, albumName }) {
  const closeDialog = () => {
    onSetEditAlbumNameDialog(false);
  };

  const saveAlbumName = ({ name }) => {
    onSubmit(name);
  };

  return (
    <EditAlbumName
      isOpen={isOpen}
      onClickCancel={closeDialog}
      onSubmit={saveAlbumName}
      albumName={albumName}
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectEditNameDialogOpen(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetEditAlbumNameDialog: setEditAlbumNameDialog,
  }
)(EditAlbumNameDialog);
