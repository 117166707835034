import React from "react";
import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import "cross-fetch/polyfill";

const AuthorizedApolloProvider = ({ children, store }) => {
  const init = () => {
    // eslint-disable-next-line no-undef
    const graphQLHost = process.env.REACT_APP_GRAPHQL_API_HOST;

    const batchLink = new BatchHttpLink({
      uri: graphQLHost,
      batchInterval: 10,
      batchMax: 3,
    });

    const errorLink = onError(({ graphQLErrors, networkError, ...rest }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );

      if (networkError) console.warn(`[Network error]: ${networkError}`);
    });

    const jwt = store?.getState()?.user?.auth?.idToken;

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...(jwt ? { authorization: `Bearer ${jwt}` } : {}),
          ...headers,
        },
      };
    });

    return new ApolloClient({
      link: from([errorLink, authLink.concat(batchLink)]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
          errorPolicy: "all",
        },
        query: {
          fetchPolicy: "cache-first",
          errorPolicy: "all",
        },
      },
    });
  };

  const client = init();

  if (client) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AuthorizedApolloProvider;
