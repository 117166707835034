import React from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import SongsTable from "../../../components/CulturalSongs";
import EditCategoryDialog from "../dialogs/EditCategoryNameDialog";
import DeleteCategoryDialog from "../dialogs/DeleteCategoryDialog";
import AddCulturalSongsDialog from "../dialogs/AddCulturalSongDialog";
import DeleteCulturalSongDialog from "../dialogs/DeleteCulturalSongDialog";
import { GET_CATEGORIES_SUB_FOLDER } from "../../../lib/api/graphql/queries/category";
import { setDeleteCulturalSongDialog } from "../../../lib/store/actions/programs";
import { API_ENDPOINTS } from "../../../lib/constants/api-endpoints";
import { NOTIFICATION_TYPES } from "../../../lib/constants/notification-types";
import { showNotification } from "../../../lib/store/actions/notifications";

function CulturalSubFolder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [filterText, setFilterText] = React.useState("");
  const [categoryData, setCategoryData] = React.useState(null);
  const [songIdToDelete, setSongIdToDelete] = React.useState(null);

  const categoryId = params.categoryId;

  const { loading, refetch: refetchFiles } = useQuery(
    GET_CATEGORIES_SUB_FOLDER,
    {
      onError: (error) => {
        dispatch(
          showNotification({
            options: {
              variant: NOTIFICATION_TYPES.Error,
            },
            message: error.message || "Error fetching category details",
          })
        );
      },
      onCompleted: (data) => {
        if (!data || !data?.categories) return;

        //find our category and sub folder
        const category = data?.categories?.find((c) => c.id === categoryId);

        setCategoryData(category);
      },
    }
  );

  const handleOnDeleteCategory = () => {
    //Since the category is gone, we need to go back to the parent categories page
    history.goBack();
  };

  const onFilterPlaylists = (e) => {
    setFilterText(e.target.value?.toLowerCase() || "");
  };

  const clickDeleteSong = (songId) => {
    setSongIdToDelete(songId);
    dispatch(setDeleteCulturalSongDialog(true));
  };

  const handleDeleteSongSuccess = async () => {
    setSongIdToDelete(null);

    await refetchFiles();
  };

  const handleUploadSuccess = async () => {
    await refetchFiles();
  };

  const songs = React.useMemo(() => {
    return categoryData?.sub_folders?.flatMap((folder) => folder?.files) ?? [];
  }, [categoryData]);

  const _songs =
    filterText.length > 0
      ? songs.filter(
          (f) =>
            String(f?.Title).toLowerCase().includes(filterText) ||
            String(f?.Artist).toLowerCase().includes(filterText)
        )
      : songs;

  const playlistId = categoryData?.sub_folders?.[0]?.id; //currently we only have 1 playlist per category

  return (
    <>
      <DeleteCulturalSongDialog
        songId={songIdToDelete}
        songName={
          songIdToDelete
            ? songs?.find((s) => s?.id === songIdToDelete)?.Title
            : ""
        }
        categoryName={categoryData?.name}
        playlistId={playlistId}
        onDeleteCB={handleDeleteSongSuccess}
      />
      <AddCulturalSongsDialog
        uploadURL={`${API_ENDPOINTS.playlists
          .replace(/:PLAYLIST_ID/, playlistId)
          ?.concat(`?id=${playlistId}`)}`}
        onUploadSuccess={handleUploadSuccess}
      />
      <EditCategoryDialog
        originalName={categoryData?.name}
        categoryId={categoryId || categoryData?.id}
      />
      <DeleteCategoryDialog
        categoryId={categoryId || categoryData?.id}
        originalName={categoryData?.name}
        onDelete={handleOnDeleteCategory}
      />
      <SongsTable
        tableHeaders={[
          { title: "Title / Artist", width: "70%" },
          "Player Controls",
          "Delete?",
        ]}
        songs={_songs ?? []}
        onFilter={onFilterPlaylists}
        isFetching={loading}
        onClickDeleteSong={clickDeleteSong}
        emptyText="No songs found - use the menu to add some."
      />
    </>
  );
}

export default CulturalSubFolder;
