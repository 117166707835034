import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import { removeNotification } from '../lib/store/actions/notifications';

class Notifications extends React.Component {
  _displayed = [];

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    if (!newSnacks.length) {
      this.displayed = [];
      return false;
    }

    const { notifications: currentSnacks, closeSnackbar } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      const newSnack = newSnacks[i];
      if (newSnack.dismissed) {
        closeSnackbar(newSnack.key);
      }

      if (notExists) continue; // eslint-disable-line
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnack.key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { enqueueSnackbar, notifications, onRemoveNotification } = this.props;
    notifications.forEach(({ key, message, options = {} }) => {
      if (this._displayed.includes(key)) return;
      const { callbackAction, isButton, clearIcon, ...restOptions } = options;
      const BtnComponent = isButton ? Button : IconButton;
      enqueueSnackbar(message, {
        ...restOptions,
        onClose: (event, reason, _key) => {
          if (options.onClose) {
            options.onClose(event, reason, _key);
          }
          this._displayed = this._displayed.filter(id => id !== key);
          onRemoveNotification(key);
        },
        action: _key => (
          <BtnComponent
            color={isButton ? 'primary' : undefined}
            onClick={this.onClose(_key, callbackAction)}
          >
            {clearIcon || <ClearIcon style={{ color: '#efefef' }} />}
          </BtnComponent>
        ),
      });
      this._displayed = [...this._displayed, key];
    });
  }

  onClose = (key, callbackAction) => () => {
    const { onRemoveNotification, closeSnackbar } = this.props;
    if (callbackAction && typeof callbackAction === 'function') callbackAction();
    closeSnackbar(key);
    onRemoveNotification(key);
  };

  render() {
    return null;
  }
}

export default compose(
  withSnackbar,
  connect(
    ({ notifications }) => ({ notifications }),
    { onRemoveNotification: removeNotification }
  )
)(Notifications);
