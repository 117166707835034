import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Dialog from '../../components/Organisation/ChangeOrgUserPasswordDialog';
import {
  makeSelectIsDialogOpen,
  setDialog,
  makeSelectDialogParams,
} from '../../lib/store/reducers/dialog';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';
import { UPDATE_USER_PASSWORD } from '../../lib/api/graphql/mutations/organizations';
import { GET_ORGANIZATION } from '../../lib/api/graphql/queries/organizations';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';

const ChangeAdminPasswordDialog = () => {
  const param = useParams();
  const dispatch = useDispatch();

  const isOpen = useSelector(
    makeSelectIsDialogOpen(DIALOG_TYPES.UPDATE_USER_PASSWORD),
  );
  const { organisation } = useSelector(makeSelectDialogParams()) || {};

  const [updatePassword, { loading: isUpdating }] = useMutation(
    UPDATE_USER_PASSWORD,
    {
      refetchQueries: [
        { query: GET_ORGANIZATION, variables: { orgId: param?.id } },
      ],
      onCompleted: () => {
        handleClose();
      },
      onError: (error) => {
        dispatch(
          showNotification({
            options: { variant: NOTIFICATION_TYPES.Error },
            message: `Error updating user password: ${error?.message}`,
          }),
        );
      },
    },
  );

  const handleChangeAdminPassword = async ({ password }) => {
    if (!organisation) return;

    const { admins } = organisation;

    const admin = admins[0];

    await updatePassword({
      variables: {
        userId: admin.id,
        password,
        orgId: param?.id,
      },
    });
  };

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialog: DIALOG_TYPES.UPDATE_USER_PASSWORD,
      }),
    );
  };

  if (!organisation) return null;

  return (
    <Dialog
      isUpdating={isUpdating}
      isOpen={isOpen}
      isOrgActive={organisation.active}
      onCancel={handleClose}
      onSubmit={handleChangeAdminPassword}
    />
  );
};

export default ChangeAdminPasswordDialog;
