import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import BusinessIcon from '@material-ui/icons/Business';
import AddIcon from '@material-ui/icons/Add';
import Page from '../PageV2';
import SMOrganisationsList from '../../containers/SMOganisations/List';
import AddOrgDialog from '../../containers/SMOganisations/AddOrgDialog';
import { setDialog } from '../../lib/store/reducers/dialog';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function OrganisationListPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const pageMenuItems = [
    {
      title: (
        <>
          <AddIcon className={classes.icon} /> New Organisation ...
        </>
      ),
      onClick: () => {
        dispatch(
          setDialog({
            open: true,
            dialog: DIALOG_TYPES.ADD_ORG,
          }),
        );
      },
    },
  ];

  return (
    <Page
      title={
        <>
          <BusinessIcon className={classes.icon} /> Organisations
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <AddOrgDialog />
      <SMOrganisationsList />
    </Page>
  );
}

export default OrganisationListPage;
