import { createSelector } from 'reselect';

// selectors
const apiRequestsSelector = state => state.apiRequest;

export const selectLoadingMessage = createSelector(
  [apiRequestsSelector],
  apiRequests => {
    return apiRequests.length > 0 && !!apiRequests[0].message ? apiRequests[0].message : '';
  }
);
