import gql from 'graphql-tag';

export const RENAME_FILE = gql`
  mutation RenameFile(
    $folderId: ObjectID!
    $fileId: ObjectID!
    $name: String!
  ) {
    renameFile(folder_id: $folderId, file_id: $fileId, name: $name)
  }
`;
