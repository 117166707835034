import { createSelector } from 'reselect';
import moment from 'moment';
import {
  API_GET_ORG_MEMBER_REQ,
  API_ADD_ORG_MEMBER_REQ,
  API_CHK_ORG_MEMBER_EMAIL_REQ,
  API_IMPORT_ORG_MEMBERS_REQ,
  API_UPD_ORG_MEMBER_PASSWORD_REQ,
  API_UPD_ORG_ADMIN_PASSWORD_REQ,
} from '../actions/types';

const membersTableSelector = (state) => {
  return state.organisation.users.list.map((m) => {
    return {
      id: m.id,
      name: `${m.firstName} ${m.lastName}`,
      email: m.email,
      created: moment(m.createdOn).format('Do MMM YYYY'),
      active: m.active,
    };
  });
};
const memberPasswordResetSelector = (state) => {
  return state.organisation.memberPasswordReset;
};
const membersTablePaginationSelector = (state) => {
  return state.organisation.users.pagination;
};
const membersImportResultsSelector = (state) => {
  return state.organisation.users.importResults;
};

const isFetchingOrgMemberSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_GET_ORG_MEMBER_REQ,
  );
};
const isAddingOrgMemberSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_ADD_ORG_MEMBER_REQ,
  );
};

const isCheckingOrgMemberEmailInUseSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_CHK_ORG_MEMBER_EMAIL_REQ,
  );
};

const isImportingOrgMembersSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_IMPORT_ORG_MEMBERS_REQ,
  );
};
const isUpdatingOrgMemberPasswordSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_UPD_ORG_MEMBER_PASSWORD_REQ,
  );
};
const isUpdatingOrgAdminPasswordSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_UPD_ORG_ADMIN_PASSWORD_REQ,
  );
};
const isEmailInUseSelector = (state) => {
  return state.organisation.emailInUse;
};
const currentOrgMemberSelector = (state) => {
  return state.organisation.users.selected;
};
const orgDetailsSelector = (state) => {
  return state.organisation.details;
};
const orgDialogsSelector = (state) => {
  return state.organisation.dialogs;
};

export const selectOrgMembersTableData = createSelector(
  [membersTableSelector, membersTablePaginationSelector],
  (members, pagination) => {
    return {
      members,
      pagination,
    };
  },
);

export const selectOrgMembersImportResults = createSelector(
  [membersImportResultsSelector],
  (results) => results,
);

export const selectOrgDetails = createSelector(
  [orgDetailsSelector],
  (details) => details,
);

export const makeSelectIsOrgActive = () =>
  createSelector([orgDetailsSelector], (details) => details.orgActive);

export const selectIsAddMemberDialogOpen = createSelector(
  [orgDialogsSelector],
  (dialogs) => dialogs.addMember,
);

export const selectIsEditMemberDialogOpen = createSelector(
  [orgDialogsSelector],
  (dialogs) => dialogs.editMember,
);

export const selectIsChangeMemberPasswordDialogOpen = createSelector(
  [orgDialogsSelector],
  (dialogs) => dialogs.memberPassword,
);

export const selectIsChangeAdminPasswordDialogOpen = createSelector(
  [orgDialogsSelector],
  (dialogs) => dialogs.adminPassword,
);

export const selectIsEmailInUse = createSelector(
  [isEmailInUseSelector],
  (isInUse) => isInUse,
);

export const selectIsFetchingOrgMember = createSelector(
  [isFetchingOrgMemberSelector],
  (isLoading) => isLoading,
);

export const selectIsAddingOrgMember = createSelector(
  [isAddingOrgMemberSelector],
  (isLoading) => isLoading,
);

export const selectIsCheckingEmailInUse = createSelector(
  [isCheckingOrgMemberEmailInUseSelector],
  (isLoading) => isLoading,
);

export const selectIsImportingOrgMembers = createSelector(
  [isImportingOrgMembersSelector],
  (isLoading) => isLoading,
);

export const selectIsUpdatingOrgMemberPassword = createSelector(
  [isUpdatingOrgMemberPasswordSelector],
  (isLoading) => isLoading,
);

export const selectOrgMemberPasswordReset = createSelector(
  [memberPasswordResetSelector],
  (member) => member,
);

export const makeSelectCurrentOrgMember = () =>
  createSelector([currentOrgMemberSelector], (member) => member);
