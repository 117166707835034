import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddMediaFilesDialog from '../../../components/AddMediaFilesDialog';
import UploadProgress from '../../../lib/utils/uploadProgress';
import { selectAddCulturalCategorySongsDialogOpen } from '../../../lib/store/selectors/programs';
import { setAddCulturalCategorySongsDialog } from '../../../lib/store/actions/programs';
import { makeSelectAuthToken } from '../../../lib/store/selectors/auth';

function AddCulturalSongsDialog({ uploadURL, onUploadSuccess }) {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectAddCulturalCategorySongsDialogOpen);
  const authToken = useSelector(makeSelectAuthToken());

  const initialState = {
    uploadProgress: {},
    isUploading: false,
    isUploadComplete: false,
    error: '',
  };

  const [values, setValues] = React.useState(initialState);

  const closeDialog = () => {
    dispatch(setAddCulturalCategorySongsDialog(false));
  };

  const onClickDone = () => {
    setValues(initialState);
    closeDialog();
  };

  const sendRequest = (song) => {
    const doUpload = new UploadProgress({
      onSend: (req) => {
        const formData = new FormData();
        formData.append('artist', song.artist);
        formData.append('title', song.title);
        formData.append('file', song.media, song.media.name);

        req.open('POST', uploadURL);
        req.setRequestHeader('Authorization', `Bearer ${authToken}`); // eslint-disable-line

        req.send(formData);
      },
      onLoad: () => {
        setValues((vals) => ({
          ...vals,
          uploadProgress: {
            ...vals.uploadProgress,
            [song.id]: {
              state: 'done',
              percentage: 100,
            },
          },
        }));
      },
      onError: () => {
        setValues((vals) => ({
          ...vals,
          error: 'Something went wrong uploading to the server.',
          uploadProgress: {
            ...vals.uploadProgress,
            [song.id]: {
              state: 'error',
              percentage: 0,
            },
          },
        }));
      },
      onProgress: (event) => {
        if (event.lengthComputable) {
          setValues((vals) => ({
            ...vals,
            uploadProgress: {
              ...vals.uploadProgress,
              [song.id]: {
                state: 'pending',
                percentage: (event.loaded / event.total) * 100,
              },
            },
          }));
        }
      },
    });

    return doUpload.upload();
  };

  const addFilesToPlaylist = async ({ files }) => {
    try {
      setValues({ ...values, uploadProgress: {}, isUploading: true });

      await files.reduce((promise, file) => {
        return promise.then(() => {
          return sendRequest(file);
        });
      }, Promise.resolve());

      setValues((vals) => ({
        ...vals,
        isUploading: false,
        isUploadComplete: true,
      }));

      if (typeof onUploadSuccess === 'function') onUploadSuccess();
    } catch (e) {
      setValues({
        ...values,
        error: e,
        isUploading: false,
        isUploadComplete: false,
      });
    }
  };

  return (
    <AddMediaFilesDialog
      isOpen={isOpen}
      onClickCancel={closeDialog}
      onSubmit={addFilesToPlaylist}
      isUploading={values.isUploading}
      isUploadComplete={values.isUploadComplete}
      uploadProgress={values.uploadProgress}
      onComplete={onClickDone}
      errorMessage={values.error}
    />
  );
}

export default AddCulturalSongsDialog;
