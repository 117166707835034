import React from 'react';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import bgImage from '../assets/images/404-bg-img.jpg';

function VisitUs() {
  return (
    <Typography variant="body2" align="center" style={{ color: '#fff' }}>
      {'Visit our '}
      <Link color="inherit" href="https://www.silvermemories.com.au/">
        Home Page!
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      background: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  },
  paper: {
    height: '500px',
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.common.white,
  },
  topMargin: {
    marginTop: theme.spacing(3),
  },
}));

export default function FourOhFour() {
  const classes = useStyles();

  return (
    <Container component="main" style={{ height: '100vh' }}>
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography component="h1" variant="h1" className={classes.text}>
          404
        </Typography>
        <Typography component="h3" variant="h3" align="center" className={classes.text}>
          Page Not Found
        </Typography>
        <Typography
          component="p"
          variant="body1"
          align="center"
          className={clsx(classes.text, classes.topMargin)}
        >
          Sorry, we can't find the page you're looking for :(
        </Typography>
        <Box mt={2}>
          <VisitUs />
        </Box>
      </Box>
    </Container>
  );
}
