import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import EditFileName from '../../components/ImageAlbums/Dialogs/EditFileName';
import {
  makeSelectDialogParams,
  makeSelectIsDialogOpen,
  setDialog,
} from '../../lib/store/reducers/dialog';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';
import { RENAME_FILE } from '../../lib/api/graphql/mutations/folder';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { getImages } from '../../lib/store/actions/imageAlbums';

function EditFileNameDialog() {
  const dispatch = useDispatch();
  const params = useParams();
  const isOpen = useSelector(
    makeSelectIsDialogOpen(DIALOG_TYPES.UPDATE_FILE_NAME),
  );
  const { image } = useSelector(makeSelectDialogParams()) || {};

  const [renameFile, { loading }] = useMutation(RENAME_FILE, {
    onCompleted: () => {
      dispatch(
        showNotification({
          message: 'File name updated!',
          options: { variant: NOTIFICATION_TYPES.Success },
        }),
      );

      dispatch(getImages(params?.id)); //refresh the images
      closeDialog();
    },
    onError: (error) => {
      dispatch(
        showNotification({
          message: error?.message || 'Failed to edit the file name',
          options: { variant: NOTIFICATION_TYPES.Error },
        }),
      );
    },
  });

  const closeDialog = () => {
    dispatch(
      setDialog({
        open: false,
        dialog: DIALOG_TYPES.UPDATE_FILE_NAME,
      }),
    );
  };

  const saveFileName = async ({ name }) => {
    if (!image?.id || !params?.id) {
      dispatch(
        showNotification({
          message: 'There was an error updating the file name',
          options: { variant: NOTIFICATION_TYPES.Warning },
        }),
      );
      return;
    }

    await renameFile({
      variables: {
        folderId: params?.id,
        fileId: image?.id,
        name,
      },
    });
  };

  return (
    <EditFileName
      isOpen={isOpen}
      loading={loading}
      onClickCancel={closeDialog}
      onSubmit={saveFileName}
      fileName={image?.name || ''}
    />
  );
}

export default EditFileNameDialog;
