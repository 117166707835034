import moment from 'moment';
import 'moment/locale/en-au';
import sort from 'fast-sort';
import { createReducer } from 'redux-create-reducer';
import * as types from '../actions/types';

moment.locale('en-au');

const initialState = {
  hymns: [],
  imageAlbums: [],
  quizzes: [],
  lyrics: [],
  radio: [],
  programs: [],
  weeklyPrograms: [],
  playlists: [],
  cultralPrograms: [],
  loaded: {
    hymns: false,
    imageAlbums: false,
    quizzes: false,
    lyrics: false,
    radio: false,
    programs: false,
    weeklyPrograms: false,
    playlists: false,
    cultralPrograms: false,
  },
};

const chartDaysGenerator = (startDate) => {
  const startDay = moment(startDate);
  const numDays = moment().diff(startDay, 'days') + 1;
  return new Array(numDays).fill().reduce((acc, _, idx) => {
    const date = moment(startDay).add(idx, 'days');
    const dateIndex = date.format('DD-MM-YYYY');
    acc[dateIndex] = {
      viewCount: 0,
      userIds: [],
      label: date.format('DD MMM'),
    };
    return { ...acc };
  }, {});
};

const mapUsageStats = (folderNames, chartDays) => (usage) => {
  const { folderId, stats } = usage;

  const stat = {
    folderId,
    folderName: folderNames[folderId]
      ? folderNames[folderId].name
      : 'Unknown (DELETED)',
    uniqueUsers: {},
    totalViewCount: 0,
  };

  const viewStats = stats.reduce((acc, cur) => {
    const { userId, createdOn } = cur;
    const dateIndex = moment(createdOn).format('DD-MM-YYYY');
    if (acc[dateIndex]) {
      acc[dateIndex].viewCount += 1;
      const hasDateUserId = !!acc[dateIndex].userIds.find(
        (uid) => uid === userId,
      );
      if (!hasDateUserId) {
        acc[dateIndex].userIds.push(userId);
      }
    } else {
      acc[dateIndex] = {
        viewCount: 1,
        userIds: [userId],
      };
    }
    stat.uniqueUsers[userId] = { name: 'that guy' };
    stat.totalViewCount += 1;
    return acc;
  }, {});

  stat.chartData = sort(Object.keys(chartDays))
    .by([{ asc: (o) => moment(o, 'DD-MM-YYYY') }])
    .map((date) => {
      if (viewStats[date]) {
        return {
          ...viewStats[date],
          label: moment(date, 'DD-MM-YYYY').format('DD MMM'),
        };
      }
      return { ...chartDays[date] };
    });

  return stat;
};

const reduceByFileId = (nameCallback, folderId, _fileId) => (acc, cur) => {
  const { userId, fileId, createdOn } = cur;

  const FileId = _fileId || fileId;

  const dateIndex = moment(createdOn).format('DD-MM-YYYY');

  if (acc[FileId]) {
    acc[FileId].totalViewCount += 1;
    acc[FileId].uniqueUsers[userId] = { name: 'that guy' };
    if (acc[FileId].viewStats[dateIndex]) {
      acc[FileId].viewStats[dateIndex].viewCount += 1;
      const hasDateUserId = !!acc[FileId].viewStats[dateIndex].userIds.find(
        (uid) => uid === userId,
      );
      if (!hasDateUserId) {
        acc[FileId].viewStats[dateIndex].userIds.push(userId);
      }
    } else {
      acc[FileId].viewStats[dateIndex] = {
        viewCount: 1,
        userIds: [userId],
      };
    }
  } else {
    acc[FileId] = {
      viewStats: {
        [dateIndex]: {
          viewCount: 1,
          userIds: [userId],
        },
      },
      parentFolderId: folderId,
      folderId: FileId,
      folderName: nameCallback(folderId, FileId),
      totalViewCount: 1,
      uniqueUsers: { [userId]: { name: 'that guy' } },
    };
  }

  return acc;
};

export const activity = createReducer(initialState, {
  // Hymns
  [types.GET_HYMNS_STATS_OK](state, action) {
    const { usageStats, hymnsNames, startDate } = action;

    const chartDays = chartDaysGenerator(startDate);
    return {
      ...state,
      loaded: {
        ...state.loaded,
        hymns: true,
      },
      hymns: sort(usageStats.map(mapUsageStats(hymnsNames, chartDays))).by([
        { asc: (o) => o.folderName },
      ]),
    };
  },
  // Cultural Programs
  [types.GET_CULTURAL_PROGRAMS_STATS_OK](state, action) {
    const { usageStats, programNames, startDate } = action;

    const chartDays = chartDaysGenerator(startDate);
    return {
      ...state,
      loaded: {
        ...state.loaded,
        cultralPrograms: true,
      },
      cultralPrograms: sort(
        usageStats.map(mapUsageStats(programNames, chartDays)),
      ).by([{ asc: (o) => o.folderName }]),
    };
  },
  // Image Albums (Sight & Sound)
  [types.GET_IMAGE_ALBUM_STATS_OK](state, action) {
    const { usageStats, albumNames, startDate } = action;
    const chartDays = chartDaysGenerator(startDate);

    return {
      ...state,
      loaded: {
        ...state.loaded,
        imageAlbums: true,
      },
      imageAlbums: sort(
        usageStats.map(mapUsageStats(albumNames, chartDays)),
      ).by([{ asc: (o) => o.folderName }]),
    };
  },
  // Quizzes
  [types.GET_QUIZZES_STATS_OK](state, action) {
    const { usageStats, quizNames, startDate } = action;
    const chartDays = chartDaysGenerator(startDate);

    return {
      ...state,
      loaded: {
        ...state.loaded,
        quizzes: true,
      },
      quizzes: sort(usageStats.map(mapUsageStats(quizNames, chartDays))).by([
        { asc: (o) => o.folderName },
      ]),
    };
  },
  // Lyrics
  [types.GET_LYRICS_STATS_OK](state, action) {
    const { usageStats, lyricsNames, startDate } = action;
    const chartDays = chartDaysGenerator(startDate);
    return {
      ...state,
      loaded: {
        ...state.loaded,
        lyrics: true,
      },
      lyrics: sort(usageStats.map(mapUsageStats(lyricsNames, chartDays))).by([
        { asc: (o) => o.folderName },
      ]),
    };
  },
  // Radio
  [types.GET_RADIO_STATS_OK](state, action) {
    const { usageStats, startDate } = action;
    const chartDays = chartDaysGenerator(startDate);
    const radioStats = [
      {
        ...usageStats,
        folderId: 'radio',
      },
    ];

    return {
      ...state,
      loaded: {
        ...state.loaded,
        radio: true,
      },
      radio: radioStats.map(
        mapUsageStats({ radio: { name: 'Radio' } }, chartDays),
      ),
    };
  },
  // Programs
  [types.GET_PROGRAMS_STATS_OK](state, action) {
    const { usageStats, programNames, startDate } = action;

    const chartDays = chartDaysGenerator(startDate);
    return {
      ...state,
      loaded: {
        ...state.loaded,
        programs: true,
      },
      programs: sort(usageStats.map(mapUsageStats(programNames, chartDays))).by(
        [{ asc: (o) => o.folderName }],
      ),
    };
  },
  // Weekly Programs
  [types.GET_WEEKLY_PROGRAMS_STATS_OK](state, action) {
    const { weeklyPrograms, usageStats, startDate } = action;
    const chartDays = chartDaysGenerator(startDate);
    // this was added becuase SM want to have EST and DST instead of QLD and NSW
    const nameMap = {
      qld: 'Eastern Standard Time',
      nsw: 'Daylight Saving Time',
    };
    const names = Object.keys(weeklyPrograms).reduce((acc, name) => {
      const { id } = weeklyPrograms[name];
      acc[id] = { name: nameMap[name] };
      return acc;
    }, {});

    return {
      ...state,
      loaded: {
        ...state.loaded,
        weeklyPrograms: true,
      },
      weeklyPrograms: sort(usageStats.map(mapUsageStats(names, chartDays))).by([
        { asc: (o) => o.folderName },
      ]),
    };
  },
  [types.GET_PLAYLISTS_STATS_OK](state, action) {
    const { usageStats, playlists, startDate } = action;
    const chartDays = chartDaysGenerator(startDate);

    const playlistNames = playlists.reduce((acc, cur) => {
      acc[cur.id] = {
        name: cur.name,
        titles: cur.list.reduce((a, c) => {
          a[c.id] = c.title;
          return a;
        }, {}),
      };
      return acc;
    }, {});

    const nameCallback = (folderId, fileId) => {
      if (!playlistNames[folderId]) return 'Unknown (DELETED)';
      return `${playlistNames[folderId].name} - ${playlistNames[folderId].titles[fileId]}`;
    };

    const playlistsStats = usageStats
      .reduce((acc, usage) => {
        const _stats = usage.stats.reduce(
          reduceByFileId(nameCallback, usage.folderId, usage.fileId),
          {},
        );
        const stats = Object.keys(_stats).map((folderId) => {
          const { viewStats, ...restStats } = _stats[folderId];
          return {
            ...restStats,
            chartData: sort(Object.keys(chartDays))
              .by([{ asc: (o) => moment(o, 'DD-MM-YYYY') }])
              .map((date) => {
                if (viewStats[date]) {
                  return {
                    ...viewStats[date],
                    label: moment(date, 'DD-MM-YYYY').format('DD MMM'),
                  };
                }
                return { ...chartDays[date] };
              }),
          };
        });
        acc.push(stats);
        return acc;
      }, [])
      .flat();

    return {
      ...state,
      loaded: {
        ...state.loaded,
        playlists: true,
      },
      playlists: playlistsStats,
    };
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
