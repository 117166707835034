import { gql } from '@apollo/client';

export const USER_LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const FORGOT_PWD = gql`
  mutation ForgotPassword($email: EmailAddress!) {
    forgotPassword(email: $email)
  }
`;
