import React from 'react';
import './CommunityAlbums.css';

class UploadProgressBar extends React.PureComponent {
  render() {
    const { progress } = this.props;
    return <span className="ProgressBar" style={{ width: `${progress}%` }} />;
  }
}

export default UploadProgressBar;
