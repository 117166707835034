import React from 'react';
import AddPDFFilesDialog from '../components/AddPDFFilesDialog';
import UploadProgress from '../lib/utils/uploadProgress';

function UploadPDFDialog({
  isOpen,
  onCloseDialog,
  onUploadCompleteCB,
  uploadURL,
  dialogTitle,
  authToken,
}) {
  const initialState = {
    uploadProgress: {},
    isUploading: false,
    isUploadComplete: false,
    error: '',
  };

  const [values, setValues] = React.useState(initialState);

  const closeDialog = () => {
    onCloseDialog();
  };

  const onClickDone = () => {
    onUploadCompleteCB();
    setValues(initialState);
    onCloseDialog();
  };

  const sendRequest = (pdf) => {
    const doUpload = new UploadProgress({
      onSend: (req) => {
        const formData = new FormData();
        formData.append('name', pdf.name);
        formData.append('file', pdf.file, pdf.file.name);

        req.open('POST', uploadURL);
        req.setRequestHeader('Authorization', `Bearer ${authToken}`); // eslint-disable-line

        req.send(formData);
      },
      onLoad: () => {
        setValues((vals) => ({
          ...vals,
          uploadProgress: {
            ...vals.uploadProgress,
            [pdf.id]: {
              state: 'done',
              percentage: 100,
            },
          },
        }));
      },
      onError: () => {
        setValues((vals) => ({
          ...vals,
          uploadProgress: {
            ...vals.uploadProgress,
            [pdf.id]: {
              state: 'error',
              percentage: 0,
            },
          },
        }));
      },
      onProgress: (event) => {
        if (event.lengthComputable) {
          setValues((vals) => ({
            ...vals,
            uploadProgress: {
              ...vals.uploadProgress,
              [pdf.id]: {
                state: 'pending',
                percentage: (event.loaded / event.total) * 100,
              },
            },
          }));
        }
      },
    });

    return doUpload.upload();
  };

  const uploadPDFFiles = async ({ files }) => {
    try {
      setValues({
        ...values,
        error: '',
        uploadProgress: {},
        isUploading: true,
      });

      await files.reduce((promise, file) => {
        return promise.then(() => {
          return sendRequest(file);
        });
      }, Promise.resolve());

      setValues((vals) => ({
        ...vals,
        isUploading: false,
        isUploadComplete: true,
      }));
    } catch (e) {
      setValues({ ...values, error: e, isUploading: false });
    }
  };

  return (
    <AddPDFFilesDialog
      isOpen={isOpen}
      onClickCancel={closeDialog}
      onSubmit={uploadPDFFiles}
      isUploading={values.isUploading}
      isUploadComplete={values.isUploadComplete}
      uploadProgress={values.uploadProgress}
      onComplete={onClickDone}
      dialogTitle={dialogTitle}
      errorMessage={values.error}
    />
  );
}

export default UploadPDFDialog;
