/* eslint-disable no-nested-ternary */
import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import LoadingIndicator from '../LoadingIndicator';
import InputField from '../InputField';
import { isValidEmail } from '../../lib/utils';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: '10px',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  progressText: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(1),
  },
}));

function AddOrgAdminDialog({ isOpen, isLoading, onCancel, onSubmit }) {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    isSubmitted: false,
  };

  const [values, setValues] = React.useState(initialValues);

  const handleClose = () => {
    setValues(initialValues);
    onCancel();
  };

  const handleChange = (field) => (event) => {
    const { value } = event.target;
    setValues((vals) => ({ ...vals, [field]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setValues((vals) => ({ ...vals, isSubmitted: true }));

    const isNotValid =
      values.firstName.trim().length === 0 ||
      values.lastName.trim().length === 0 ||
      values.email.trim().length === 0 ||
      !isValidEmail(values.email) ||
      values.password.trim().length < 5;

    if (isNotValid) return;

    onSubmit({
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      email: values.email.trim(),
      password: values.password.trim(),
    });
  };

  const classes = useStyles();

  const fields = [
    {
      id: 'firstName',
      label: 'First name',
      inputType: 'text',
      value: values.firstName,
      onChange: handleChange('firstName'),
      error: values.isSubmitted && values.firstName.trim().length === 0,
      helperText:
        values.isSubmitted && values.firstName.trim().length === 0
          ? 'First name is required'
          : '',
    },
    {
      id: 'lastName',
      label: 'Last name',
      inputType: 'text',
      value: values.lastName,
      onChange: handleChange('lastName'),
      error: values.isSubmitted && values.lastName.trim().length === 0,
      helperText:
        values.isSubmitted && values.lastName.trim().length === 0
          ? 'Last name is required'
          : '',
    },
    {
      id: 'email',
      label: 'Login Email',
      inputType: 'text',
      value: values.email,
      autoComplete: 'new-password',
      onChange: handleChange('email'),
      error:
        values.isSubmitted &&
        (values.email.trim().length === 0 || !isValidEmail(values.email)),
      helperText:
        values.isSubmitted &&
        (values.email.trim().length === 0 || !isValidEmail(values.email))
          ? 'A valid email is required'
          : '',
    },
    {
      id: 'password',
      label: 'Password',
      inputType: 'text',
      type: 'password',
      value: values.password,
      autoComplete: 'new-password',
      onChange: handleChange('password'),
      error: values.isSubmitted && values.password.trim().length < 5,
      helperText: 'A password with at least 5 characters',
    },
  ];

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Admin</DialogTitle>
        <DialogContent>
          <form
            className={classes.form}
            id="add-admin-form"
            onSubmit={handleSubmit}
            autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Enter in the admin details and press save
                </Typography>
              </Grid>
              {fields.map((fieldProps) => (
                <Grid item xs={12} key={fieldProps.id}>
                  <InputField {...fieldProps} classes={classes} />
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Fade in={isLoading}>
            <Box
              width="45%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center">
              <LoadingIndicator size={16} />
              <Typography
                variant="body2"
                component="span"
                className={classes.progressText}>
                Saving, please wait ...
              </Typography>
            </Box>
          </Fade>
          <Button
            disabled={isLoading}
            variant="outlined"
            color="primary"
            onClick={handleClose}>
            <ClearIcon className={classes.rightMargin} />
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            form="add-admin-form">
            <CheckCircleIcon className={classes.rightMargin} />
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddOrgAdminDialog;
