/* eslint-disable arrow-body-style */
import * as types from './types';
import { lyricsApi } from '../../api';
import { apiRequest } from './api-request';
import { showNotification } from './notifications';
import { NOTIFICATION_TYPES } from '../../constants/notification-types';
import { getAuthToken, checkHttpError } from './authTokenHelper';

export const setDeleteLyricsDialog = isOpen => ({
  type: types.TOGGLE_DELETE_LYRICS_DIALOG,
  isOpen,
});

export const setEditLyricsNameDialog = isOpen => ({
  type: types.TOGGLE_EDIT_LYRICS_NAME_DIALOG,
  isOpen,
});

export const setAddLyricsDialog = isOpen => ({
  type: types.TOGGLE_ADD_LYRICS_DIALOG,
  isOpen,
});

export const getLyrics = callback => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_GET_LYRICS_REQ,
    message: 'Loading lyrics',
  });
  dispatch(apiReqAction);
  lyricsApi
    .getLyrics(getAuthToken(getState()))
    .then(
      lyrics => {
        dispatch({
          type: types.GET_LYRICS_OK,
          lyrics: lyrics ? lyrics.map(l => ({ id: l.id, name: l.fileName, lyricUrl: l.url })) : [],
          apiReqId: apiReqAction.id,
        });
        if (callback && typeof callback === 'function') callback();
      },
      error => dispatch(checkHttpError(error, apiReqAction.id))
    )
    .catch(error => {
      dispatch(
        showNotification({
          message: `Get lyrics: ${error.message}` || 'Cannot get lyrics',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        })
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};

export const deleteLyrics = ({ fileId }, successCB) => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_DEL_LYRICS_REQ,
    message: 'Deleting lyrics',
  });
  dispatch(apiReqAction);

  lyricsApi
    .deleteLyrics(getAuthToken(getState()), { fileId })
    .then(
      isSuccess => {
        if (!isSuccess) throw new Error('Problem deleting lyrics');
        dispatch({
          type: types.DELETE_LYRICS_OK,
          fileId,
          apiReqId: apiReqAction.id,
        });
        dispatch(
          showNotification({
            message: 'Lyrics successfully deleted',
            options: {
              variant: NOTIFICATION_TYPES.Success,
              autoHideDuration: 2000,
            },
          })
        );
        if (successCB && typeof successCB === 'function') successCB();
      },
      error => dispatch(checkHttpError(error, apiReqAction.id))
    )
    .catch(error => {
      dispatch(
        showNotification({
          message: `Delete lyrics: ${error.message}` || 'Cannot delete lyrics',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        })
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};

export const updateLyricsName = ({ id, name }, successCB) => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_UPD_LYRICS_REQ,
    message: 'Updating name',
  });
  dispatch(apiReqAction);

  lyricsApi
    .updateLyricsName(getAuthToken(getState()), { id, name })
    .then(
      () => {
        dispatch({
          type: types.UPDATE_LYRICS_NAME_OK,
          lyrics: { id, name },
          apiReqId: apiReqAction.id,
        });
        dispatch(
          showNotification({
            message: 'Lyrics name updated',
            options: {
              variant: NOTIFICATION_TYPES.Success,
            },
          })
        );
        if (successCB && typeof successCB === 'function') successCB();
      },
      error => dispatch(checkHttpError(error, apiReqAction.id))
    )
    .catch(error => {
      dispatch(
        showNotification({
          message: `Update lyrics name: ${error.message}` || 'Cannot update lyrics name',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        })
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};
