import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { showNotification } from "../../../lib/store/actions/notifications";
import { NOTIFICATION_TYPES } from "../../../lib/constants/notification-types";
import {
  makeSelectIsDialogOpen,
  setDialog,
} from "../../../lib/store/reducers/dialog";
import { DIALOG_TYPES } from "../../../lib/constants/dialog-types";
import { SET_AUTOMATED_MESSAGE } from "../../../lib/api/graphql/mutations/messages";
import AutomatedMsgForm from "../../../components/Messages/Dialogs/NewMessage";

function NewAutomatedMsgDialog({ originalText }) {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    makeSelectIsDialogOpen(DIALOG_TYPES.ADD_OR_UPDATE_AUTOMATED_MESSAGE)
  );

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialog: DIALOG_TYPES.ADD_OR_UPDATE_AUTOMATED_MESSAGE,
      })
    );
  };

  const [setAutomatedMessage, { loading }] = useMutation(
    SET_AUTOMATED_MESSAGE,
    {
      refetchQueries: ["GetAutomatedMessage"],
      onCompleted: () => {
        dispatch(
          showNotification({
            message: "Automated message is all set!",
            options: { variant: NOTIFICATION_TYPES.Success },
          })
        );
        handleClose();
      },
      onError: (error) => {
        dispatch(
          showNotification({
            message: error?.message || "Failed to set the automated message",
            options: { variant: NOTIFICATION_TYPES.Error },
          })
        );
      },
    }
  );

  const handleSend = async (text) => {
    if (!text) return;
    await setAutomatedMessage({
      variables: { content: text },
    });
  };

  return (
    <AutomatedMsgForm
      originalText={originalText}
      isOpen={isOpen}
      onClickCancel={handleClose}
      onSubmit={handleSend}
      loading={loading}
      customSendBtnText="Set"
      customHeaderText="Set automated message"
    />
  );
}

export default NewAutomatedMsgDialog;
