import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import LoadingIndicator from '../LoadingIndicator';
import defaultAvatar from '../../assets/images/music-note.jpg';
import TableTemplate from '../TableTemplate';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  fontBold: {
    fontWeight: 'bold',
  },
  button: {
    padding: '0 3px',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  filterBox: {
    padding: theme.spacing(0, 1, 1),
  },
}));

export default function MembersTableList({
  tableHeaders,
  playlists,
  onFilter,
  filterText,
  onClickPlaylist,
  isFetching,
}) {
  const classes = useStyles();

  const _tableKeyExtractor = row => row.id;

  const _tableRowRenderer = row => {
    const { id, artwork, ...rowData } = row; // eslint-disable-line

    return Object.keys(rowData).map((field, idx) => {
      if (field === 'name') {
        return (
          <TableCell key={`${row.id}-${idx}`} component="th" scope="row">
            <Tooltip title={`View/Edit ${row.name}'s playlist`} placement="right">
              <Button color="primary" className={classes.button} onClick={onClickPlaylist(row.id)}>
                <Avatar
                  alt={row.name}
                  src={row.artwork || defaultAvatar}
                  className={classes.avatar}
                />
                <span className={classes.fontBold}>{row.name}</span>
              </Button>
            </Tooltip>
          </TableCell>
        );
      }
      return <TableCell key={`${row.id}-${idx}`}>{row[field]}</TableCell>;
    });
  };

  return (
    <Paper className={classes.root}>
      <Box display="flex" flexDirection="row" width="100%" className={classes.filterBox}>
        <TextField
          id="contacts-search"
          label="Filter by name"
          type="search"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          placeholder="Type in the name ..."
          onChange={onFilter}
          value={filterText}
          autoComplete="off"
        />
      </Box>
      <TableTemplate
        data={playlists}
        isLoading={isFetching}
        tableHeaders={tableHeaders}
        empty={
          <Box display="flex" justifyContent="center" flexDirection="row">
            <p>No playlists found - use the menu to create one.</p>
          </Box>
        }
        loading={
          <Box display="flex" justifyContent="center" flexDirection="row">
            <LoadingIndicator size={28} />
          </Box>
        }
        keyExtractor={_tableKeyExtractor}
        rowRenderer={_tableRowRenderer}
      />
    </Paper>
  );
}
