import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InputField from '../../InputField';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  submitIcon: {
    margin: theme.spacing(0, 1),
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
}));

function EditFileName({ isOpen, onClickCancel, onSubmit, fileName, loading }) {
  const initialValues = {
    name: '',
    isSubmitted: false,
  };

  const [values, setValues] = React.useState(initialValues);

  React.useEffect(() => {
    setValues((vals) => ({ ...vals, name: fileName || '' }));
  }, [isOpen, fileName]);

  const handleChange = (event) => {
    setValues({ ...values, name: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, isSubmitted: true });
    const { name } = values;
    const nameError = String(name).trim().length === 0;

    if (!nameError) {
      onSubmit({ name });
      setValues({ ...values, isSubmitted: false });
    }
  };

  const textFieldProps = {
    inputType: 'text',
    id: 'name',
    label: 'Name',
    value: values.name,
    name: 'name',
    onChange: handleChange,
    placeholder: 'Image file name',
    InputLabelProps: {
      shrink: true,
    },
    autoFocus: true,
    error: values.isSubmitted && String(values.name).trim().length === 0,
    helperText:
      values.isSubmitted && String(values.name).trim().length === 0
        ? 'A name is required'
        : '',
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClickCancel}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit file name</DialogTitle>
        <DialogContent>
          <form
            className={classes.form}
            id="edit-file-name-form"
            onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
              className={classes.dialogGridContent}>
              <Grid item xs={12}>
                <InputField {...textFieldProps} classes={classes} />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClickCancel} disabled={loading}>
            <ClearIcon className={classes.rightMargin} />
            Cancel
          </Button>
          <Button
            disabled={String(values.name).trim().length === 0 || loading}
            variant="contained"
            color="primary"
            type="submit"
            form="edit-file-name-form">
            <CheckCircleIcon className={classes.rightMargin} />
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditFileName;
