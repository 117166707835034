export const APP_ROUTES = {
  dashboard: "/dashboard",
  login: "/login",
  logout: "/logout",
  // Organisations
  smOrganisations: "/organisations",
  // Community
  members: "/members",
  messages: "/messages",
  activity: "/activity",
  // Appp Content
  playlists: "/playlists",
  programs: "/programs",
  sightsound: "/sightsound",
  culturalprograms: "/culturalprograms",
  weeklyprograms: "/weeklyprograms",
  quizzes: "/quizzes",
  hymns: "/hymns",
  // Lyrics will be removed
  lyrics: "/lyrics",
  /* ORGANISATION Admin Pages */
  organisation: {
    members: "/org-members",
    account: "/my-account",
    import: "/org-member-import",
  },
};
