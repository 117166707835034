import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { setDialog } from '../lib/store/reducers/dialog';

const useStyles = makeStyles({
  root: {
    color: '#efefef',
  },
  selected: {
    color: '#222',
    background: 'linear-gradient(45deg, #6bbefe 30%, #0065b3 90%)',
    borderRadius: 3,
    border: 0,
  },
  link: {
    color: '#efefef',
  },
  linkSelected: {
    color: '#222',
  },
});

const NavMenuItem = ({
  icon: Icon,
  text,
  linkTo,
  dialog,
  dialogType,
  isSelected,
  notificationCount,
}) => {
  const dispatch = useDispatch();
  if (dialog && !dialogType) {
    console.warn('dialog type is required when dialog is true');
  }

  const classes = useStyles();
  const renderLink = React.forwardRef((itemProps, ref) => (
    <RouterLink to={linkTo} {...itemProps} innerRef={ref} />
  ));

  const iconComponent = notificationCount ? (
    <Badge badgeContent={notificationCount} color="error">
      <Icon />
    </Badge>
  ) : (
    <Icon />
  );

  const handleOpenDialog = () => {
    dispatch(
      setDialog({
        open: dialog,
        dialog: dialogType,
      }),
    );
  };

  return (
    <li>
      <ListItem
        button
        onClick={dialog && dialogType ? handleOpenDialog : undefined}
        component={dialog && dialogType ? undefined : renderLink} // if dialog is true
        selected={isSelected}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}>
        <ListItemIcon
          className={isSelected ? classes.linkSelected : classes.link}>
          {iconComponent}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </li>
  );
};

export default NavMenuItem;
