import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import UpdateCategory from "../../../components/Programs/Dialogs/NewCulturalCategory";
import { EDIT_CATEGORY_NAME } from "../../../lib/api/graphql/mutations/category";

import { setUpdateCulturalCategoryDialog } from "../../../lib/store/actions/programs";
import { selectUpdateCulturalCategoryDialogOpen } from "../../../lib/store/selectors/programs";
import { showNotification } from "../../../lib/store/actions/notifications";
import { NOTIFICATION_TYPES } from "../../../lib/constants/notification-types";

function EditCategoryDialog({ originalName, categoryId }) {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectUpdateCulturalCategoryDialogOpen);

  const [editCategory, { loading }] = useMutation(EDIT_CATEGORY_NAME, {
    refetchQueries: ["Sub_folders"],
    onCompleted: () => {
      dispatch(
        showNotification({
          message: "Category updated",
          options: { variant: NOTIFICATION_TYPES.Success },
        })
      );
      handleClose();
    },
    onError: (error) => {
      dispatch(
        showNotification({
          message: error?.message || "Failed to update the category",
          options: { variant: NOTIFICATION_TYPES.Error },
        })
      );
    },
  });

  const handleClose = () => {
    dispatch(setUpdateCulturalCategoryDialog(false));
  };

  const handleUpdate = async ({ name }) => {
    await editCategory({ variables: { categoryId, name } });
  };

  return (
    <UpdateCategory
      isOpen={isOpen}
      onClickCancel={handleClose}
      onSubmit={handleUpdate}
      loading={loading}
      originalName={originalName}
    />
  );
}

export default EditCategoryDialog;
