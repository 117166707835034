export const DEVICE_NAME = 'Silver Memories';

export const DRAWER_WIDTH = 240;

export const MESSAGE_TYPES = {
  message: 0,
  song: 1,
  birthday: 2,
  broadcast: 3,
  autoResponse: 4,
};

export const GQL_MESSAGE_TYPES = {
  MESSAGE: 0,
  SONG_REQUEST: 1,
  BIRTHDAY_SHOUTOUT: 2,
};

export const STATISTICS_CONTENT_TYPE = {
  lyrics: 0,
  quizzes: 1,
  playlists: 2,
  programs: 3,
  imageAlbum: 4,
  weeklyProgram: 5,
  radio: 6,
  weeklyProgramQLD: 7,
  weeklyProgramNSW: 8,
  hymns: 9,
  culturalPrograms: 10,
};

export const GQL_STATISTICS_CONTENT_TYPE = {
  lyrics: 'LYRICS',
  quizzes: 'QUIZZES',
  playlists: 'PLAYLISTS',
  programs: 'PROGRAMS',
  imageAlbum: 'IMAGE',
  weeklyProgram: 'WEEKLY_PROGRAM',
  radio: 'RADIO',
  weeklyProgramQLD: 'WEEKLY_PROGRAM_QLD',
  weeklyProgramNSW: 'WEEKLY_PROGRAM_NSW',
  hymns: 'HYMNS',
  culturalPrograms: 'CULTURAL_PODCAST',
};

export const STATISTICS_ACCESS_TYPE = {
  viewed: 0,
  run: 1,
};

export const WEEKLY_PROGRAM_TYPES = {
  qld: 7,
  nsw: 8,
};

export const SUBSCRIPTION_PRODUCTS = {
  'com.mbs.silvermemories.radioapp.annualsub': 'Annual',
  'com.mbs.silvermemories.radioapp.android.annualsub': 'Annual',
  'com.mbs.silvermemories.radioapp.monthlysub': 'Monthly',
  'com.mbs.silvermemories.radioapp.android.monthlysub': 'Monthly',
};

export const SUBSCRIPTION_COSTS = {
  'com.mbs.silvermemories.radioapp.annualsub': '$99.99/Year',
  'com.mbs.silvermemories.radioapp.android.annualsub': '$99.99/Year',
  'com.mbs.silvermemories.radioapp.monthlysub': '$9.99/Month',
  'com.mbs.silvermemories.radioapp.android.monthlysub': '$9.99/Month',
};
