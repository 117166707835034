import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LoadingIndicator from '../LoadingIndicator';
import TableTemplate from '../TableTemplate';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  fontBold: {
    fontWeight: 'bold',
  },
  button: {
    padding: '0 3px',
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  location: {
    fontWeight: 'bold',
    fontSize: '1em',
    display: 'block',
  },
}));

export default function WeeklyTable({ tableHeaders, schedules, isFetching, onClickChange }) {
  const classes = useStyles();

  /* PDF Functions */
  const handleClickChange = location => () => {
    onClickChange(location);
  };

  /* Table helper/render functions */
  const _tableKeyExtractor = row => row.location;

  const _tableRowRenderer = row => {
    const { location, url, label } = row; // eslint-disable-line

    return (
      <>
        <TableCell component="th" scope="row">
          <div className={classes.locationContainer}>
            <span className={classes.location}>{String(label).toUpperCase()}</span>
          </div>
        </TableCell>
        <TableCell>
          <a target="_blank" rel="noopener noreferrer" href={url}>
            Weekly program (PDF)
          </a>
        </TableCell>
        <TableCell scope="row">
          <IconButton
            aria-label={`Change ${String(label).toUpperCase()} program`}
            onClick={handleClickChange(location)}
          >
            <CloudUploadIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <Paper className={classes.root}>
      <TableTemplate
        data={schedules}
        isLoading={isFetching}
        tableHeaders={tableHeaders}
        empty={
          <Box display="flex" justifyContent="center" flexDirection="row">
            <p>Nothing found</p>
          </Box>
        }
        loading={
          <Box display="flex" justifyContent="center" flexDirection="row">
            <LoadingIndicator size={28} />
          </Box>
        }
        keyExtractor={_tableKeyExtractor}
        rowRenderer={_tableRowRenderer}
      />
    </Paper>
  );
}
