import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import CreateCategory from "../../../components/Programs/Dialogs/NewCulturalCategory";
import { CREATE_CATEGORY } from "../../../lib/api/graphql/mutations/category";
import { CREATE_PLAYLIST } from "../../../lib/api/graphql/mutations/playlist";

import { setCreateCulturalCategoryDialog } from "../../../lib/store/actions/programs";
import { selectCreateCulturalCategoryDialogOpen } from "../../../lib/store/selectors/programs";
import { showNotification } from "../../../lib/store/actions/notifications";
import { NOTIFICATION_TYPES } from "../../../lib/constants/notification-types";

function CreateCategoryDialog() {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectCreateCulturalCategoryDialogOpen);

  const [createPlaylist, { loading: createPlaylistLoading }] = useMutation(
    CREATE_PLAYLIST,
    {
      onError: (error) => {
        dispatch(
          showNotification({
            message: error?.message || "Failed to create playlist",
            options: {
              variant: NOTIFICATION_TYPES.Error,
            },
          })
        );
      },
    }
  );

  const [createCategory, { loading: createCategoryLoading }] = useMutation(
    CREATE_CATEGORY,
    {
      refetchQueries: ["Categories"],
      onCompleted: () => {
        dispatch(
          showNotification({
            message: "New category saved to the database",
            options: {
              variant: NOTIFICATION_TYPES.Success,
            },
          })
        );

        closeDialog();
      },
      onError: (error) => {
        dispatch(
          showNotification({
            message: error?.message || "Failed to create category",
            options: {
              variant: NOTIFICATION_TYPES.Error,
            },
          })
        );
      },
    }
  );

  const closeDialog = () => {
    dispatch(setCreateCulturalCategoryDialog(false));
  };

  const addCategory = async ({ name }) => {
    // Currently we create a playlist for each category and attach to it when creating a new category (thus, 1 playlist per category to upload the songs)
    // in future, we can create a view for playlists when you click category and allow them to create multiple playlists for a category
    const { data } = await createPlaylist({
      variables: { name, type: "CULTURAL_PODCAST" },
    });

    const playlistId = data?.createPlaylist?.id;

    if (playlistId) {
      await createCategory({ variables: { name, subFolders: [playlistId] } });
    }
  };

  return (
    <CreateCategory
      isOpen={isOpen}
      onClickCancel={closeDialog}
      onSubmit={addCategory}
      loading={createCategoryLoading || createPlaylistLoading}
    />
  );
}

export default CreateCategoryDialog;
