import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import CreateAlbumDialog from './CreateAlbumDialog';
import ImageAlbumListComponent from '../../components/ImageAlbums/ImageAlbumList';
import {
  getImageAlbums,
  setCreateImageAlbumDialog,
  createNewAlbum,
} from '../../lib/store/actions/imageAlbums';
import {
  selectImageAlbums,
  selectIsFetchingAlbums,
  selectIsCreatingImageAlbum,
} from '../../lib/store/selectors/imageAlbums';
import { APP_ROUTES } from '../../lib/constants/app-routes';

function ImageAlbumList({
  onGetImageAlbums,
  onSetCreateImageAlbumDialog,
  albums,
  isFetching,
  history,
  onCreateNewAlbum,
  isCreatingImageAlbum,
}) {
  const [filterText, setFilterText] = React.useState('');

  React.useEffect(() => {
    onGetImageAlbums();
  }, [onGetImageAlbums]);

  const editAlbum = id => () => {
    history.push(`${APP_ROUTES.sightsound}/${id}`);
  };

  const createImageAlbum = name => {
    onCreateNewAlbum(name, () => {
      onSetCreateImageAlbumDialog(false);
    });
  };

  const onFilter = event => {
    const { value } = event.target;
    const text = String(value).toLowerCase();
    setFilterText(text);
  };

  const _albums =
    filterText.length > 0
      ? albums.filter(c =>
          String(c.name)
            .toLowerCase()
            .includes(filterText)
        )
      : albums;

  return (
    <>
      <CreateAlbumDialog onSubmit={createImageAlbum} isCreating={isCreatingImageAlbum} />
      <ImageAlbumListComponent
        tableHeaders={['Image Album Name', 'Number of images']}
        albums={_albums}
        onClick={editAlbum}
        isFetching={isFetching}
        onFilter={onFilter}
        filterText={filterText}
      />
    </>
  );
}

export default compose(
  withRouter,
  connect(
    state => ({
      albums: selectImageAlbums(state),
      isFetching: selectIsFetchingAlbums(state),
      isCreatingImageAlbum: selectIsCreatingImageAlbum(state),
    }),
    {
      onGetImageAlbums: getImageAlbums,
      onSetCreateImageAlbumDialog: setCreateImageAlbumDialog,
      onCreateNewAlbum: createNewAlbum,
    }
  )
)(ImageAlbumList);
