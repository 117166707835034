import { authRequest } from './apiSetup';
import { API_ENDPOINTS } from '../../constants/api-endpoints';

/*
 * Lyrics API
 ** */

export async function getLyrics(idToken) {
  const endpoint = API_ENDPOINTS.lyrics.replace(/:FILE_ID/, '');
  const response = await authRequest(idToken, { method: 'GET', endpoint });
  return response.data;
}

export async function deleteLyrics(idToken, { fileId }) {
  const endpoint = API_ENDPOINTS.lyrics.replace(/:FILE_ID/, fileId);
  const response = await authRequest(idToken, { method: 'DELETE', endpoint });
  return response.state === 1;
}

export async function updateLyricsName(idToken, { id, name }) {
  const endpoint = API_ENDPOINTS.lyrics.replace(/:FILE_ID/, id);
  const response = await authRequest(idToken, { method: 'PUT', endpoint, body: JSON.stringify(name) });
  return response.state === 1;
}
