import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PeopleIcon from '@material-ui/icons/People';
import Page from '../PageV2';
import MemberProfile from '../../containers/Members/MemberProfile';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function MemberPage() {
  const classes = useStyles();

  return (
    <Page
      title={
        <>
          <PeopleIcon className={classes.icon} /> Member Details
        </>
      }
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <MemberProfile />
    </Page>
  );
}

export default MemberPage;
