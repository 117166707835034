import React from 'react';
import { connect } from 'react-redux';
import UpdateNameDialog from '../../components/UpdateNameDialog';
import { setEditPlaylistNameDialog, savePlaylistName } from '../../lib/store/actions/playlists';
import {
  selectIsEditPlaylistNameDialogOpen,
  selectIsUpdatingPlaylist,
} from '../../lib/store/selectors/playlists';

function EditPlaylistNameDialog({
  isOpen,
  onSetEditPlaylistNameDialog,
  onSavePlaylistName,
  isUpdating,
  playlistId,
  playlistName,
}) {
  const closeDialog = () => {
    onSetEditPlaylistNameDialog(false);
  };

  const savePlaylist = ({ name }) => {
    if (!playlistId) return;
    onSavePlaylistName({ name, id: playlistId }, () => {
      onSetEditPlaylistNameDialog(false);
    });
  };

  return (
    <UpdateNameDialog
      isOpen={isOpen}
      onClickCancel={closeDialog}
      onSubmit={savePlaylist}
      isUpdating={isUpdating}
      currentName={playlistName}
      dialogTitle="Update playlist name"
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectIsEditPlaylistNameDialogOpen(state),
    isUpdating: selectIsUpdatingPlaylist(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetEditPlaylistNameDialog: setEditPlaylistNameDialog,
    onSavePlaylistName: savePlaylistName,
  }
)(EditPlaylistNameDialog);
