import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UploadToPlaylistDialog from '../UploadToPlaylistDialog';
import DeleteProgramFileDialog from './DeleteProgramFileDialog';
import SongsTable from '../../components/SongsTable';
import {
  getProgramsPlaylist,
  setDeleteProgramFileDialog,
  setAddProgramFileDialog,
} from '../../lib/store/actions/programs';
import { makeSelectAuthToken } from '../../lib/store/selectors/auth';
import {
  selectProgramsPlaylistTableData,
  selectIsFetchingProgramsPlaylist,
  selectIsAddProgramFileDialogOpen,
} from '../../lib/store/selectors/programs';
import { API_ENDPOINTS } from '../../lib/constants/api-endpoints';

class ProgramsList extends React.PureComponent {
  _isMounted = false;

  state = {
    filterText: '',
    fileIdToDelete: '',
  };

  componentDidMount() {
    this._isMounted = true;
    const { onGetProgramsPlaylist } = this.props;
    onGetProgramsPlaylist();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState = (state, callback) => {
    if (this._isMounted) {
      this.setState(state, () => {
        if (callback && typeof callback === 'function') callback();
      });
    }
  };

  onFilterPrograms = event => {
    const { value } = event.target;
    this.updateState({
      filterText: String(value).toLowerCase(),
    });
  };

  clickDeleteProgram = fileIdToDelete => {
    const { onSetDeleteProgramFileDialog } = this.props;
    this.updateState({ fileIdToDelete });
    onSetDeleteProgramFileDialog(true);
  };

  deleteProgramFileCallback = () => {
    this.updateState({ fileIdToDelete: '' });
  };

  closeAddToProgramDialog = () => {
    const { onSetAddProgramFileDialog } = this.props;
    onSetAddProgramFileDialog(false);
  };

  refreshProgramFiles = () => {
    const { onGetProgramsPlaylist } = this.props;
    onGetProgramsPlaylist();
  };

  render() {
    const { filterText, fileIdToDelete } = this.state;
    const { programs, isFetching, isAddDialogOpen, authToken } = this.props;

    const _programs =
      filterText.length > 0
        ? programs.filter(
            c =>
              String(c.title)
                .toLowerCase()
                .includes(filterText) ||
              String(c.artist)
                .toLowerCase()
                .includes(filterText)
          )
        : programs;

    const tableHeaders = [{ title: 'Title / Artist', width: '70%' }, 'Player Controls', 'Delete?'];

    const selectedProgram = programs.find(p => p.id === fileIdToDelete);

    return (
      <>
        <UploadToPlaylistDialog
          isOpen={isAddDialogOpen}
          onCloseDialog={this.closeAddToProgramDialog}
          onUploadCompleteCB={this.refreshProgramFiles}
          dialogTitle="Add new program"
          uploadURL={`${API_ENDPOINTS.programs.replace(/:PROGRAM_ID/, '')}`}
          authToken={authToken}
        />
        <DeleteProgramFileDialog
          fileId={fileIdToDelete}
          fileName={selectedProgram ? selectedProgram.title : ''}
          onDeleteCB={this.deleteProgramFileCallback}
        />
        <SongsTable
          tableHeaders={tableHeaders}
          songs={_programs}
          onFilter={this.onFilterPrograms}
          isFetching={isFetching}
          onClickDeleteSong={this.clickDeleteProgram}
          emptyText="No programs found - use the menu to add some."
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const selectAuthToken = makeSelectAuthToken();
  return {
    programs: selectProgramsPlaylistTableData(state),
    isFetching: selectIsFetchingProgramsPlaylist(state),
    isAddDialogOpen: selectIsAddProgramFileDialogOpen(state),
    authToken: selectAuthToken(state),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      onGetProgramsPlaylist: getProgramsPlaylist,
      onSetDeleteProgramFileDialog: setDeleteProgramFileDialog,
      onSetAddProgramFileDialog: setAddProgramFileDialog,
    }
  )
)(ProgramsList);
