import * as types from './types';
import { authApi } from '../../api';
import { apiRequest } from './api-request';

export const logout = (firebaseId, persistor) => (dispatch, getState) => {
  const apiReqAction = apiRequest({ requestType: types.API_LOGOUT_REQ });
  dispatch(apiReqAction);

  authApi
    .logout(getState().user.auth.idToken, firebaseId || '')
    .catch((err) => {
      console.log('Logout error:', err);
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    })
    .finally(() => {
      dispatch({ type: types.LOGOUT });
      if (persistor && persistor.purge && typeof persistor.purge === 'function')
        persistor.purge();
    });
};
