import React from 'react';
import Main from '../../components/Activity/Main';
import Radio from './Radio';
import Programs from './Programs';
// import WeeklyPrograms from './WeeklyPrograms';
import Playlists from './Playlists';
import SightAndSound from './SightAndSound';
import Hymns from './Hymns';
import Quizzes from './Quizzes';
import CulturalPrograms from './CulturalPrograms';

function MainContainer() {
  const TABS = [
    {
      title: 'Radio',
      component: <Radio />,
    },
    {
      title: 'Pre-Recorded Broadcasts',
      component: <Programs />,
    },
    // { // Not needed currently
    //   title: 'Weekly Programs',
    //   component: <WeeklyPrograms />,
    // },
    {
      title: 'Music Genres',
      component: <Playlists />,
    },
    {
      title: 'Silver Scenes',
      component: <SightAndSound />,
    },
    {
      title: 'Hymns',
      component: <Hymns />,
    },
    {
      title: 'Quizzes',
      component: <Quizzes />,
    },
    {
      title: 'Cultural Programs',
      component: <CulturalPrograms />,
    },
  ];

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  const titles = TABS.map((t) => t.title);
  const { component } = TABS[tabIndex];
  return (
    <Main onChangeTab={handleTabChange} tabIndex={tabIndex} tabTitles={titles}>
      {component}
    </Main>
  );
}

export default MainContainer;
