import { createReducer } from "redux-create-reducer";
import * as types from "../actions/types";

const initialState = {
  playlist: [],
  names: {},
  schedules: {},
  dialogs: {
    deleteProgramFile: false,
    addProgramFile: false,
    uploadSchedule: false,
    createCulturalCategory: false,
    updateCulturalCategory: false,
    deleteCultureCategory: false,
    addCulturalCategorySongs: false,
    deleteCulturalSong: false,
  },
};

export const programs = createReducer(initialState, {
  // Playlists
  [types.GET_PROGRAM_PLAYLIST_OK](state, action) {
    return {
      ...state,
      names: action.programPlaylist.reduce((obj, a) => {
        obj[a.id] = {
          name: a.title,
        };
        return obj;
      }, {}),
      playlist: [...action.programPlaylist],
    };
  },
  [types.DELETE_PROGRAM_FILE_OK](state, action) {
    const { fileId } = action;
    const { [fileId]: unwanted, ...restNames } = state.names; // eslint-disable-line
    return {
      ...state,
      names: {
        ...restNames,
      },
      playlist: state.playlist.filter((p) => p.id !== fileId),
    };
  },
  // Weekly Schedules
  [types.GET_PROGRAM_SCHEDULES_OK](state, action) {
    const urls = action.programSchedules.reduce((acc, cur) => {
      const { name, url, id } = cur;
      const index = String(name).toLowerCase();
      acc[index] = {
        url,
        id,
      };
      return acc;
    }, {});

    return {
      ...state,
      schedules: { ...urls },
    };
  },
  // Dialogs
  [types.TOGGLE_DELETE_PROGRAM_FILE_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        deleteProgramFile: action.isOpen,
      },
    };
  },
  [types.TOGGLE_ADD_PROGRAM_FILE_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        addProgramFile: action.isOpen,
      },
    };
  },
  [types.TOGGLE_CREATE_CULTURAL_CATEGORY](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        createCulturalCategory: action.isOpen,
      },
    };
  },
  [types.TOGGLE_ADD_CULTURAL_CATEGORY_SONGS](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        addCulturalCategorySongs: action.isOpen,
      },
    };
  },
  [types.TOGGLE_UPDATE_CULTURAL_CATEGORY](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        updateCulturalCategory: action.isOpen,
      },
    };
  },
  [types.TOGGLE_DELETE_CULTURAL_CATEGORY](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        deleteCultureCategory: action.isOpen,
      },
    };
  },
  [types.TOGGLE_DELETE_CULTURAL_SONG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        deleteCulturalSong: action.isOpen,
      },
    };
  },
  [types.TOGGLE_UPLOAD_SCHEDULE_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        uploadSchedule: action.isOpen,
      },
    };
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
