import { STATISTICS_CONTENT_TYPE as SCT } from './index';

const apihost = process.env.REACT_APP_API_HOST;
const serverHost = process.env.REACT_APP_SERVER_HOST;

export const API_ENDPOINTS = {
  activity: `${apihost}statistic/:ACTIVITY_TYPE`,
  hymns: `${apihost}hymn/:HYMN_ID`,
  imageAlbumFile: `${apihost}image/:ALBUM_ID/:FILE_ID`,
  imageAlbums: `${apihost}image/:ALBUM_ID`,
  login: `${apihost}Auth/adminlogin`,
  logout: `${apihost}Auth/logout?firebaseDeviceId=:FIREBASE_DEVICE_ID`,
  lyrics: `${apihost}pdf/${SCT.lyrics}/:FILE_ID`,
  members: `${apihost}user/clients`,
  member: `${apihost}user/:MEMBER_ID`,
  messages: `${apihost}message/:MESSAGE_ID`,
  orgDetails: `${apihost}organisation/details`,
  orgMemberEnable: `${apihost}organisation/:USER_ID/enableclient`,
  orgMemberDisable: `${apihost}organisation/:USER_ID/disableclient`,
  orgMember: `${apihost}organisation/user/:USER_ID`,
  orgMembers: `${apihost}organisation/users`,
  orgChangeUserPassword: `${apihost}organisation/user/:USER_ID/changeuserpassword`,
  playlists: `${apihost}audioplaylist/:PLAYLIST_ID`,
  playlistName: `${apihost}audioplaylist/:PLAYLIST_ID/name`,
  playlistArtwork: `${apihost}AudioPlaylist/:PLAYLIST_ID/artwork`,
  categoryArtwork: `${serverHost}/upload/icon`,
  playlistFile: `${apihost}audioplaylist/:PLAYLIST_ID/:FILE_ID`,
  programs: `${apihost}program/:PROGRAM_ID`,
  weeklyPrograms: `${apihost}weeklyprogram/:CONTENT_TYPE`,
  quizzes: `${apihost}pdf/${SCT.quizzes}/:FILE_ID`,
};
