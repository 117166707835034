import { v4 as uuidV4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { makeSelectUserDetails } from '../../lib/store/selectors/user';
import { GET_ORGANIZATION } from '../../lib/api/graphql/queries/organizations';

import { showNotification } from '../../lib/store/actions/notifications';
import * as types from '../../lib/store/actions/types';

export function useGetOrgDetails() {
  const dispatch = useDispatch();
  const user = useSelector(makeSelectUserDetails());

  const { loading, refetch } = useQuery(GET_ORGANIZATION, {
    variables: {
      orgId: user?.organisationId,
    },
    skip: !user?.organisationId,
    onCompleted: (data) => {
      if (!data?.organization?.id) return;

      const { id, Name, Licences, active_count, members_count, Active } =
        data.organization;

      const orgDetails = {
        id: id,
        name: Name,
        licences: Licences,
        licencesActive: active_count,
        members: members_count,
        orgActive: Active,
      };

      dispatch({
        type: types.GET_ORG_DETAILS_OK,
        details: orgDetails,
        apiReqId: uuidV4(),
      });
    },
    onError: (error) => {
      dispatch(
        showNotification({
          message: error?.message,
          options: {
            variant: 'error',
          },
        }),
      );
    },
  });

  return {
    loading,
    refetch,
  };
}
