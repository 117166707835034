import { v4 as uuidV4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Dialog from '../../../components/Organisation/EditOrgMemberDialog';
import {
  toggleEditMemberDialog,
  refreshOrgMembers,
} from '../../../lib/store/actions/organisation';
import {
  selectIsEditMemberDialogOpen,
  selectIsFetchingOrgMember,
  selectIsEmailInUse,
  makeSelectCurrentOrgMember,
  makeSelectIsOrgActive,
} from '../../../lib/store/selectors/organisation';
import { CHECK_EMAIL_EXISTS } from '../../../lib/api/graphql/queries/user';
import { UPDATE_USER } from '../../../lib/api/graphql/mutations/organizations';
import * as types from '../../../lib/store/actions/types';
import { showNotification } from '../../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../../lib/constants/notification-types';

const EditOrgMemberDialog = ({
  isOpen,
  isLoading,
  isEmailInUse,
  member,
  isOrgActive,
  onRefreshMembers,
  onToggleDialog,
}) => {
  const dispatch = useDispatch();

  const [checkEmail, { loading: isCheckingEmail }] = useLazyQuery(
    CHECK_EMAIL_EXISTS,
    {
      onCompleted: (data) => {
        dispatch({
          type: types.CHK_ORG_MEMBER_EMAIL_OK,
          isInUse: !!data?.checkEmail,
          apiReqId: uuidV4(),
        });
      },
      onError: (error) => {
        dispatch(
          showNotification({
            message:
              `Validate email: ${error.message}` || 'Cannot validate email',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
      },
    },
  );

  const [updateMember, { loading: isUpdating }] = useMutation(UPDATE_USER, {
    refetchQueries: ['Organization', 'PaginatedClients'],
    onCompleted: () => {
      dispatch(
        showNotification({
          message: 'Member updated',
          options: {
            variant: NOTIFICATION_TYPES.Success,
          },
        }),
      );
      onRefreshMembers();
      onToggleDialog();
    },

    onError: (error) => {
      dispatch(
        showNotification({
          message: `Error: ${error.message}` || 'Error updating member',
          options: {
            variant: NOTIFICATION_TYPES.Error,
          },
        }),
      );
    },
  });

  const handleUpdateMember = async ({ updatedData }) => {
    if (!member) return;

    if (Object.keys(updatedData).length === 0) {
      onToggleDialog();
      return;
    }

    await updateMember({
      variables: {
        userId: member.id,
        firstName: updatedData?.firstName || member?.firstName,
        lastName: updatedData?.lastName || member?.lastName,
        postcode: updatedData?.postcode || member?.postcode,
        state: updatedData?.state || member?.state || undefined,
        email: updatedData?.email || member?.email,
      },
    });
  };

  const handleClose = () => {
    onToggleDialog();
  };

  const handleCheckEmail = async (email) => {
    await checkEmail({ variables: { email } });
  };

  return (
    <Dialog
      member={member}
      isLoading={isLoading}
      isUpdating={isUpdating}
      isOpen={isOpen}
      isOrgActive={isOrgActive}
      isEmailInUse={isEmailInUse}
      isCheckingEmailInUse={isCheckingEmail}
      onCheckEmail={handleCheckEmail}
      onCancel={handleClose}
      onSubmit={handleUpdateMember}
    />
  );
};

const mapStateToProps = (state) => {
  const selectCurrentOrgMember = makeSelectCurrentOrgMember();
  const selectIsOrgActive = makeSelectIsOrgActive();

  return {
    isOpen: selectIsEditMemberDialogOpen(state),
    isOrgActive: selectIsOrgActive(state),
    member: selectCurrentOrgMember(state),
    isLoading: selectIsFetchingOrgMember(state),
    isEmailInUse: selectIsEmailInUse(state),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    onRefreshMembers: refreshOrgMembers,
    onToggleDialog: toggleEditMemberDialog,
  }),
)(EditOrgMemberDialog);
