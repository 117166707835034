import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { GET_STATS } from '../../lib/api/graphql/queries/stats';
import { GQL_STATISTICS_CONTENT_TYPE } from '../../lib/constants';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { showNotification } from '../../lib/store/actions/notifications';
import * as types from '../../lib/store/actions/types';

export function useGetStats({ type }) {
  const dispatch = useDispatch();

  const [getStats, { loading: isFetching }] = useLazyQuery(GET_STATS, {
    onError: (err) => {
      dispatch(
        showNotification({
          message: `Get activity: ${err.message}` || 'Cannot get activity',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
    },
  });

  const programsHandler = async (_stats, startDate) => {
    const _programNames = _stats.reduce((acc, cur) => {
      if (!cur || !cur.object) return acc;

      acc[cur.object.folder_id] = { name: cur.object.file_name };

      return acc;
    }, {});

    dispatch({
      type: types.GET_PROGRAMS_STATS_OK,
      usageStats: _stats,
      startDate,
      programNames: { ..._programNames },
      apiReqId: uuidV4(),
    });
  };

  const radioHandler = (_stats, startDate) => {
    dispatch({
      type: types.GET_RADIO_STATS_OK,
      usageStats: {
        count: _stats[0]?.count || 0,
        stats: _stats[0]?.stats || [],
      },
      startDate,
      apiReqId: uuidV4(),
    });
  };

  const hymnsHandler = (_stats, startDate) => {
    const _hymnsNames = _stats.reduce((acc, cur) => {
      if (!cur) return acc;

      acc[cur.entity_id] = { name: cur.object.file_name };

      return acc;
    }, {});

    dispatch({
      type: types.GET_HYMNS_STATS_OK,
      usageStats: _stats,
      startDate,
      hymnsNames: { ..._hymnsNames },
      apiReqId: uuidV4(),
    });
  };

  const weeklyProgramHandler = async () => {};

  const playlistsHandler = async (_stats, startDate) => {
    const _playlists = _stats.reduce((acc, cur) => {
      if (!cur || !cur.entity_id || !cur.object) return acc;

      const index = acc.findIndex((o) => o.id === cur.object?.folder_id);

      //if existing folder in the playlist
      if (index >= 0) {
        acc[index].list.push({
          id: cur?.object?.file_id,
          title: cur?.object?.file_name,
        });
        return acc;
      } else {
        acc.push({
          id: cur.object.folder_id,
          name: cur?.object?.folder_name,
          list: [
            {
              id: cur?.object?.file_id,
              title: cur?.object?.file_name,
            },
          ],
        });
      }

      return acc;
    }, []);

    dispatch({
      type: types.GET_PLAYLISTS_STATS_OK,
      usageStats: _stats,
      startDate,
      playlists: [..._playlists],
      apiReqId: uuidV4(),
    });
  };

  const quizzesHandler = async (_stats, startDate) => {
    const _quizzesNames = _stats.reduce((acc, cur) => {
      if (!cur || !cur.object) return acc;
      acc[cur.entity_id] = { name: cur.object.file_name };
      return acc;
    }, {});

    dispatch({
      type: types.GET_QUIZZES_STATS_OK,
      usageStats: _stats,
      startDate,
      quizNames: { ..._quizzesNames },
      apiReqId: uuidV4(),
    });
  };

  const imageAlbumsHandler = async (_stats, startDate) => {
    const albumNames = _stats.reduce((acc, cur) => {
      if (!cur) return acc;
      acc[cur.object.folder_id] = { name: cur.object.folder_name };
      return acc;
    }, {});

    dispatch({
      type: types.GET_IMAGE_ALBUM_STATS_OK,
      usageStats: _stats,
      startDate,
      albumNames: { ...albumNames },
      apiReqId: uuidV4(),
    });
  };

  const culturalProgramsHandler = async (_stats, startDate) => {
    const programNames = _stats.reduce((acc, cur) => {
      if (!cur || !cur.object) return acc;
      acc[cur.entity_id] = {
        name: `${cur.object.folder_name ? cur.object.folder_name + ' - ' : ''}${
          cur.object.file_name
        }`,
      };
      return acc;
    }, {});

    dispatch({
      type: types.GET_CULTURAL_PROGRAMS_STATS_OK,
      usageStats: _stats,
      startDate,
      programNames: { ...programNames },
      apiReqId: uuidV4(),
    });
  };

  const handleGetStats = async (startDate) => {
    const { data } = await getStats({
      variables: { type },
    });

    if (Array.isArray(data?.stats)) {
      const _stats = data?.stats.map((stat) => {
        return {
          ...stat,
          folderId: [
            GQL_STATISTICS_CONTENT_TYPE.quizzes,
            GQL_STATISTICS_CONTENT_TYPE.culturalPrograms,
            GQL_STATISTICS_CONTENT_TYPE.hymns,
          ].includes(type)
            ? stat?.entity_id
            : stat?.object?.folder_id || stat?.entity_id,
          fileId: stat.object?.file_id,
        };
      });

      switch (type) {
        case GQL_STATISTICS_CONTENT_TYPE.programs:
          programsHandler(_stats, startDate);
          break;
        case GQL_STATISTICS_CONTENT_TYPE.radio:
          radioHandler(_stats, startDate);
          break;
        case GQL_STATISTICS_CONTENT_TYPE.hymns:
          hymnsHandler(_stats, startDate);
          break;
        case GQL_STATISTICS_CONTENT_TYPE.playlists:
          playlistsHandler(_stats, startDate);
          break;
        case GQL_STATISTICS_CONTENT_TYPE.quizzes:
          quizzesHandler(_stats, startDate);
          break;
        case GQL_STATISTICS_CONTENT_TYPE.imageAlbum:
          imageAlbumsHandler(_stats, startDate);
          break;
        case GQL_STATISTICS_CONTENT_TYPE.weeklyProgram:
          weeklyProgramHandler(_stats, startDate);
          break;
        case GQL_STATISTICS_CONTENT_TYPE.culturalPrograms:
          culturalProgramsHandler(_stats, startDate);
          break;
        default:
          break;
      }
    }
  };

  return {
    fetchStats: handleGetStats,
    loading: isFetching,
  };
}
