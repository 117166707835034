import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useQuery, useMutation } from '@apollo/client';
import { compose } from 'recompose';
import { useDispatch, useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MembersTableList from '../../../components/Members/TableList';
import MembersTableRow from '../../../components/Organisation/MembersTableRow';
import {
  toggleEditMemberDialog,
  getOrgMemberById,
  clearSelectedOrgMember,
  toggleUpdateMemberPasswordDialog,
} from '../../../lib/store/actions/organisation';
import { selectOrgMembersTableData } from '../../../lib/store/selectors/organisation';
import { showNotification } from '../../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../../lib/constants/notification-types';
import { GET_PAGINATED_MEMBERS } from '../../../lib/api/graphql/queries/user';
import { TOGGLE_CLIENT_ACTIVE_STATUS } from '../../../lib/api/graphql/mutations/organizations';

import * as types from '../../../lib/store/actions/types';

function MembersList({
  onClearSelectedOrgMember,
  onGetGetMemberById,
  onToggleEditMemberDialog,
}) {
  const dispatch = useDispatch();
  const paginationData = useSelector(selectOrgMembersTableData);

  const [searchText, setSearchText] = React.useState('');
  const [sortOrder, setSortOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [resultsPerPage, setResultsPerPage] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(0);

  const [updateMember, { loading: isUpdatingUserStatus }] = useMutation(
    TOGGLE_CLIENT_ACTIVE_STATUS,
    {
      refetchQueries: ['Organization', 'PaginatedClients'],
      onCompleted: () => {
        dispatch(
          showNotification({
            message: 'User status updated',
            options: {
              variant: NOTIFICATION_TYPES.Success,
            },
          }),
        );
      },

      onError: (error) => {
        dispatch(
          showNotification({
            message: `Error: ${error.message}` || 'Error updating user status',
            options: {
              variant: NOTIFICATION_TYPES.Error,
            },
          }),
        );
      },
    },
  );

  const { loading: isFetching } = useQuery(GET_PAGINATED_MEMBERS, {
    variables: {
      search: searchText,
      sort: sortOrder === 'asc' ? 'ASC' : 'DESC',
      sortField:
        orderBy === 'location'
          ? 'State'
          : orderBy === 'joined'
          ? 'CreatedOn'
          : orderBy,
      resultsPerPage,
      pageNumber,
    },
    onCompleted: (data) => {
      if (!data || !Array.isArray(data?.paginatedClients?.edges)) return;

      const { edges, totalCount } = data.paginatedClients;

      const newPagination = {
        currentPage: pageNumber,
        totalRows: totalCount,
      };

      const users = edges.map((edge) => ({
        id: edge.id,
        firstName: edge.FirstName,
        lastName: edge.LastName,
        email: edge.Email,
        subscription: edge.subscription,
        product: edge?.subscription?.type,
        createdOn: edge.CreatedOn,
        isSubscriptionActive: edge?.subscription?.status === 'active',
        subscriptionStatus: edge?.subscription?.status,
        location: {
          state: edge.State || '',
          country: edge.Country || '',
          postcode: edge.Postcode || '',
        },
        active: edge.Active,
      }));

      dispatch({
        type: types.GET_ORG_MEMBERS_OK,
        pagination: newPagination,
        users: users,
        apiReqId: uuidV4(),
      });
    },
    onError: (error) => {
      dispatch(
        showNotification({
          message: `Get members: ${error.message}` || 'Cannot get members',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
    },
  });

  const onSearchMembers = (_searchText) => {
    setPageNumber(0);
    setSearchText(_searchText);
  };

  const handleChangePage = (newPage) => {
    const { pagination: _pagination } = paginationData;
    let _page;
    if (newPage === 'first') {
      _page = 0;
    } else if (newPage === 'last') {
      _page = Math.floor(_pagination.totalRows / resultsPerPage);
    } else {
      _page = newPage;
    }

    setPageNumber(_page);
  };

  const handleChangeRowsPerPage = (rows) => {
    setResultsPerPage(rows);
    setPageNumber(0);
  };

  const handleRequestSortTableColumn = (_, property) => {
    const isAsc = sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPageNumber(0);
  };

  const onClickMember = (id) => {
    onClearSelectedOrgMember();
    onToggleEditMemberDialog();
    onGetGetMemberById({ id });
  };

  const handleToggleActiveStatus = async ({ userId }) => {
    await updateMember({
      variables: { userId },
    });
  };

  const handleRenderRow = (data) => (
    <MembersTableRow
      onClickMember={onClickMember}
      onToggleActiveStatus={handleToggleActiveStatus}
      isUpdatingUserStatus={isUpdatingUserStatus}
      {...data}
    />
  );

  const tableHeaders = [
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email (Login)' },
    { id: 'joined', label: 'Date Created' },
    { id: 'active', label: 'Account Status' },
  ];

  const { members, pagination } = paginationData;

  return (
    <MembersTableList
      rowsPerPageOptions={[25, 50, 75]}
      tableHeaders={tableHeaders}
      members={members}
      pagination={pagination}
      isFetching={isFetching}
      rowsPerPage={resultsPerPage}
      order={sortOrder}
      orderBy={orderBy}
      rowRenderer={handleRenderRow}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onChangePage={handleChangePage}
      onRequestSort={handleRequestSortTableColumn}
      onSearch={onSearchMembers}
    />
  );
}

export default compose(
  withRouter,
  connect(null, {
    onGetGetMemberById: getOrgMemberById,
    onClearSelectedOrgMember: clearSelectedOrgMember,
    onToggleEditMemberDialog: toggleEditMemberDialog,
    onToggleUpdatePasswordDialog: toggleUpdateMemberPasswordDialog,
  }),
)(MembersList);
