import React from "react";
import { v4 as uuidV4 } from "uuid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClearIcon from "@material-ui/icons/Clear";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/PlaylistAdd";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LoadingIndicator from "./LoadingIndicator";
import InputField from "./InputField";
import { truncateString, formatBytes } from "../lib/utils";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: "10px",
    maxHeight: "400px",
    overflowY: "scroll",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  submitIcon: {
    margin: theme.spacing(0, 1),
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  progressText: {
    fontStyle: "italic",
    marginLeft: theme.spacing(1),
  },
  fileInput: {
    display: "none",
  },
  folderIcon: {
    color: theme.palette.primary.main,
  },
  folderIconError: {
    color: "rgb(210, 93, 107)",
  },
  progressBarContainer: {
    position: "relative",
  },
  progressBar: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    backgroundColor: "rgba(0, 197, 0, 0.5)",
  },
  percentageText: {
    position: "absolute",
    left: 0,
    backgroundColor: "green",
    color: "white",
    padding: "0 3px",
    width: "32px",
    display: "block",
    textAlign: "center",
  },
  errorMsg: {
    backgroundColor: "#fd695d",
    color: "#fdefef",
    padding: "3px",
    borderRadius: "4px",
    fontWeight: "bold",
  },
}));

function AddMediaFilesDialog({
  isOpen,
  onClickCancel,
  onSubmit,
  isUploading,
  uploadProgress,
  isUploadComplete,
  onComplete,
  dialogTitle,
  errorMessage,
}) {
  const initialValues = {
    songs: [
      {
        id: uuidV4(),
        title: "",
        artist: "",
        media: null,
      },
    ],
    isSubmitted: false,
  };

  const [values, setValues] = React.useState(initialValues);

  const handleChange = (id, field) => (event) => {
    const { value } = event.target;
    setValues((vals) => ({
      ...vals,
      songs: vals.songs.map((s) => {
        if (s.id === id) {
          return { ...s, [field]: value };
        }
        return { ...s };
      }),
    }));
  };

  const handleAddFile = (id) => (event) => {
    if (event.target.files.length === 0) return;
    const audioFile = event.target.files[0];
    const validFileTypes = ["audio/mp3", "audio/mpeg"];

    if (new RegExp(validFileTypes.join("|")).test(audioFile.type)) {
      audioFile.id = uuidV4();
      setValues((vals) => ({
        ...vals,
        songs: vals.songs.map((s) => {
          if (s.id === id) {
            return {
              ...s,
              media: audioFile,
            };
          }
          return { ...s };
        }),
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isUploadComplete) {
      setValues(initialValues);
      onComplete();
      return;
    }

    setValues({ ...values, isSubmitted: true });

    const isValidInput = values.songs.map((song) =>
      Boolean(song.title.trim().length > 0 && song.media !== null)
    );

    if (isValidInput.includes(false)) return;

    onSubmit({ files: values.songs });
  };

  const addInputRow = () => {
    setValues((vals) => ({
      ...vals,
      isSubmitted: false,
      songs: [
        ...vals.songs,
        {
          id: uuidV4(),
          title: "",
          artist: "",
          media: null,
        },
      ],
    }));
  };

  const deleteInputRow = (songId) => () => {
    setValues((vals) => ({
      ...vals,
      songs: vals.songs.filter((s) => s.id !== songId),
    }));
  };

  const artistTextFieldProps = {
    inputType: "text",
    label: "Artist",
    onChange: handleChange,
    placeholder: "Artist (optional)",
    InputLabelProps: {
      shrink: true,
    },
    disabled: isUploading || isUploadComplete,
  };

  const titleTextFieldProps = {
    inputType: "text",
    label: "Title",
    onChange: handleChange,
    placeholder: "Title",
    InputLabelProps: {
      shrink: true,
    },
    disabled: isUploading || isUploadComplete,
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {dialogTitle || "Add to Playlist"}
        </DialogTitle>
        <DialogContent>
          <form
            className={classes.form}
            id="create-album-form"
            onSubmit={handleSubmit}
          >
            <Grid container justifyContent="center" alignItems="center">
              {values.songs.map((song) => {
                return (
                  <Box key={song.id} mb={2} width="100%">
                    <Grid container spacing={1}>
                      <Grid item xs={11}>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <InputField
                              {...titleTextFieldProps}
                              id={`title-${song.id}`}
                              name={`title-${song.id}`}
                              onChange={handleChange(song.id, "title")}
                              value={song.title}
                              error={
                                values.isSubmitted &&
                                String(song.title).trim().length === 0
                              }
                              helperText={
                                values.isSubmitted &&
                                String(values.title).trim().length === 0
                                  ? "Title is required"
                                  : ""
                              }
                              classes={classes}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <InputField
                              {...artistTextFieldProps}
                              id={`artist-${song.id}`}
                              name={`artist-${song.id}`}
                              onChange={handleChange(song.id, "artist")}
                              value={song.artist}
                              classes={classes}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Grid container spacing={1}>
                              <Grid item xs={2}>
                                <input
                                  accept="audio/mpeg"
                                  className={classes.fileInput}
                                  id={`file-input-${song.id}`}
                                  type="file"
                                  onChange={handleAddFile(song.id)}
                                />
                                <Tooltip
                                  title="Choose an mp3 file ..."
                                  placement="right"
                                >
                                  <label htmlFor={`file-input-${song.id}`}>
                                    <IconButton
                                      disabled={isUploading || isUploadComplete}
                                      component="span"
                                      className={
                                        song.media === null &&
                                        values.isSubmitted
                                          ? classes.folderIconError
                                          : classes.folderIcon
                                      }
                                    >
                                      <FolderOpenIcon fontSize="inherit" />
                                    </IconButton>
                                  </label>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={10}>
                                <Tooltip
                                  title={
                                    song.media
                                      ? `${song.media.name} (${formatBytes(
                                          song.media.size
                                        )})`
                                      : ""
                                  }
                                  placement="top"
                                >
                                  <div className={classes.progressBarContainer}>
                                    <p>
                                      {song.media ? (
                                        `${truncateString(
                                          song.media.name,
                                          15
                                        )} (${formatBytes(song.media.size)})`
                                      ) : (
                                        <span style={{ fontStyle: "italic" }}>
                                          Choose file ...
                                        </span>
                                      )}
                                    </p>
                                    {(isUploading || isUploadComplete) && (
                                      <span
                                        className={classes.progressBar}
                                        style={{
                                          width: `${
                                            uploadProgress[song.id]
                                              ? uploadProgress[song.id]
                                                  .percentage
                                              : 0
                                          }%`,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                            position: "relative",
                                          }}
                                        >
                                          {isUploading &&
                                            uploadProgress[song.id] && (
                                              <span
                                                className={
                                                  classes.percentageText
                                                }
                                              >
                                                {Math.floor(
                                                  uploadProgress[song.id]
                                                    .percentage
                                                )}
                                                %
                                              </span>
                                            )}
                                          {uploadProgress[song.id] &&
                                            uploadProgress[song.id].state ===
                                              "done" && (
                                              <CheckCircleIcon
                                                fontSize="small"
                                                style={{
                                                  position: "absolute",
                                                  right: "2px",
                                                  color: "green",
                                                }}
                                              />
                                            )}
                                        </div>
                                      </span>
                                    )}
                                  </div>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1}>
                        <Box
                          height="100%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Tooltip
                            title={
                              values.songs.length > 1 ? "Remove this row" : ""
                            }
                            placement="top"
                          >
                            <div>
                              <IconButton
                                disabled={
                                  values.songs.length === 1 ||
                                  isUploading ||
                                  isUploadComplete
                                }
                                type="button"
                                onClick={deleteInputRow(song.id)}
                                color="secondary"
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}

              <Grid item xs={12}>
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Tooltip title="Add another ..." placement="right">
                    <div>
                      <IconButton
                        disabled={isUploading || isUploadComplete}
                        type="button"
                        onClick={addInputRow}
                        color="primary"
                      >
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </form>
          <div style={{ minHeight: "26px" }}>
            {errorMessage && (
              <Typography
                component="p"
                variant="body2"
                className={classes.errorMsg}
              >
                {errorMessage && <span>Upload failed: {errorMessage}</span>}
              </Typography>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Fade in={isUploading}>
            <Box
              width="45%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <LoadingIndicator size={16} />
              <Typography
                variant="body2"
                component="span"
                className={classes.progressText}
              >
                Uploading, please wait ...
              </Typography>
            </Box>
          </Fade>
          <Button
            disabled={isUploading || isUploadComplete}
            variant="outlined"
            color="primary"
            onClick={onClickCancel}
          >
            <ClearIcon className={classes.rightMargin} />
            Cancel
          </Button>
          <Button
            disabled={String(values.name).trim().length === 0 || isUploading}
            variant="contained"
            color="primary"
            type="submit"
            form="create-album-form"
          >
            <CheckCircleIcon className={classes.rightMargin} />
            {isUploadComplete ? "Done" : "Start upload ..."}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddMediaFilesDialog;
