import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UploadToPlaylistDialog from '../UploadToPlaylistDialog';
import DeleteHymnFileDialog from './DeleteHymnFileDialog';
import SongsTable from '../../components/SongsTable';
import {
  getHymnsPlaylist,
  setDeleteHymnsFileDialog,
  setAddHymnsFileDialog,
} from '../../lib/store/actions/hymns';
import { makeSelectAuthToken } from '../../lib/store/selectors/auth';
import {
  selectHymnsPlaylistTableData,
  selectIsFetchingHymnsPlaylist,
  selectIsAddHymnsFileDialogOpen,
} from '../../lib/store/selectors/hymns';
import { API_ENDPOINTS } from '../../lib/constants/api-endpoints';

class HymnsList extends React.PureComponent {
  _isMounted = false;

  state = {
    filterText: '',
    fileIdToDelete: '',
  };

  componentDidMount() {
    this._isMounted = true;
    const { onGetHymnsPlaylist } = this.props;
    onGetHymnsPlaylist();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState = (state, callback) => {
    if (this._isMounted) {
      this.setState(state, () => {
        if (callback && typeof callback === 'function') callback();
      });
    }
  };

  handleFilter = event => {
    const { value } = event.target;
    this.updateState({
      filterText: String(value).toLowerCase(),
    });
  };

  handleClickDelete = fileIdToDelete => {
    const { onSetDeleteHymnsFileDialog } = this.props;
    this.updateState({ fileIdToDelete });
    onSetDeleteHymnsFileDialog(true);
  };

  deleteFileCallback = () => {
    this.updateState({ fileIdToDelete: '' });
  };

  handleCloseAddDialog = () => {
    const { onSetAddHymnsFileDialog } = this.props;
    onSetAddHymnsFileDialog(false);
  };

  handleRefreshFiles = () => {
    const { onGetHymnsPlaylist } = this.props;
    onGetHymnsPlaylist();
  };

  render() {
    const { filterText, fileIdToDelete } = this.state;
    const { hymns, isFetching, isAddDialogOpen, authToken } = this.props;

    const _hymns =
      filterText.length > 0
        ? hymns.filter(
            c =>
              String(c.title)
                .toLowerCase()
                .includes(filterText) ||
              String(c.artist)
                .toLowerCase()
                .includes(filterText)
          )
        : hymns;

    const tableHeaders = [{ title: 'Title / Artist', width: '70%' }, 'Player Controls', 'Delete?'];

    const selectedHymn = hymns.find(p => p.id === fileIdToDelete);

    return (
      <>
        <UploadToPlaylistDialog
          isOpen={isAddDialogOpen}
          onCloseDialog={this.handleCloseAddDialog}
          onUploadCompleteCB={this.handleRefreshFiles}
          dialogTitle="Add new hymn"
          uploadURL={`${API_ENDPOINTS.hymns.replace(/:HYMN_ID/, '')}`}
          authToken={authToken}
        />
        <DeleteHymnFileDialog
          fileId={fileIdToDelete}
          fileName={selectedHymn ? selectedHymn.title : ''}
          onDeleteCB={this.deleteFileCallback}
        />
        <SongsTable
          tableHeaders={tableHeaders}
          songs={_hymns}
          onFilter={this.handleFilter}
          isFetching={isFetching}
          onClickDeleteSong={this.handleClickDelete}
          emptyText="No hymns found - use the menu to add some."
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const selectAuthToken = makeSelectAuthToken();
  return {
    hymns: selectHymnsPlaylistTableData(state),
    isFetching: selectIsFetchingHymnsPlaylist(state),
    isAddDialogOpen: selectIsAddHymnsFileDialogOpen(state),
    authToken: selectAuthToken(state),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      onGetHymnsPlaylist: getHymnsPlaylist,
      onSetDeleteHymnsFileDialog: setDeleteHymnsFileDialog,
      onSetAddHymnsFileDialog: setAddHymnsFileDialog,
    }
  )
)(HymnsList);
