import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TablePaginated from '../TablePaginated';
import { useDebouncedEffect } from '../../lib/utils/hooks/useDebounceEffect';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  fontBold: {
    fontWeight: 'bold',
  },
  button: {
    padding: '0 3px',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  filterBox: {
    padding: theme.spacing(0, 1, 1),
  },
}));

export default function MembersTableList({
  tableHeaders,
  members,
  pagination,
  rowsPerPageOptions,
  rowsPerPage,
  order,
  orderBy,
  isFetching,
  rowRenderer,
  onChangeRowsPerPage,
  onChangePage,
  onRequestSort,
  onSearch,
}) {
  const [searchText, setSearchText] = React.useState('');

  const classes = useStyles();

  const handleSubmitSearch = () => {
    onSearch(searchText);
  };

  useDebouncedEffect(
    () => {
      handleSubmitSearch();
    },
    [searchText],
    800,
  );

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const _tableRowRenderer = ({ rowData: row }) => {
    if (typeof rowRenderer === 'function') {
      return rowRenderer(row);
    }

    return null;
  };

  return (
    <Paper className={classes.root}>
      <TablePaginated
        rowsPerPageOptions={rowsPerPageOptions || [10, 40, 60]}
        tableHeaders={tableHeaders}
        tableData={[...members]}
        searchEnabled
        onChangeSearchText={handleChangeSearch}
        onSubmitSearch={handleSubmitSearch}
        searchText={searchText}
        searchPlaceholder="Search by member name"
        searchLabel="Search Members"
        isLoading={isFetching}
        rowRenderer={_tableRowRenderer}
        sortedHeaders
        useSortable
        order={order}
        orderBy={orderBy}
        totalCount={
          typeof pagination.totalRows === 'number' ? pagination.totalRows : 0
        }
        rowsPerPage={rowsPerPage}
        pageNumber={
          typeof pagination.currentPage === 'number'
            ? pagination.currentPage
            : 0
        }
        onRequestSort={onRequestSort}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        emptyTable={
          <Box display="flex" justifyContent="center" flexDirection="row">
            <p>No members found</p>
          </Box>
        }
      />
    </Paper>
  );
}
