import { createSelector } from 'reselect';
import {
  API_GET_QUIZZES_REQ,
  API_DEL_QUIZZES_REQ,
  API_UPD_QUIZZES_REQ,
} from '../actions/types';

/* Quizzes selectors */

const quizzesSelector = (state) => state.quizzes;
export const selectQuizzes = createSelector(
  [quizzesSelector],
  (quizzes) => quizzes,
);

const quizzesTableSelector = (state) => {
  return state.quizzes.list;
};
export const makeSelectQuizzesTableData = () =>
  createSelector([quizzesTableSelector], (quizzes) => quizzes);

const isFetchingQuizzesSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_GET_QUIZZES_REQ,
  );
};
export const selectIsFetchingQuizzes = createSelector(
  [isFetchingQuizzesSelector],
  (isFetching) => isFetching,
);

const deleteQuizDialogOpenSelector = (state) =>
  state.quizzes.dialogs.deleteQuiz;
export const selectIsDeleteQuizDialogOpen = createSelector(
  [deleteQuizDialogOpenSelector],
  (isOpen) => isOpen,
);

const isDeletingQuizSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_DEL_QUIZZES_REQ,
  );
};
export const selectIsDeletingQuiz = createSelector(
  [isDeletingQuizSelector],
  (isDeleting) => isDeleting,
);

const editQuizNameDialogOpenSelector = (state) =>
  state.quizzes.dialogs.editQuizName;
export const selectIsEditQuizNameDialogOpen = createSelector(
  [editQuizNameDialogOpenSelector],
  (isOpen) => isOpen,
);

const isUpdatingQuizNameSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_UPD_QUIZZES_REQ,
  );
};
export const selectIsUpdatingQuizName = createSelector(
  [isUpdatingQuizNameSelector],
  (isUpdating) => isUpdating,
);

const addQuizzesDialogOpenSelector = (state) =>
  state.quizzes.dialogs.addQuizzes;
export const selectIsAddQuizzesDialogOpen = createSelector(
  [addQuizzesDialogOpenSelector],
  (isOpen) => isOpen,
);
