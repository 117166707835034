import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import CreateCategoryDialog from '../dialogs/CreateCategoryDialog';
import CulturalCategoryTable from '../../../components/Programs/CulturalCategoryTable';
import { APP_ROUTES } from '../../../lib/constants/app-routes';
import { GET_CATEGORIES } from '../../../lib/api/graphql/queries/category';
import { showNotification } from '../../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../../lib/constants/notification-types';

function CulturalPrograms() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [filterText, setFilterText] = React.useState('');

  const { data, loading } = useQuery(GET_CATEGORIES, {
    onError: (error) => {
      dispatch(
        showNotification({
          options: { variant: NOTIFICATION_TYPES.Error },
          message: error.message || 'Error fetching categories',
        }),
      );
    },
  });

  const onFilterPlaylists = (event) => {
    const { value } = event.target;
    setFilterText(value.toLowerCase());
  };

  const onClickCategory = (categoryId) => () => {
    history.push(`${APP_ROUTES.culturalprograms}/${categoryId}`);
  };

  const _categories =
    filterText.length > 0
      ? data?.categories.filter((c) =>
          String(c.name).toLowerCase().includes(filterText),
        )
      : data?.categories;

  const tableHeaders = ['Name', 'Number of songs'];

  return (
    <>
      <CreateCategoryDialog />
      <CulturalCategoryTable
        tableHeaders={tableHeaders}
        playlists={_categories || []}
        onFilter={onFilterPlaylists}
        onClickCategory={onClickCategory}
        isFetching={loading}
      />
    </>
  );
}

export default CulturalPrograms;
