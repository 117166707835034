import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PeopleIcon from '@material-ui/icons/People';
import AddIcon from '@material-ui/icons/Add';
import ImportIcon from '@material-ui/icons/ImportContacts';
import Page from '../../pages/PageV2';
import { toggleAddMemberDialog } from '../../lib/store/actions/organisation';
import AddOrgMemberDialog from '../../containers/Organisation/Members/AddOrgMemberDialog';
import EditOrgMemberDialog from '../../containers/Organisation/Members/EditOrgMemberDialog';
import ChangeOrgMemberPasswordDialog from '../../containers/Organisation/Members/ChangeOrgMemberPasswordDialog';
import OrgDetails from '../../containers/Organisation/Details';
import MembersList from '../../containers/Organisation/Members/OrgMembersList';
import { APP_ROUTES } from '../../lib/constants/app-routes';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function MembersListPage({ history, onToggleMemberDialog }) {
  const classes = useStyles();

  const pageMenuItems = [
    {
      title: (
        <>
          <AddIcon className={classes.icon} /> Add Member ...
        </>
      ),
      onClick: () => {
        onToggleMemberDialog();
      },
    },
    {
      title: (
        <>
          <ImportIcon className={classes.icon} /> CSV Import ...
        </>
      ),
      onClick: () => {
        history.push(APP_ROUTES.organisation.import);
      },
    },
  ];

  return (
    <Page
      title={
        <>
          <PeopleIcon className={classes.icon} /> Members
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <AddOrgMemberDialog />
      <EditOrgMemberDialog />
      <ChangeOrgMemberPasswordDialog />
      <OrgDetails />
      <MembersList />
    </Page>
  );
}

export default compose(
  withRouter,
  connect(null, { onToggleMemberDialog: toggleAddMemberDialog }),
)(MembersListPage);
