import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { v4 as uuidV4 } from 'uuid';
import QuizzesTable from '../../components/Quizzes/QuizzesTable';
import { GET_FOLDER } from '../../lib/api/graphql/queries/folder';
import { API_ENDPOINTS } from '../../lib/constants/api-endpoints';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { showNotification } from '../../lib/store/actions/notifications';
import {
  setAddQuizzesDialog,
  setDeleteQuizDialog,
  setEditQuizNameDialog,
} from '../../lib/store/actions/quizzes';
import * as types from '../../lib/store/actions/types';
import { makeSelectAuthToken } from '../../lib/store/selectors/auth';
import {
  makeSelectQuizzesTableData,
  selectIsAddQuizzesDialogOpen,
} from '../../lib/store/selectors/quizzes';
import UploadPDFDialog from '../UploadPDFDialog';
import DeleteQuizDialog from './DeleteQuizDialog';
import EditQuizNameDialog from './EditQuizNameDialog';

const QuizzesList = ({
  onSetDeleteQuizDialog,
  onSetEditQuizNameDialog,
  onSetAddQuizzesDialog,
  quizzes,
  isAddDialogOpen,
  authToken,
}) => {
  const dispatch = useDispatch();

  const { loading, refetch } = useQuery(GET_FOLDER, {
    variables: {
      type: 'QUIZZES',
    },
    onCompleted: (data) => {
      if (!data?.folder?.files) return;

      const _quizzes = data.folder.files.map((q) => ({
        id: q.id,
        name: q.FileName,
        url: q.Url,
      }));

      dispatch({
        type: types.GET_QUIZZES_OK,
        quizzes: _quizzes,
        apiReqId: uuidV4(),
      });
    },
    onError: (error) => {
      dispatch(
        showNotification({
          message: `Get quizzes: ${error.message}` || 'Cannot get quizzes',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
    },
  });

  const [filterText, setFilterText] = useState('');
  const [selectedId, setSelectedId] = useState('');

  const onFilterQuizzes = (event) => {
    const { value } = event.target;
    setFilterText(String(value).toLowerCase());
  };

  const clickDeleteQuiz = (selected) => {
    setSelectedId(selected);
    onSetDeleteQuizDialog(true);
  };

  const clickEditQuiz = (selected) => {
    setSelectedId(selected);
    onSetEditQuizNameDialog(true);
  };

  const deleteQuizCallback = () => {
    setSelectedId('');
  };

  const closeAddPDFDialog = () => {
    onSetAddQuizzesDialog(false);
  };

  const refreshQuizzes = () => {
    refetch();
  };

  const filteredQuizzes =
    filterText.length > 0
      ? quizzes.filter((c) => String(c.name).toLowerCase().includes(filterText))
      : quizzes;

  const tableHeaders = [
    { title: 'Quiz', width: '70%' },
    'Edit Name',
    'Delete?',
  ];

  const selectedQuiz = quizzes.find((p) => p.id === selectedId);

  return (
    <>
      <UploadPDFDialog
        isOpen={isAddDialogOpen}
        onCloseDialog={closeAddPDFDialog}
        onUploadCompleteCB={refreshQuizzes}
        uploadURL={API_ENDPOINTS.quizzes.replace(':FILE_ID', '')}
        dialogTitle="Add new quizzes"
        authToken={authToken}
      />
      <EditQuizNameDialog
        quizId={selectedId}
        quizName={selectedQuiz ? selectedQuiz.name : ''}
      />
      <DeleteQuizDialog
        fileId={selectedId}
        fileName={selectedQuiz ? selectedQuiz.name : ''}
        onDeleteCB={deleteQuizCallback}
      />
      <QuizzesTable
        tableHeaders={tableHeaders}
        quizzes={filteredQuizzes}
        onFilter={onFilterQuizzes}
        isFetching={loading}
        filterText={filterText}
        onClickDelete={clickDeleteQuiz}
        onClickEdit={clickEditQuiz}
        emptyText="No quizzes found - use the menu to add some."
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const selectQuizzesTableData = makeSelectQuizzesTableData();
  const selectAuthToken = makeSelectAuthToken();
  return {
    quizzes: selectQuizzesTableData(state),
    isAddDialogOpen: selectIsAddQuizzesDialogOpen(state),
    authToken: selectAuthToken(state),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    onSetDeleteQuizDialog: setDeleteQuizDialog,
    onSetEditQuizNameDialog: setEditQuizNameDialog,
    onSetAddQuizzesDialog: setAddQuizzesDialog,
  }),
)(QuizzesList);
