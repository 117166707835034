import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useQuery } from '@apollo/client';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { withRouter, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/en-au';
import Profile from '../../components/Members/Profile';
import { selectCurrentMember } from '../../lib/store/selectors/members';
import { APP_ROUTES } from '../../lib/constants/app-routes';
import { GET_USER_DETAILS } from '../../lib/api/graphql/queries/organizations';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import * as types from '../../lib/store/actions/types';

moment.locale('en-au');

const MemberDetails = ({ member }) => {
  const { id: paramMemberId } = useParams();
  const dispatch = useDispatch();

  const { loading: isFetching } = useQuery(GET_USER_DETAILS, {
    variables: { userId: paramMemberId },
    onCompleted: (data) => {
      if (!data?.user?.id) return;

      const memberInfo = {
        id: data.user.id,
        firstName: data.user.FirstName,
        lastName: data.user.LastName,
        email: data.user.Email,
        location: {
          state: data.user.State || '',
          postcode: data.user.Postcode || '',
          country: data.user.Country || '',
        },
        memberSince: data.user.CreatedOn,
        lastAccess: data.user.LastAccess,
        savedCards: data.user.saved_cards,
        messages: data.user.messages,
        subscription: data.user.subscription || {},
        organisation: data.user.Organisation,
        isSubscriptionActive: data.user.subscription?.status === 'active',
        subscriptionStatus: data.user.subscription?.status,
        navigation: {},
      };

      dispatch({
        type: types.GET_MEMBER_OK,
        member: memberInfo,
        memberId: paramMemberId,
        apiReqId: uuidV4(),
      });
    },
    onError: (error) => {
      dispatch(
        showNotification({
          message: `Get member: ${error.message}` || 'Cannot get member',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
    },
  });

  // const currentSubscription = () => {
  //   if (!member) return null;

  //   const { current } = member.subscription || {};
  //   if (!current) return null;

  //   const latestReceiptInfo = [...current.latestReceiptInfo]
  //     .sort((a, b) => {
  //       return (
  //         new Date(parseInt(a.purchaseDateMs, 10)) -
  //         new Date(parseInt(b.purchaseDateMs, 10))
  //       );
  //     })
  //     .pop();

  //   if (!latestReceiptInfo) return null;

  //   const { productId, expiresDateMs } = latestReceiptInfo;

  //   return {
  //     product: SUBSCRIPTION_PRODUCTS[productId],
  //     expires: moment(parseInt(expiresDateMs, 10)).format(
  //       'Do MMM YYYY HH:mm:ss',
  //     ),
  //     transactionId: latestReceiptInfo.transactionId,
  //   };
  // };

  // const subscriptionHistory = () => {
  //   if (!member) return [];
  //   if (!member.subscription && !Array.isArray(member.subscription?.history))
  //     return [];

  //   const { history } = member.subscription || {};

  //   if (!history) return [];

  //   const data = history.map((item) => ({
  //     id: uuidV4(),
  //     product: SUBSCRIPTION_PRODUCTS[item.productId],
  //     purchaseDate: moment(parseInt(item.purchaseDateMs, 10)).format(
  //       'Do MMM YYYY HH:mm:ss',
  //     ),
  //     expiredDate: moment(parseInt(item.expiresDateMs, 10)).format(
  //       'Do MMM YYYY HH:mm:ss',
  //     ),
  //     transactionId: item.transactionId,
  //   }));

  //   return data;
  // };

  const genNavLinks = (navigation) => {
    const { prev, next } = navigation | {};

    return {
      prev: prev
        ? {
            link: `${APP_ROUTES.members}/${prev.id}`,
            name: prev.name,
          }
        : null,
      next: next
        ? {
            link: `${APP_ROUTES.members}/${next.id}`,
            name: next.name,
          }
        : null,
    };
  };

  const _member = member
    ? {
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        location: {
          state: member.location.state || '',
          postcode: member.location.postcode,
          country: member.location.country,
        },
        since: moment(member.createdOn).format('Do MMM YYYY'),
        savedCards: member.savedCards,
        lastAccess: member.lastAccess,
        messages: member.messages,
      }
    : null;

  const subscription = member
    ? {
        // history: subscriptionHistory(),
        // latest: currentSubscription(),
        // pending: pendingRenewalInfo(),
        ...member.subscription,
        isActive: member.isSubscriptionActive,
      }
    : null;

  const _navigation = member ? genNavLinks(member.navigation) : null;

  return (
    <Profile
      member={_member}
      navigation={_navigation}
      isFetching={isFetching || !_member}
      subscription={subscription}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    member: selectCurrentMember(state, ownProps),
    isUpdating: false,
  };
};

export default compose(withRouter, connect(mapStateToProps))(MemberDetails);
