import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import GamesIcon from '@material-ui/icons/Games';
import Page from './PageV2';
import QuizzesList from '../containers/Quizzes/QuizzesList';
import { setAddQuizzesDialog } from '../lib/store/actions/quizzes';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function QuizzesPage({ onSetAddQuizzesDialog }) {
  const classes = useStyles();

  const pageMenuItems = [
    {
      title: (
        <>
          <AddIcon className={classes.icon} /> Add Quiz(es) ...
        </>
      ),
      onClick: () => onSetAddQuizzesDialog(true),
    },
  ];

  return (
    <Page
      title={
        <>
          <GamesIcon className={classes.icon} /> Quizzes
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <QuizzesList />
    </Page>
  );
}

export default connect(null, {
  onSetAddQuizzesDialog: setAddQuizzesDialog,
})(QuizzesPage);
