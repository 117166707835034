import React from 'react';
import { connect } from 'react-redux';
import CreatePlaylist from '../../components/Playlist/Dialogs/CreatePlaylist';
import { setCreatePlaylistDialog, createPlaylist } from '../../lib/store/actions/playlists';
import {
  selectIsCreatePlaylistDialogOpen,
  selectIsCreatingPlaylist,
} from '../../lib/store/selectors/playlists';

function CreatePlaylistDialog({ isOpen, onSetCreatePlaylistDialog, onCreatePlaylist, isCreating }) {
  const closeDialog = () => {
    onSetCreatePlaylistDialog(false);
  };

  const addPlaylist = ({ name }) => {
    onCreatePlaylist({ name }, () => {
      onSetCreatePlaylistDialog(false);
    });
  };

  return (
    <CreatePlaylist
      isOpen={isOpen}
      onClickCancel={closeDialog}
      onSubmit={addPlaylist}
      isCreating={isCreating}
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectIsCreatePlaylistDialogOpen(state),
    isCreating: selectIsCreatingPlaylist(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetCreatePlaylistDialog: setCreatePlaylistDialog,
    onCreatePlaylist: createPlaylist,
  }
)(CreatePlaylistDialog);
