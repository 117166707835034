import { gql } from '@apollo/client';

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrg($name: String!, $licences: Int) {
    createOrg(name: $name, licences: $licences) {
      id
    }
  }
`;

export const TOGGLE_ORG_ACTIVE_STATUS = gql`
  mutation ToggleOrg($orgId: ObjectID!) {
    toggleOrg(org_id: $orgId)
  }
`;

export const EDIT_ORG = gql`
  mutation EditOrg($orgId: ObjectID!, $licences: Int!, $name: String) {
    editOrg(org_id: $orgId, licences: $licences, name: $name) {
      id
    }
  }
`;

export const ADD_ADMIN_TO_ORG = gql`
  mutation InviteUser($orgId: ObjectID, $member: OrgAdminInput!) {
    inviteUser(org_id: $orgId, member: $member) {
      id
    }
  }
`;

export const ADD_ORG_USER = gql`
  mutation AddOrgUser($member: OrgUserInput!, $orgId: ObjectID) {
    addOrgUser(member: $member, org_id: $orgId) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $userId: ObjectID!
    $firstName: String
    $lastName: String
    $postcode: String
    $state: AddressState
    $email: EmailAddress
  ) {
    updateUser(
      user_id: $userId
      first_name: $firstName
      last_name: $lastName
      postcode: $postcode
      state: $state
      email: $email
    ) {
      id
    }
  }
`;

export const BULK_ADD_ORG_USERS = gql`
  mutation BulkAddOrgUser(
    $members: [OrgUserInput]!
    $sendCredentials: Boolean
    $orgId: ObjectID
  ) {
    bulkAddOrgUser(
      members: $members
      send_credentials: $sendCredentials
      org_id: $orgId
    ) {
      id
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword(
    $userId: ObjectID!
    $password: String!
    $orgId: ObjectID
  ) {
    updateUserPassword(user_id: $userId, password: $password, org_id: $orgId)
  }
`;

export const TOGGLE_CLIENT_ACTIVE_STATUS = gql`
  mutation ToggleActiveStatus($userId: ObjectID!) {
    toggleClient(client_id: $userId)
  }
`;
