import React from 'react';
import { connect } from 'react-redux';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { deleteHymnsFile, setDeleteHymnsFileDialog } from '../../lib/store/actions/hymns';
import {
  selectIsDeleteHymnsFileDialogOpen,
  selectIsDeletingHymnsFile,
} from '../../lib/store/selectors/hymns';

function DeleteHymnFileDialog({
  isOpen,
  isDeleting,
  fileId,
  onSetDeleteHymnsFileDialog,
  onDeleteHymnsFile,
  fileName,
  onDeleteCB,
}) {
  const closeDialog = () => {
    onSetDeleteHymnsFileDialog(false);
  };

  const deleteCurrentPlaylistSong = () => {
    onDeleteHymnsFile({ fileId }, () => {
      onSetDeleteHymnsFileDialog(false);
      onDeleteCB();
    });
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={closeDialog}
      onConfirm={deleteCurrentPlaylistSong}
      isInProgress={isDeleting}
      inProgressMessage="Deleting, please wait ..."
      confirmationBody={
        <>
          Please confirm you'd like to delete "{fileName}"?
          <br />
          <br />
          <strong>Note:</strong> This action cannot be undone.
        </>
      }
      confirmationBtnText="Yes, delete it!"
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectIsDeleteHymnsFileDialogOpen(state),
    isDeleting: selectIsDeletingHymnsFile(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetDeleteHymnsFileDialog: setDeleteHymnsFileDialog,
    onDeleteHymnsFile: deleteHymnsFile,
  }
)(DeleteHymnFileDialog);
