import { createSelector } from 'reselect';
import {
  API_GET_IMAGE_ALBUMS_REQ,
  API_GET_IMAGES_REQ,
  API_DEL_IMAGE_ALBUMS_REQ,
  API_ADD_IMAGE_ALBUMS_REQ,
} from '../actions/types';

// selectors
const createDialogOpenSelector = (state) =>
  state.imageAlbums.dialogs.createAlbum;
const editNameDialogOpenSelector = (state) =>
  state.imageAlbums.dialogs.editAlbumName;
const deleteDialogOpenSelector = (state) =>
  state.imageAlbums.dialogs.deleteAlbum;
const deleteImageModeSelector = (state) => state.imageAlbums.modes.deleteImages;
export const stateSelector = (state) => state.imageAlbums;
const albumsSelector = (state) => state.imageAlbums.albums;
const isFetchingAlbumSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_GET_IMAGE_ALBUMS_REQ,
  );
};
const isFetchingImagesSelector = (state) => {
  return !!state.apiRequest.find(
    (req) =>
      req.requestType === API_GET_IMAGE_ALBUMS_REQ ||
      req.requestType === API_GET_IMAGES_REQ,
  );
};
const isDeletingImageAlbumSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_DEL_IMAGE_ALBUMS_REQ,
  );
};
const isCreatingImageAlbumSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_ADD_IMAGE_ALBUMS_REQ,
  );
};
const getCurrentAlbumSelector = (state, props) => {
  if (!state.imageAlbums.albums) return null;
  const { id } = props.match.params;
  const album = state.imageAlbums.albums.find((a) => a.id === id);
  return album;
};
const imagesSelector = (state, props) => {
  if (!state.imageAlbums.albums) return [];
  const { id } = props.match.params;
  const album = state.imageAlbums.albums.find((a) => a.id === id);
  return album.images;
};
export const selectImageAlbumsState = createSelector(
  [stateSelector],
  (albums) => albums,
);

export const selectIsCreateDialogOpen = createSelector(
  [createDialogOpenSelector],
  (createAlbum) => createAlbum,
);

export const selectEditNameDialogOpen = createSelector(
  [editNameDialogOpenSelector],
  (editName) => editName,
);

export const selectDeleteDialogOpen = createSelector(
  [deleteDialogOpenSelector],
  (deleteAlbum) => deleteAlbum,
);

export const selectImageAlbums = createSelector(
  [albumsSelector],
  (imageAlbums) => imageAlbums,
);

export const selectImages = createSelector(
  [imagesSelector],
  (images) => images,
);

export const selectIsFetchingAlbums = createSelector(
  [isFetchingAlbumSelector],
  (isFetching) => isFetching,
);

export const makeSelectIsFetchingImages = () =>
  createSelector([isFetchingImagesSelector], (isFetching) => isFetching);

export const selectIsDeletingImageAlbum = createSelector(
  [isDeletingImageAlbumSelector],
  (isDelete) => isDelete,
);

export const selectIsCreatingImageAlbum = createSelector(
  [isCreatingImageAlbumSelector],
  (isCreating) => isCreating,
);

export const selectIsDeleteImageMode = createSelector(
  [deleteImageModeSelector],
  (isDeleteMode) => isDeleteMode,
);

export const makeSelectCurrentAlbum = () =>
  createSelector([getCurrentAlbumSelector], (album) => album);
