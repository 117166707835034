import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Page from '../PageV2';
import ProgamsList from '../../containers/Programs/ProgamsList';
import { setAddProgramFileDialog } from '../../lib/store/actions/programs';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function ProgramsPlaylistPage({ onSetAddProgramFileDialog }) {
  const classes = useStyles();

  const pageMenuItems = [
    {
      title: (
        <>
          <AddIcon className={classes.icon} /> Add files ...
        </>
      ),
      onClick: () => onSetAddProgramFileDialog(true),
    },
  ];

  return (
    <Page
      title={
        <>
          <LibraryBooksIcon className={classes.icon} /> Pre-Recorded Broadcasts
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <ProgamsList />
    </Page>
  );
}

export default connect(null, {
  onSetAddProgramFileDialog: setAddProgramFileDialog,
})(ProgramsPlaylistPage);
