import { Box, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  messageIcon: {
    color: '#B3B3B3',
    width: '26px',
    height: '26px',
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
}));

function EmptyMessage({
  emptyHeaderText,
  emptyBodyText,
  createButtonText,
  onClickCreate,
}) {
  const classes = useStyles();

  return (
    <Paper
      style={{ backgroundColor: '#FAFAFA', height: '200px' }}
      elevation={0}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBlock: 15,
        }}>
        <MailOutlineIcon className={classes.messageIcon} />
      </Box>
      <Typography
        style={{
          fontFamily: 'Archivo, sans-serif',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '28px',
          letterSpacing: '0px',
          textAlign: 'center',
          color: '#808080',
        }}>
        {emptyHeaderText || 'No Message to show'}
      </Typography>
      <Box mt={1}>
        <Typography
          style={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#B3B3B4',
          }}>
          {emptyBodyText ||
            'You’ll be able to see the Message preview here once You add it.'}
        </Typography>
      </Box>
      <Box mt={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          disabled={false}
          variant="contained"
          color="primary"
          onClick={onClickCreate}>
          <CheckCircleIcon className={classes.rightMargin} />
          {createButtonText || 'Create'}
        </Button>
      </Box>
    </Paper>
  );
}

export default EmptyMessage;
