import React from 'react';

export const PersistorContext = React.createContext(null);

const withPersistor = Component => props => (
  <PersistorContext.Consumer>
    {persistor => <Component {...props} persistor={persistor} />}
  </PersistorContext.Consumer>
);

export default withPersistor;
