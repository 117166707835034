import React from 'react';
import moment from 'moment';
import 'moment/locale/en-au';
import { useDispatch } from 'react-redux';
import { grey } from '@material-ui/core/colors';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import EmptyMessage from '../../components/Messages/EmptyMessage';
import { GET_LAST_BROADCAST } from '../../lib/api/graphql/queries/messages';
import LoadingIndicator from '../../components/LoadingIndicator';
import Post from '../../components/Messages/Post';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import AddBroadcastDialog from './dialogs/AddBroadcastMessage';
import { setDialog } from '../../lib/store/reducers/dialog';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 1.5),
    border: 'solid',
    borderColor: grey[300],
    borderWidth: 1,
    borderRadius: 4,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  subHeaderText: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#E1C0EE',
  },
}));

function BroadcastMessage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GET_LAST_BROADCAST, {
    onError: () => {
      dispatch(
        showNotification({
          options: { variant: NOTIFICATION_TYPES.Error },
          message: 'Error while fetching last broadcast message',
        }),
      );
    },
  });

  const handleOpenAddBroadcastDialog = () => {
    dispatch(setDialog({ open: true, dialog: DIALOG_TYPES.ADD_NEW_BROADCAST }));
  };

  const isEmpty = !data?.last_broadcast?.id && !loading && !error;

  if (isEmpty) {
    return (
      <div className={classes.root}>
        <AddBroadcastDialog />
        <EmptyMessage
          emptyHeaderText="No Broadcast Message to show"
          emptyBodyText="You’ll be able to see the Broadcast Message preview here once You add it."
          onClickCreate={handleOpenAddBroadcastDialog}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AddBroadcastDialog />
      {loading ? (
        <div className={classes.loadingContainer}>
          <LoadingIndicator size={26} />
        </div>
      ) : (
        <Post
          showUser={false}
          type={3} // broadcast
          read
          body={data?.last_broadcast?.text || 'Broadcast message'}
          customSubHeader={
            <Typography className={classes.subHeaderText} whiteSpace="pre">
              {'Posted: '}
              {data?.last_broadcast?.createdAt
                ? moment(data?.last_broadcast?.createdAt).format(
                    'dddd MMM DD, YYYY hh:mm A',
                  )
                : '-'}
            </Typography>
          }
          bodyTextStyles={{
            fontFamily: 'Roboto, sans-serif',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0px',
            textAlign: 'left',
            whiteSpace: 'pre',
            maxHeight: '200px',
            overflow: 'auto',
            color: '#FFFFFF',
          }}
        />
      )}
    </div>
  );
}

export default BroadcastMessage;
