import React from 'react';
import { connect } from 'react-redux';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { deletePlaylistSong, setDeletePlaylistSongDialog } from '../../lib/store/actions/playlists';
import {
  selectDeletePlaylistSongDialogOpen,
  selectIsDeletingPlaylistSong,
} from '../../lib/store/selectors/playlists';

function DeletePlaylistSongDialog({
  isOpen,
  isDeleting,
  playlistId,
  onSetDeletePlaylistSongDialog,
  onDeletePlaylistSong,
  playlistName,
  songName,
  songId,
  onDeleteCB,
}) {
  const closeDialog = () => {
    onSetDeletePlaylistSongDialog(false);
  };

  const deleteCurrentPlaylistSong = () => {
    onDeletePlaylistSong({ playlistId, songId }, () => {
      onSetDeletePlaylistSongDialog(false);
      onDeleteCB();
    });
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={closeDialog}
      onConfirm={deleteCurrentPlaylistSong}
      isInProgress={isDeleting}
      inProgressMessage="Deleting, please wait ..."
      confirmationBody={
        <>
          Please confirm you'd like to delete the song "{songName}" from the playlist "
          {playlistName}"?
          <br />
          <br />
          <strong>Note:</strong> This action cannot be undone.
        </>
      }
      confirmationBtnText="Yes, delete it!"
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectDeletePlaylistSongDialogOpen(state),
    isDeleting: selectIsDeletingPlaylistSong(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetDeletePlaylistSongDialog: setDeletePlaylistSongDialog,
    onDeletePlaylistSong: deletePlaylistSong,
  }
)(DeletePlaylistSongDialog);
