import { createSelector } from 'reselect';
import {
  API_GET_PLAYLISTS_REQ,
  API_ADD_PLAYLIST_REQ,
  API_UPD_PLAYLIST_REQ,
  API_DEL_PLAYLIST_REQ,
  API_DEL_PLAYLIST_SONG_REQ,
} from '../actions/types';

// selectors
const createPlaylistDialogOpenSelector = (state) =>
  state.playlists.dialogs.createPlaylist;
const editNameDialogOpenSelector = (state) =>
  state.playlists.dialogs.updatePlaylistName;
const deleteDialogOpenSelector = (state) =>
  state.playlists.dialogs.deletePlaylist;
const deleteSongDialogOpenSelector = (state) =>
  state.playlists.dialogs.deletePlaylistSong;
const addSongsToPlaylistDialogOpenSelector = (state) =>
  state.playlists.dialogs.addSongsToPlaylist;

const playlistSelector = (state) => state.playlists.lists;

const playlistsTableSelector = (state) => {
  return state.playlists.lists.map((o) => ({
    id: o.id,
    name: o.name,
    artwork: o.artwork,
    items: o.list.length,
  }));
};

const isFetchingPlaylistsSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_GET_PLAYLISTS_REQ,
  );
};

const getCurrentPlaylistSelector = (state, props) => {
  if (state.playlists === 0) return null;
  const { id } = props.match.params;
  const playlist = state.playlists.lists.find((o) => o.id === id);
  return playlist || null;
};

const isCreatingPlaylistSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_ADD_PLAYLIST_REQ,
  );
};

const isUpdatingPlaylistSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_UPD_PLAYLIST_REQ,
  );
};

const isDeletingPlaylistSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_DEL_PLAYLIST_REQ,
  );
};

const isDeletingPlaylistSongSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_DEL_PLAYLIST_SONG_REQ,
  );
};

export const selectIsCreatePlaylistDialogOpen = createSelector(
  [createPlaylistDialogOpenSelector],
  (isOpen) => isOpen,
);

export const selectIsEditPlaylistNameDialogOpen = createSelector(
  [editNameDialogOpenSelector],
  (isOpen) => isOpen,
);

export const selectDeletePlaylistDialogOpen = createSelector(
  [deleteDialogOpenSelector],
  (isOpen) => isOpen,
);

export const selectAddSongsToPlaylistDialogOpen = createSelector(
  [addSongsToPlaylistDialogOpenSelector],
  (isOpen) => isOpen,
);

export const selectDeletePlaylistSongDialogOpen = createSelector(
  [deleteSongDialogOpenSelector],
  (isOpen) => isOpen,
);

export const selectPlaylistsTableData = createSelector(
  [playlistsTableSelector],
  (playlists) => playlists,
);

export const selectPlaylists = createSelector(
  [playlistSelector],
  (playlists) => playlists,
);

export const selectIsFetchingPlaylists = createSelector(
  [isFetchingPlaylistsSelector],
  (isFetching) => isFetching,
);

export const selectCurrentPlaylist = createSelector(
  [getCurrentPlaylistSelector],
  (playlist) => playlist,
);

export const selectCurrentPlaylistSongs = createSelector(
  [getCurrentPlaylistSelector],
  (playlist) => {
    return playlist ? playlist.list : [];
  },
);

export const makeSelectCurrentPlaylistArtwork = () =>
  createSelector([getCurrentPlaylistSelector], (playlist) => {
    return playlist ? playlist.artwork : '';
  });

export const makeSelectCurrentPlaylistName = () =>
  createSelector([getCurrentPlaylistSelector], (playlist) => {
    return playlist ? playlist.name : '';
  });

export const selectIsCreatingPlaylist = createSelector(
  [isCreatingPlaylistSelector],
  (isCreating) => isCreating,
);

export const selectIsUpdatingPlaylist = createSelector(
  [isUpdatingPlaylistSelector],
  (isUpdating) => isUpdating,
);

export const selectIsDeletingPlaylist = createSelector(
  [isDeletingPlaylistSelector],
  (isDeleting) => isDeleting,
);

export const selectIsDeletingPlaylistSong = createSelector(
  [isDeletingPlaylistSongSelector],
  (isDeleting) => isDeleting,
);
