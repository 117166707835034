import { combineReducers } from 'redux';
import { activity } from './activity';
import { apiRequest } from './api-request';
import { hymns } from './hymns';
import { imageAlbums } from './imageAlbums';
import { lyrics } from './lyrics';
import { members } from './members';
import { messages } from './messages';
import { notifications } from './notifications';
import { organisation } from './organisation';
import { playlists } from './playlists';
import { programs } from './programs';
import { quizzes } from './quizzes';
import { user } from './user';
import { dialogsReducer, DIALOGS_FEATURE_KEY } from './dialog';

export default combineReducers({
  activity,
  apiRequest,
  hymns,
  imageAlbums,
  lyrics,
  members,
  messages,
  notifications,
  organisation,
  playlists,
  programs,
  quizzes,
  user,
  [DIALOGS_FEATURE_KEY]: dialogsReducer,
});
