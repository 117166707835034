import React from 'react';
import Papa from 'papaparse';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import PeopleIcon from '@material-ui/icons/People';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Page from '../PageV2';
import MembersList from '../../containers/Members/MembersList';
import { GET_CSV_MEMBERS } from '../../lib/api/graphql/queries/user';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { convertToCsv } from '../../lib/utils';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function MembersListPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [getCsvMembers, { loading: isLoadingCSV }] = useLazyQuery(
    GET_CSV_MEMBERS,
    {
      onError: (error) => {
        dispatch(
          showNotification({
            message: `Get report: ${error.message}` || 'Cannot get report',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
      },
    },
  );

  const handleGetReport = async () => {
    dispatch(
      showNotification({
        message: 'Generating CSV report. Please wait ...',
        options: {
          variant: NOTIFICATION_TYPES.Info,
        },
      }),
    );

    const { data } = await getCsvMembers({
      variables: {
        resultsPerPage: null,
        pageNumber: 0,
        sort: 'ASC',
        sortField: 'name',
        search: '',
      },
    });

    if (!data || !Array.isArray(data?.paginatedClients?.edges)) return;
    const { edges } = data.paginatedClients;

    const users = edges.map((edge) => ({
      firstName: edge.FirstName,
      lastName: edge.LastName,
      email: edge.Email,
      active: edge.Active,
      agreeMail: edge.AgreeMail,
      lastAccess: edge.LastAccess,
      isSubscriptionActive: edge?.subscription?.status === 'active',
      subscriptionType: edge?.subscription?.type || '-',
      subscriptionStatus: edge?.subscription?.status,
      createdOn: edge.CreatedOn,
      state: edge.State || '-',
      country: edge.Country || '-',
      postcode: edge.Postcode || '-',
    }));

    convertToCsv(
      Papa.unparse(users),
      `members-${moment().format('YYYY-MM-DD_HHmmss')}.csv`,
      'text/csv;encoding:utf-8',
    );
  };

  const pageMenuItems = [
    {
      title: (
        <>
          <DownloadIcon className={classes.icon} />{' '}
          {isLoadingCSV
            ? 'Generating CSV. Please wait ...'
            : 'Download CSV Report ...'}
        </>
      ),
      onClick: isLoadingCSV ? undefined : handleGetReport,
    },
  ];

  return (
    <Page
      title={
        <>
          <PeopleIcon className={classes.icon} /> Members
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <MembersList />
    </Page>
  );
}

export default MembersListPage;
