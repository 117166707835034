import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog from '../../components/SMOrganisations/AddOrgDialog';
import {
  setDialog,
  makeSelectIsDialogOpen,
} from '../../lib/store/reducers/dialog';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { CREATE_ORGANIZATION } from '../../lib/api/graphql/mutations/organizations';
import { GET_ORGANIZATIONS } from '../../lib/api/graphql/queries/organizations';

const AddOrgDialog = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(makeSelectIsDialogOpen(DIALOG_TYPES.ADD_ORG));

  const [createOrg, { loading: isLoading }] = useMutation(CREATE_ORGANIZATION, {
    refetchQueries: [{ query: GET_ORGANIZATIONS }],
    onCompleted: () => {
      handleClose();
      dispatch(
        showNotification({
          message: 'New organisation added',
          options: {
            variant: NOTIFICATION_TYPES.Success,
          },
        }),
      );
    },
    onError: (error) => {
      dispatch(
        showNotification({
          options: { variant: NOTIFICATION_TYPES.Error },
          message: `Error while creating organisation: ${error.message}`,
        }),
      );
    },
  });

  const handleAddOrg = async ({ name, licences }) => {
    await createOrg({
      variables: {
        name,
        licences,
      },
    });
  };

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialog: DIALOG_TYPES.ADD_ORG,
      }),
    );
  };

  return (
    <Dialog
      isLoading={isLoading}
      isOpen={isOpen}
      onCancel={handleClose}
      onSubmit={handleAddOrg}
    />
  );
};

export default compose(withRouter)(AddOrgDialog);
