import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import webLocalStorage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

const persistConfig = {
  key: 'silver-memories-dashboard',
  storage: webLocalStorage,
  throttle: 2000,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const extraMiddlewares = [];

if (process.env.NODE_ENV === 'development') {
  extraMiddlewares.push(logger);
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(extraMiddlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);

export default store;
export { store, persistor };
