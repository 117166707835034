import * as types from './types';
import { showNotification } from './notifications';
import { NOTIFICATION_TYPES } from '../../constants/notification-types';
import { logout } from './auth';

export const getAuthToken = state => state.user.auth.idToken;

export const checkHttpError = (error, apiReqId) => dispatch => {
  if (error.name === 'HttpError' && error.message === '401') {
    // NOTE: error.config contains the original API request information, in case its decided
    // later on that the user should be automaticially logged on again and the same api request sent
    // console.log(' error config:', error.config);
    dispatch({ type: types.API_END, apiReqId });
    dispatch(logout());
    dispatch(
      showNotification({
        message: 'Unauthorised: Please log in again',
        options: {
          variant: NOTIFICATION_TYPES.Warning,
        },
      })
    );
  } else {
    throw new Error(error.message);
  }
};
