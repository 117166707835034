import React from "react";
import AddScheduleFilesDialog from "../../components/AddScheduleFilesDialog";
import UploadProgress from "../../lib/utils/uploadProgress";

function UploadScheduleDialog({
  isOpen,
  onCloseDialog,
  onUploadCompleteCB,
  uploadURL,
  dialogTitle,
  scheduleLocation,
  authToken,
}) {
  const initialState = {
    uploadProgress: {},
    isUploading: false,
    isUploadComplete: false,
  };

  const [values, setValues] = React.useState(initialState);

  const closeDialog = () => {
    onCloseDialog();
  };

  const onClickDone = () => {
    onUploadCompleteCB();
    setValues(initialState);
    onCloseDialog();
  };

  const sendRequest = (file) => {
    const doUpload = new UploadProgress({
      onSend: (req) => {
        const formData = new FormData();
        formData.append("location", scheduleLocation);
        formData.append("file", file, file.name);

        req.open("POST", uploadURL);
        req.setRequestHeader("Authorization", `Bearer ${authToken}`); // eslint-disable-line

        req.send(formData);
      },
      onLoad: () => {
        setValues((vals) => ({
          ...vals,
          uploadProgress: {
            state: "done",
            percentage: 100,
          },
        }));
      },
      onError: () => {
        setValues((vals) => ({
          ...vals,
          uploadProgress: {
            state: "error",
            percentage: 0,
          },
        }));
      },
      onProgress: (event) => {
        if (event.lengthComputable) {
          setValues((vals) => ({
            ...vals,
            uploadProgress: {
              state: "pending",
              percentage: (event.loaded / event.total) * 100,
            },
          }));
        }
      },
    });

    return doUpload.upload();
  };

  const addFilesToSchedule = async ({ file }) => {
    try {
      setValues({ ...values, uploadProgress: {}, isUploading: true });
      await sendRequest(file);
      setValues((vals) => ({
        ...vals,
        isUploading: false,
        isUploadComplete: true,
      }));
    } catch (e) {
      setValues({ ...values, error: e, isUploading: false });
    }
  };

  return (
    <AddScheduleFilesDialog
      isOpen={isOpen}
      onClickCancel={closeDialog}
      onSubmit={addFilesToSchedule}
      isUploading={values.isUploading}
      isUploadComplete={values.isUploadComplete}
      uploadProgress={values.uploadProgress}
      onComplete={onClickDone}
      dialogTitle={dialogTitle}
      errorMessage={values.error}
    />
  );
}

export default UploadScheduleDialog;
