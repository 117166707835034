import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import CSVImport from '../../../components/Organisation/CSVImport';
import { useHistory } from 'react-router-dom';
import { clearOrgMemberImportResults } from '../../../lib/store/actions/organisation';
import {
  selectOrgMembersImportResults,
  makeSelectIsOrgActive,
} from '../../../lib/store/selectors/organisation';
import { showNotification } from '../../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../../lib/constants/notification-types';
import { makeSelectUserOrgId } from '../../../lib/store/selectors/user';
import { BULK_ADD_ORG_USERS } from '../../../lib/api/graphql/mutations/organizations';

function CSVImportMember({ isOrgActive, onClearImportResults }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userOrgId = useSelector(makeSelectUserOrgId());

  const [bulkAddMembers, { loading: isImporting }] = useMutation(
    BULK_ADD_ORG_USERS,
    {
      refetchQueries: ['Organization', 'PaginatedClients'],
      onCompleted: () => {
        dispatch(
          showNotification({
            message: 'Members Imported',
            options: {
              variant: NOTIFICATION_TYPES.Success,
            },
          }),
        );
        history.goBack();
      },

      onError: (error) => {
        dispatch(
          showNotification({
            message:
              `Import Error: ${error.message}` || 'Error importing members',
            options: {
              variant: NOTIFICATION_TYPES.Error,
            },
          }),
        );
      },
    },
  );

  React.useEffect(() => {
    onClearImportResults();
  }, [onClearImportResults]);

  const handleSubmit = async (data) => {
    const csvData = data.map((m) => ({
      FirstName: m.FirstName,
      LastName: m.LastName,
      Email: m.Email,
      Postcode: m.Postcode || undefined,
      State: m.State || undefined,
      Country: m.country || undefined,
      BirthDate: m.BirthDate || undefined,
    }));

    await bulkAddMembers({
      variables: {
        orgId: userOrgId,
        members: csvData,
      },
    });
  };

  return (
    <CSVImport
      onSubmit={handleSubmit}
      onClearResults={onClearImportResults}
      isLoading={isImporting}
      isOrgActive={isOrgActive}
    />
  );
}

const mapStateToProps = (state) => {
  const selectIsOrgActive = makeSelectIsOrgActive();

  return {
    importResults: selectOrgMembersImportResults(state),
    isOrgActive: selectIsOrgActive(state),
  };
};

export default connect(mapStateToProps, {
  onClearImportResults: clearOrgMemberImportResults,
})(CSVImportMember);
