/* eslint-disable arrow-body-style */
import * as types from './types';
import { programsApi } from '../../api';
import { apiRequest } from './api-request';
import { showNotification } from './notifications';
import { NOTIFICATION_TYPES } from '../../constants/notification-types';
import { getAuthToken, checkHttpError } from './authTokenHelper';

export const setDeleteProgramFileDialog = (isOpen) => ({
  type: types.TOGGLE_DELETE_PROGRAM_FILE_DIALOG,
  isOpen,
});

export const setAddProgramFileDialog = (isOpen) => ({
  type: types.TOGGLE_ADD_PROGRAM_FILE_DIALOG,
  isOpen,
});

export const setCreateCulturalCategoryDialog = (isOpen) => ({
  type: types.TOGGLE_CREATE_CULTURAL_CATEGORY,
  isOpen,
});

export const setAddCulturalCategorySongsDialog = (isOpen) => ({
  type: types.TOGGLE_ADD_CULTURAL_CATEGORY_SONGS,
  isOpen,
});

export const setUpdateCulturalCategoryDialog = (isOpen) => ({
  type: types.TOGGLE_UPDATE_CULTURAL_CATEGORY,
  isOpen,
});

export const setDeleteCulturalCategoryDialog = (isOpen) => ({
  type: types.TOGGLE_DELETE_CULTURAL_CATEGORY,
  isOpen,
});

export const setDeleteCulturalSongDialog = (isOpen) => ({
  type: types.TOGGLE_DELETE_CULTURAL_SONG,
  isOpen,
});

/* PROGRAMS */
export const getProgramsPlaylist = (callback) => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_GET_PROGRAM_PLAYLIST_REQ,
    message: 'Loading programs',
  });
  dispatch(apiReqAction);
  programsApi
    .getProgramPlaylist(getAuthToken(getState()))
    .then(
      (programPlaylist) => {
        dispatch({
          type: types.GET_PROGRAM_PLAYLIST_OK,
          programPlaylist:
            programPlaylist !== null ? programPlaylist.audioFileList : [],
          apiReqId: apiReqAction.id,
        });
        if (callback && typeof callback === 'function')
          callback(programPlaylist?.audioFileList || []);
      },
      (error) => dispatch(checkHttpError(error, apiReqAction.id)),
    )
    .catch((error) => {
      dispatch(
        showNotification({
          message: `Get programs: ${error.message}` || 'Cannot get programs',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};

export const deleteProgramFile =
  ({ fileId }, successCB) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_DEL_PROGRAM_FILE_REQ,
      message: 'Deleting program',
    });
    dispatch(apiReqAction);

    programsApi
      .deletePlaylistFile(getAuthToken(getState()), { fileId })
      .then(
        () => {
          dispatch({
            type: types.DELETE_PROGRAM_FILE_OK,
            fileId,
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'Program successfully deleted',
              options: {
                variant: NOTIFICATION_TYPES.Success,
                autoHideDuration: 2000,
              },
            }),
          );
          if (successCB && typeof successCB === 'function') successCB();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message:
              `Delete program: ${error.message}` || 'Cannot delete program',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

/* WEEKLY PROGRAMS (PDF SCHEDULES) */
export const setUploadScheduleDialog = (isOpen) => ({
  type: types.TOGGLE_UPLOAD_SCHEDULE_DIALOG,
  isOpen,
});

export const getProgramSchedules = (callback) => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_GET_PROGRAM_SCHEDULES_REQ,
    message: 'Loading weekly programs',
  });
  dispatch(apiReqAction);
  programsApi
    .getProgramSchedules(getAuthToken(getState()))
    .then(
      (programSchedules) => {
        dispatch({
          type: types.GET_PROGRAM_SCHEDULES_OK,
          programSchedules,
          apiReqId: apiReqAction.id,
        });
        if (callback && typeof callback === 'function')
          callback(programSchedules);
      },
      (error) => dispatch(checkHttpError(error, apiReqAction.id)),
    )
    .catch((error) => {
      dispatch(
        showNotification({
          message:
            `Get weekly programs: ${error.message}` ||
            'Cannot get weekly programs',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};
