import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";

import { DELETE_CATEGORY } from "../../../lib/api/graphql/mutations/category";
import { setDeleteCulturalCategoryDialog } from "../../../lib/store/actions/programs";
import { selectDeleteCulturalCategoryDialogOpen } from "../../../lib/store/selectors/programs";
import { showNotification } from "../../../lib/store/actions/notifications";
import { NOTIFICATION_TYPES } from "../../../lib/constants/notification-types";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

function DeleteCategoryDialog({ originalName, categoryId, onDelete }) {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectDeleteCulturalCategoryDialogOpen);

  const [deleteCategory, { loading }] = useMutation(DELETE_CATEGORY, {
    onCompleted: () => {
      dispatch(
        showNotification({
          message: "Category deleted, navigating to categories...",
          options: { variant: NOTIFICATION_TYPES.Success },
        })
      );
      handleClose();
      onDelete();
    },
    onError: (error) => {
      dispatch(
        showNotification({
          message: error?.message || "Failed to delete the category",
          options: { variant: NOTIFICATION_TYPES.Error },
        })
      );
    },
  });

  const handleClose = () => {
    dispatch(setDeleteCulturalCategoryDialog(false));
  };

  const handleDelete = async () => {
    await deleteCategory({ variables: { categoryId } });
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onConfirm={handleDelete}
      onCancel={handleClose}
      confirmationBtnText="Yes, delete it!"
      inProgressMessage="Deleting category..."
      isInProgress={loading}
      confirmationBody={
        <>
          Please confirm you'd like to delete the category "{originalName}"?
          <br />
          <br />
          <strong>Note:</strong> This action cannot be undone.
        </>
      }
    />
  );
}

export default DeleteCategoryDialog;
