import React from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import NavMenuItem from './NavMenuItem';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#abd6f7',
  },
  divider: {
    backgroundColor: 'rgba(239, 239, 239, 0.3)',
  },
}));

const NavMenuSection = ({
  heading,
  menuItems,
  hasDivider,
  currentPath,
  notifications,
}) => {
  const classes = useStyles();
  return (
    <div>
      <List>
        {heading && (
          <ListSubheader inset className={classes.heading}>
            {heading}
          </ListSubheader>
        )}
        {menuItems.map((item, index) => {
          return (
            <NavMenuItem
              key={index}
              {...item}
              isSelected={currentPath.includes(item.linkTo)}
              notificationCount={notifications[item.linkTo]}
            />
          );
        })}
      </List>
      {hasDivider && <Divider classes={{ root: classes.divider }} />}
    </div>
  );
};

export default NavMenuSection;
