import { createReducer } from 'redux-create-reducer';
import * as types from '../actions/types';

const initialState = {
  playlist: [],
  names: {},
  dialogs: {
    deleteHymnsFile: false,
    addHymnsFile: false,
  },
};

export const hymns = createReducer(initialState, {
  // Playlists
  [types.GET_HYMNS_PLAYLIST_OK](state, action) {
    return {
      ...state,
      names: action.hymnsPlaylist.reduce((obj, a) => {
        obj[a.id] = {
          name: a.title,
        };
        return obj;
      }, {}),
      playlist: [...action.hymnsPlaylist],
    };
  },
  [types.DELETE_HYMNS_FILE_OK](state, action) {
    const { fileId } = action;
    const { [fileId]: unwanted, ...restNames } = state.names; // eslint-disable-line
    return {
      ...state,
      names: {
        ...restNames,
      },
      playlist: state.playlist.filter(p => p.id !== fileId),
    };
  },
  // Dialogs
  [types.TOGGLE_DELETE_HYMNS_FILE_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        deleteHymnsFile: action.isOpen,
      },
    };
  },
  [types.TOGGLE_ADD_HYMNS_FILE_DIALOG](state, action) {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        addHymnsFile: action.isOpen,
      },
    };
  },
  [types.LOGOUT]() {
    return initialState;
  },
});
