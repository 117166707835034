/* eslint-disable no-unused-vars */
import React from "react";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";

function VirtualList({ data, rowRender }) {
  const listRef = React.useRef(null);
  const measurerRef = React.useRef(null);

  const cache = new CellMeasurerCache({
    defaultWidth: 600,
    fixedWidth: true,
  });

  const _rowRenderer = ({
    index, // Index of row
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    key, // Unique key within array of rendered rows
    parent, // Reference to the parent List (instance)
    style, // Style object to be applied to row (to position it);  This must be passed through to the rendered row element.
  }) => {
    const currentItem = data[index];

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        overscanRowCount={10}
        parent={parent}
        ref={measurerRef}
        rowIndex={index}
      >
        {() => {
          return (
            <div key={key} style={style}>
              {rowRender(currentItem)}
            </div>
          );
        }}
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <List
            ref={listRef}
            height={height}
            width={width}
            rowCount={data.length}
            rowHeight={cache.rowHeight}
            rowRenderer={_rowRenderer}
            deferredMeasurementCache={cache}
            style={{ outline: "none" }}
          />
        );
      }}
    </AutoSizer>
  );
}

export default VirtualList;
