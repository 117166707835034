import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Dialog from '../../components/SMOrganisations/AddOrgAdminDialog';
import {
  makeSelectIsDialogOpen,
  setDialog,
} from '../../lib/store/reducers/dialog';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';
import { ADD_ADMIN_TO_ORG } from '../../lib/api/graphql/mutations/organizations';
import { GET_ORGANIZATION } from '../../lib/api/graphql/queries/organizations';
import { showNotification } from '../../lib/store/actions/notifications';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';

const AddAdminDialog = () => {
  const param = useParams();
  const dispatch = useDispatch();

  const isOpen = useSelector(makeSelectIsDialogOpen(DIALOG_TYPES.ADD_ADMIN));

  const [addAdmin, { loading: isAdding }] = useMutation(ADD_ADMIN_TO_ORG, {
    refetchQueries: [
      { query: GET_ORGANIZATION, variables: { orgId: param?.id } },
    ],
    onCompleted: () => {
      handleClose();
    },
    onError: (error) => {
      dispatch(
        showNotification({
          options: { variant: NOTIFICATION_TYPES.Error },
          message: `Error adding admin user: ${error?.message}`,
        }),
      );
    },
  });

  const handleAddAdmin = async ({ firstName, lastName, email, password }) => {
    await addAdmin({
      variables: {
        orgId: param.id,
        member: {
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          password: password,
        },
      },
    });
  };

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialog: DIALOG_TYPES.ADD_ADMIN,
      }),
    );
  };

  return (
    <Dialog
      isLoading={isAdding}
      isOpen={isOpen}
      onCancel={handleClose}
      onSubmit={handleAddAdmin}
    />
  );
};

export default AddAdminDialog;
