import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import EditAlbumNameDialog from './EditAlbumNameDialog';
import DeleteAlbumDialog from './DeleteAlbumDialog';
import ImageAlbumComponent from '../../components/ImageAlbums/ImageAlbum';
import {
  setDeleteImageMode,
  deleteImages,
  setEditAlbumNameDialog,
  saveAlbumName,
  getImages,
} from '../../lib/store/actions/imageAlbums';
import { setDialog } from '../../lib/store/reducers/dialog';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';
import {
  makeSelectCurrentAlbum,
  makeSelectIsFetchingImages,
  selectIsDeleteImageMode,
} from '../../lib/store/selectors/imageAlbums';
import { APP_ROUTES } from '../../lib/constants/app-routes';
import EditFileNameDialog from './EditFileNameDialog';

const ImageAlbum = ({ album, isDeleteImageMode, isFetching, history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    albumId: album ? album.id : null,
    images: album ? album.images.map((i) => ({ ...i, selected: false })) : null,
  });

  useEffect(() => {
    if (album && album.id !== values.albumId) {
      setValues({
        ...values,
        albumId: album.id,
        images: album.images.map((i) => ({
          ...i,
          selected: false,
        })),
      });
    }
  }, [album, values.albumId]);

  const handleDeleteImages = () => {
    if (!values.albumId) return;

    const imageIds = [...values.images]
      .filter((image) => image.selected)
      .map((image) => image.id);

    if (imageIds.length > 0) {
      dispatch(deleteImages({ albumId: values.albumId, imageIds }));
    }

    cancelDeleteImageMode();
  };

  const cancelDeleteImageMode = () => {
    dispatch(setDeleteImageMode(false));
  };

  const handleSaveAlbumName = (albumName) => {
    dispatch(setEditAlbumNameDialog(false));
    dispatch(saveAlbumName({ albumId: values.albumId, albumName }));
  };

  const refreshAlbumImages = () => {
    dispatch(getImages(album.id));
  };

  const onSelectImage = (_, obj) => {
    setValues((vals) => {
      return {
        ...vals,
        images: vals.images.map((image, i) => {
          if (i === obj.index) {
            return { ...image, selected: !image.selected };
          }
          return image;
        }),
      };
    });
  };

  const onNavigateToImageAlbumsPage = () => {
    history.replace(APP_ROUTES.sightsound);
  };

  const handleEditImage = (image) => () => {
    dispatch(
      setDialog({
        open: true,
        dialog: DIALOG_TYPES.UPDATE_FILE_NAME,
        params: { image },
      }),
    );
  };

  return (
    <>
      <EditAlbumNameDialog
        onSubmit={handleSaveAlbumName}
        albumName={album ? album.name : ''}
      />
      <DeleteAlbumDialog
        albumId={album ? album.id : null}
        albumName={album ? album.name : ''}
        onNavigateToImageAlbumsPage={onNavigateToImageAlbumsPage}
      />
      <EditFileNameDialog />
      <ImageAlbumComponent
        images={values.images}
        isLoading={isFetching}
        isDeleteImageMode={isDeleteImageMode}
        onDeleteImages={handleDeleteImages}
        onCancelDeleteImageMode={cancelDeleteImageMode}
        onCompleteUploadCB={refreshAlbumImages}
        onSelectImage={onSelectImage}
        onEditImage={handleEditImage}
        albumId={album ? album.id : null}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const selectCurrentAlbum = makeSelectCurrentAlbum();
  const selectIsFetchingImages = makeSelectIsFetchingImages();
  return {
    album: selectCurrentAlbum(state, ownProps),
    isDeleteImageMode: selectIsDeleteImageMode(state),
    isFetching: selectIsFetchingImages(state),
  };
};

export default compose(withRouter, connect(mapStateToProps))(ImageAlbum);
