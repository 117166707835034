/*
 * NOTE: Icons are found here: https://material.io/tools/icons/?style=baseline
 * */

import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { APP_ROUTES } from './app-routes';

export const ORG_NAVIGATION_MENU_ITEMS = {
  main: {
    heading: 'Community',
    menuItems: [
      {
        icon: PeopleIcon,
        linkTo: APP_ROUTES.organisation.members,
        text: 'Members',
      },
    ],
  },
  profile: {
    heading: 'Acount',
    menuItems: [
      {
        icon: PersonIcon,
        linkTo: APP_ROUTES.organisation.account,
        text: 'My Profile',
      },
    ],
  },
  other: {
    heading: '',
    menuItems: [
      {
        icon: LogoutIcon,
        linkTo: APP_ROUTES.logout,
        text: 'Logout',
      },
    ],
  },
};
