import gql from 'graphql-tag';

export const GET_FOLDER = gql`
  query GetFolder($type: StorageFolderType!) {
    folder(type: $type) {
      id
      Type
      Name
      CreatedOn
      files {
        liked
        id
        Url
        FileName
        CreatedOn
      }
    }
  }
`;
