import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WeeklyTable from '../../components/Programs/WeeklyTable';
import UploadScheduleDialog from './UploadScheduleDialog';
import { getProgramSchedules, setUploadScheduleDialog } from '../../lib/store/actions/programs';
import {
  makeSelectProgramsScheduleTableData,
  selectIsFetchingProgramsSchedule,
  selectIsUploadScheduleDialogOpen,
} from '../../lib/store/selectors/programs';
import { makeSelectAuthToken } from '../../lib/store/selectors/auth';
import { API_ENDPOINTS } from '../../lib/constants/api-endpoints';
import { WEEKLY_PROGRAM_TYPES } from '../../lib/constants';

class WeeklySchedules extends React.PureComponent {
  _isMounted = false;

  state = {
    locationToEdit: '',
  };

  componentDidMount() {
    this._isMounted = true;
    const { onGetProgramSchedules } = this.props;
    onGetProgramSchedules();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState = (state, callback) => {
    if (this._isMounted) {
      this.setState(state, () => {
        if (callback && typeof callback === 'function') callback();
      });
    }
  };

  onChangeProgramSchedule = location => {
    this.updateState(
      {
        locationToEdit: location,
      },
      () => {
        const { onSetUploadScheduleDialog } = this.props;
        onSetUploadScheduleDialog(true);
      }
    );
  };

  onCloseUploadScheduleDialog = () => {
    const { onSetUploadScheduleDialog } = this.props;
    onSetUploadScheduleDialog(false);
  };

  refreshWeeklySchedules = () => {
    const { onGetProgramSchedules } = this.props;
    onGetProgramSchedules();
  };

  render() {
    const { locationToEdit } = this.state;
    const { schedules, isFetching, isUploadDialogOpen, authToken } = this.props;

    const tableHeaders = [
      { title: 'Time Zone', width: '40%' },
      { title: 'Weekly Program', width: '40%' },
      'Upload',
    ];

    const dialogTitle = `Upload a new program for ${String(
      locationToEdit === 'qld' ? 'EST' : 'DST'
    ).toUpperCase()}`;

    return (
      <>
        <UploadScheduleDialog
          isOpen={isUploadDialogOpen}
          onCloseDialog={this.onCloseUploadScheduleDialog}
          onUploadCompleteCB={this.refreshWeeklySchedules}
          dialogTitle={dialogTitle}
          scheduleLocation={locationToEdit}
          uploadURL={`${API_ENDPOINTS.weeklyPrograms.replace(
            /:CONTENT_TYPE/,
            WEEKLY_PROGRAM_TYPES[locationToEdit]
          )}`}
          authToken={authToken}
        />
        <WeeklyTable
          tableHeaders={tableHeaders}
          schedules={schedules}
          isFetching={isFetching}
          onClickChange={this.onChangeProgramSchedule}
          emptyText="No weekly programs found"
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const selectProgramsScheduleTableData = makeSelectProgramsScheduleTableData();
  const selectAuthToken = makeSelectAuthToken();
  return {
    schedules: selectProgramsScheduleTableData(state),
    isFetching: selectIsFetchingProgramsSchedule(state),
    isUploadDialogOpen: selectIsUploadScheduleDialogOpen(state),
    authToken: selectAuthToken(state),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      onGetProgramSchedules: getProgramSchedules,
      onSetUploadScheduleDialog: setUploadScheduleDialog,
    }
  )
)(WeeklySchedules);
