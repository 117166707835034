import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-au';
import StatsList from '../../components/Activity/StatsList';
import {
  selectRadioStatsData,
  selectHasLoadedRadioStatsData,
} from '../../lib/store/selectors/activity';
import { GQL_STATISTICS_CONTENT_TYPE } from '../../lib/constants';
import { useGetStats } from './useGetStats';

moment.locale('en-au');

function Radio({ stats, hasLoaded }) {
  const { fetchStats, loading } = useGetStats({
    type: GQL_STATISTICS_CONTENT_TYPE.radio,
  });

  React.useEffect(() => {
    if (!hasLoaded) {
      const thirtyDaysAgo = moment().subtract(1, 'month').startOf('day');
      fetchStats(thirtyDaysAgo.toISOString());
    }
  }, [hasLoaded]);

  const renderRowTitle = () => '';

  const refreshData = () => {
    const thirtyDaysAgo = moment().subtract(1, 'month').startOf('day');
    fetchStats(thirtyDaysAgo.toISOString());
  };

  return (
    <StatsList
      stats={stats}
      isLoading={loading}
      renderRowTitle={renderRowTitle}
      totalTitle="Total Plays"
      graphTitle="Plays - Last 30 days"
      onClickRefresh={refreshData}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    stats: selectRadioStatsData(state),
    hasLoaded: selectHasLoadedRadioStatsData(state),
  };
};

export default connect(mapStateToProps)(Radio);
