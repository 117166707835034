import React from 'react';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: 'auto',
  },
});

function ImportInstructions() {
  const classes = useStyles();

  const [show, setShow] = React.useState(false);

  const handleToggleShow = () => {
    setShow(!show);
  };

  const fields = [
    { name: 'FirstName', example: 'John', required: true },
    { name: 'LastName', example: 'Doe', required: true },
    { name: 'Email', example: 'john.doe@email.com', required: true },
    { name: 'BirthDate', example: '2023-02-27', required: false },
    { name: 'Country', example: 'Australia', required: false },
    { name: 'Postcode', example: '4006', required: false },
    { name: 'State', example: 'QLD', required: false },
  ];

  const csvData = [
    [
      'FirstName',
      'LastName',
      'Email',
      'BirthDate',
      'Country',
      'Postcode',
      'State',
    ],
    [
      'John',
      'Doe',
      'john.doe@email.com',
      '2023-02-21',
      'Australia',
      '4006',
      'QLD',
    ],
    [
      'Jane',
      'Doe',
      'jane.doe@email.com',
      '2023-02-11',
      'New Zealand',
      '0600',
      '',
    ],
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" component="p">
          Download an example / starting CSV File:{' '}
          <CSVLink data={csvData} filename="example-member-import.csv">
            Example CSV
          </CSVLink>
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          onClick={handleToggleShow}>
          {show ? 'Hide' : 'Show'} Instructions
        </Button>
      </Grid>
      {show && (
        <>
          <Grid item xs={12}>
            <Typography variant="body2" component="span">
              The following table shows the required CSV file contents and
              example values:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <div className={classes.tableWrapper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {fields.map((row) => (
                        <TableCell
                          key={row.name}
                          component="th"
                          style={{ whiteSpace: 'nowrap' }}>
                          {row.name} {row.required ? '*' : ''}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {fields.map((row) => (
                        <TableCell key={row.name}>{row.example}</TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <p>
              <strong>NOTES:</strong>
            </p>
            <ul>
              <li>
                Items listed with a <strong>*</strong> require values, others
                can be blank values
              </li>
              <li>
                <strong>Email</strong>: must be a valid email address
              </li>
              <li>
                <strong>BirthDate</strong>: must be a valid date in the format{' '}
                (YYYY-MM-DD)
              </li>
              <li>
                <strong>Country</strong>: Valid values are "Australia" or "New
                Zealand"
              </li>
              <li>
                <strong>Postcode</strong>: Valid postcode for the selected
                country
              </li>
              <li>
                <strong>State</strong>: If the country is Australia, a valid{' '}
                <em>abbrievated</em> Australian state, e.g. one of ACT, NSW, NT,
                QLD, SA, TAS, VIC, WA. Leave blank if the country is New Zealand
              </li>
            </ul>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default ImportInstructions;
