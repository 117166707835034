import axios from 'axios';
import { API_ENDPOINTS } from '../constants/api-endpoints';

function logRequestResponse(response, type) {
  if (process.env.NODE_ENV !== 'production') {
    console.group(
      `%c API CALL RESPONSE %creceived @ ${new Date().toLocaleString()}`,
      'color: #93014C',
      'font-weight: normal; color: #7F7E7E'
    );
    let color = '#F54725'; // non-authorized
    if (type === 'error') color = 'red';
    if (type === 'authorized') color = '#CD6AEF';
    console.log(
      `%c${type} %crequest/response:`,
      `font-weight: bold; color: ${color}`,
      'font-weight: normal; color: #7F7E7E',
      response
    );
    console.groupEnd('API REQUEST RESPONSE');
  }
}

export class HttpError extends Error {
  constructor(message, requestConfig) {
    super(message);
    this.name = 'HttpError';
    this.config = requestConfig;
  }
}

axios.interceptors.response.use(
  response => response,
  error => {
    logRequestResponse(
      {
        request: error.config,
        response: error.response,
      },
      'error'
    );
    if (error.response && error.response.status === 401) {
      throw new HttpError('401', error.response.config);
    }
    if (
      error.response &&
      error.response.config &&
      error.response.config.url &&
      error.response.status === 400
    ) {
      return error.response;
    }
    throw new Error(error);
  }
);

function request({ method, endpoint, body = null, headers = {} }) {
  const config = {
    method,
    url: endpoint,
    data: body,
    headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headers },
    timeout: 60000,
  };

  return axios(config);
}

function authorizedRequest(authToken, params) {
  return request({
    ...params,
    headers: { Authorization: `Bearer ${authToken}` },
  }).then(response => {
    logRequestResponse(response, 'authorized');
    if (response.status === 401) {
      throw new Error('Unauthorised');
    }

    return response.data;
  });
}

function nonAuthorizedRequest(params) {
  return request({
    ...params,
  }).then(response => {
    logRequestResponse(response, 'non-authorized');
    return response.data;
  });
}

export default () => ({
  authRequest: authorizedRequest,

  nonAuthRequest: nonAuthorizedRequest,

  loginRequest(email, password, firebaseId) {
    return request({
      method: 'post',
      endpoint: API_ENDPOINTS.login,
      body: { email, password, firebaseDeviceId: firebaseId },
    })
      .then(response => {
        logRequestResponse(response, 'non-authorized');
        if (response.status === 200) {
          return response.data;
        }
        throw new Error('There was an error processing the request');
      })
      .catch(error => {
        const errorMessage =
          error.message === '401' ? 'Invalid username or password' : error.message;
        throw new Error(errorMessage);
      });
  },
});
