import React from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "../LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
  },
  name: {
    fontSize: "1.3em",
    color: theme.palette.primary.main,
  },
  genderAge: {
    fontSize: "1.3em",
    color: theme.palette.primary.dark,
  },
  contact: {
    fontSize: "1.2em",
    color: theme.palette.primary.dark,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  imageBtn: {
    position: "relative",
    height: 180,
    width: 180,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.4,
      },
      "& $imageText": {
        opacity: 0.9,
      },
    },
    // borderRadius: '50%',
    marginBottom: theme.spacing(2),
    overflow: "hidden",
  },
  focusVisible: {},
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
    // borderRadius: '50%',
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0,
    transition: theme.transitions.create("opacity"),
    // borderRadius: '50%',
  },
  fileInput: {
    display: "none",
  },
  imageText: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
    transition: theme.transitions.create("opacity"),
    opacity: 0,
  },
}));

function NameAndArwork({
  name,
  artwork,
  onUploadImage,
  isLoading,
  isUpdating,
}) {
  const classes = useStyles();

  const handleUploadImage = (event) => {
    event.preventDefault();
    if (event.target.files.length === 0) return;
    onUploadImage(event.target.files[0]);
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      direction="column"
      className={classes.gridRoot}
    >
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <LoadingIndicator size={26} />
        </Box>
      )}
      {!isLoading && (
        <>
          <Box>
            <input
              accept="image/*"
              className={classes.fileInput}
              id="upload-image-btn"
              type="file"
              onChange={handleUploadImage}
              disabled={isUpdating}
            />
            <label htmlFor="upload-image-btn">
              <ButtonBase
                disabled={isUpdating}
                component="span"
                id="upload-image-btn"
                focusRipple
                className={classes.imageBtn}
                focusVisibleClassName={classes.focusVisible}
              >
                <span
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: `url(${artwork})`,
                  }}
                />
                <span className={classes.imageBackdrop} />
                {!isUpdating && (
                  <span className={classes.imageText}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                    >
                      Change Image...
                    </Typography>
                  </span>
                )}
                {isUpdating && <LoadingIndicator size={42} />}
              </ButtonBase>
            </label>
            <Divider />
          </Box>
          <Box mt={1} width="100%" display="flex" flexDirection="column">
            <Box width="100%">
              <Typography component="p" className={classes.name} align="center">
                {name}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Grid>
  );
}

export default NameAndArwork;
