import React from 'react';
import { connect } from 'react-redux';
import UpdateNameDialog from '../../components/UpdateNameDialog';
import { setEditQuizNameDialog, updateQuizName } from '../../lib/store/actions/quizzes';
import {
  selectIsEditQuizNameDialogOpen,
  selectIsUpdatingQuizName,
} from '../../lib/store/selectors/quizzes';

function EditQuizNameDialog({
  isOpen,
  onSetEditQuizNameDialog,
  onUpdateQuizName,
  isUpdating,
  quizId,
  quizName,
}) {
  const closeDialog = () => {
    onSetEditQuizNameDialog(false);
  };

  const saveQuizName = ({ name }) => {
    if (!quizId) return;
    onUpdateQuizName({ name, id: quizId }, () => {
      onSetEditQuizNameDialog(false);
    });
  };

  return (
    <UpdateNameDialog
      isOpen={isOpen}
      onClickCancel={closeDialog}
      onSubmit={saveQuizName}
      isUpdating={isUpdating}
      currentName={quizName}
      dialogTitle="Update quiz name"
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectIsEditQuizNameDialogOpen(state),
    isUpdating: selectIsUpdatingQuizName(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetEditQuizNameDialog: setEditQuizNameDialog,
    onUpdateQuizName: updateQuizName,
  }
)(EditQuizNameDialog);
