import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH } from '../lib/constants';
import logo from '../assets/images/sm-logo-2021.png';
import conpago from '../assets/images/conpago.png';

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  iconColor: {
    color: 'white',
  },
  version: {
    color: '#c7c7c7',
    marginBottom: theme.spacing(2),
  },
  text: {
    color: '#c7c7c7',
  },
  logo: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    width: '100%',
    height: '48px',
    margin: '8px 0',
  },
  divider: {
    backgroundColor: 'rgba(239, 239, 239, 0.3)',
  },
  versionBox: {
    flexGrow: 1,
  },
}));

const AppNavigation = ({ isDrawerOpen, menu }) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !isDrawerOpen && classes.drawerPaperClose,
        ),
      }}
      open={isDrawerOpen}>
      <Grid item className={classes.logo} />
      <Divider className={classes.divider} />
      {menu}
      <Box
        className={classes.versionBox}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <Typography
            component="span"
            variant="caption"
            className={classes.text}>
            POWERED BY
          </Typography>
          <img src={conpago} style={{ width: '60%' }} alt="Conpago" />
        </Box>
      </Box>
    </Drawer>
  );
};

export default AppNavigation;
