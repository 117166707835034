import React from 'react';
import { connect } from 'react-redux';
import CreateAlbum from '../../components/ImageAlbums/Dialogs/CreateAlbum';
import { setCreateImageAlbumDialog } from '../../lib/store/actions/imageAlbums';
import { selectIsCreateDialogOpen } from '../../lib/store/selectors/imageAlbums';

function CreateAlbumDialog({ isOpen, onSetCreateImageAlbumDialog, onSubmit, isCreating }) {
  const closeDialog = () => {
    onSetCreateImageAlbumDialog(false);
  };

  const addImageAlbum = ({ name }) => {
    onSubmit(name);
  };

  return (
    <CreateAlbum
      isOpen={isOpen}
      onClickCancel={closeDialog}
      onSubmit={addImageAlbum}
      isCreating={isCreating}
    />
  );
}

const mapStateToProps = state => {
  return {
    isOpen: selectIsCreateDialogOpen(state),
  };
};

export default connect(
  mapStateToProps,
  {
    onSetCreateImageAlbumDialog: setCreateImageAlbumDialog,
  }
)(CreateAlbumDialog);
