import React from 'react';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
// import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import ReactSelect from './ReactSelect';

const InputField = ({
  inputType,
  options,
  classes,
  label,
  style,
  id,
  helperText,
  InputLabelProps,
  fileInputProps,
  fileButtonInputProps,
  // isMultiSelectError, // ugh, this is not the way
  ...inputFieldProps
}) => (
  <>
    {/* {inputType === 'multiselect' && (
      <FormControl style={{ width: '100%' }} error={isMultiSelectError}>
        <ReactSelect
          id={id}
          label={label}
          InputLabelProps={InputLabelProps}
          options={options}
          isError={isMultiSelectError}
          {...inputFieldProps}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )} */}
    {(inputType === 'text' || inputType === 'select') && (
      <TextField
        id={id}
        fullWidth
        select={inputType === 'select'}
        className={classes.textField}
        variant="outlined"
        label={label}
        {...inputFieldProps}
        style={style}
        helperText={helperText}
        InputLabelProps={InputLabelProps}
      >
        {inputType === 'select' &&
          options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
    )}
    {inputType === 'date' && (
      <DatePicker
        id={id}
        fullWidth
        format="Do MMMM YYYY"
        label={label}
        InputLabelProps={InputLabelProps}
        style={style}
        helperText={helperText}
        {...inputFieldProps}
      />
    )}
    {inputType === 'time' && (
      <TimePicker
        id={id}
        fullWidth
        label={label}
        InputLabelProps={InputLabelProps}
        style={style}
        helperText={helperText}
        {...inputFieldProps}
      />
    )}
    {inputType === 'switch' && (
      <FormControlLabel
        style={style}
        control={<Switch id={id} {...inputFieldProps} />}
        label={label}
      />
    )}
    {inputType === 'file' && (
      <>
        <input id={id} type="file" {...fileInputProps} />
        <label htmlFor={id}>
          <Button component="span" {...fileButtonInputProps}>
            {label}
          </Button>
        </label>
      </>
    )}
    {inputType === 'canvas' && <div style={style} />}
    {inputType === 'button' && <Button style={style} {...inputFieldProps} />}
  </>
);

export default InputField;
