import React from 'react';
import clsx from 'clsx';
import Papa from 'papaparse';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ImportPreview from './CSVImportPreviewList';
import ImportFailed from './CSVImportFailedList';
import ImportUnlicensed from './CSVImportUnlicensedList';
import ImportInstructions from './CSVImportInstructions';
import ErrorMessage from '../ErrorMessage';
import { isValidEmail } from '../../lib/utils';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.2em',
    marginBottom: '0.2em',
    fontWeight: '600',
  },
  fileName: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
  },
  textItalic: {
    fontStyle: 'italic',
  },
  textCenter: {
    textAlign: 'center',
  },
  success: {
    color: 'green',
  },
  failed: {
    color: 'red',
  },
}));

function CSVImport({
  importResults,
  isLoading,
  isOrgActive,
  onSubmit,
  onClearResults,
}) {
  const classes = useStyles();
  const initialValues = {
    file: null,
    csvData: [],
    csvParsingErrors: {},
    imports: {},
  };
  const [values, setValues] = React.useState(initialValues);

  React.useEffect(() => {
    setValues((vals) => ({
      ...vals,
      imports: {
        ...importResults,
      },
    }));
  }, [importResults]);

  const handleFileSubmit = () => {
    if (Array.isArray(values.csvData) && values.csvData.length > 0) {
      onSubmit(values.csvData);
    } else {
      window.alert('No data in the provided CSV file');
    }
  };

  const validateCSVRow = (row, index) => {
    try {
      const { Country, Email, FirstName, LastName, State } = row;

      const countries = [
        {
          name: 'Australia',
          states: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
        },
        { name: 'New Zealand', states: [] },
      ];

      // confirm required fields have a value
      if (!FirstName || !LastName || !Email) {
        return {
          index,
          failReason: 'First name, last name, email are required',
        };
      }

      // confirm email is valid
      if (!isValidEmail(Email)) {
        return {
          index,
          field: 'email',
          failReason: 'Email address is invalid',
        };
      }

      // confirm country is either Australia or New Zealand
      if (Country && !countries.find((c) => c.name === Country)) {
        return {
          index,
          field: 'country',
          failReason: 'Country must either be Australia or New Zealand',
        };
      }

      // confirm valid State provided if country === Australia
      if (
        State &&
        Country === countries[0].name &&
        !countries[0].states.find((_state) => _state === State)
      ) {
        return {
          index,
          field: 'State',
          failReason: 'The State must be a valid Australian State',
        };
      }

      return true;
    } catch (e) {
      return { index, row, failReason: 'Failed to parse data' };
    }
  };

  const handleFileChange = async (event) => {
    const { files } = event.target;
    const file = files[0];

    if (file) {
      onClearResults();
      Papa.parse(file, {
        skipEmptyLines: 'greedy',
        header: true,
        complete({ data }) {
          const dataValid = data.map(validateCSVRow);
          const csvParsingErrors = dataValid
            .filter((r) => r !== true)
            .reduce((acc, cur) => {
              acc[cur.index] = { ...cur };
              return acc;
            }, {});

          setValues((vals) => ({
            ...vals,
            file,
            csvData: data,
            csvParsingErrors,
          }));
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      {!isOrgActive && (
        <Grid item xs={12}>
          <Box
            mt={1}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center">
            <ErrorMessage message="Organisation Inactive. Cannot make changes." />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <div style={{ marginTop: 10 }} />
        <Typography component="h3" variant="h4" className={classes.title}>
          Step 1 - Create the CSV file
        </Typography>

        <ImportInstructions />
      </Grid>
      <Grid item xs={12}>
        <form id="csvImportForm" onSubmit={handleFileSubmit}>
          <Typography component="h3" variant="h4" className={classes.title}>
            Step 2 - Select the CSV File
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center">
            <Button
              variant="contained"
              color="primary"
              component="label"
              disabled={isLoading || !isOrgActive}>
              Select CSV ...
              <input
                type="file"
                name="file"
                onChange={handleFileChange}
                accept=".csv,text/csv"
                style={{ display: 'none' }}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </Button>

            <Typography
              component="span"
              variant="body1"
              className={clsx(
                classes.fileName,
                !values.file && classes.textItalic,
              )}>
              {values.file
                ? values.file.name
                : 'Press the button to choose a file ...'}
            </Typography>
            {values.file && (
              <Typography
                component="span"
                variant="body1"
                style={{ display: 'inline-block', marginLeft: '8px' }}>
                {Object.keys(values.csvParsingErrors).length === 0 ? (
                  <CheckIcon style={{ color: green[500] }} />
                ) : (
                  <CloseIcon style={{ color: red[500] }} />
                )}
              </Typography>
            )}
          </Box>
        </form>
      </Grid>
      <Grid item xs={12}>
        {values.file && (
          <ImportPreview
            errors={values.csvParsingErrors}
            data={values.csvData}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography component="h3" variant="h4" className={classes.title}>
          Step 3 - Process CSV File and Create Members
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !values.file ||
            Object.keys(values.csvParsingErrors).length > 0 ||
            isLoading ||
            !isOrgActive
          }
          form="csvImportForm"
          type="button"
          onClick={handleFileSubmit}>
          Process CSV file
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography component="h3" variant="h4" className={classes.title}>
          Step 4 - Review Import Results
        </Typography>
      </Grid>
      {isLoading && (
        <Grid item xs={12}>
          <Typography
            component="p"
            variant="body1"
            className={classes.textCenter}>
            Importing members ...
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        {typeof values.imports.successful === 'number' && (
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center">
            <Typography
              component="p"
              variant="body1"
              className={clsx(
                values.imports.successful > 0 && classes.success,
                classes.textCenter,
              )}>
              Successful Imports
            </Typography>
            <Typography
              component="p"
              variant="body1"
              className={clsx(
                values.imports.successful > 0 && classes.success,
                classes.textCenter,
              )}>
              {typeof values.imports.successful === 'number'
                ? values.imports.successful
                : 'None'}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        {Array.isArray(values.imports.failedUsers) && (
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center">
            <Typography
              component="p"
              variant="body1"
              className={clsx(
                values.imports.failedUsers.length > 0 && classes.failed,
                classes.textCenter,
              )}>
              Failed Imports
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
              mb={1}>
              <Typography
                component="p"
                variant="body1"
                className={clsx(
                  values.imports.failedUsers.length > 0 && classes.failed,
                  classes.textCenter,
                )}>
                {values.imports.failedUsers.length}
              </Typography>
            </Box>
          </Box>
        )}
        {Array.isArray(values.imports.failedUsers) &&
          values.imports.failedUsers.length > 0 && (
            <ImportFailed data={values.imports.failedUsers || []} />
          )}
      </Grid>
      <Grid item xs={12}>
        {Array.isArray(values.imports.unlicencedUsers) &&
          values.imports.unlicencedUsers.length > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center">
              <Typography
                component="p"
                variant="body1"
                className={clsx(classes.failed, classes.textCenter)}>
                Imported members set as <em>inactive</em> due to insufficient
                license count
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="row"
                alignItems="center"
                mb={1}>
                <Typography
                  component="p"
                  variant="body1"
                  className={clsx(classes.failed, classes.textCenter)}>
                  {values.imports.unlicencedUsers.length}
                </Typography>
              </Box>
            </Box>
          )}
        {Array.isArray(values.imports.unlicencedUsers) &&
          values.imports.unlicencedUsers.length > 0 && (
            <ImportUnlicensed
              data={values.imports.unlicencedUsers || []}
              originalData={values.csvData}
            />
          )}
      </Grid>
    </Grid>
  );
}

export default CSVImport;
