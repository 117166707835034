import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { red, grey } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Check';
import KeyIcon from '@material-ui/icons/VpnKey';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import LicenseCount from './LicenseCount';
import InputField from '../InputField';
import LoadingIndicator from '../LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    // flexGrow: 1,
  },
  title: {
    fontSize: '2em',
  },
  sectionContainer: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  sectionTitle: {
    paddingLeft: 3,
    backgroundColor: '#efefef',
    fontSize: '1.1em',
    fontWeight: '500',
    display: 'block',
  },
  sectionValue: {
    paddingLeft: 3,
    fontSize: '1em',
    display: 'block',
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
}));

export default function Profile({
  organisation,
  isLoading,
  isUpdating,
  onUpdateName,
  onToggleStatus,
  onClickUpdLicense,
  onClickAddAdmin,
  onClickChangePassword,
}) {
  const classes = useStyles();

  const initialState = {
    isActive: false,
    isEditName: false,
    orgName: '',
  };

  const [values, setValues] = React.useState(initialState);

  React.useEffect(() => {
    if (typeof organisation.active === 'boolean') {
      setValues((vals) => ({
        ...vals,
        isActive: organisation.active,
        orgName: organisation.name,
      }));
    }
  }, [organisation]);

  const handleSwitchStatus = () => {
    if (values.isActive) {
      const confirm = window.confirm(
        'Are you sure you want to disable this organisation? This will also disable any members under the organisation.',
      );
      if (!confirm) {
        return;
      }
    }

    onToggleStatus(!values.isActive);
    setValues((vals) => ({
      ...vals,
      isActive: !vals.isActive,
    }));
  };

  const handleSaveName = () => {
    if (values.orgName !== organisation.name) {
      onUpdateName(String(values.orgName).trim());
    }
    setValues((vals) => ({ ...vals, isEditName: !vals.isEditName }));
  };

  const handleToggleEditName = () => {
    setValues((vals) => ({ ...vals, isEditName: !vals.isEditName }));
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    setValues((vals) => ({ ...vals, orgName: value }));
  };

  const editTextFieldProps = {
    inputType: 'text',
    id: 'name',
    label: 'Name',
    value: values.orgName,
    name: 'name',
    margin: 'dense',
    onChange: handleNameChange,
    placeholder: 'Organisation name',
    InputLabelProps: {
      shrink: true,
    },
    disabled: isLoading || isUpdating,
    autoFocus: true,
    error: values.isSubmitted && String(values.orgName).trim().length === 0,
    helperText:
      values.isSubmitted && String(values.orgName).trim().length === 0
        ? 'A name is required'
        : '',
  };

  if (isLoading || Object.keys(organisation).length === 0)
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={2}>
        <LoadingIndicator size={25} />
      </Box>
    );

  const hasAdmins =
    Array.isArray(organisation.admins) && organisation.admins.length > 0;

  return (
    <Grid container className={classes.gridRoot} spacing={1}>
      <Box mt={4} width="100%" />

      <Grid item xs={12} md={12}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          style={{ height: 52 }}>
          {values.isEditName && (
            <div>
              <InputField {...editTextFieldProps} classes={classes} />
            </div>
          )}
          {!values.isEditName && (
            <div>
              <Typography variant="h1" className={classes.title}>
                {values.orgName}
              </Typography>
            </div>
          )}
          <IconButton
            color="primary"
            aria-label="edit name"
            component="span"
            disabled={isUpdating}
            onClick={values.isEditName ? handleSaveName : handleToggleEditName}>
            {!values.isEditName && <EditIcon />}
            {values.isEditName && <SaveIcon />}
          </IconButton>
        </Box>
      </Grid>

      <Grid item xs={12} md={8}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div
              style={{
                border: '1px solid',
                borderColor: values.isActive ? grey[100] : red[200],
                padding: 10,
                backgroundColor: values.isActive ? '#fff' : red[50],
              }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center">
                <div>
                  <Typography variant="body1">Account Status: </Typography>
                </div>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Box display="flex" flexDirection="column">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          onChange={handleSwitchStatus}
                          name="active"
                          color="primary"
                          disabled={isUpdating}
                        />
                      }
                      label={
                        <Box>
                          <Typography
                            variant="body1"
                            style={{
                              marginRight: 4,
                              color:
                                organisation.active === false
                                  ? red[700]
                                  : '#222',
                            }}>
                            {organisation.active ? 'Active' : 'Inactive'}
                          </Typography>
                        </Box>
                      }
                    />
                    <Typography variant="caption">
                      (Click to toggle ...)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                border: '1px solid',
                borderColor: grey[100],
                padding: 10,
              }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center">
                <div>
                  <Typography variant="body1">Admin: </Typography>
                </div>
                {hasAdmins && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center">
                    <Typography variant="body1" style={{ marginRight: 10 }}>
                      {organisation.admins
                        .map(
                          (adm) =>
                            `${adm.firstName} ${adm.lastName} (${adm.email})`,
                        )
                        .join(', ')}
                    </Typography>
                    <Tooltip title="Change admin password" placement="top">
                      <IconButton onClick={onClickChangePassword}>
                        <KeyIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                {!hasAdmins && (
                  <Button
                    variant="outlined"
                    disabled={isUpdating}
                    color="primary"
                    onClick={onClickAddAdmin}>
                    <AddIcon className={classes.rightMargin} />
                    Add Admin
                  </Button>
                )}
              </Box>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                border: '1px solid',
                borderColor: grey[100],
                padding: 10,
              }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center">
                <div>
                  <Typography variant="body1">Account Created: </Typography>
                </div>
                <div>
                  <Typography variant="body1">
                    {moment(organisation.createdOn).format('DD MMM YYYY')}
                  </Typography>
                </div>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <LicenseCount
          disabled={isUpdating}
          licenses={organisation.licences}
          activeMembers={organisation.activeMembers}
          members={organisation.members}
          onClickUpdate={onClickUpdLicense}
        />
      </Grid>
    </Grid>
  );
}
