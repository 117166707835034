import { createSelector } from 'reselect';
import {
  API_GET_PROGRAM_PLAYLIST_REQ,
  API_DEL_PROGRAM_FILE_REQ,
  API_GET_PROGRAM_SCHEDULES_REQ,
} from '../actions/types';

/* Playlist selectors */
const deleteProgramFileDialogOpenSelector = (state) =>
  state.programs.dialogs.deleteProgramFile;
export const selectIsDeleteProgramFileDialogOpen = createSelector(
  [deleteProgramFileDialogOpenSelector],
  (isOpen) => isOpen,
);

const addProgramFileDialogOpenSelector = (state) =>
  state.programs.dialogs.addProgramFile;
export const selectIsAddProgramFileDialogOpen = createSelector(
  [addProgramFileDialogOpenSelector],
  (isOpen) => isOpen,
);

const programsSelector = (state) => {
  return state.programs;
};
export const selectPrograms = createSelector(
  [programsSelector],
  (programs) => programs,
);

const programsPlaylistsTableSelector = (state) => {
  return state.programs.playlist;
};
export const selectProgramsPlaylistTableData = createSelector(
  [programsPlaylistsTableSelector],
  (playlist) => playlist,
);

const isFetchingProgramsPlaylistSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_GET_PROGRAM_PLAYLIST_REQ,
  );
};
export const selectIsFetchingProgramsPlaylist = createSelector(
  [isFetchingProgramsPlaylistSelector],
  (isFetching) => isFetching,
);

const isDeletingProgramFileSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_DEL_PROGRAM_FILE_REQ,
  );
};
export const selectIsDeletingProgramFile = createSelector(
  [isDeletingProgramFileSelector],
  (isDeleting) => isDeleting,
);

/* Weekly Schedule selectors */
const programsScheduleTableSelector = (state) => {
  const _schedules = Object.keys(state.programs.schedules);
  const schedules = _schedules.map((location) => ({
    location,
    label:
      location === 'qld' ? 'Eastern Standard Time' : 'Daylight Saving Time',
    url: state.programs.schedules[location].url,
    id: state.programs.schedules[location].id,
  }));
  return schedules;
};
export const makeSelectProgramsScheduleTableData = () =>
  createSelector([programsScheduleTableSelector], (schedule) => schedule);

const isFetchingProgramsScheduleSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_GET_PROGRAM_SCHEDULES_REQ,
  );
};
export const selectIsFetchingProgramsSchedule = createSelector(
  [isFetchingProgramsScheduleSelector],
  (isFetching) => isFetching,
);

const uploadScheduleDialogOpenSelector = (state) =>
  state.programs.dialogs.uploadSchedule;
export const selectIsUploadScheduleDialogOpen = createSelector(
  [uploadScheduleDialogOpenSelector],
  (isOpen) => isOpen,
);

const createCulturalCategoryDialogOpenSelector = (state) =>
  state.programs.dialogs.createCulturalCategory;
export const selectCreateCulturalCategoryDialogOpen = createSelector(
  [createCulturalCategoryDialogOpenSelector],
  (isOpen) => isOpen,
);

const updateCulturalCategoryDialogOpenSelector = (state) =>
  state.programs.dialogs.updateCulturalCategory;
export const selectUpdateCulturalCategoryDialogOpen = createSelector(
  [updateCulturalCategoryDialogOpenSelector],
  (isOpen) => isOpen,
);

const deleteCulturalCategoryDialogOpenSelector = (state) =>
  state.programs.dialogs.deleteCultureCategory;
export const selectDeleteCulturalCategoryDialogOpen = createSelector(
  [deleteCulturalCategoryDialogOpenSelector],
  (isOpen) => isOpen,
);

const deleteCulturalSongDialogOpenSelector = (state) =>
  state.programs.dialogs.deleteCulturalSong;
export const selectDeleteCulturalSongDialogOpen = createSelector(
  [deleteCulturalSongDialogOpenSelector],
  (isOpen) => isOpen,
);

const addCulturalCategorySongsDialogOpenSelector = (state) =>
  state.programs.dialogs.addCulturalCategorySongs;
export const selectAddCulturalCategorySongsDialogOpen = createSelector(
  [addCulturalCategorySongsDialogOpenSelector],
  (isOpen) => isOpen,
);
