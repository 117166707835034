import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PasswordIcon from '@material-ui/icons/VpnKey';
import PersonIcon from '@material-ui/icons/Person';
import Page from '../pages/PageV2';
import AdminProfile from '../containers/Organisation/AdminProfile';
import ChangeAdminPasswordDialog from '../containers/Organisation/ChangeAdminPasswordDialog';
import { toggleUpdateAdminPasswordDialog } from '../lib/store/actions/organisation';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function MembersListPage({ onToggleDialog }) {
  const classes = useStyles();

  const pageMenuItems = [
    {
      title: (
        <>
          <PasswordIcon className={classes.icon} /> Change my password
        </>
      ),
      onClick: onToggleDialog,
    },
  ];

  return (
    <Page
      title={
        <>
          <PersonIcon className={classes.icon} /> My Profile
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <ChangeAdminPasswordDialog />
      <AdminProfile />
    </Page>
  );
}

export default compose(
  withRouter,
  connect(null, { onToggleDialog: toggleUpdateAdminPasswordDialog }),
)(MembersListPage);
