import React from 'react';
import FsLightbox from 'fslightbox-react';

function LightBox({ toggler, images, openIndex }) {
  return (
    <FsLightbox toggler={toggler} sources={images} sourceIndex={openIndex} />
  );
}

export default LightBox;
