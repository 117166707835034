/* eslint-disable arrow-body-style */
import * as types from './types';
import { quizzesApi } from '../../api';
import { apiRequest } from './api-request';
import { showNotification } from './notifications';
import { NOTIFICATION_TYPES } from '../../constants/notification-types';
import { getAuthToken, checkHttpError } from './authTokenHelper';

export const setDeleteQuizDialog = (isOpen) => ({
  type: types.TOGGLE_DELETE_QUIZ_DIALOG,
  isOpen,
});

export const setEditQuizNameDialog = (isOpen) => ({
  type: types.TOGGLE_EDIT_QUIZ_NAME_DIALOG,
  isOpen,
});

export const setAddQuizzesDialog = (isOpen) => ({
  type: types.TOGGLE_ADD_QUIZZES_DIALOG,
  isOpen,
});

export const getQuizzes = (callback) => (dispatch, getState) => {
  const apiReqAction = apiRequest({
    requestType: types.API_GET_QUIZZES_REQ,
    message: 'Loading quizzes',
  });
  dispatch(apiReqAction);
  quizzesApi
    .getQuizzes(getAuthToken(getState()))
    .then(
      (quizzes) => {
        const _quizzes = quizzes
          ? quizzes.map((q) => ({ id: q.id, name: q.fileName, url: q.url }))
          : [];

        dispatch({
          type: types.GET_QUIZZES_OK,
          quizzes: _quizzes,
          apiReqId: apiReqAction.id,
        });
        if (callback && typeof callback === 'function') callback(_quizzes);
      },
      (error) => dispatch(checkHttpError(error, apiReqAction.id)),
    )
    .catch((error) => {
      dispatch(
        showNotification({
          message: `Get quizzes: ${error.message}` || 'Cannot get quizzes',
          options: {
            variant: NOTIFICATION_TYPES.Warning,
          },
        }),
      );
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
    });
};

export const deleteQuiz =
  ({ fileId }, successCB) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_DEL_QUIZZES_REQ,
      message: 'Deleting quiz',
    });
    dispatch(apiReqAction);

    quizzesApi
      .deleteQuiz(getAuthToken(getState()), { fileId })
      .then(
        () => {
          dispatch({
            type: types.DELETE_QUIZZES_OK,
            fileId,
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'Quiz successfully deleted',
              options: {
                variant: NOTIFICATION_TYPES.Success,
                autoHideDuration: 2000,
              },
            }),
          );
          if (successCB && typeof successCB === 'function') successCB();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message: `Delete quiz: ${error.message}` || 'Cannot delete quiz',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };

export const updateQuizName =
  ({ id, name }, successCB) =>
  (dispatch, getState) => {
    const apiReqAction = apiRequest({
      requestType: types.API_UPD_QUIZZES_REQ,
      message: 'Updating name',
    });
    dispatch(apiReqAction);

    quizzesApi
      .updateQuizName(getAuthToken(getState()), { id, name })
      .then(
        () => {
          dispatch({
            type: types.UPDATE_QUIZ_NAME_OK,
            quiz: { id, name },
            apiReqId: apiReqAction.id,
          });
          dispatch(
            showNotification({
              message: 'Quiz name updated',
              options: {
                variant: NOTIFICATION_TYPES.Success,
              },
            }),
          );
          if (successCB && typeof successCB === 'function') successCB();
        },
        (error) => dispatch(checkHttpError(error, apiReqAction.id)),
      )
      .catch((error) => {
        dispatch(
          showNotification({
            message:
              `Update quiz name: ${error.message}` || 'Cannot update quiz name',
            options: {
              variant: NOTIFICATION_TYPES.Warning,
            },
          }),
        );
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id });
      });
  };
