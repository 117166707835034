import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import Page from './PageV2';
import HymnsList from '../containers/Hymns/HymnsList';
import { setAddHymnsFileDialog } from '../lib/store/actions/hymns';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function HymnsPage({ onSetAddHymnsFileDialog }) {
  const classes = useStyles();

  const pageMenuItems = [
    {
      title: (
        <>
          <AddIcon className={classes.icon} /> Add Hymns ...
        </>
      ),
      onClick: () => onSetAddHymnsFileDialog(true),
    },
  ];

  return (
    <Page
      title={
        <>
          <QueueMusicIcon className={classes.icon} /> Our Favourite Hymns
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <HymnsList />
    </Page>
  );
}

export default connect(null, {
  onSetAddHymnsFileDialog: setAddHymnsFileDialog,
})(HymnsPage);
