import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import GamesIcon from '@material-ui/icons/Games';
import Page from './PageV2';
import Alert from '../components/Alert';
import LyricsList from '../containers/Lyrics/LyricsList';
// import { setAddLyricsDialog } from '../lib/store/actions/lyrics';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

// function LyricsPage({ onSetAddLyricsDialog }) {
function LyricsPage() {
  const classes = useStyles();

  const pageMenuItems = [
    {
      title: (
        <>
          <AddIcon className={classes.icon} /> Add Lyric(s) - DISABLED
        </>
      ),
      onClick: () => console.log('DISABLED'),
    },
  ];

  return (
    <Page
      title={
        <>
          <GamesIcon className={classes.icon} /> Lyrics
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 9,
      }}>
      <Alert severity="warning">
        <strong>WARNING:</strong> Lyrics are no longer available on the new
        version of the tablet app. This page is here for legacy reasons and will
        be removed with the next release of the Admin Dashboard. New uploads
        have been disabled.
      </Alert>
      <LyricsList />
    </Page>
  );
}

export default connect(null, {
  // onSetAddLyricsDialog: setAddLyricsDialog,
})(LyricsPage);
