import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Fade from '@material-ui/core/Fade';
import LoadingIndicator from './LoadingIndicator';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  submitIcon: {
    margin: theme.spacing(0, 1),
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  progressText: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(1),
  },
}));

function DeleteDialog({
  isOpen,
  onCancel,
  onConfirm,
  confirmationBody,
  confirmationBtnText,
  isInProgress,
  inProgressMessage,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmation Required</DialogTitle>
        <DialogContent>
          <Typography variant="body1" component="p">
            {confirmationBody}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Fade in={isInProgress}>
            <Box
              width="40%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <LoadingIndicator size={16} />
              <Typography variant="body2" component="span" className={classes.progressText}>
                {inProgressMessage}
              </Typography>
            </Box>
          </Fade>
          <Button variant="contained" color="primary" onClick={onCancel} disabled={isInProgress}>
            <ClearIcon className={classes.rightMargin} />
            Cancel
          </Button>
          <Button variant="outlined" color="primary" onClick={onConfirm} disabled={isInProgress}>
            <CheckCircleIcon className={classes.rightMargin} />
            {confirmationBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteDialog;
