import React from "react";
import { compose } from "recompose";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Title from "../components/Title";
import ProtectComponent from "../containers/Protected";
import { withDashboardLayout } from "../containers/DashboardLayout";
import { USER_ROLES } from "../lib/constants/user-roles";

const useStyles = makeStyles((theme) => ({
  gridListroot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    padding: theme.spacing(2),
  },
  gridList: {
    maxWidth: "80%",
  },
  gridListTileBarRoot: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  gridListTileBarTitle: {
    color: "#222222",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  menuButton: {
    marginLeft: "auto",
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function Page({
  title,
  menuItems,
  children,
  gridContainerProps,
  gridItemProps,
  isMenuDisabled,
}) {
  const _isMounted = React.useRef(true);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const classes = useStyles();

  React.useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const handleOpenMenu = (event) => {
    if (_isMounted.current) setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    if (_isMounted.current) setMenuAnchor(null);
  };

  const handleClickMenuItem = (callback) => () => {
    callback();
    handleCloseMenu();
  };

  return (
    <Grid container {...gridContainerProps}>
      <Grid item xs={12} {...gridItemProps}>
        <Paper className={classes.paper}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            direction="row"
          >
            <Grid item className={classes.centerContent}>
              <Title>{title}</Title>
            </Grid>
            {menuItems && (
              <Grid item>
                <Tooltip title="More Options" placement="top">
                  <div>
                    <IconButton
                      disabled={isMenuDisabled}
                      aria-label="More"
                      aria-controls="more-options-menu"
                      aria-haspopup="true"
                      onClick={handleOpenMenu}
                    >
                      <MenuIcon fontSize="large" />
                    </IconButton>
                  </div>
                </Tooltip>
                <Menu
                  id="more-options-menu"
                  anchorEl={menuAnchor}
                  keepMounted
                  open={Boolean(menuAnchor)}
                  onClose={handleCloseMenu}
                >
                  {menuItems.map((menu, i) => (
                    <MenuItem
                      key={i}
                      onClick={handleClickMenuItem(menu.onClick)}
                    >
                      {menu.title}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            )}
          </Grid>
          <Divider />
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default compose(
  ProtectComponent((userRole) => userRole === USER_ROLES.ADMIN),
  withDashboardLayout
)(Page);
