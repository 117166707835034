import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Dialog from '../../../components/Organisation/ChangeOrgUserPasswordDialog';
import {
  updateOrgMemberPassword,
  toggleUpdateMemberPasswordDialog,
} from '../../../lib/store/actions/organisation';
import {
  selectIsChangeMemberPasswordDialogOpen,
  selectIsUpdatingOrgMemberPassword,
  selectOrgMemberPasswordReset,
  makeSelectIsOrgActive,
} from '../../../lib/store/selectors/organisation';

const ChangeOrgMemberPasswordDialog = ({
  isOpen,
  isUpdating,
  member,
  isOrgActive,
  onUpdatePassword,
  onToggleDialog,
}) => {
  const handleUpdateMember = ({ password }) => {
    if (!member) return;

    onUpdatePassword({
      memberId: member.id,
      password,
      callback: () => {
        onToggleDialog(null);
      },
    });
  };

  const handleClose = () => {
    onToggleDialog(null);
  };

  return (
    <Dialog
      isUpdating={isUpdating}
      isOpen={isOpen}
      isOrgActive={isOrgActive}
      name={member ? member.name : ''}
      onCancel={handleClose}
      onSubmit={handleUpdateMember}
    />
  );
};

const mapStateToProps = (state) => {
  const selectIsOrgActive = makeSelectIsOrgActive();
  return {
    member: selectOrgMemberPasswordReset(state),
    isOpen: selectIsChangeMemberPasswordDialogOpen(state),
    isOrgActive: selectIsOrgActive(state),
    isUpdating: selectIsUpdatingOrgMemberPassword(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    onUpdatePassword: updateOrgMemberPassword,
    onToggleDialog: toggleUpdateMemberPasswordDialog,
  }),
)(ChangeOrgMemberPasswordDialog);
