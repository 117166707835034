import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import TheaterComedyIcon from '@material-ui/icons/Theaters'; //TODO: Find an icon, in the latest library it can be imported like import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import Page from '../../PageV2';
import CategoryPage from '../../../containers/Programs/Cultural/CulturalCategory';
import { setCreateCulturalCategoryDialog } from '../../../lib/store/actions/programs';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function CulturalProgramsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const pageMenuItems = [
    {
      title: (
        <>
          <AddIcon className={classes.icon} /> Create New Category
        </>
      ),
      onClick: () => dispatch(setCreateCulturalCategoryDialog(true)),
    },
  ];

  return (
    <Page
      title={
        <>
          <TheaterComedyIcon className={classes.icon} /> Cultural Programs
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gridItemProps={{
        xs: 12,
        lg: 8,
      }}>
      <CategoryPage />
    </Page>
  );
}

export default CulturalProgramsPage;
