import { gql } from '@apollo/client';

export const GET_PLAYLIST = gql`
  query Playlist($playlistId: ID!) {
    playlist(playlist_id: $playlistId) {
      id
      Name
      ArtworkURL
      files {
        id
        Url
        Title
        CreatedOn
        Artist
      }
    }
  }
`;

export const GET_PLAYLIST_NAME_AND_ARTWORK = gql`
  query Playlist($playlistId: ID!) {
    playlist(playlist_id: $playlistId) {
      id
      Name
      ArtworkURL
    }
  }
`;
