import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(0),
    color: blue[500],
  },
}));

export default function LoadingIndicator(props) {
  const classes = useStyles();

  return <CircularProgress className={classes.progress} {...props} />;
}
