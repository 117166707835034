import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: red[50],
    borderColor: red[100],
    borderRadius: 4,
    border: '1px solid',
    textAlign: 'center',
    padding: 5,
  },
  message: {
    color: red[800],
  },
}));

function ErrorMessage({ message }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="body1" className={classes.message}>
        {message}
      </Typography>
    </Box>
  );
}

export default ErrorMessage;
