import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Profile from '../../components/SMOrganisations/Profile';
import {
  EDIT_ORG,
  TOGGLE_ORG_ACTIVE_STATUS,
} from '../../lib/api/graphql/mutations/organizations';
import { GET_ORGANIZATION } from '../../lib/api/graphql/queries/organizations';
import { DIALOG_TYPES } from '../../lib/constants/dialog-types';
import { NOTIFICATION_TYPES } from '../../lib/constants/notification-types';
import { showNotification } from '../../lib/store/actions/notifications';
import { setDialog } from '../../lib/store/reducers/dialog';
import AddAdminDialog from './AddAdminDialog';
import ChangeAdminPasswordDialog from './ChangeAdminPasswordDialog';
import UpdLicenseDialog from './UpdLicenseDialog';

const SMOrgProfile = ({ match }) => {
  const dispatch = useDispatch();
  const {
    data,
    loading: isLoadingV2,
    refetch,
  } = useQuery(GET_ORGANIZATION, {
    variables: {
      orgId: match.params.id,
    },
    onError: () => {
      dispatch(
        showNotification({
          options: { variant: NOTIFICATION_TYPES.Error },
          message: 'Error while fetching organisation details',
        }),
      );
    },
  });

  const org = data?.organization || {};

  const organisation = org
    ? {
        active: org.Active,
        activeMembers: org.active_count || 0,
        admins:
          org.admins?.map((a) => ({
            email: a?.Email,
            id: a?.id,
            firstName: a?.FirstName,
            lastName: a?.LastName,
          })) || [],
        createdOn: org.CreatedOn,
        licences: org.Licences,
        id: org.id,
        members: org.member_count || 0,
        name: org.Name,
      }
    : {};

  const [toggleActiveStatus, { loading: isTogglingStatus }] = useMutation(
    TOGGLE_ORG_ACTIVE_STATUS,
    {
      onCompleted: async () => {
        await refetch();
      },
      onError: () => {
        dispatch(
          showNotification({
            options: { variant: NOTIFICATION_TYPES.Error },
            message: 'Error changing active status',
          }),
        );
      },
    },
  );

  const [editOrg, { loading: isEditingName }] = useMutation(EDIT_ORG, {
    onCompleted: async () => {
      await refetch();
    },
    onError: () => {
      dispatch(
        showNotification({
          options: { variant: NOTIFICATION_TYPES.Error },
          message: 'Error editing organisation details',
        }),
      );
    },
  });

  const handleUpdateName = async (name) => {
    await editOrg({
      variables: {
        orgId: organisation.id,
        name,
        licences: organisation.licences,
      },
    });
  };

  const handleToggleStatus = async () => {
    await toggleActiveStatus({
      variables: {
        orgId: match.params.id,
      },
    });
  };

  const handleUpdateLicense = () => {
    dispatch(
      setDialog({
        open: true,
        dialog: DIALOG_TYPES.UPDATE_ORG_LICENSE,
        params: { organisation },
      }),
    );
  };

  const handleAddAdmin = () => {
    dispatch(
      setDialog({
        open: true,
        dialog: DIALOG_TYPES.ADD_ADMIN,
      }),
    );
  };

  const handleChangeAdminPassword = () => {
    dispatch(
      setDialog({
        open: true,
        dialog: DIALOG_TYPES.UPDATE_USER_PASSWORD,
        params: { organisation },
      }),
    );
  };

  return (
    <>
      <ChangeAdminPasswordDialog />
      <AddAdminDialog />
      <UpdLicenseDialog />
      <Profile
        organisation={organisation}
        isLoading={isLoadingV2}
        isUpdating={isEditingName || isTogglingStatus}
        onUpdateName={handleUpdateName}
        onToggleStatus={handleToggleStatus}
        onClickUpdLicense={handleUpdateLicense}
        onClickAddAdmin={handleAddAdmin}
        onClickChangePassword={handleChangeAdminPassword}
      />
    </>
  );
};

export default compose(withRouter)(SMOrgProfile);
