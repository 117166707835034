import React from 'react';
import { connect } from 'react-redux';
import Profile from '../../components/Organisation/AdminProfile';
import { makeSelectUserDetails } from '../../lib/store/selectors/user';

import { selectOrgDetails } from '../../lib/store/selectors/organisation';

import { useGetOrgDetails } from './useGetOrgDetails.js';

function AdminProfile({ user, organisation }) {
  const { loading: gettingOrgDetails } = useGetOrgDetails();

  return (
    <Profile
      admin={user}
      organisation={organisation}
      isLoading={gettingOrgDetails}
    />
  );
}

const mapStateToProps = (state) => {
  const selectUserDetails = makeSelectUserDetails();
  return {
    user: selectUserDetails(state),
    organisation: selectOrgDetails(state),
  };
};

export default connect(mapStateToProps)(AdminProfile);
