import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Gallery from 'react-photo-gallery';
import Lightbox from '../LightBox';
import LoadingIndicator from '../LoadingIndicator';
import SelectedImage from './SelectedImage';
import UploadFileList from '../../containers/SightNSound/UploadImage/UploadFileList';

const styles = (theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1.5),
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  centerText: {
    textAlign: 'center',
  },
});

const ImageAlbum = ({
  isLoading,
  isDeleteImageMode,
  onCancelDeleteImageMode,
  classes,
  onCompleteUploadCB,
  onSelectImage,
  images,
  onDeleteImages,
  onEditImage,
  albumId,
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [lightboxToggler, setLightboxToggler] = useState(false);

  const onOpenLightbox = (_, obj) => {
    setLightboxToggler(!lightboxToggler);
    setImageIndex(obj.index);
  };

  const galleryProps = {
    onClick: isDeleteImageMode ? onSelectImage : onOpenLightbox,
    renderImage: (props) => (
      <SelectedImage
        {...props}
        isDeleteImageMode={isDeleteImageMode}
        onEdit={onEditImage}
      />
    ),
  };

  const isEmptyAlbum =
    Array.isArray(images) && images.length === 0 && !isLoading;
  const hasImages = Array.isArray(images) && images.length > 0;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Box component="div" className={classes.centerContent} mt={2}>
            <UploadFileList onComplete={onCompleteUploadCB} albumId={albumId} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Box mt={2}>
            <Box mb={2} display="flex" justifyContent="flex-end">
              <Box mr={1} component="span">
                <Button
                  disabled={!isDeleteImageMode}
                  variant="contained"
                  color="primary"
                  onClick={onDeleteImages}>
                  <DeleteIcon className={classes.icon} />
                  Delete Selected Images
                </Button>
              </Box>
              <Box component="span">
                <Button
                  disabled={!isDeleteImageMode}
                  color="primary"
                  onClick={onCancelDeleteImageMode}>
                  <ClearIcon className={classes.icon} />
                  Cancel
                </Button>
              </Box>
            </Box>
            {hasImages && (
              <>
                <Gallery
                  photos={images}
                  direction="column"
                  columns={5}
                  {...galleryProps}
                />
                <Lightbox
                  images={images.map((img) => img.src)}
                  openIndex={imageIndex}
                  toggler={lightboxToggler}
                />
              </>
            )}
            {isLoading && (
              <Box className={classes.loadingBox}>
                <LoadingIndicator size={26} />
              </Box>
            )}
            {isEmptyAlbum && (
              <Box className={classes.loadingBox}>
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.centerText}>
                  Looks like this image album is empty.
                  <br />
                  Simply drag and drop some images into the{' '}
                  <strong>File Drop Zone</strong> to upload them :)
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(ImageAlbum);
