import React from "react";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/en-au";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { green, lightBlue, purple, red } from "@material-ui/core/colors";
import { MESSAGE_TYPES } from "../../lib/constants";
// import CardActions from '@material-ui/core/CardActions';
// import ChatIcon from '@material-ui/icons/ChatBubbleOutline';
// import IconButton from '@material-ui/core/IconButton';

moment.locale("en-au");

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  cardMessage: {
    backgroundColor: lightBlue[50],
  },
  cardSong: {
    backgroundColor: purple[50],
  },
  cardBirthday: {
    backgroundColor: green[50],
  },
  broadcast: {
    backgroundColor: "#724A82",
    // color: "#FFFFFF",
    color: theme.palette.primary.contrastText,
    marginBottom: 0,
  },

  autoResponse: {
    backgroundColor: "#315B66",
    // color: "#FFFFFF",
    color: theme.palette.primary.contrastText,
    marginBottom: 0,
  },

  avatarMessage: {
    backgroundColor: lightBlue[500],
  },
  avatarSong: {
    backgroundColor: purple[500],
  },
  avatarBirthday: {
    backgroundColor: green[500],
  },
  unread: {
    borderLeftColor: red[500],
    borderLeftWidth: 8,
    borderLeft: "solid",
  },
  // media: {
  //   height: 0,
  //   paddingTop: '56.25%', // 16:9
  // },
  // threadChatCount: {
  //   fontSize: '0.7em',
  //   display: 'inline-block',
  //   marginLeft: theme.spacing(1),
  // },
}));

function Post({
  type,
  read,
  body,
  createdOn,
  user,
  showUser = true,
  customSubHeader,
  bodyTextStyles = {},
}) {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.card, {
        [classes.cardMessage]: type === MESSAGE_TYPES.message,
        [classes.cardSong]: type === MESSAGE_TYPES.birthday,
        [classes.cardBirthday]: type === MESSAGE_TYPES.song,
        [classes.broadcast]: type === MESSAGE_TYPES.broadcast,
        [classes.autoResponse]: type === MESSAGE_TYPES.autoResponse,
        [classes.unread]: !read,
      })}
    >
      <CardHeader
        avatar={
          !showUser ? undefined : (
            <Avatar
              aria-label="recipe"
              className={clsx({
                [classes.avatarMessage]: type === MESSAGE_TYPES.message,
                [classes.avatarSong]: type === MESSAGE_TYPES.birthday,
                [classes.avatarBirthday]: type === MESSAGE_TYPES.song,
              })}
            >
              {user?.firstName && user?.lastName
                ? `${String(user.firstName).charAt(0)}${String(
                    user.lastName
                  ).charAt(0)}`
                : "DU"}
            </Avatar>
          )
        }
        title={
          !showUser
            ? undefined
            : user?.firstName && user?.lastName
            ? `${user.firstName} ${user.lastName}`
            : "Demo User"
        }
        subheader={
          customSubHeader
            ? customSubHeader
            : createdOn
            ? `Posted: ${moment(createdOn).calendar()}`
            : ""
        }
      />
      {/* 
      EXAMPLE SHOW IMAGE IN POST:
      <CardMedia
        className={classes.media}
        image="https://images.pexels.com/photos/2208836/pexels-photo-2208836.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        title="Paella dish"
      /> */}
      <CardContent>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          style={{ ...bodyTextStyles }}
        >
          {body}
        </Typography>
      </CardContent>
      {/* 
      EXAMPLE SHOW BUTTON TO VIEW MESSAGE THREAD:
      <CardActions disableSpacing>
        <IconButton aria-label="Open chat thread">
          <ChatIcon /> <span className={classes.threadChatCount}>5</span>
        </IconButton>
      </CardActions> */}
    </Card>
  );
}

export default Post;
