import { createSelector } from 'reselect';
import moment from 'moment';
import { API_GET_MEMBER_REQ } from '../actions/types';

const membersTableSelector = (state) => {
  return state.members.list.map((m) => {
    return {
      id: m.id,
      email: m.email,
      name: `${m.firstName} ${m.lastName}`,
      memberSince: moment(m.createdOn).format('Do MMM YYYY'),
      location: `${m.location.country}, ${
        m.location.country === 'Australia'
          ? `${m.location.state}, ${m.location.postcode}`
          : m.location.postcode
      }`,
      isSubscriptionActive: m.isSubscriptionActive,
      subscriptionStatus: m.subscriptionStatus,
      product: m.product,
      platform: m.platform,
    };
  });
};
const membersTablePaginationSelector = (state) => {
  return state.members.pagination;
};

const isFetchingMemberSelector = (state) => {
  return !!state.apiRequest.find(
    (req) => req.requestType === API_GET_MEMBER_REQ,
  );
};

const getCurrentMemberSelector = (state) => {
  return state.members.selected;
};

export const selectMembersTableData = createSelector(
  [membersTableSelector, membersTablePaginationSelector],
  (members, pagination) => {
    return {
      members,
      pagination,
    };
  },
);

export const selectIsFetchingMember = createSelector(
  [isFetchingMemberSelector],
  (isFetching) => isFetching,
);

export const selectCurrentMember = createSelector(
  [getCurrentMemberSelector],
  (member) => member,
);
