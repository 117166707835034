/* eslint-disable no-nested-ternary */
import React from 'react';
import { Grid, Typography, Box, Divider, Chip } from '@material-ui/core';
import ErrorMessage from '../ErrorMessage';
import Loading from '../LoadingIndicator';

function Label({ name }) {
  return (
    <Typography component="span" variant="caption">
      {String(name).toUpperCase()}
    </Typography>
  );
}

function Value({ name }) {
  if (typeof name === 'function') return name();
  return (
    <Typography component="span" variant="body1">
      {name}
    </Typography>
  );
}

function Item({ label, value }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      my={2}
    >
      <div>
        <Label name={label} />
      </div>
      <div>
        <Value name={value} />
      </div>
    </Box>
  );
}

function Chippy({ active }) {
  return (
    <Chip
      size="small"
      label={active ? 'Active' : 'In-Active'}
      color={active === true ? 'primary' : 'secondary'}
      style={{ marginLeft: 8 }}
    />
  );
}

function AdminProfile({ admin, organisation, isLoading }) {
  if (isLoading) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            mt={1}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Loading size={20} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={false} md={3} />
      <Grid item xs={12} md={6}>
        <Grid container spacing={0}>
          {!organisation.orgActive && (
            <Grid item xs={12}>
              <Box mt={1} />
              <ErrorMessage message="Organisation Inactive. Cannot make changes." />
            </Grid>
          )}
          <Grid item xs={12}>
            <Item label="Name" value={`${admin.firstName} ${admin.lastName}`} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Item label="Email" value={admin.email} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Item
              label="Organisation"
              value={() => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography component="span" variant="body1">
                    {organisation.name}
                  </Typography>
                  <Chippy active={organisation.orgActive} />
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AdminProfile;
