import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import defaultAvatar from '../../assets/images/user-avatar.png';
import InputField from '../InputField';

const useStyles = makeStyles((theme) => ({
  chip: {
    alignSelf: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  fontBold: {
    fontWeight: 'bold',
  },
}));

function MembersTableRow({
  id,
  name,
  email,
  created,
  active,
  onClickMember,
  onToggleActiveStatus,
  isUpdatingUserStatus,
}) {
  const classes = useStyles();

  const handleClick = () => {
    onClickMember(id);
  };

  const handleToggleActiveStatus = () => {
    if (
      window.confirm(
        `Are you sure you want to change ${name}'s account status to ${
          active ? 'inactive' : 'active'
        }?`,
      )
    ) {
      onToggleActiveStatus({ userId: id, active: !active });
    }
  };

  return (
    <>
      <TableCell component="th" scope="row">
        <Tooltip title={`View/Edit ${name}'s profile`} placement="right">
          <Button
            color="primary"
            className={classes.button}
            onClick={handleClick}>
            <Avatar alt={name} src={defaultAvatar} className={classes.avatar} />
            <span className={classes.fontBold}>{name}</span>
          </Button>
        </Tooltip>
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{created}</TableCell>
      <TableCell>
        <div style={{ display: 'flex' }}>
          <Chip
            variant="outlined"
            size="small"
            label={active ? 'Active' : 'Inactive'}
            className={classes.chip}
            color={active ? 'primary' : 'secondary'}
          />
          <div style={{ marginLeft: 10, alignSelf: 'center' }}>
            <InputField
              inputType="switch"
              checked={active}
              classes={classes}
              color="primary"
              disabled={isUpdatingUserStatus}
              onChange={handleToggleActiveStatus}
            />
          </div>
        </div>
      </TableCell>
    </>
  );
}

export default MembersTableRow;
