import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import LoadingIndicator from './LoadingIndicator';

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    '&:hover': {
      color: `${theme.palette.common.white} !important`,
    },
  },
  active: {
    color: `${theme.palette.common.white} !important`,
  },
  icon: {
    color: `${theme.palette.common.white} !important`,
  },
}))(TableSortLabel);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    whiteSpace: 'nowrap',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useEnhancedTableHeadStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function EnhancedTableHead(props) {
  const classes = useEnhancedTableHeadStyles();
  //useSortable is just a prop to say that we are passing sortable boolean inside headCells. Only bringing this as a prop to avoid changing the code in other places.
  //TODO for future, prefer to pass sortable prop inside headCells which would be more cleaner and enables to sort specific columns.
  const { order, orderBy, onRequestSort, headCells, useSortable } = props;

  const createSortHandler = (property) => (event) => {
    if (onRequestSort && typeof onRequestSort === 'function')
      onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}>
            {typeof useSortable === 'boolean' && !headCell?.sortable ? (
              headCell.label
            ) : (
              <StyledTableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </StyledTableSortLabel>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.defaultProps = {
  headCells: [],
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 'first');
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    // onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    onChangePage(event, 'last');
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.getContrastText(theme.palette.primary.light),
    },
  },
  greyBg: {
    backgroundColor: '#f1f1f1',
    color: theme.palette.getContrastText('#f1f1f1'),
  },
  whiteBg: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.getContrastText(theme.palette.common.white),
  },
  fontBold: {
    fontWeight: 'bold',
  },
  button: {
    padding: '0 3px',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  filterBox: {
    padding: theme.spacing(0, 1, 1),
  },
}));

function TablePaginated({
  tableHeaders,
  tableData,
  searchEnabled,
  onChangeSearchText,
  onSubmitSearch,
  searchText,
  searchPlaceholder,
  searchLabel,
  isLoading,
  rowRenderer,
  sortedHeaders,
  order,
  orderBy,
  rowsPerPage,
  pageNumber,
  totalCount,
  rowsPerPageOptions,
  onRequestSort,
  onChangePage,
  onChangeRowsPerPage,
  emptyTable,
  useSortable,
}) {
  const classes = useStyles2();

  function handleChangePage(event, newPage) {
    if (onChangePage && typeof onChangePage === 'function')
      onChangePage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    const { value } = event.target;

    if (onChangeRowsPerPage && typeof onChangeRowsPerPage === 'function') {
      onChangeRowsPerPage(parseInt(value, 10));
    }
  }

  return (
    <div className={classes.root}>
      {searchEnabled && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
          className={classes.filterBox}>
          <TextField
            id="table-search"
            label={searchLabel || 'Search Data'}
            type="search"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
            placeholder={searchPlaceholder || 'Search table data ...'}
            onInput={onChangeSearchText}
            value={searchText}
            autoComplete="off"
          />
          <Box mt={2} mb={1}>
            <IconButton
              onClick={onSubmitSearch}
              disabled={searchText.length === 0}
              aria-label="Search">
              <SearchIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      <div className={classes.tableWrapper}>
        <Table size="small" className={classes.table}>
          {!sortedHeaders && (
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <StyledTableCell key={header.id}>
                    {header.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          {sortedHeaders && (
            <EnhancedTableHead
              headCells={tableHeaders}
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
              useSortable={useSortable}
            />
          )}
          <TableBody>
            {emptyTable &&
              !isLoading &&
              Array.isArray(tableData) &&
              tableData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={tableHeaders ? tableHeaders.length : 1}>
                    {emptyTable}
                  </TableCell>
                </TableRow>
              )}
            {tableData.slice().map((row, i) => (
              <TableRow
                key={i}
                className={clsx(
                  classes.tableRow,
                  i % 2 === 0 ? classes.greyBg : classes.whiteBg,
                )}>
                {rowRenderer && rowRenderer({ rowData: row })}
              </TableRow>
            ))}
            {/* {isLoading && (
              <TableRow>
                <TableCell colSpan={tableHeaders ? tableHeaders.length : 1}>
                  <Box display="flex" justifyContent="center" flexDirection="row">
                    <LoadingIndicator size={28} />
                  </Box>
                </TableCell>
              </TableRow>
            )} */}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                colSpan={
                  Array.isArray(tableHeaders)
                    ? Math.floor(tableHeaders.length / 2)
                    : 1
                }>
                <Box display="flex" flexDirection="row" alignItems="center">
                  {isLoading ? (
                    <>
                      <Typography component="div" variant="body2">
                        <em>Loading ...</em>
                      </Typography>
                      <Box mr={1} />
                      <LoadingIndicator size={12} />
                    </>
                  ) : (
                    ''
                  )}
                </Box>
              </TableCell>

              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
                colSpan={6}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
}

TablePaginated.defaultProps = {
  tableData: [],
  sortedHeaders: false,
  searchEnabled: true,
  searchText: '',
  searchPlaceholder: '',
  searchLabel: '',
};

export default TablePaginated;
