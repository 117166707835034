import gql from 'graphql-tag';

export const CHECK_EMAIL_EXISTS = gql`
  query CheckEmail($email: EmailAddress!) {
    checkEmail(email: $email)
  }
`;

export const GET_PAGINATED_MEMBERS = gql`
  query PaginatedClients(
    $search: String
    $pageNumber: Int
    $resultsPerPage: Int
    $sortField: UserSort
    $sort: Sort
  ) {
    paginatedClients(
      search: $search
      pageNumber: $pageNumber
      resultsPerPage: $resultsPerPage
      sort_field: $sortField
      sort: $sort
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        id
        FirstName
        LastName
        Email
        subscription {
          type
          status
        }
        CreatedOn
        State
        Postcode
        BirthDate
        Country
        Active
        AgreeMail
      }
    }
  }
`;

export const GET_CSV_MEMBERS = gql`
  query PaginatedClients(
    $search: String
    $pageNumber: Int
    $resultsPerPage: Int
    $sortField: UserSort
    $sort: Sort
  ) {
    paginatedClients(
      search: $search
      pageNumber: $pageNumber
      resultsPerPage: $resultsPerPage
      sort_field: $sortField
      sort: $sort
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        id
        FirstName
        LastName
        Email
        subscription {
          type
          status
        }
        CreatedOn
        State
        Postcode
        BirthDate
        Country
        Active
        AgreeMail
        LastAccess
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query User($userId: ObjectID!) {
    user(user_id: $userId) {
      id
      Email
      FirstName
      LastName
      Organisation {
        id
      }
    }
  }
`;
